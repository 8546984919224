var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    { attrs: { "no-gutters": "" } },
    [
      _c("v-autocomplete", {
        class: { "fd-readonly": _vm.$attrs.readonly },
        style:
          _vm.disabled || _vm.$attrs.readonly ? "pointer-events: none;" : "",
        attrs: {
          outlined: "",
          "hide-selected": "",
          multiple: "",
          label: _vm.openLabel,
          items: _vm.allItems,
          readonly: "",
          disabled: _vm.disabled
        },
        on: {
          click: function($event) {
            if (!_vm.$attrs.readonly) {
              _vm.sheet = true
            }
          }
        },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function({ cAttrs, item }) {
              return [
                _c(
                  "div",
                  _vm._b(
                    {
                      staticClass: "py-2",
                      staticStyle: { "min-width": "100%", display: "flex" }
                    },
                    "div",
                    cAttrs,
                    false
                  ),
                  [
                    _c(
                      "div",
                      { staticStyle: { flex: "0 1", "white-space": "nowrap" } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(item["nameCode"]) +
                            "\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    !_vm.disabled
                      ? _c(
                          "v-icon",
                          {
                            staticClass: "fd-list-item-icon",
                            staticStyle: { flex: "0 1" },
                            on: {
                              click: function($event) {
                                return _vm.removeItem(item)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          mdi-close-circle-outline\n        "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", {
                      staticStyle: { flex: "1 0" },
                      on: {
                        click: function($event) {
                          _vm.sheet = true
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "item",
            fn: function({ cAttrs, item }) {
              return [_vm._v("\n      " + _vm._s(item["nameCode"]) + "\n    ")]
            }
          }
        ]),
        model: {
          value: _vm.value,
          callback: function($$v) {
            _vm.value = $$v
          },
          expression: "value"
        }
      }),
      _vm._v(" "),
      _c(
        "v-navigation-drawer",
        {
          staticStyle: { "max-height": "90%" },
          attrs: {
            bottom: "",
            "mobile-breakpoint": "99999",
            absolute: "",
            height: "auto",
            tag: "div"
          },
          model: {
            value: _vm.sheet,
            callback: function($$v) {
              _vm.sheet = $$v
            },
            expression: "sheet"
          }
        },
        [
          _c(
            "v-sheet",
            [
              _c(
                "v-row",
                { attrs: { justify: "end", height: "68px" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-5 mr-8 mb-3",
                      attrs: { color: "primary" },
                      on: { click: _vm.close }
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.closeLabel) + "\n        "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "py-3" },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "bottom-sheet-control",
                      attrs: { flat: "", tile: "" }
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "mx-4",
                        attrs: {
                          "hide-details": "",
                          loading: _vm.loading,
                          outlined: "",
                          label: _vm.placeholder,
                          autofocus: "",
                          clearable: "",
                          disabled: _vm.disabled
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-data-table", {
                                directives: [
                                  {
                                    name: "fd-column",
                                    rawName: "v-fd-column:nameCode",
                                    value: "IWP",
                                    expression: "'IWP'",
                                    arg: "nameCode"
                                  },
                                  {
                                    name: "fd-column",
                                    rawName: "v-fd-column:include.no-sort",
                                    value: _vm.$t("common.include"),
                                    expression: "$t('common.include')",
                                    arg: "include",
                                    modifiers: { "no-sort": true }
                                  }
                                ],
                                ref: "datatable",
                                class: [
                                  {
                                    "disappear-nicely": !_vm.showTable,
                                    "appear-nicely-less-delay": _vm.showTable
                                  }
                                ],
                                attrs: {
                                  "data-cy": "list",
                                  search: _vm.search,
                                  items: _vm.allItems,
                                  loading: _vm.loading,
                                  "loading-text": _vm.$t(
                                    "common.table-loading-message"
                                  ),
                                  "items-per-page": _vm.itemsPerPage,
                                  "footer-props": {
                                    "items-per-page-options":
                                      _vm.itemsPerPageOptions
                                  },
                                  "mobile-breakpoint": "0"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "item.include",
                                    fn: function({ item }) {
                                      return [
                                        _c("v-simple-checkbox", {
                                          attrs: {
                                            value: _vm.isItemSelected(item),
                                            disabled:
                                              _vm.processing || _vm.disabled
                                          },
                                          on: {
                                            input: function($event) {
                                              return _vm.flipItemSelected(item)
                                            }
                                          }
                                        })
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }