import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import { TranslateResult } from "vue-i18n";

type AdditionalDetailsBottomDialogResult = string | undefined;

const AdditionalDetailsBottomDialog = FDVue.extend({
  name: "fd-additional-details-bottom-dialog",

  mixins: [dialogSupport],

  components: {},

  data() {
    return {
      title: this.$t("common.additional-details") as string | TranslateResult,
      fieldLabel: this.$t("common.additional-details") as string | TranslateResult,
      rules: [] as any[],
      details: "" as string
    };
  },

  computed: {},

  mounted: async function() {},

  methods: {
    async open(
      title?: string | TranslateResult,
      label?: string | TranslateResult,
      rules?: any[],
      mountPoint?: Element | undefined
    ): Promise<AdditionalDetailsBottomDialogResult> {
      console.log(`AdditionalDetailsBottomDialog.open`);
      if (!!title) this.title = title;
      if (!!label) this.fieldLabel = label;
      if (!!rules) this.rules = rules;

      if (await this.showDialog(mountPoint)) return this.details;
      else return undefined;
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    acceptDialog() {
      if (!(this.$refs.form as HTMLFormElement).validate()) return;

      this.closeDialog(true);
    }
  }
});

export default AdditionalDetailsBottomDialog;

export async function showAdditionalDetailsBottomDialog(
  options?: {
    title?: string | TranslateResult | undefined;
    label?: string | TranslateResult | undefined;
    rules?: any[];
  },
  parent?: Vue | Element | null | undefined
): Promise<AdditionalDetailsBottomDialogResult> {
  let dialog = createDialog(AdditionalDetailsBottomDialog);
  dialog.optOutOfErrorHandling();
  let mountPoint = undefined as HTMLElement | undefined;
  if (!!parent) {
    mountPoint = document.createElement("div");
    mountPoint.id = "mountPoint";
    if (!!(parent as Vue)) (parent as Vue).$el.appendChild(mountPoint);
    else if (!!(parent as Element)) (parent as Element).appendChild(mountPoint);
  }
  let result = await dialog.open(options?.title, options?.label, options?.rules, mountPoint);
  console.log(`showAdditionalDetailsBottomDialog done result: ${result}`);
  return result;
}

