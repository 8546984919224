import FDVue from "@fd/lib/vue";
import { mapActions } from "vuex";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import rules from "@fd/lib/vue/rules";
import ServiceError from "@fd/lib/client-util/serviceError";
import { CountSheetGroupWithParts, PartWithTags } from "@fd/current/client/services";
import {
  FDColumnDirective,
  FDTableSortableDirective,
  SortableEvent
} from "@fd/lib/vue/utility/dataTable";
import { SortParts } from "@fd/current/client/dataMixins/countSheetGroupSorting";

const CountSheetGroupNewDialog = FDVue.extend({
  name: "fd-count-sheet-group-new-dialog",

  mixins: [dialogSupport, errorHandling, rules],

  directives: {
    fdColumn: FDColumnDirective,
    fdTableSortable: FDTableSortableDirective
  },

  components: {
    "fd-count-sheet-group-parts-selection-form": () =>
      import("../forms/CountSheetGroupPartsSelectionForm.vue")
  },

  data: function() {
    return {
      step: 1,
      lastStep: 3,

      detailsStep: 1,
      partSelectionStep: 2,
      partOrderStep: 3,

      detailserror: false,
      partserror: false,
      saving: false,

      countSheetGroup: {
        name: ""
      } as CountSheetGroupWithParts,
      parts: [] as PartWithTags[]
    };
  },

  computed: {},

  methods: {
    async open(lastDisplayOrder: number) {
      this.countSheetGroup.order = lastDisplayOrder + 1;
      this.optOutOfErrorHandling();
      return await this.showDialog!();
    },
    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },

    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },

    validate(): boolean {
      this.detailserror = !(this.$refs.detailsform as HTMLFormElement).validate();
      this.partserror = !(this.$refs.partsform as HTMLFormElement).validate();

      return !(this.detailserror || this.partserror);
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    //Method used in conjunction with new view dialog.
    async saveDialog() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!this.validate()) {
        return;
      }
      this.saving = true;
      this.processing = true;
      try {
        this.countSheetGroup.parts = this.parts;
        await this.addCountSheetGroup({
          ...this.countSheetGroup
        });
        this.closeDialog!(true);
      } catch (error) {
        this.handleError(error as ServiceError);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    ...mapActions({
      addCountSheetGroup: "ADD_COUNT_SHEET_GROUP"
    }),
    async partSelected(item: PartWithTags) {
      this.parts.push(item);
      SortParts(this.parts);
      this.parts.forEach((part, index) => (part.countSheetGroupDisplayOrder = index + 1));
    },
    async partDeselected(item: PartWithTags) {
      if (this.parts.includes(item)) this.parts.splice(this.parts.indexOf(item), 1);
    },
    async dragEnded(e: SortableEvent) {
      console.log(`dragEnded`);
      let oldIndex = e.oldIndex ?? 0;
      let newIndex = e.newIndex ?? 0;
      if (oldIndex == newIndex) return;

      this.parts.splice(newIndex, 0, ...this.parts.splice(oldIndex, 1));
      console.log(`Parts Indexes`);
      this.parts.forEach((item, index) => {
        console.log(`  ${item.publicID}: ${item.countSheetGroupDisplayOrder} --> ${index + 1}`);
        item.countSheetGroupDisplayOrder = index + 1;
      });
    }
  },

  created: async function() {
    this.processing = true;
    try {
    } catch (error) {
      this.handleError(error as ServiceError);
    } finally {
      this.processing = false;
    }
  }
});

export default CountSheetGroupNewDialog;

export async function showCountSheetGroupNewDialog(lastDisplayOrder: number): Promise<boolean> {
  let dialog = createDialog(CountSheetGroupNewDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open(lastDisplayOrder);
}

