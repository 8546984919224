export function formatWorkOrderNumber(workOrderNumber: string | number | null | undefined): string {
  // We care about the number of digits/characters, since we want to display at least 5 numbers in the number
  // As such, the easiest way to ensure the number is a string, and check its length
  // If the number is already 5 or more digits, we don't need to do anything.
  // If the number is smaller than 5 digits, pad the left with as many 0's as necessary until it's 5 characters long

  workOrderNumber = `${workOrderNumber}`;
  if (workOrderNumber.length < 5) workOrderNumber = `00000${workOrderNumber}`.slice(-5);
  return "WO-" + workOrderNumber;
}

