import FDVue from "@fd/lib/vue";
import { mapMutations } from "vuex";
import userAccess from "../dataMixins/userAccess";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import tabbedView, { PageTab, Tab } from "@fd/lib/vue/mixins/tabbedView";
import fileHandling, {
  FileData,
  canOpenFileInNewWindow,
  componentsFromFileName,
  confirmUniqueName,
  isFilePhoto,
  isFilePreviewable
} from "@fd/lib/vue/mixins/fileHandling";
import { FDColumnDirective } from "@fd/lib/vue/utility/dataTable";
import {
  ExternalLink,
  Person,
  ProjectLocation,
  ScaffoldDesignStatus,
  ScaffoldDesignWithDetails,
  SummarizedScaffoldDesignPermissions,
  Tag,
  externalLinkService,
  noteService,
  personService,
  projectLocationService,
  scaffoldDesignService,
  ScaffoldDesignEstimateSnapshotWithDetails,
  ScaffoldDesignWorkingComponent,
  ScaffoldSubType,
  ScaffoldType,
  scaffoldDesignWorkingEstimateService,
  scaffoldDesignEstimateSnapshotService,
  walkdownReferenceDataService,
  ScaffoldTypeModifier
} from "../services";
import { Attachment } from "../dataMixins/attachment";
import { openExternalLinkDetails } from "./components/ExternalLinkDialog.vue";
import { showTextPromptDialog } from "../../../common/client/views/components/TextPromptDialog.vue";
import downloadBlob from "@fd/lib/client-util/downloadBlob";
import rules from "@fd/lib/vue/rules";
import * as DateUtil from "@fd/lib/client-util/datetime";
import notes from "../dataMixins/notes";
import { ParseNoteWithSenderDetails } from "../dataMixins/notes";
import { SortNotesArray } from "../dataMixins/notes";
import { GetPersonName, HasName, SortItemsWithName } from "../utils/person";
import { TranslateResult } from "vue-i18n";
import {
  createEstimateComponentModifyDialog,
  createEstimateComponentNewDialog
} from "./components/dialogs/SP.ScaffoldDesignEstimateComponentNewDialog.vue";
import { createWorkingEstimateTakeoffDialogForScaffoldDesign } from "./components/dialogs/SP.WorkingEstimateTakeoffDialog.vue";
import estimates from "../dataMixins/estimates";
import { BasicSelectItem } from "../../../lib/vue/utility/select";

type Keyword = Tag;

export default FDVue.extend({
  name: "fd-scaffold-design-existing",

  mixins: [userAccess, serviceErrorHandling, tabbedView, fileHandling, rules, notes, estimates],

  directives: {
    fdColumn: FDColumnDirective
  },

  components: {
    "fd-chip-selector": () => import("@fd/lib/vue/components/ChipItemSelector.vue"),
    "fd-async-search-box": () => import("@fd/lib/vue/components/AsyncSearchBox.vue"),
    "fd-add-file-button": () => import("@fd/lib/vue/components/AddFileButton.vue"),
    "sp-estimate-summary-table": () => import("./components/estimates/SP.EstimateSummaryTable.vue"),
    "sp-estimates-list": () => import("./components/estimates/SP.EstimatesList.vue")
  },

  data: function() {
    return {
      // *** GLOBAL ***
      slidein: false,

      firstTabKey: `1`,
      detailsTab: new PageTab({
        nameKey: "scaffold-designs.existing.tabs.details",
        key: `1`,
        visible: true,
        error: false
      }),
      photosTab: new PageTab({
        nameKey: "scaffold-designs.existing.tabs.photos",
        key: `2`,
        visible: true,
        error: false
      }),
      attachmentsTab: new PageTab({
        nameKey: "scaffold-designs.existing.tabs.attachments",
        key: "3",
        visible: false,
        error: false
      }),
      estimatesTab: new PageTab({
        nameKey: "scaffold-designs.existing.tabs.estimate",
        key: "4",
        visible: false,
        error: false
      }),
      notesTab: new PageTab({
        nameKey: "scaffold-designs.existing.tabs.notes",
        key: `5`,
        visible: false
      }),
      designToolTab: new PageTab({
        nameKey: "scaffold-designs.existing.tabs.design-tool",
        key: `6`,
        visible: false
      }),

      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,
      cancelling: false,

      /*** DATA ***/
      scaffoldDesignID: "",
      scaffoldDesign: {
        currentUserPermissions: {} as SummarizedScaffoldDesignPermissions,
        scaffoldDesignStatusID: 0
      } as ScaffoldDesignWithDetails,
      scaffoldDesignDetailsAreEditable: true,

      /*** DETAILS ***/
      // KEYWORDS
      selectedKeywords: [] as Keyword[],

      // REFERENCE DATA
      allAreas: [] as ProjectLocation[],
      allSubAreas: [] as ProjectLocation[],
      allDesigners: [] as Person[],
      allReviewers: [] as Person[],
      allManagers: [] as Person[],
      priorityValues: Array.from(Array(5).keys()).map(x => x + 1),
      progressValues: Array.from(Array(101).keys()).filter(x => x % 5 == 0),

      /*** ESTIMATES ****/
      estimatePanel: [0, 1],
      tablesearchestimates: "",
      downloadFileWhenGenerated: false,
      generatingSnapshot: false,
      estimatesList: [] as ScaffoldDesignEstimateSnapshotWithDetails[],
      summaryPanelTimeUnitDivider: 1, // Display all times in the summary table as minutes (initial value)
      workingComponents: [] as ScaffoldDesignWorkingComponent[],

      allScaffoldTypeModifiers: [] as ScaffoldTypeModifier[],
      // allScaffoldDistances: [] as ScaffoldDistanceModifier[],
      // allScaffoldElevations: [] as ScaffoldElevationModifier[],
      // allScaffoldHeights: [] as ScaffoldHeightModifier[],
      // allBuildDismantleRatios: [] as BuildDismantleRatio[],
      // allScaffoldCongestionFactors: [] as ScaffoldCongestionFactor[],
      // allInternalModifiers: [] as InternalModifier[],
      // allHoardingModifiers: [] as HoardingModifier[],

      // *** ATTACHMENTS ***
      touchedFileName: "",
      showPhotoTabAttachmentAlert: false,
      showAttachmentTabPhotoAlert: false,
      tablesearchfiles: "",
      allFiles: [] as FileData[],
      externalLinks: [] as ExternalLink[],

      /*** IMAGE EDIT ****/
      newFileData: undefined as FileData | undefined,
      editingFileData: undefined as FileData | undefined
    };
  },

  computed: {
    // *** GLOBAL ***
    tabDefinitions(): Tab[] {
      // Details is not included since it's the first tab and is always visible
      return [this.photosTab, this.attachmentsTab, this.estimatesTab, this.notesTab] as Tab[];
    },
    scaffoldDesignRules(): any {
      return {
        areaID: [this.rules.required],
        subAreaID: [this.rules.required],
        siteContact: [],
        specificWorkLocation: [],
        detailedWorkDescription: []
      };
    },
    selectableStatuses(): any[] {
      return (Object.keys(ScaffoldDesignStatus)
        .filter(x => !isNaN(Number(x)))
        .map(x => Number(x)) as number[]).map(x => {
        return {
          value: x,
          text: this.$t(`scaffold-designs.status.${x}`)
        };
      });
    },
    scaffoldDesignIsCancelled(): boolean {
      return this.scaffoldDesign.scaffoldDesignStatusID == ScaffoldDesignStatus.Cancelled;
    },
    scaffoldDesignIsOnHold(): boolean {
      return this.scaffoldDesign.scaffoldDesignStatusID == ScaffoldDesignStatus.OnHold;
    },
    currentUserCanEditDetails(): boolean {
      return this.scaffoldDesign.currentUserPermissions.canEditDetails;
    },
    scaffoldDesignIsEditableAndUserHasPermissions(): boolean {
      return this.currentUserCanEditDetails && this.scaffoldDesignDetailsAreEditable;
    },
    // *** DETAILS ***
    availableKeywords(): Keyword[] {
      return this.$store.getters.sortedEnabledTags;
    },
    allKeywords(): Keyword[] {
      return this.$store.state.tags.fullList as Keyword[];
    },
    subAreas(): ProjectLocation[] {
      let areaID = this.scaffoldDesign.areaID;
      if (!areaID) return [];

      let subAreas = this.allSubAreas.filter(
        x => !!x.parentLocationID && x.parentLocationID == areaID
      );
      // if (
      //   this.scaffoldDesign.currentUserPermissions.canEditArea &&
      //   !this.readonly &&
      //   subAreas.length == 1 &&
      //   !this.scaffoldDesign.subAreaID
      // ) {
      //   this.$nextTick(() => {
      //     this.scaffoldDesign.subAreaID = subAreas[0].id;
      //   });
      // }
      return subAreas;
    },

    // *** ESTIMATE ***
    scaffoldTypes(): (BasicSelectItem & { subTypes: BasicSelectItem[] })[] {
      var usedScaffoldTypeIDs = this.allScaffoldTypeModifiers.map(x => x.scaffoldTypeID ?? -1);
      var filteredScaffoldTypes = this.allScaffoldTypes.filter(x =>
        usedScaffoldTypeIDs.includes(x.value)
      );

      var visibleScaffoldTypes = [] as (BasicSelectItem & { subTypes: BasicSelectItem[] })[];
      for (let scaffoldType of filteredScaffoldTypes) {
        let subTypes = this.activeScaffoldSubTypesForType(scaffoldType.value);
        if (!subTypes.length) continue;

        visibleScaffoldTypes.push({
          ...scaffoldType,
          subTypes: subTypes
        });
      }

      return visibleScaffoldTypes;
    },
    canGenerateSnapshot(): boolean {
      return (
        (this.workingComponents as any[]).findIndex(
          x => !!x.isNew || !!x.isModified || !!x.isRemoved
        ) !== -1
      );
    },

    // *** PHOTOS ***
    photoFiles(): FileData[] {
      return this.allFiles.filter(x => x.isPhoto);
    },

    // *** ATTACHMENTS ***
    nonPhotoAttachments(): Attachment[] {
      let attachments = [] as Attachment[];

      this.allFiles.forEach(file => {
        attachments.push({
          type: "file",
          name: file.name,
          isPhoto: file.isPreviewable ?? false,
          isPreviewable: file.isPreviewable ?? false,
          canOpenInNew: canOpenFileInNewWindow(file.name),
          file: file
        });
      });

      this.externalLinks.forEach(link => {
        attachments.push({
          type: "link",
          name: link.name!,
          isPhoto: false,
          isPreviewable: false,
          canOpenInNew: true,
          link: link
        });
      });

      return attachments.filter(x => !x.isPhoto);
    }
  },

  methods: {
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),

    onSubmit(e: Event) {
      e.preventDefault();
      this.save(false);
    },

    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },
    cancel() {
      this.$router.back();
    },

    async save(closeOnComplete: boolean) {
      this.processing = true;
      this.saving = true;
      try {
        this.scaffoldDesign.coordX = isNaN(Number(this.scaffoldDesign.coordX))
          ? null
          : Number(this.scaffoldDesign.coordX);
        this.scaffoldDesign.coordY = isNaN(Number(this.scaffoldDesign.coordY))
          ? null
          : Number(this.scaffoldDesign.coordY);
        this.scaffoldDesign.coordZ = isNaN(Number(this.scaffoldDesign.coordZ))
          ? null
          : Number(this.scaffoldDesign.coordZ);
        let tagIDs =
          this.selectedKeywords.length > 0 ? this.selectedKeywords.map(x => x.id!) : undefined;

        await scaffoldDesignService.updateItem(
          this.scaffoldDesign.id!,
          {
            ...this.scaffoldDesign,
            tagIDs: tagIDs
          } as ScaffoldDesignWithDetails,
          "ScaffoldDesignExisting.save"
        );

        var snackbarPayload = {
          text: this.$t("scaffold-designs.save-success", [this.scaffoldDesign.scaffoldNumber]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        if (closeOnComplete) {
          this.$router.push("/scaffolddesigns");
        } else {
          await this.loadScaffoldDesignDetails();
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    // *** LOADING ***
    async loadScaffoldDesignDetails() {
      var scaffoldDesign = await scaffoldDesignService.getByID(this.scaffoldDesignID);
      this.scaffoldDesign = scaffoldDesign;
      this.scaffoldDesignDetailsAreEditable =
        scaffoldDesign.scaffoldDesignStatusID == ScaffoldDesignStatus.New ||
        scaffoldDesign.scaffoldDesignStatusID == ScaffoldDesignStatus.Started ||
        scaffoldDesign.scaffoldDesignStatusID == ScaffoldDesignStatus.OnHold ||
        scaffoldDesign.scaffoldDesignStatusID == ScaffoldDesignStatus.Declined;
    },

    async loadPeople() {
      var allPeople = SortItemsWithName(
        (await personService.getAllDesignPersonnel()).map(
          p =>
            ({
              ...p,
              name: GetPersonName(p)
            } as Person & HasName)
        )
      );
      this.allDesigners = allPeople.filter(x => !!x.isDesigner);
      this.allReviewers = allPeople.filter(x => !!x.isDesigner || !!x.isDesignManager);
      this.allManagers = allPeople.filter(x => !!x.isDesignManager);
    },

    // DOES NOT manage processing or error message logic
    async loadAreas(): Promise<void> {
      let areas = await projectLocationService.getVisibleAreas();
      this.allAreas = SortItemsWithName(areas);
    },

    // DOES NOT manage processing or error message logic
    async loadSubAreas(): Promise<void> {
      let subAreas = await projectLocationService.getVisibleSubAreas();
      this.allSubAreas = SortItemsWithName(subAreas);
    },

    // DOES NOT manage processing or error message logic
    async loadScaffoldTypeModifiers(): Promise<void> {
      this.allScaffoldTypeModifiers = SortItemsWithName(
        await walkdownReferenceDataService.getAllScaffoldTypeModifiers()
      );
    },

    // *** ATTACHMENTS ***
    // Attachments - Catch the generic "Attachment" objects and pass along to link or file-specific actions
    async openAttachment(item: Attachment) {
      if (!item.canOpenInNew) return;

      if (!!item.file && item.canOpenInNew) {
        await this.openFileInNewWindow(item.file);
      } else if (!!item.link) {
        let url = item.link.address;
        window.open(url, "_blank");
      }
    },
    async editAttachment(item: Attachment) {
      if (!!item.link) {
        await this.editLink(item.link);
      } else if (!!item.file && item.file.isPreviewable) {
        await this.editFile(item.file);
      } else if (!!item.file) {
        await this.editNameForFile(item.file);
      }
    },
    async deleteAttachment(item: Attachment) {
      if (!!item.link) {
        await this.deleteLink(item.link);
      } else if (!!item.file) {
        await this.deleteFile(item.file);
      }
    },

    // Links
    async loadLinks() {
      let currentProcessing = this.processing;
      this.processing = true;
      try {
        var links = await externalLinkService.getByScaffoldDesignID(this.scaffoldDesign.id!);
        this.externalLinks = links;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = currentProcessing;
      }
    },
    // Method to open the dialog for when the user wishes to add a new External Link.
    async openNewExternalLinkDialog() {
      let newLink = await openExternalLinkDetails();
      if (!!newLink) {
        await this.saveNewExternalLink(newLink);
      }
    },
    async saveNewExternalLink(newLink: ExternalLink) {
      let currentProcessing = this.processing;
      this.processing = true;
      try {
        newLink.scaffoldDesignID = this.scaffoldDesign.id;
        await externalLinkService.addItem(newLink);

        if (!this.externalLinks) this.externalLinks = [];
        this.externalLinks.push(newLink);

        this.showAttachmentTabPhotoAlert = false;
        this.showPhotoTabAttachmentAlert = false;

        var snackbarPayload = {
          text: this.$t("scaffold-designs.existing.save-link-success", [newLink.name]),
          type: "success"
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.touchedFileName = newLink.name ?? "";
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = currentProcessing;
      }
    },
    async editLink(link: ExternalLink) {
      let editedLink = await openExternalLinkDetails(link);
      if (!!editedLink) {
        let currentProcessing = this.processing;
        this.processing = true;
        try {
          await externalLinkService.updateItem(link.id!, {
            ...link,
            name: editedLink.name,
            address: editedLink.address
          });
          link.name = editedLink.name;
          link.address = editedLink.address;

          var snackbarPayload = {
            text: this.$t("scaffold-designs.existing.update-link-success", [link.name]),
            type: "success"
          };
          this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
          this.touchedFileName = link.name ?? "";
        } catch (error) {
          this.handleError(error as Error);
        } finally {
          this.processing = currentProcessing;
        }
      }
    },
    async deleteLink(link: ExternalLink) {
      let currentProcessing = this.processing;
      this.processing = true;
      try {
        await externalLinkService.deleteItem(link.id!);
        this.externalLinks.splice(this.externalLinks.indexOf(link), 1);

        var snackbarPayload = {
          text: this.$t("scaffold-designs.existing.delete-link-success", [link.name]),
          type: "info",
          undoCallback: async () => {
            await this.saveNewExternalLink(link);
          }
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.touchedFileName = link.name ?? "";
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = currentProcessing;
      }
    },

    // Files & Photos
    async loadFiles() {
      if (!this.scaffoldDesign.id) return;

      var fileNames = await scaffoldDesignService.getScaffoldDesignFileList(this.scaffoldDesign.id);
      this.allFiles = fileNames.map(function(fileName) {
        return {
          name: fileName,
          isPreviewable: isFilePreviewable(fileName),
          isPhoto: isFilePhoto(fileName)
        } as FileData;
      });
    },
    async selectFile() {
      (this.$refs.addFileButton as any).click();
    },
    async selectNewFile(originalFile: File) {
      this.processing = true;
      var fileData = await this.optimizedFileDataForUpload(originalFile, this.allFiles);
      this.processing = false;
      if (!fileData) return;

      // GIF files with animations will lose their animation during this process
      // Both due to the quality compression done above (resizing the dimensions of an animated GIF does nothing), and also going through the edit image process
      // This is OK as we shouldn't need animations for any reason
      if (fileData.isPreviewable) {
        this.newFileData = fileData;
        this.imageName = fileData.name;
        this.editImageSource = this.covertFileToDataURL(fileData.file);
      } else {
        await this.saveNewFileData(fileData);
      }
    },
    async handleEdit(res: File, fileName: string | undefined) {
      this.editImageSource = undefined;
      this.imageName = "";

      if (!!this.newFileData) {
        this.newFileData.file = res;
        if (!!fileName) this.newFileData.name = confirmUniqueName(fileName, this.allFiles);

        await this.saveNewFileData(this.newFileData);

        this.newFileData = undefined;
      } else if (!!this.editingFileData) {
        var originalFileName = this.editingFileData.name;

        var allFilesWithoutEditedFileData = this.allFiles.slice();
        allFilesWithoutEditedFileData.splice(
          allFilesWithoutEditedFileData.indexOf(this.editingFileData),
          1
        );
        var uniqueFileName = confirmUniqueName(
          fileName ?? originalFileName,
          allFilesWithoutEditedFileData
        );

        this.editingFileData.name = uniqueFileName;
        this.editingFileData.file = res;

        this.saveEditedFileData(this.editingFileData, originalFileName);

        this.editingFileData = undefined;
      }
    },
    async saveEditedFileData(fileData: FileData, originalFileName: string) {
      if (!fileData) return;
      if (!this.scaffoldDesign.id) return;

      this.processing = true;
      try {
        if (!fileData.file) {
          // If we're only renaming the file, the data may not be downloaded yet
          let fileNameToDownload = originalFileName ?? fileData.name;
          fileData.file = await scaffoldDesignService.downloadScaffoldDesignFile(
            this.scaffoldDesign.id,
            fileNameToDownload
          );
        }
        await scaffoldDesignService.uploadScaffoldDesignFile(
          this.scaffoldDesign.id,
          fileData.name,
          fileData.file as Blob
        );

        if (!!originalFileName && originalFileName != fileData.name) {
          // File has been renamed.  The file in the list has already been updated with all relevant data, but we need to delete the file with the old name
          // We don't call the delete method here because we don't care about its data, an undo, or a delete snackbar
          await scaffoldDesignService.deleteScaffoldDesignFile(
            this.scaffoldDesign.id,
            originalFileName
          );
        }
        let snackbarText = fileData.isPhoto
          ? this.$t("scaffold-designs.existing.update-photo-success", [fileData.name])
          : this.$t("scaffold-designs.existing.update-file-success", [fileData.name]);
        let snackbarPayload = {
          text: snackbarText,
          type: "success"
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.touchedFileName = fileData.name;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async saveNewFileData(fileData: FileData | undefined) {
      if (!fileData) return;
      if (!this.scaffoldDesign.id) return;

      this.processing = true;
      try {
        await scaffoldDesignService.uploadScaffoldDesignFile(
          this.scaffoldDesign.id,
          fileData.name,
          fileData.file as Blob
        );

        this.allFiles.push(fileData);

        let snackbarText = fileData.isPhoto
          ? this.$t("scaffold-designs.existing.save-photo-success", [fileData.name])
          : this.$t("scaffold-designs.existing.save-file-success", [fileData.name]);
        let snackbarPayload = {
          text: snackbarText,
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        this.touchedFileName = fileData.name;
        this.showPhotoTabAttachmentAlert = this.selectedTab == this.photosTab && !fileData.isPhoto;
        this.showAttachmentTabPhotoAlert =
          this.selectedTab == this.attachmentsTab && fileData.isPhoto == true;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async editNameForFile(fileData: FileData) {
      let components = componentsFromFileName(fileData.name);
      let newName = await showTextPromptDialog({
        title: this.$t("attachments.edit-file-name-title"),
        label: this.$t("common.name"),
        rules: [this.rules.required],
        text: components.name
      });
      if (!!newName?.length && newName.toLowerCase() != components.name.toLowerCase()) {
        let newFileName = `${newName}.${components.extension}`;
        var originalFileName = fileData.name;
        if (newFileName.toLowerCase() == originalFileName.toLowerCase()) return;

        var uniqueFileName = confirmUniqueName(newFileName, this.allFiles);

        fileData.name = uniqueFileName;
        this.saveEditedFileData(fileData, originalFileName);

        this.editingFileData = undefined;
      }
    },
    editFile(fileData: FileData) {
      if (!fileData.isPhoto) return;

      this.editingFileData = fileData;
      this.imageName = fileData.name;
      if (!!fileData.file) {
        this.editImageSource = this.covertFileToDataURL(fileData.file);
      } else {
        this.editImageSource = `/services/FormidableDesigns.Services.V1.ScaffoldDesignService.DownloadScaffoldDesignFile?designId=${this.scaffoldDesign.id}&fileName=${fileData.name}`;
      }
    },
    async downloadFile(fileData: FileData) {
      if (!!fileData.file) {
        downloadBlob(fileData.file, fileData.name);
        return;
      }
      if (!this.scaffoldDesign.id) return;

      let fileName = fileData.name;
      this.processing = true;
      try {
        var file = await scaffoldDesignService.downloadScaffoldDesignFile(
          this.scaffoldDesign.id!,
          fileName
        );
        downloadBlob(file, fileName);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async openFileInNewWindow(fileData: FileData) {
      if (!this.scaffoldDesign.id) return;

      let currentProcessing = this.processing;
      this.processing = true;
      if (!fileData.file) {
        // the data probably hasn't been downloaded yet
        fileData.file = await scaffoldDesignService.downloadScaffoldDesignFile(
          this.scaffoldDesign.id,
          fileData.name
        );
      }
      let url = URL.createObjectURL(fileData.file);
      window.open(url, "_blank");
      this.processing = currentProcessing;
    },
    async viewFile(fileData: FileData) {
      if (!fileData.isPreviewable) return;
      if (!this.scaffoldDesign.id) return;

      this.imageName = fileData.name;
      if (!fileData.file) {
        // Cache the file data to avoid having to download it multiple times
        var file = await scaffoldDesignService.downloadScaffoldDesignFile(
          this.scaffoldDesign.id!,
          fileData.name
        );
        fileData.file = file;
      }
      if (!!fileData.file) {
        this.imageSource = this.covertFileToDataURL(fileData.file);
      } else {
        this.imageSource = `/services/FormidableDesigns.Services.V1.ScaffoldDesignService.DownloadScaffoldDesignFile?designId=${this.scaffoldDesign.id}&fileName=${fileData.name}`;
      }
    },
    async deleteFile(fileData: FileData) {
      if (!this.scaffoldDesign.id) return;

      this.processing = true;
      try {
        if (!fileData.file) {
          // When deleting from the table, the data probably hasn't been downloaded yet
          // So we can't do an undo unless we get the file data to re-save first
          fileData.file = await scaffoldDesignService.downloadScaffoldDesignFile(
            this.scaffoldDesign.id!,
            fileData.name
          );
        }
        await scaffoldDesignService.deleteScaffoldDesignFile(
          this.scaffoldDesign.id!,
          fileData.name
        );

        this.allFiles.splice(this.allFiles.indexOf(fileData), 1);

        let snackbarText = fileData.isPhoto
          ? this.$t("scaffold-designs.existing.delete-photo-success", [fileData.name])
          : this.$t("scaffold-designs.existing.delete-file-success", [fileData.name]);
        var snackbarPayload = {
          text: snackbarText,
          type: "info",
          undoCallback: async () => {
            await this.saveNewFileData(fileData);
          }
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        this.touchedFileName = fileData.name;
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    /*** ESTIMATES ***/
    async loadEstimateHistory() {
      var estimatesList = await scaffoldDesignEstimateSnapshotService.getEstimateSnapshotsForScaffoldDesignWithID(
        this.scaffoldDesignID
      );
      this.estimatesList = estimatesList
        .sort((a, b) => {
          // Sort with newest at top
          let aCreated = new Date(a.created!);
          let bCreated = new Date(b.created!);
          return bCreated.getTime() - aCreated.getTime();
        })
        .map(e => ({
          ...e,
          dateTimeString: DateUtil.localizedDateTimeString(
            new Date(DateUtil.isoDateTimeString(e.created))
          ),
          dateString: DateUtil.stripTimeFromLocalizedDateTime(
            new Date(DateUtil.isoDateTimeString(e.created))
          ),

          estimatedTotalTime:
            (e.estimatedTotalDemobilizationMinutes ?? 0.0) +
            (e.estimatedTotalDismantleMinutes ?? 0.0) +
            (e.estimatedTotalErectMinutes ?? 0.0) +
            (e.estimatedTotalHoardingMinutes ?? 0.0) +
            (e.estimatedTotalMobilizationMinutes ?? 0.0) +
            (e.estimatedTotalModifyMinutes ?? 0.0),
          estimatedErectMPP:
            (e.estimatedTotalErectMinutes ?? 0.0) / (e.estimatedTotalPartCount ?? 1),
          estimatedDismantleMPP:
            (e.estimatedTotalDismantleMinutes ?? 0.0) / (e.estimatedTotalPartCount ?? 1)
        }));
    },
    async loadWorkingData() {
      this.processing = true;
      try {
        await Promise.all([this.loadWorkingComponents()]);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async loadWorkingComponents() {
      this.workingComponents = await scaffoldDesignWorkingEstimateService.getWorkingComponentsForScaffoldDesign(
        this.scaffoldDesignID
      );
    },
    activeScaffoldSubTypesForType(
      scaffoldTypeID: ScaffoldType
    ): { text: string | TranslateResult; value: number }[] {
      var usedScaffoldSubTypeIDs = this.allScaffoldTypeModifiers
        .filter(
          x => !!x.isActive && x.scaffoldSubTypeID != null && x.scaffoldSubTypeID != undefined
        )
        .map(x => x.scaffoldSubTypeID!);
      if (!usedScaffoldSubTypeIDs.length) return [];

      var allScaffoldSubTypesForType = this.allScaffoldSubTypesForType(scaffoldTypeID);
      return allScaffoldSubTypesForType
        .filter(x => usedScaffoldSubTypeIDs.includes(x.value))
        .map(st => {
          let existingTypeModifier = this.allScaffoldTypeModifiers.find(
            mod => mod.scaffoldSubTypeID != null && mod.scaffoldSubTypeID == st.value
          );
          return {
            text: existingTypeModifier?.name ?? st.text,
            value: st.value
          };
        });
    },
    async downloadEstimateExcelFile(estimateID: string | null | undefined) {
      if (!estimateID?.length) estimateID = this.currentEstimate.id!;
      this.processing = true;
      try {
        var blob = await scaffoldDesignEstimateSnapshotService.downloadScaffoldDesignEstimateGeneratedExcelFile(
          estimateID!
        );
        let tag = `T${`00000${this.scaffoldDesign.scaffoldNumber}`.slice(-5)}`;
        downloadBlob(blob, `scaffold-design-estimate-${tag}.xlsx`);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async openNewDialog(scaffoldTypeID: ScaffoldType, scaffoldSubTypeID: ScaffoldSubType) {
      let existingTypeModifier = this.allScaffoldTypeModifiers.find(
        x =>
          x.scaffoldTypeID == scaffoldTypeID &&
          x.scaffoldSubTypeID != null &&
          x.scaffoldSubTypeID == scaffoldSubTypeID
      );
      console.log(
        `ScaffoldDesignEstimateComponents.openNewDialog scaffoldTypeID: ${scaffoldTypeID}, scaffoldSubTypeID: ${scaffoldSubTypeID}, existingTypeModifier: ${existingTypeModifier}`
      );
      if (!existingTypeModifier?.id?.length) return;

      if (
        await createEstimateComponentNewDialog(
          this.scaffoldDesignID,
          existingTypeModifier.id,
          scaffoldTypeID,
          scaffoldSubTypeID
        )
      ) {
        this.loadWorkingData();
      }
    },

    async editItem(item: ScaffoldDesignWorkingComponent) {
      console.log(`ScaffoldDesignEstimateComponents.editItem`);
      // Spread the item into a new obj so the dialog doesn't edit the actual item, which allows the user to cancel their changes
      if (
        await createEstimateComponentModifyDialog(this.scaffoldDesignID, {
          ...item
        } as ScaffoldDesignWorkingComponent)
      ) {
        this.loadWorkingData();
      }
    },
    async reAddItem(item: ScaffoldDesignWorkingComponent) {
      console.log(`ScaffoldDesignEstimateComponents.reAddItem`);
      // Spread the item into a new obj so the dialog doesn't edit the actual item, which allows the user to cancel their changes
      if (
        await createEstimateComponentModifyDialog(this.scaffoldDesignID, {
          ...item
        } as ScaffoldDesignWorkingComponent)
      ) {
        this.loadWorkingData();
      }
    },
    async deleteItem(item: ScaffoldDesignWorkingComponent) {
      console.log(`ScaffoldDesignEstimateComponents.deleteItem`);

      if (await scaffoldDesignWorkingEstimateService.removeWorkingComponent(item.id!)) {
        this.loadWorkingData();
      }
    },
    async openWorkingTakeoffDialog() {
      await createWorkingEstimateTakeoffDialogForScaffoldDesign(this.scaffoldDesignID);
    },
    async generateSnapshot() {
      this.processing = true;
      this.generatingSnapshot = true;
      try {
        var blob = await scaffoldDesignEstimateSnapshotService.generateAndUploadNewEstimateSnapshotForScaffoldDesignWithID(
          this.scaffoldDesignID,
          this.downloadFileWhenGenerated
        );
        if (this.downloadFileWhenGenerated && !!blob) {
          let tag = `T${`00000${this.scaffoldDesign.scaffoldNumber}`.slice(-5)}`;
          downloadBlob(blob, `scaffold-design-estimate-${tag}.xlsx`);
        }
        await Promise.all([this.loadWorkingData(), this.loadEstimateHistory()]);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.generatingSnapshot = false;
      }
    },

    /*** NOTES ***/
    async addNewNote() {
      if (!this.newNoteText.length) return;

      this.processing = true;
      this.saving = true;
      try {
        var newNote = await noteService.addNewNoteForScaffoldDesign(
          this.newNoteText,
          this.scaffoldDesign.id!
        );
        this.inlineMessage.message = "";
        let noteToAdd = ParseNoteWithSenderDetails(newNote);
        noteToAdd.isNew = true;
        this.notes.push(noteToAdd);
        this.notes = SortNotesArray(this.notes);
        this.newNoteText = "";
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    }
  },

  watch: {
    scaffoldDesign: function(newValue) {
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/scaffolddesigns") {
        this.notifyNewBreadcrumb({
          text: this.$t("scaffold-designs.list.title"),
          to: "/scaffolddesigns",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }
      this.notifyNewBreadcrumb({
        text: `T-${`00000${this.scaffoldDesign.scaffoldNumber}`.slice(-5)}`,
        to: `/scaffolddesigns/${this.$route.params.id}`
      });
    }
  },

  created: async function() {
    this.setFilteringContext({
      context: "scaffold-design-existing",
      parentalContext: "scaffold-designs",
      selectedTab: this.firstTabKey
    });

    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/scaffolddesigns") {
      this.notifyNewBreadcrumb({
        text: this.$t("scaffold-designs.list.title"),
        to: "/scaffolddesigns",
        resetHistory: true
      });
      // This is needed in order to salvage the "last breadcrumbs" in the store.
      this.$store.commit("NOTIFY_NAVIGATION_STARTED");
    }

    this.notifyNewBreadcrumb({
      text: this.$t("loading-dot-dot-dot"),
      disabled: true
    });

    this.scaffoldDesignID = this.$route.params.id;

    this.processing = true;
    try {
      await this.$store.dispatch("LOAD_TAGS");
      await Promise.all([
        // this.loadCoordinators(),
        // this.loadGeneralForemen(),
        // this.loadForemen(),
        this.loadPeople(),
        this.loadAreas(),
        this.loadSubAreas(),
        // this.loadCostCodes(),
        // this.loadScaffoldBayHeights(),
        // this.loadScaffoldBayLengths(),
        // this.loadScaffoldBayWidths(),
        this.loadScaffoldTypeModifiers()
        // this.loadScaffoldHeights(),
        // this.loadScaffoldElevations(),
        // this.loadScaffoldDistances(),
        // this.loadScaffoldCongestionFactors(),
        // this.loadBuildDismantleRatios(),
        // this.loadInternalModifiers(),
        // this.loadHoardingModifiers()
      ]);
      // processing has been set to false after the reference data loaded.
      this.processing = true;

      await this.loadScaffoldDesignDetails();
      this.processing = true;

      await Promise.all([this.loadWorkingData(), this.loadEstimateHistory()]);
      this.processing = true;

      let notes = await noteService.getForScaffoldDesign(this.scaffoldDesign.id!);
      this.notes = notes.map(x => ParseNoteWithSenderDetails(x));
      if (!!this.scaffoldDesign.notes?.length) {
        this.notes.push({
          isPinned: true,
          isNew: false,
          initials: "",
          name: `${this.$t("scaffold-requests.notes")}`,
          role: "",
          date: "",
          time: "",
          text: this.scaffoldDesign.notes,
          sender: undefined,
          id: undefined,
          noteThreadID: undefined,
          personID: undefined,
          sentTime: new Date(0),
          archivedDate: undefined,
          created: undefined,
          createdBy: undefined,
          updated: undefined,
          updatedBy: undefined
        });
      }
      this.notes = SortNotesArray(this.notes);

      await this.loadFiles();
      await this.loadLinks();
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

