var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "1024px", persistent: "" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              class: [
                _vm.processing
                  ? "dialog-new-top-color-processing"
                  : "dialog-new-top-color"
              ]
            },
            [
              _c(
                "span",
                {
                  class: [_vm.processing ? "breadcrumb-processing-opacity" : ""]
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("common.attachments")) +
                        " -\n          " +
                        _vm._s(
                          _vm.$t("to-do-list.work-order-number", [
                            _vm.workOrder.internalNumber
                          ])
                        )
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.processing
                ? _c("v-progress-circular", {
                    attrs: {
                      indeterminate: true,
                      rotate: 0,
                      size: 32,
                      width: 4,
                      color: "white"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mx-2", attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "12" } },
                [
                  _c(
                    "v-card-title",
                    [
                      _c("input", {
                        ref: "addFileButton",
                        staticClass: "d-none",
                        attrs: {
                          type: "file",
                          onclick: "this.value=null;",
                          disabled: _vm.processing || !_vm.allowAdd
                        },
                        on: {
                          change: v => _vm.selectNewFile(v.target.files[0])
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "v-menu",
                        {
                          attrs: { "offset-y": "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function({ on }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "add-fab-button-primary",
                                        attrs: {
                                          fab: "",
                                          elevation: "5",
                                          depressed:
                                            !_vm.allowAdd || _vm.processing,
                                          disabled:
                                            !_vm.allowAdd || _vm.processing,
                                          color: "new"
                                        }
                                      },
                                      on
                                    ),
                                    [_c("v-icon", [_vm._v("mdi-plus")])],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item",
                                {
                                  on: { click: _vm.openNewExternalLinkDialog }
                                },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "attachments.external-link.menu-title"
                                        )
                                      )
                                    )
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { on: { click: _vm.selectFile } },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(_vm.$t("common.local-file")))
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-divider", {
                        staticClass: "mx-4",
                        attrs: { inset: "", vertical: "" }
                      }),
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function({ on }) {
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      {
                                        staticClass: "ml-1",
                                        attrs: { color: "black", dark: "" }
                                      },
                                      on
                                    ),
                                    [_vm._v("info")]
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("users.files-table-listing-tooltip")
                              )
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("v-text-field", {
                        staticClass: "pl-0",
                        attrs: {
                          "append-icon": "search",
                          label: _vm.$t("common.search"),
                          "single-line": "",
                          "hide-details": "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.tablesearchfiles,
                          callback: function($$v) {
                            _vm.tablesearchfiles = $$v
                          },
                          expression: "tablesearchfiles"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("fd-alert", {
                    staticClass: "mx-3",
                    attrs: {
                      value: !!_vm.newSavedPhotoName,
                      type: "info",
                      label: _vm.$t("attachments.file-is-photo-alert-message", [
                        _vm.newSavedPhotoName.toUpperCase()
                      ]),
                      dismissible: ""
                    }
                  }),
                  _vm._v(" "),
                  _c("v-data-table", {
                    directives: [
                      {
                        name: "fd-column",
                        rawName:
                          "v-fd-column:icon.no-sort.hide-when-extra-small.class_fd-table-icon-cell",
                        value: "",
                        expression: "''",
                        arg: "icon",
                        modifiers: {
                          "no-sort": true,
                          "hide-when-extra-small": true,
                          "class_fd-table-icon-cell": true
                        }
                      },
                      {
                        name: "fd-column",
                        rawName: "v-fd-column:name",
                        value: _vm.$t("common.name"),
                        expression: "$t('common.name')",
                        arg: "name"
                      },
                      {
                        name: "fd-column",
                        rawName:
                          "v-fd-column:action.no-sort.class_fd-actions-cell-edit-only.hide-when-header-text-empty",
                        value:
                          _vm.actionCount == 1 ? _vm.$t("common.action") : "",
                        expression:
                          "\n            actionCount == 1 ? $t('common.action') : ''\n          ",
                        arg: "action",
                        modifiers: {
                          "no-sort": true,
                          "class_fd-actions-cell-edit-only": true,
                          "hide-when-header-text-empty": true
                        }
                      },
                      {
                        name: "fd-column",
                        rawName:
                          "v-fd-column:actions.no-sort.class_fd-actions-cell.hide-when-header-text-empty",
                        value:
                          _vm.actionCount > 1 ? _vm.$t("common.actions") : "",
                        expression:
                          "\n            actionCount > 1 ? $t('common.actions') : ''\n          ",
                        arg: "actions",
                        modifiers: {
                          "no-sort": true,
                          "class_fd-actions-cell": true,
                          "hide-when-header-text-empty": true
                        }
                      }
                    ],
                    class: _vm.$vuetify.breakpoint.xsOnly
                      ? "fd-action-table"
                      : "fd-actions-table",
                    attrs: {
                      items: _vm.items,
                      search: _vm.tablesearchfiles,
                      loading: _vm.processing,
                      "loading-text": _vm.$t("common.table-loading-message"),
                      "sort-by": ["name"],
                      "mobile-breakpoint": "0",
                      "hide-default-footer":
                        !_vm.items || _vm.items.length < 10,
                      "footer-props": { "items-per-page-options": [10] },
                      "items-per-page": 10
                    },
                    on: {
                      "dblclick:row": (e, data) => _vm.openAttachment(data.item)
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item.icon",
                        fn: function({ item }) {
                          return [
                            item.type == "link"
                              ? _c("v-icon", [_vm._v("fa-link")])
                              : item.type == "file" && item.isPhoto
                              ? _c("v-icon", [_vm._v("fa-camera")])
                              : _c("v-icon", [_vm._v("fa-file")])
                          ]
                        }
                      },
                      {
                        key: "item.action",
                        fn: function({ item }) {
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: {
                                  disabled:
                                    !_vm.canOpenInNewTab ||
                                    !_vm.$vuetify.breakpoint.lgAndUp ||
                                    _vm.processing,
                                  left: ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function({ on }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  icon: "",
                                                  disabled:
                                                    !_vm.canOpenInNewTab ||
                                                    !item.canOpenInNew ||
                                                    _vm.processing
                                                }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: { small: "" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.openAttachment(
                                                        item
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    mdi-open-in-new\n                  "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("common.open-in-new-tab"))
                                  )
                                ])
                              ]
                            )
                          ]
                        }
                      },
                      {
                        key: "item.actions",
                        fn: function({ item }) {
                          return [
                            _c(
                              "div",
                              { class: [_vm.actionColumnClass] },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: {
                                      disabled:
                                        !_vm.canOpenInNewTab ||
                                        !_vm.$vuetify.breakpoint.lgAndUp ||
                                        _vm.processing,
                                      left: ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function({ on }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      icon: "",
                                                      disabled:
                                                        !_vm.canOpenInNewTab ||
                                                        !item.canOpenInNew ||
                                                        _vm.processing
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { small: "" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.openAttachment(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      mdi-open-in-new\n                    "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("common.open-in-new-tab"))
                                      )
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.canPreview
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: {
                                          disabled:
                                            !_vm.$vuetify.breakpoint.lgAndUp ||
                                            _vm.processing,
                                          left: ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function({ on }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          disabled:
                                                            !item.isPreviewable ||
                                                            _vm.processing
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.viewFile(
                                                                item.file
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      mdi-eye\n                    "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.$t("common.view")))
                                        ])
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.canEdit
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: {
                                          disabled:
                                            !_vm.$vuetify.breakpoint.lgAndUp ||
                                            _vm.processing,
                                          top: ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function({ on }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          link: "",
                                                          disabled:
                                                            _vm.processing
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.editAttachment(
                                                                item
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      mdi-pencil\n                    "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.$t("common.edit")))
                                        ])
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.canDownload
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: {
                                          disabled:
                                            !_vm.$vuetify.breakpoint.lgAndUp ||
                                            _vm.processing,
                                          top: ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function({ on }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          link: "",
                                                          disabled:
                                                            !item.file ||
                                                            _vm.processing
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.downloadFile(
                                                                item.file
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      mdi-cloud-download\n                    "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("common.download"))
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.canDelete
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: {
                                          disabled:
                                            !_vm.$vuetify.breakpoint.lgAndUp ||
                                            _vm.processing,
                                          top: ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function({ on }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          disabled:
                                                            _vm.processing
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.deleteAttachment(
                                                                item
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      mdi-delete\n                    "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("common.remove"))
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.cancelDialog }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.close")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }