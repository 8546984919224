import FDVue from "@fd/lib/vue";
import { mapMutations, mapActions } from "vuex";
import {
  CountSheetGroupWithParts,
  countSheetGroupService,
  partService,
  PartWithTags
} from "../services";

import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import rules from "@fd/lib/vue/rules";
import tabbedView, { Tab } from "@fd/lib/vue/mixins/tabbedView";
import ServiceError from "@fd/lib/client-util/serviceError";
import {
  FDColumnDirective,
  FDRowNavigateDirective,
  FDTableSortableDirective,
  SortableEvent
} from "@fd/lib/vue/utility/dataTable";
import { SortParts } from "../dataMixins/countSheetGroupSorting";
import { showCountSheetGroupPartsDialog } from "./components/dialogs/CountSheetGroupPartsDialog.vue";
import { TranslateResult } from "vue-i18n";

type CountSheetGroupWithDetails = CountSheetGroupWithParts & { archived: boolean };
export default FDVue.extend({
  name: "fd-count-sheet-group-existing",
  mixins: [errorHandling, rules, tabbedView],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective,
    fdTableSortable: FDTableSortableDirective
  },

  data: function() {
    return {
      countSheetGroup: {} as CountSheetGroupWithDetails,
      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,
      deleting: false,

      slidein: false,

      firstTabKey: `0`,
      detailsTab: {
        tabname: "Details",
        key: "0",
        visible: true
      } as Tab,
      partsTab: {
        tabname: "Parts",
        key: "1",
        visible: false
      } as Tab,

      parts: [] as PartWithTags[]
    };
  },

  computed: {
    tabDefinitions(): Tab[] {
      // Details is not included since it's the first tab and is always visible
      return [this.partsTab] as Tab[];
    }
  },

  methods: {
    onSubmit(e: Event) {
      e.preventDefault();
      this.save(false);
    },
    // Method used in conjunction with the Save button.
    async save(closeOnComplete: boolean) {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        if (!this.countSheetGroup.archived) {
          this.countSheetGroup.archivedDate = null;
        } else if (this.countSheetGroup.archived && !this.countSheetGroup.archivedDate) {
          this.countSheetGroup.archivedDate = new Date(new Date().toUTCString());
        }

        await countSheetGroupService.updateCountSheetGroupParts(
          this.countSheetGroup.id!,
          this.parts ?? []
        );
        await this.updateCountSheetGroup({
          ...this.countSheetGroup,
          parts: undefined
        });
        if (closeOnComplete) {
          this.$router.push("/countsheetgroups");
        }
      } catch (error) {
        this.handleError(error as ServiceError);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    // the following works with the delete "Action" button in the Datatable.
    async deleteItem() {
      this.inlineMessage.message = null;
      this.processing = true;
      this.deleting = true;
      try {
        await this.deleteCountSheetGroup({
          id: this.$route.params.id,
          name: this.countSheetGroup.name
        });
        this.$router.push("/countsheetgroups");
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.deleting = false;
      }
    },

    async cancel() {
      this.$router.push("/countsheetgroups");
    },

    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT",
      setSelectedTab: "SET_SELECTED_TAB_INDEX_IN_FILTERING_CONTEXT"
    }),

    ...mapActions({
      loadCountSheetGroup: "LOAD_COUNT_SHEET_GROUP",
      updateCountSheetGroup: "UPDATE_COUNT_SHEET_GROUP",
      deleteCountSheetGroup: "DELETE_COUNT_SHEET_GROUP"
    }),

    // *** PARTS
    async openPartsDialog() {
      this.optOutOfErrorHandling();
      // Just in case the user is opening this dialog before the object has loaded, pass in the ID param
      let parts = await showCountSheetGroupPartsDialog({
        countSheetGroupID: this.countSheetGroup?.id ?? this.$route.params.id
      });
      if (!!parts) {
        // Save parts selection here
        this.parts = SortParts(parts.slice());
      }
    },

    async loadParts() {
      if (!this.countSheetGroup?.id) return;
      this.parts = SortParts(await partService.getByCountSheetGroupID(this.countSheetGroup.id));
    },
    async dragEnded(e: SortableEvent) {
      console.log(`dragEnded`);
      let oldIndex = e.oldIndex ?? 0;
      let newIndex = e.newIndex ?? 0;
      if (oldIndex == newIndex) return;

      this.parts.splice(newIndex, 0, ...this.parts.splice(oldIndex, 1));
      this.parts.forEach((item, index) => {
        item.countSheetGroupDisplayOrder = index + 1;
      });

      await this.saveCountSheetGroupParts(
        this.$t("countsheetgroups.snack-bar-group-parts-order-updated-message"),
        null
      );
    },

    async deleteCountSheetGroupPart(item: PartWithTags) {
      let index = this.parts.indexOf(item);
      this.parts.splice(index, 1);
      await this.saveCountSheetGroupParts(
        this.$t("countsheetgroups.snack-bar-group-part-removed-message"),
        () => {
          this.parts.splice(index, 0, item);
          this.saveCountSheetGroupParts(
            this.$t("countsheetgroups.snack-bar-group-part-added-message"),
            null
          );
        }
      );
    },

    //Method used in conjunction with new view dialog.
    async saveCountSheetGroupParts(
      message: string | TranslateResult,
      undoCallback: Function | null
    ) {
      if (!this.countSheetGroup.id) return;

      this.processing = true;
      this.saving = true;
      try {
        let selectedParts = this.parts;
        selectedParts = SortParts(selectedParts);
        selectedParts.forEach((part, index) => {
          part.countSheetGroupDisplayOrder = index + 1;
        });
        await countSheetGroupService.updateCountSheetGroupParts(
          this.countSheetGroup.id,
          selectedParts
        );

        var snackbarPayload = {
          text: message,
          type: "info",
          undoCallback: undoCallback
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as ServiceError);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    }
  },

  watch: {
    countSheetGroup() {
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/countsheetgroups") {
        this.notifyNewBreadcrumb({
          text: this.$t("countsheetgroups.setup.title"),
          to: "/countsheetgroups",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }
      this.notifyNewBreadcrumb({
        text: `${this.countSheetGroup.name}`,
        to: `/countsheetgroups/${this.$route.params.id}`
      });
    }
  },

  created: async function() {
    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/countsheetgroups") {
      this.notifyNewBreadcrumb({
        text: this.$t("countsheetgroups.setup.title"),
        to: "/countsheetgroups",
        resetHistory: true
      });
      // This is needed in order to salvage the "last breadcrumbs" in the store.
      this.$store.commit("NOTIFY_NAVIGATION_STARTED");
    }
    this.notifyNewBreadcrumb({
      text: ``,
      to: `/countsheetgroups/${this.$route.params.id}`
    });

    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    this.setFilteringContext({
      context: "countsheetgroupexisting",
      parentalContext: "countsheetgroups",
      selectedTab: this.firstTabKey
    });

    this.processing = true;
    try {
      await this.loadCountSheetGroup(this.$route.params.id);
      var countSheetGroup = this.$store.state.countSheetGroups.fullList.find(
        (x: CountSheetGroupWithParts) => x.id == this.$route.params.id
      );
      this.countSheetGroup = {
        ...countSheetGroup,
        archived: !!countSheetGroup.archivedDate
      } as CountSheetGroupWithDetails;

      await this.loadParts();
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});


