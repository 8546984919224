import { PropType } from "vue";
import FDVue from "../../../../../lib/vue";
import {
  ContractorNorm,
  ScaffoldElevationModifier,
  ScaffoldRequestTypes,
  ScaffoldTypeModifier
} from "../../../services";

type NormWithResponse = ContractorNorm & {
  answeredDescription: string | undefined;
  quantity: number | null | undefined;
  loadedQuantity: number | null | undefined;
  hours: number | null | undefined;
  loadedHours: number | null | undefined;
  text: string | null | undefined;
  loadedText: string | null | undefined;
};
export default FDVue.extend({
  name: "sp-build-sheet-form",

  components: {
    "fp-add-remove-field": () => import("@fd/lib/vue/components/FP.AddRemoveField.vue")
  },
  props: {
    scaffoldTypeModifiers: { type: Array as PropType<Array<ScaffoldTypeModifier>> },
    scaffoldElevationModifiers: { type: Array as PropType<Array<ScaffoldElevationModifier>> },
    totalTimesheetHours: { type: Number },
    processing: { type: Boolean },

    normsWithResponses: { type: Array as PropType<Array<NormWithResponse>> },
    length: { type: Number },
    width: { type: Number },
    height: { type: Number },
    vlfChangeAmount: { type: Number },
    scaffoldTypeModifierID: { type: String },
    scaffoldElevationModifierID: { type: String },
    notes: { type: String },

    hazardStatusResult: { type: String },
    confinedSpace: { type: Boolean },
    ppeRequired: { type: Boolean },
    deckLevels: { type: Number },

    currentScaffoldVlf: { type: Number },
    scaffoldTotalVLF: { type: Number },
    scaffoldRequestType: { type: Number },
    canEditBuildSheetResponses: { type: Boolean }
  },
  data: function() {
    return {};
  },
  computed: {
    vlfBeforeValue(): number {
      return this.currentScaffoldVlf ?? this.scaffoldTotalVLF ?? 0;
    },
    allowVLFAdd(): boolean {
      return (
        this.scaffoldRequestType == ScaffoldRequestTypes.Erect ||
        this.scaffoldRequestType == ScaffoldRequestTypes.Modify
      );
    },
    allowVLFRemove(): boolean {
      return (
        this.scaffoldRequestType == ScaffoldRequestTypes.Modify ||
        this.scaffoldRequestType == ScaffoldRequestTypes.Dismantle
      );
    },
    canEditBuildSheet(): boolean {
      return this.canEditBuildSheetResponses ?? false;
    },

    totalNormHours(): number {
      let total = this.normsWithResponses.reduce((total, norm) => {
        return total + (norm.hours ?? 0);
      }, 0);
      return total;
    }
  },
  methods: {
    clearNormResponses(item: NormWithResponse) {
      item.quantity = null;
      item.hours = null;
      item.text = null;
      this.$emit("update:normsWithResponses", this.normsWithResponses);
    },
    normQuantityUpdated(norm: NormWithResponse, quantity: any) {
      norm.quantity = this.$parse.sanitizedNumber(quantity);
      this.$emit("update:normsWithResponses", this.normsWithResponses);
    },
    normHoursUpdated(norm: NormWithResponse, hours: any) {
      norm.hours = this.$parse.sanitizedNumber(hours);
      this.$emit("update:normsWithResponses", this.normsWithResponses);
    },

    // *** INLINE NAVIGATION ***
    getFieldRef(fieldName: string, item: NormWithResponse) {
      let field = fieldName!.replace("-", "").replace("-", "");
      let id = item.id!.replace("-", "").replace("-", "");
      let fieldRef = `${field}_${id}`;
      console.log(`\t getFieldRef: ${fieldRef}`);
      return fieldRef;
    },
    focusFieldForVisibleItemAtIndex(fieldName: string, index: number) {
      console.log(`focusFieldForVisibleItemAtIndex: ${fieldName}, index: ${index}`);
      if (!this.normsWithResponses.length) return;

      if (index < 0) index = 0;
      if (index >= this.normsWithResponses.length) index = this.normsWithResponses.length - 1;
      let item = this.normsWithResponses[index];

      let itemFieldRef = this.getFieldRef(fieldName, item);
      let itemField = this.$refs[itemFieldRef] as any;
      if (!!itemField["length"]) itemField = itemField[0];
      itemField = itemField;
      this.$nextTick(() => {
        console.log(
          `\t itemField: ${itemField} element: ${itemField?.$el} textField: ${itemField?.textField}`
        );
        itemField?.focus();
        itemField?.$el?.focus();
        itemField?.$textField?.focus();
      });
    },
    async selectPreviousField(fieldName: string, item: NormWithResponse) {
      let currentItemIndex = this.normsWithResponses.indexOf(item);
      if (currentItemIndex <= 0) {
        return;
      }

      let previousIndex = currentItemIndex - 1;
      this.focusFieldForVisibleItemAtIndex(fieldName, previousIndex);
    },
    async selectNextField(fieldName: string, item: NormWithResponse) {
      let currentItemIndex = this.normsWithResponses.indexOf(item);
      if (currentItemIndex >= this.normsWithResponses.length - 1) {
        return;
      }

      let nextIndex = currentItemIndex + 1;
      this.focusFieldForVisibleItemAtIndex(fieldName, nextIndex);
    },
    async enterPressed(e: KeyboardEvent, fieldName: string, item: NormWithResponse) {
      if (e.shiftKey) await this.selectPreviousField(fieldName, item);
      else await this.selectNextField(fieldName, item);
    }
  }
});

