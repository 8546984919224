import Vue from "vue";
import Vuetify from "vuetify";
import router from "./router";
import store from "./store";
import i18n from "./i18n";

// Import the fontawesome css for use with <v-icon> components
import "@fortawesome/fontawesome-pro/css/all.css"; // Ensure you are using css-loader
import { LookupPlugin } from "./store/referenceData";
import { FormatPlugin } from "@fd/lib/vue/plugins/format";
import { ParsePlugin } from "@fd/lib/vue/plugins/parse";
import { DataTableColumnPlugin } from "@fd/lib/vue/utility/dataTable";

Vue.use(Vuetify);

console.log(`Loading Vuetify, version: ${Vuetify.version}`);
console.log(`Browser User Agent: ${navigator.userAgent}`);

Vue.component("fd-add-button", () => import("@fd/lib/vue/components/AddButton.vue"));
Vue.component("fd-inline-add-button", () => import("@fd/lib/vue/components/AddButtonInline.vue"));
Vue.component("fd-back-button", () => import("@fd/lib/vue/components/BackButton.vue"));
Vue.component("fd-btn", () => import("@fd/lib/vue/components/BasicButton.vue"));
Vue.component("fd-checkbox", () => import("@fd/lib/vue/components/Checkbox.vue"));
Vue.component("fd-date-picker", () => import("@fd/lib/vue/components/FP.DatePicker.vue"));
Vue.component("fp-date-picker", () => import("@fd/lib/vue/components/FP.DatePicker.vue"));
Vue.component("fd-date-range-picker", () => import("@fd/lib/vue/components/DateRangePicker.vue"));
Vue.component("fp-date-range-picker", () => import("@fd/lib/vue/components/DateRangePicker.vue"));
Vue.component("fd-archived-data-loader", () =>
  import("@fd/lib/vue/components/ArchivedDataLoader.vue")
);
Vue.component("fd-textarea", () => import("@fd/lib/vue/components/TextArea.vue"));
Vue.component("fd-text-field", () => import("@fd/lib/vue/components/TextField.vue"));
Vue.component("fd-rich-textarea", () => import("@fd/lib/vue/components/RichTextArea.vue"));
Vue.component("fd-toolbar", () => import("@fd/lib/vue/components/Toolbar.vue"));
Vue.component("fd-alert", () => import("@fd/lib/vue/components/Alert.vue"));
Vue.component("fd-select", () => import("@fd/lib/vue/components/Select.vue"));
Vue.component("fd-value-display", () => import("@fd/lib/vue/components/FP.ValueDisplay.vue"));
Vue.component("fd-progress-badge", () => import("@fd/lib/vue/components/ProgressBadge.vue"));
Vue.component("fd-menu-save", () => import("@fd/lib/vue/components/MenuSaveButton.vue"));
Vue.component("fp-item-disabled-message", () =>
  import("@fd/lib/vue/components/FP.ItemDisabledMessage.vue")
);

export default {
  router,
  store,
  i18n,
  vuetify: new Vuetify({
    //Icon library definitions to be used on this view.
    icons: {
      iconfont: "mdi" || "md" || "fa" || "faSvg"
    },
    lang: {
      t: (key, ...params) => i18n.t(key, params) as string
    },
    breakpoint: {
      mobileBreakpoint: 1195
    },
    theme: {
      themes: {
        light: {
          // Primary has been overwritten using our $info-blue scss color variable's value
          // The rest of the values are copied from the vuetify base "light" theme values
          primary: "#2196f3",
          secondary: "#424242",
          accent: "#82B1FF",
          error: "#FF5252",
          info: "#2196F3",
          // success: "#008000",
          success: "#00C853",
          warning: "#FB8C00"
        }
      }
    }
  })
};

Vue.use(LookupPlugin);
Vue.use(FormatPlugin);
Vue.use(ParsePlugin);
Vue.use(DataTableColumnPlugin);

