import FDItemList from "@fd/lib/vue/components/FDItemList";
import { mapActions } from "vuex";
import { createNewWorkPackageDialog } from "./components/WorkPackageNewDialog.vue";
import { WorkPackageForList, workPackageService } from "../services";
import * as DateUtil from "@fd/lib/client-util/datetime";
import store from "../store";
import { LoadWorkPackagesWithStatusesParameter } from "../store/workpackages";
import { TranslateResult } from "vue-i18n";

type FormattedWorkPackage = WorkPackageForList & {
  archived: boolean;
  formattedStartDate: string;
  formattedFinishDate: string;
};

export default FDItemList.extend({
  name: "fd-work-packages-list",

  mixins: [],

  components: {},

  data: function() {
    return {
      // Overrides from FDItemList
      title: this.$t("workpackages.list-title"),
      defaultStatusesForFiltering: ["Initialized", "Issued", "Not Started", "In Progress"],
      suppressInitialDataLoad: true,
      // Table Footer page size options
      itemsPerPageOptions: [5, 10, 15, 50],
      statusItems: [] as string[],
      workPackages: [] as FormattedWorkPackage[]
    };
  },

  computed: {
    columnHeaders: {
      cache: true,
      get(): { [key: string]: string | TranslateResult } {
        return {
          activityID: this.$t("common.activityid"),
          name: this.$t("common.name"),
          startDate: this.$t("common.startdate"),
          finishDate: this.$t("common.finishdate"),
          completionPercentage: this.$t("workpackages.completion-percentage"),
          status: this.$t("common.status"),
          archived: this.$t("common.archived"),
          actions: this.$t("common.actions")
        };
      }
    },
    statusesForFiltering: {
      get(): string[] {
        var statusesForFiltering = this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )?.statusesForFiltering;
        return statusesForFiltering ?? [];
      },
      set(val: string[]) {
        this.$store.commit("SET_STATUSES_FOR_FILTERING", val);
        this.refreshItemList();
      }
    },
    items(): FormattedWorkPackage[] {
      return this.workPackages;
    }
  },

  methods: {
    async openNewDialog() {
      this.optOutOfErrorHandling();
      let result = await createNewWorkPackageDialog();
      console.log(`dialog result: ${result}`);
      if (!result) return;

      // Loading the statuses will update them in the store, which automatically triggers a refresh.  No need to load items.
      this.loadWorkPackageStatuses();
    },

    async reloadTableData() {
      this.processing = true;
      try {
        await this.refreshItemList();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async refreshItemList() {
      try {
        await this.loadWorkPackages({
          forcedArchivedState: this.showArchived,
          archivedFromDate: this.showArchivedFromDate,
          archivedToDate: this.showArchivedToDate,
          statuses: this.statusesForFiltering
        } as LoadWorkPackagesWithStatusesParameter);

        let workPackages: FormattedWorkPackage[] = this.$store.state.workPackages.fullList.map(
          (x: WorkPackageForList) => {
            return {
              ...x,
              archived: !!x.archivedDate,
              formattedStartDate: DateUtil.stripTimeFromLocalizedDateTime(x.startDate),
              formattedFinishDate: DateUtil.stripTimeFromLocalizedDateTime(x.finishDate)
            };
          }
        );
        this.workPackages = workPackages;
      } catch (error) {
        this.handleError(error as Error);
      }
    },

    async deleteItem(workPackage: WorkPackageForList) {
      try {
        await store.dispatch("DELETE_WORK_PACKAGE", workPackage);
      } catch (error) {
        this.handleError(error as Error);
      }
    },

    async flipArchived(item: any) {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        // We want to use the opposite value for archived, since we're flipping it
        var archivedDate = item.archived ? null : new Date(new Date().toUTCString());
        await this.updateWorkPackage({ ...item, archivedDate: archivedDate });
        if (this.displayedItems.find(x => x.id == item.id))
          this.displayedItems.find(x => x.id == item.id)!.archived = !!archivedDate;
      } catch (error) {
        this.handleError(error as Error, "workpackages.save-network-error");
      } finally {
        this.processing = false;
      }
    },

    filterByStatuses(items: WorkPackageForList[]): WorkPackageForList[] {
      if (this.statusesForFiltering != null && this.statusesForFiltering.length) {
        let returnValueItems = items.filter(x => {
          if (!x.workStatus) return false;
          for (let status of this.statusesForFiltering) {
            if (x.workStatus == status) {
              return true;
            }
          }
          return false;
        });
        return returnValueItems;
      } else {
        return items;
      }
    },

    async loadWorkPackageStatuses() {
      this.processing = true;
      try {
        this.statusItems = await workPackageService.getUsedWorkStatuses();

        this.statusesForFiltering = this.statusesForFiltering.filter(x => {
          return this.statusItems.includes(x);
        });

        // Loading the statuses will update them in the store, which automatically triggers a refresh.  No need to load items.
      } catch (error) {
        this.handleError(error as Error);
        console.log(
          `loadWorkPackageStatuses inlineMessage.message = ${this.inlineMessage.message}`
        );
      } finally {
        this.processing = false;
      }
    },

    ...mapActions({
      loadWorkPackages: "LOAD_WORK_PACKAGES_WITH_STATUSES",
      updateWorkPackage: "UPDATE_WORK_PACKAGE"
    })
  },

  created: async function() {
    // Loading the statuses will update them in the store, which automatically triggers a refresh.  No need to load items.
    await this.loadWorkPackageStatuses();
  }
});

