// GENERATED CODE - DO NOT EDIT
import store from "../store";
import { Record, BasicReferenceDataCrudService } from "../store/baseTypes";
import { createReferenceDataStore } from "../store/referenceData/register";
import {
  ContractorPurchaseOrder, contractorPurchaseOrderService,
  Supplier, supplierService,
  Contractor, contractorService2,
  Discipline, disciplineService2,
  Person, personBySecurityIDService2,
  personService2,
  ProjectLocation, projectLocationService2,
  ScaffoldBayLength, scaffoldBayLengthService2,
  ScaffoldBayWidth, scaffoldBayWidthService2,
  ScaffoldBayHeight, scaffoldBayHeightService2,
  HoardingModifier, hoardingModifierService2,
  InternalModifier, internalModifierService2,
  ScaffoldCongestionFactor, scaffoldCongestionFactorService2,
  ScaffoldElevationModifier, scaffoldElevationModifierService2,
  ScaffoldHeightModifier, scaffoldHeightModifierService2,
  ScaffoldTypeModifier, scaffoldTypeModifierService2,
} from "./baseServices.generated";
// TODO: Revisit the fact that the ID field is undefined-able - this forces us to add '& Record' to the type
// TODO: Revisit the fact that the name field is undefined-able
export const contractorPurchaseOrderDataStore = createReferenceDataStore<ContractorPurchaseOrder & Record>(
  "contractorPurchaseOrder",
  contractorPurchaseOrderService as BasicReferenceDataCrudService<ContractorPurchaseOrder & Record>,
  x => x.purchaseOrderNumber!,
  store
);
// TODO: Revisit the fact that the ID field is undefined-able - this forces us to add '& Record' to the type
// TODO: Revisit the fact that the name field is undefined-able
export const supplierDataStore = createReferenceDataStore<Supplier & Record>(
  "supplier",
  supplierService as BasicReferenceDataCrudService<Supplier & Record>,
  x => x.name!,
  store
);
// TODO: Revisit the fact that the ID field is undefined-able - this forces us to add '& Record' to the type
// TODO: Revisit the fact that the name field is undefined-able
export const contractorDataStore = createReferenceDataStore<Contractor & Record>(
  "contractor",
  contractorService2 as BasicReferenceDataCrudService<Contractor & Record>,
  x => x.name!,
  store
);
// TODO: Revisit the fact that the ID field is undefined-able - this forces us to add '& Record' to the type
// TODO: Revisit the fact that the name field is undefined-able
export const disciplineDataStore = createReferenceDataStore<Discipline & Record>(
  "discipline",
  disciplineService2 as BasicReferenceDataCrudService<Discipline & Record>,
  x => x.name!,
  store
);
// TODO: Revisit the fact that the ID field is undefined-able - this forces us to add '& Record' to the type
// TODO: Revisit the fact that the name field is undefined-able
export const personBySecurityIDDataStore = createReferenceDataStore<Person & Record>(
  "personBySecurityID",
  personBySecurityIDService2 as BasicReferenceDataCrudService<Person & Record>,
  x => `${x.firstName} ${x.lastName}`,
  store
);
// TODO: Revisit the fact that the ID field is undefined-able - this forces us to add '& Record' to the type
// TODO: Revisit the fact that the name field is undefined-able
export const personDataStore = createReferenceDataStore<Person & Record>(
  "person",
  personService2 as BasicReferenceDataCrudService<Person & Record>,
  x => `${x.firstName} ${x.lastName}`,
  store
);
// TODO: Revisit the fact that the ID field is undefined-able - this forces us to add '& Record' to the type
// TODO: Revisit the fact that the name field is undefined-able
export const projectLocationDataStore = createReferenceDataStore<ProjectLocation & Record>(
  "projectLocation",
  projectLocationService2 as BasicReferenceDataCrudService<ProjectLocation & Record>,
  x => x.name!,
  store
);
// TODO: Revisit the fact that the ID field is undefined-able - this forces us to add '& Record' to the type
// TODO: Revisit the fact that the name field is undefined-able
export const scaffoldBayLengthDataStore = createReferenceDataStore<ScaffoldBayLength & Record>(
  "scaffoldBayLength",
  scaffoldBayLengthService2 as BasicReferenceDataCrudService<ScaffoldBayLength & Record>,
  x => x.name!,
  store
);
// TODO: Revisit the fact that the ID field is undefined-able - this forces us to add '& Record' to the type
// TODO: Revisit the fact that the name field is undefined-able
export const scaffoldBayWidthDataStore = createReferenceDataStore<ScaffoldBayWidth & Record>(
  "scaffoldBayWidth",
  scaffoldBayWidthService2 as BasicReferenceDataCrudService<ScaffoldBayWidth & Record>,
  x => x.name!,
  store
);
// TODO: Revisit the fact that the ID field is undefined-able - this forces us to add '& Record' to the type
// TODO: Revisit the fact that the name field is undefined-able
export const scaffoldBayHeightDataStore = createReferenceDataStore<ScaffoldBayHeight & Record>(
  "scaffoldBayHeight",
  scaffoldBayHeightService2 as BasicReferenceDataCrudService<ScaffoldBayHeight & Record>,
  x => x.name!,
  store
);
// TODO: Revisit the fact that the ID field is undefined-able - this forces us to add '& Record' to the type
// TODO: Revisit the fact that the name field is undefined-able
export const hoardingModifierDataStore = createReferenceDataStore<HoardingModifier & Record>(
  "hoardingModifier",
  hoardingModifierService2 as BasicReferenceDataCrudService<HoardingModifier & Record>,
  x => x.name!,
  store
);
// TODO: Revisit the fact that the ID field is undefined-able - this forces us to add '& Record' to the type
// TODO: Revisit the fact that the name field is undefined-able
export const internalModifierDataStore = createReferenceDataStore<InternalModifier & Record>(
  "internalModifier",
  internalModifierService2 as BasicReferenceDataCrudService<InternalModifier & Record>,
  x => x.name!,
  store
);
// TODO: Revisit the fact that the ID field is undefined-able - this forces us to add '& Record' to the type
// TODO: Revisit the fact that the name field is undefined-able
export const scaffoldCongestionFactorDataStore = createReferenceDataStore<ScaffoldCongestionFactor & Record>(
  "scaffoldCongestionFactor",
  scaffoldCongestionFactorService2 as BasicReferenceDataCrudService<ScaffoldCongestionFactor & Record>,
  x => x.name!,
  store
);
// TODO: Revisit the fact that the ID field is undefined-able - this forces us to add '& Record' to the type
// TODO: Revisit the fact that the name field is undefined-able
export const scaffoldElevationModifierDataStore = createReferenceDataStore<ScaffoldElevationModifier & Record>(
  "scaffoldElevationModifier",
  scaffoldElevationModifierService2 as BasicReferenceDataCrudService<ScaffoldElevationModifier & Record>,
  x => x.name!,
  store
);
// TODO: Revisit the fact that the ID field is undefined-able - this forces us to add '& Record' to the type
// TODO: Revisit the fact that the name field is undefined-able
export const scaffoldHeightModifierDataStore = createReferenceDataStore<ScaffoldHeightModifier & Record>(
  "scaffoldHeightModifier",
  scaffoldHeightModifierService2 as BasicReferenceDataCrudService<ScaffoldHeightModifier & Record>,
  x => x.name!,
  store
);
// TODO: Revisit the fact that the ID field is undefined-able - this forces us to add '& Record' to the type
// TODO: Revisit the fact that the name field is undefined-able
export const scaffoldTypeModifierDataStore = createReferenceDataStore<ScaffoldTypeModifier & Record>(
  "scaffoldTypeModifier",
  scaffoldTypeModifierService2 as BasicReferenceDataCrudService<ScaffoldTypeModifier & Record>,
  x => x.name!,
  store
);
