import FDVue from "@fd/lib/vue";
import rules from "@fd/lib/vue/rules";
import VueI18n from "vue-i18n";
import {
  WorkPackage,
  ScaffoldSearchResult,
  ScaffoldRequestWithDetails,
  ScaffoldRequestTypes,
  ScaffoldRequestWithChildIDs
} from "../services";
import { GetPersonName } from "../utils/person";
import userAccess from "./userAccess";

export type WorkPackageWithNameCode = WorkPackage & { nameCode: string };
export type ScaffoldRequestWithChildIDsAndArchived = ScaffoldRequestWithChildIDs & {
  archived: boolean;
};
export type ScaffoldRequestRules = {
  requestType: ((value: any) => boolean | VueI18n.TranslateResult)[];
  requestSubType: ((value: any) => boolean | VueI18n.TranslateResult)[];
  requiredDate: ((value: any) => boolean | VueI18n.TranslateResult)[];
  requiredUntilDate: ((value: any) => boolean | VueI18n.TranslateResult)[];
  scaffoldNumber: ((value: any) => boolean | VueI18n.TranslateResult)[];
  requestingContractorID: ((value: any) => boolean | VueI18n.TranslateResult)[];
  disciplineID: ((value: any) => boolean | VueI18n.TranslateResult)[];
  requestingEmployeeID: ((value: any) => boolean | VueI18n.TranslateResult)[];
  areaID: ((value: any) => boolean | VueI18n.TranslateResult)[];
  subAreaID: ((value: any) => boolean | VueI18n.TranslateResult)[];
  siteContact: ((value: any) => boolean | VueI18n.TranslateResult)[];
  location: ((value: any) => boolean | VueI18n.TranslateResult)[];
  description: ((value: any) => boolean | VueI18n.TranslateResult)[];
  notes: ((value: any) => boolean | VueI18n.TranslateResult)[];
  serviceOrderReason: ((value: any) => boolean | VueI18n.TranslateResult)[];
  serviceOrderReferenceNumber: ((value: any) => boolean | VueI18n.TranslateResult)[];
  changeOrderReason: ((value: any) => boolean | VueI18n.TranslateResult)[];
  changeOrderReferenceNumber: ((value: any) => boolean | VueI18n.TranslateResult)[];
  reworkReason: ((value: any) => boolean | VueI18n.TranslateResult)[];
  reworkReferenceNumber: ((value: any) => boolean | VueI18n.TranslateResult)[];
  clientWorkOrderReason: ((value: any) => boolean | VueI18n.TranslateResult)[];
  clientWorkOrderReferenceNumber: ((value: any) => boolean | VueI18n.TranslateResult)[];
  purchaseOrderNumber: ((value: any) => boolean | VueI18n.TranslateResult)[];
};

export type ScaffoldRequestWithExtraDetails = ScaffoldRequestWithDetails & {
  archived: boolean;
  requestorName: string;
  filteredWorkOrderID: number | null | undefined;
};

export function ParseScaffoldRequestWithExtraDetails(
  request: ScaffoldRequestWithDetails
): ScaffoldRequestWithExtraDetails {
  return {
    ...request,
    archived: !!request.archivedDate,
    siteContact: request.siteContact?.toUpperCase(),
    filteredWorkOrderID: undefined
  } as ScaffoldRequestWithExtraDetails;
}

export const scaffoldRequest = FDVue.extend({
  mixins: [rules, userAccess],
  data: function() {
    return {
      isSavingDraft: true,
      scaffoldRequest: {} as ScaffoldRequestWithChildIDsAndArchived
    };
  },
  methods: {
    getScaffoldDescription(scaffold: ScaffoldSearchResult): string {
      let paddedTagNumber = `00000${scaffold.internalNumber}`.slice(-5);
      let existingRequestString = "";

      if (!!scaffold.existingRequestNumber?.length) {
        existingRequestString = ` | R-${scaffold.existingRequestNumber}`;
      }

      return `T-${paddedTagNumber}${existingRequestString}`;
    },

    getScaffoldDetails(scaffold: ScaffoldSearchResult): string {
      // The sub area is more specific than the area, so if a subarea exists, display it
      // Otherwise, display the area
      let areaString = "";
      if (scaffold.subAreaName && scaffold.subAreaName.trim().length > 0) {
        areaString = `${scaffold.subAreaName.trim()}`;
      }
      if (areaString.length == 0 && scaffold.areaName && scaffold.areaName.trim().length > 0) {
        areaString = `${scaffold.areaName.trim()}`;
      }

      var detailsString = areaString.length > 0 ? `${areaString}` : "";
      return `${detailsString}`;
    },

    /// This value's purpose is solely to determine which validation rules to return
    /// As such, modifying it changes which rules are applied to the UI, which requires a DOM refresh
    /// We need to use an awaited setter here to provide time for the UI to update
    updateIsDraft(val: boolean): Promise<void> {
      let _this = this;
      this.isSavingDraft = val;
      return new Promise((resolve, reject) => {
        _this.$nextTick(() => {
          resolve();
        });
      });
    }
  },

  computed: {
    // Override this in the file using this mixin. Used to calculate rules.
    isErectRequest(): boolean {
      return this.scaffoldRequest.requestType == ScaffoldRequestTypes.Erect;
    },
    // Override this in the file using this mixin. Used to calculate rules.
    requiredDate(): Date | null | undefined {
      return this.scaffoldRequest.requiredDate;
    },
    hasTag(): boolean {
      return !!this.scaffoldRequest.scaffoldID?.length;
    },
    requiredUntilDateRules(): ((value: any) => boolean | VueI18n.TranslateResult)[] {
      let requiredUntilLaterThanRequired = (value: any) => {
        return (
          !value ||
          !this.requiredDate ||
          new Date(value).getTime() >= this.requiredDate.getTime() ||
          this.$t("scaffold-requests.required-until-date-later-than-required-date")
        );
      };
      let rules: ((value: any) => boolean | VueI18n.TranslateResult)[] = [
        requiredUntilLaterThanRequired
      ];
      if (
        !this.isSavingDraft &&
        !!this.isErectRequest &&
        !!this.$store.state.curEnvironment.scaffoldRequestDismantleDateRequired
      ) {
        rules.push(this.rules.required);
      }

      return rules;
    },
    scaffoldRequestRules(): ScaffoldRequestRules {
      let requiredDateLaterThanToday = (value: any) => {
        // The required date is valid if empty, or if entered and is today or later
        return (
          !value ||
          this.currentUserCanEnterHistoricalData ||
          new Date(value).getTime() >= new Date(new Date().toDateString()).getTime() ||
          this.$t("scaffold-requests.required-date-later-than-today")
        );
      };
      if (this.isSavingDraft) {
        return {
          requestType: [this.rules.required],
          requestSubType: [this.rules.required],
          requiredDate: [requiredDateLaterThanToday],
          requiredUntilDate: this.requiredUntilDateRules,
          scaffoldNumber: [],
          requestingContractorID: [],
          disciplineID: [],
          requestingEmployeeID: [],
          areaID: [],
          subAreaID: [],
          siteContact: [],
          location: [],
          description: [],
          notes: [],
          clientWorkOrderReason: this.scaffoldRequest.isClientWorkOrder ? [] : [],
          clientWorkOrderReferenceNumber: this.scaffoldRequest.isClientWorkOrder
            ? [this.rules.required]
            : [],
          serviceOrderReason: this.scaffoldRequest.isServiceOrder ? [] : [],
          serviceOrderReferenceNumber: this.scaffoldRequest.isServiceOrder
            ? [this.rules.required]
            : [],
          changeOrderReason: this.scaffoldRequest.isChangeOrder ? [] : [],
          changeOrderReferenceNumber: this.scaffoldRequest.isChangeOrder
            ? [this.rules.required]
            : [],
          reworkReason: this.scaffoldRequest.isRework ? [] : [],
          reworkReferenceNumber: this.scaffoldRequest.isRework ? [this.rules.required] : [],
          purchaseOrderNumber: this.$store.state.curEnvironment.enablePurchaseOrders
            ? [this.rules.required]
            : []
        };
      } else {
        var disciplineRequiredRule = this.rules.required;
        var requestorRequiredRule = this.rules.required;
        var areaRequiredRule = this.rules.required;
        var subAreaRequiredRule = this.rules.required;
        return {
          requestType: [this.rules.required],
          requestSubType: [this.rules.required],
          requiredDate: [this.rules.required, requiredDateLaterThanToday],
          requiredUntilDate: this.requiredUntilDateRules,
          scaffoldNumber: this.isErectRequest ? [] : [this.rules.required],
          requestingContractorID: [this.rules.required],
          disciplineID: [disciplineRequiredRule],
          requestingEmployeeID: [requestorRequiredRule],
          areaID: this.hasTag ? [] : [areaRequiredRule],
          subAreaID: this.hasTag ? [] : [subAreaRequiredRule],
          siteContact: [],
          location: [this.rules.required],
          description: [this.rules.required],
          notes: [],
          clientWorkOrderReason: this.scaffoldRequest.isClientWorkOrder ? [] : [],
          clientWorkOrderReferenceNumber: this.scaffoldRequest.isClientWorkOrder
            ? [this.rules.required]
            : [],
          serviceOrderReason: this.scaffoldRequest.isServiceOrder ? [] : [],
          serviceOrderReferenceNumber: this.scaffoldRequest.isServiceOrder
            ? [this.rules.required]
            : [],
          changeOrderReason: this.scaffoldRequest.isChangeOrder ? [] : [],
          changeOrderReferenceNumber: this.scaffoldRequest.isChangeOrder
            ? [this.rules.required]
            : [],
          reworkReason: this.scaffoldRequest.isRework ? [] : [],
          reworkReferenceNumber: this.scaffoldRequest.isRework ? [this.rules.required] : [],
          purchaseOrderNumber: this.$store.state.curEnvironment.enablePurchaseOrders
            ? [this.rules.required]
            : []
        };
      }
    }
  }
});

