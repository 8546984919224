import FDVue from "@fd/lib/vue";
import userAccess from "../dataMixins/userAccess";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import { mapActions, mapMutations } from "vuex";
import tabbedView, { PageTab, Tab } from "@fd/lib/vue/mixins/tabbedView";
import rules from "@fd/lib/vue/rules";
import {
  BuildDismantleRatio,
  Classification,
  contractorService,
  ContractorWithTags,
  Environment,
  environmentService,
  InspectionTime,
  personService,
  ProjectCostCode,
  ScaffoldDistanceModifier,
  ScaffoldElevationModifier,
  ScaffoldHeightModifier,
  ScaffoldTypeModifier,
  Yard
} from "../services";
import {
  PersonWithName,
  GetPersonName,
  SortItemsWithName,
  SortItemsByStringProp
} from "../utils/person";
import {
  BasicSelectItem,
  GroupableSelectListOption,
  SelectListOption
} from "@fd/lib/vue/utility/select";
import {
  getNameOfDayFromNumber,
  isoDateString,
  isoTimeWithOffsetString,
  stripDateFromLocalizedDateTime
} from "../../../lib/client-util/datetime";
import { FDColumnDirective } from "../../../lib/vue/utility/dataTable";
import { openNewScaffoldInspectionTimeDialog } from "./components/dialogs/SP.ScaffoldInspectionTimeDialog.vue";
import { stripHtml, truncateWithEllipsis } from "../../../lib/vue/utility/helper";
import FPMap, { FPMapLocation } from "@fd/lib/vue/components/FP.Map.vue";
import { showLocationDialog } from "./components/dialogs/SP.LocationDialog.vue";

type ClassificationWithSelected = Classification & {
  overtimeSelected: boolean;
  doubletimeSelected: boolean;
};

export default FDVue.extend({
  name: "fd-environment-configuration",
  mixins: [userAccess, serviceErrorHandling, rules, tabbedView],
  components: {
    "fd-time-picker": () => import("@fd/lib/vue/components/TimePicker.vue"),
    "fp-map": FPMap
  },
  directives: {
    fdColumn: FDColumnDirective
  },
  data: function() {
    return {
      environment: {} as Environment,
      saving: false,
      styles: ["sunrise", "day", "sunset", "night"],

      // Form data errors
      detailsTabError: false,
      workflowTabError: false,

      firstTabKey: `0`,
      detailsTab: new PageTab({
        nameKey: "configuration.tabs.site-info",
        key: "0",
        visible: true
      }),
      notificationsTab: new PageTab({
        nameKey: "configuration.tabs.notifications",
        key: "1",
        visible: false
      }),
      workflowTab: new PageTab({
        nameKey: "configuration.tabs.workflow",
        key: "2",
        visible: false
      }),
      defaultsTab: new PageTab({
        nameKey: "configuration.tabs.defaults",
        key: "3",
        visible: false
      }),
      labourTab: new PageTab({
        nameKey: "configuration.tabs.labour",
        key: "4",
        visible: false
      }),
      inspectionsTab: new PageTab({
        nameKey: "configuration.tabs.inspections",
        key: "5",
        visible: false
      }),
      importsTab: new PageTab({
        nameKey: "configuration.tabs.imports",
        key: "6",
        visible: false
      }),

      // *** DETAILS ***
      siteProductivityOptions: Array.from(Array(11).keys()).map(k => {
        let value = k * 0.05 + 0.5;
        let percent = `${(value * 100).toFixed(0)}%`;
        return {
          text: percent,
          value: value
        };
      }),

      // *** DEFAULTS ***
      allContractors: [] as ContractorWithTags[],
      scaffoldContractors: [] as ContractorWithTags[],
      maintenanceContractors: [] as ContractorWithTags[],
      paintContractors: [] as ContractorWithTags[],
      insulationContractors: [] as ContractorWithTags[],
      allCoordinators: [] as PersonWithName[],
      allForemen: [] as PersonWithName[],
      allGeneralForemen: [] as PersonWithName[],
      inspectionTimes: [] as InspectionTime[],

      // *** LABOUR ***
      showOnlyIncludedClassifications: false,
      classificationsTableSearch: "",
      selectableClassifications: [] as Array<ClassificationWithSelected>,
      // allowAdvancedDailyThresholds: false,
      advancedDailyThresholds: false,

      dailySTValue: null as number | null,
      dailyOTValue: 0 as number | null,
      weeklySTValue: null as number | null,
      weeklyOTValue: 0 as number | null,

      dailyExampleHours: 14,
      weeklyExampleHours: 76,

      weekdayThresholdValues: {
        mondaySTValue: null as number | null,
        mondayOTValue: 0 as number | null,

        tuesdaySTValue: null as number | null,
        tuesdayOTValue: 0 as number | null,

        wednesdaySTValue: null as number | null,
        wednesdayOTValue: 0 as number | null,

        thursdaySTValue: null as number | null,
        thursdayOTValue: 0 as number | null,

        fridaySTValue: null as number | null,
        fridayOTValue: 0 as number | null,

        saturdaySTValue: null as number | null,
        saturdayOTValue: 0 as number | null,

        sundaySTValue: null as number | null,
        sundayOTValue: 0 as number | null
      },

      // *** IMPORTS ***
      importFile: null as any,
      uploading: false
    };
  },
  computed: {
    location: {
      get(): FPMapLocation | undefined {
        if (!this.environment.latitude || !this.environment.longitude) return undefined;
        return { lat: this.environment.latitude, lng: this.environment.longitude, colour: null };
      },
      set(val: FPMapLocation) {
        this.environment.latitude = val.lat;
        this.environment.longitude = val.lng;
      }
    },
    allowAdvancedDailyThresholds(): boolean {
      console.log(
        `allowAdvancedDailyThresholds ${this.$store.state.curEnvironment.allowExperimentalFeatures}`
      );
      return this.$store.state.curEnvironment.allowExperimentalFeatures;
    },
    allowImports(): boolean {
      return this.$store.state.curEnvironment.allowExperimentalFeatures;
    },
    dailyExampleSTValue(): number {
      let timeSummary = this.getDailyTimeSummary(true, true);
      return timeSummary.stHours;
    },
    dailyExampleOTValue(): number {
      let timeSummary = this.getDailyTimeSummary(true, true);
      return timeSummary.stHours + timeSummary.otHours;
    },
    dailyExampleSTValueNoOT(): number {
      let timeSummary = this.getDailyTimeSummary(false, true);
      return timeSummary.stHours;
    },
    dailyExampleOTValueNoOT(): number {
      let timeSummary = this.getDailyTimeSummary(false, true);
      return timeSummary.stHours + timeSummary.otHours;
    },
    dailyExampleSTValueNoDD(): number {
      let timeSummary = this.getDailyTimeSummary(true, false);
      return timeSummary.stHours;
    },
    dailyExampleOTValueNoDD(): number {
      let timeSummary = this.getDailyTimeSummary(true, false);
      return timeSummary.stHours + timeSummary.otHours;
    },

    weeklyExampleSTValue(): number {
      let timeSummary = this.getWeeklyTimeSummary();
      return timeSummary.stHours;
    },
    weeklyExampleOTValue(): number {
      let timeSummary = this.getWeeklyTimeSummary();
      return timeSummary.stHours + timeSummary.otHours;
    },

    // Hour options for max daily hours select control
    // Range of 1 through 24
    dayHourValues(): BasicSelectItem[] {
      let hours = Array.from(Array(24).keys()).map(
        x =>
          ({
            text: (x + 1).toFixed(2),
            value: x + 1
          } as BasicSelectItem)
      );
      return hours;
    },
    // Hour options for daily threshold selects
    // Includes both a "+" (remainder) and a "0.00"
    dailyHourValues(): BasicSelectItem[] {
      let hours = Array.from(Array(24).keys()).map(
        x =>
          ({
            text: x.toFixed(2),
            value: x
          } as BasicSelectItem)
      );
      return [{ text: "+", value: null } as BasicSelectItem].concat(hours);
    },
    dailyDDValue() {
      if (this.dailySTValue == null || this.dailyOTValue == null) return 0.0;
      else return null;
    },
    mondayDDValue() {
      if (
        this.weekdayThresholdValues.mondaySTValue == null ||
        this.weekdayThresholdValues.mondayOTValue == null
      )
        return 0.0;
      else return null;
    },
    tuesdayDDValue() {
      if (
        this.weekdayThresholdValues.tuesdaySTValue == null ||
        this.weekdayThresholdValues.tuesdayOTValue == null
      )
        return 0.0;
      else return null;
    },
    wednesdayDDValue() {
      if (
        this.weekdayThresholdValues.wednesdaySTValue == null ||
        this.weekdayThresholdValues.wednesdayOTValue == null
      )
        return 0.0;
      else return null;
    },
    thursdayDDValue() {
      if (
        this.weekdayThresholdValues.thursdaySTValue == null ||
        this.weekdayThresholdValues.thursdayOTValue == null
      )
        return 0.0;
      else return null;
    },
    fridayDDValue() {
      if (
        this.weekdayThresholdValues.fridaySTValue == null ||
        this.weekdayThresholdValues.fridayOTValue == null
      )
        return 0.0;
      else return null;
    },
    saturdayDDValue() {
      if (
        this.weekdayThresholdValues.saturdaySTValue == null ||
        this.weekdayThresholdValues.saturdayOTValue == null
      )
        return 0.0;
      else return null;
    },
    sundayDDValue() {
      if (
        this.weekdayThresholdValues.sundaySTValue == null ||
        this.weekdayThresholdValues.sundayOTValue == null
      )
        return 0.0;
      else return null;
    },
    // Hour options for weekly hours example select control
    // Range of 1 through 24
    weekHourValues(): BasicSelectItem[] {
      let hours = Array.from(Array(168).keys()).map(
        x =>
          ({
            text: (x + 1).toFixed(2),
            value: x + 1
          } as BasicSelectItem)
      );
      return hours;
    },
    // Hour options for weekly threshold selects
    // Includes both a "+" (remainder) and a "0.00"
    weeklyHourValues(): BasicSelectItem[] {
      let hours = Array.from(Array(168).keys()).map(
        x =>
          ({
            text: x.toFixed(2),
            value: x
          } as BasicSelectItem)
      );
      return [{ text: "+", value: null } as BasicSelectItem].concat(hours);
    },
    weeklyDDValue() {
      if (this.weeklySTValue == null || this.weeklyOTValue == null) return 0.0;
      else return null;
    },
    computedBasicLabourThresholds(): Environment & {
      enableDailyOvertimeThreshold: boolean;
      enableDailyDoubletimeThreshold: boolean;
    } {
      let weeklyOTThreshold = null;
      let weeklyDDThreshold = null;
      if (this.weeklySTValue == null || this.weeklySTValue == undefined) {
      } else {
        let weeklySTValue = this.sanitizeNumber(this.weeklySTValue) ?? 0;
        if (this.weeklyOTValue == null || this.weeklyOTValue == undefined) {
          weeklyOTThreshold = weeklySTValue;
        } else {
          let weeklyOTValue = this.sanitizeNumber(this.weeklyOTValue) ?? 0;
          if (weeklyOTValue == null || weeklyOTValue == undefined) {
            weeklyDDThreshold = weeklySTValue;
          } else {
            weeklyOTThreshold = weeklySTValue;
            weeklyDDThreshold = weeklySTValue + weeklyOTValue;
          }
        }
      }

      let dailyOTThreshold = null;
      let dailyDDThreshold = null;

      if (this.dailySTValue == null || this.dailySTValue == undefined) {
      } else {
        let dailySTValue = this.sanitizeNumber(this.dailySTValue) ?? 0;
        if (this.dailyOTValue == null || this.dailyOTValue == undefined) {
          dailyOTThreshold = dailySTValue;
        } else {
          let dailyOTValue = this.sanitizeNumber(this.dailyOTValue) ?? 0;
          if (dailyOTValue == null || dailyOTValue == undefined) {
            dailyDDThreshold = dailySTValue;
          } else {
            dailyOTThreshold = dailySTValue;
            dailyDDThreshold = dailySTValue + dailyOTValue;
          }
        }
      }

      return {
        defaultEmployeeOvertimeHoursThreshold: weeklyOTThreshold,
        defaultEmployeeDoubletimeHoursThreshold: weeklyDDThreshold,
        defaultDailyEmployeeOvertimeHoursThreshold: dailyOTThreshold,
        defaultDailyEmployeeDoubletimeHoursThreshold: dailyDDThreshold,
        enableDailyOvertimeThreshold: dailyOTThreshold != null && dailyOTThreshold != undefined,
        enableDailyDoubletimeThreshold: dailyDDThreshold != null && dailyDDThreshold != undefined
      } as Environment & {
        enableDailyOvertimeThreshold: boolean;
        enableDailyDoubletimeThreshold: boolean;
      };
    },
    canAddInspectionTime(): boolean {
      return this.inspectionTimes.length < 4;
    },
    weekendingDayOptions(): any[] {
      return Array.from(Array(7).keys()).map(x => ({
        text: getNameOfDayFromNumber(x),
        value: x
      }));
    },
    tabDefinitions(): Tab[] {
      // Details is not included since it's the first tab and is always visible
      return [
        this.notificationsTab,
        this.workflowTab,
        this.defaultsTab,
        this.labourTab,
        this.inspectionsTab,
        this.importsTab
      ] as Tab[];
    },
    configurationRules(): any {
      return {
        // SCAFFOLD ASSIGNMENTS
        defaultWorkOrderAssignedContractorID: this.environment.automaticallyApproveScaffoldRequests
          ? [this.rules.required]
          : [],
        defaultWorkOrderAssignedCoordinatorID: this.environment.automaticallyApproveScaffoldRequests
          ? [this.rules.required]
          : [],
        defaultWorkOrderAssignedGeneralForemanID: this.environment
          .automaticallyApproveScaffoldRequests
          ? [this.rules.required]
          : [],
        defaultWorkOrderAssignedForemanID: this.environment.automaticallyApproveScaffoldRequests
          ? [this.rules.required]
          : [],
        // MAINTENANCE ASSIGNMENTS
        defaultMaintenanceWorkOrderAssignedContractorID: this.environment
          .automaticallyApproveMaintenanceRequests
          ? [this.rules.required]
          : [],
        defaultMaintenanceWorkOrderAssignedCoordinatorID: this.environment
          .automaticallyApproveMaintenanceRequests
          ? [this.rules.required]
          : [],
        defaultMaintenanceWorkOrderAssignedGeneralForemanID: this.environment
          .automaticallyApproveMaintenanceRequests
          ? [this.rules.required]
          : [],
        defaultMaintenanceWorkOrderAssignedForemanID: this.environment
          .automaticallyApproveMaintenanceRequests
          ? [this.rules.required]
          : [],
        // PAINT ASSIGNMENTS
        defaultPaintWorkOrderAssignedContractorID: this.environment
          .automaticallyApprovePaintRequests
          ? [this.rules.required]
          : [],
        defaultPaintWorkOrderAssignedCoordinatorID: this.environment
          .automaticallyApprovePaintRequests
          ? [this.rules.required]
          : [],
        defaultPaintWorkOrderAssignedGeneralForemanID: this.environment
          .automaticallyApprovePaintRequests
          ? [this.rules.required]
          : [],
        defaultPaintWorkOrderAssignedForemanID: this.environment.automaticallyApprovePaintRequests
          ? [this.rules.required]
          : [],
        // INSULATION ASSIGNMENTS
        defaultInsulationWorkOrderAssignedContractorID: this.environment
          .automaticallyApproveInsulationRequests
          ? [this.rules.required]
          : [],
        defaultInsulationWorkOrderAssignedCoordinatorID: this.environment
          .automaticallyApproveInsulationRequests
          ? [this.rules.required]
          : [],
        defaultInsulationWorkOrderAssignedGeneralForemanID: this.environment
          .automaticallyApproveInsulationRequests
          ? [this.rules.required]
          : [],
        defaultInsulationWorkOrderAssignedForemanID: this.environment
          .automaticallyApproveInsulationRequests
          ? [this.rules.required]
          : [],
        // COUNT SHEETS
        defaultCountSheetFromYardID: this.environment.automaticallyApproveCountSheets
          ? [this.rules.required]
          : [],
        defaultCountSheetToYardID: []
      };
    },

    allYards(): Yard[] {
      return (this.$store.state.yards.fullList as Yard[]).filter(x => !x.isSystemYard);
    },

    allCostCodes(): ProjectCostCode[] {
      return this.$store.state.projectCostCodes.fullList as ProjectCostCode[];
    },

    allScaffoldTypeModifiers(): ScaffoldTypeModifier[] {
      return SortItemsWithName(
        this.$store.state.scaffoldTypeModifiers.fullList as ScaffoldTypeModifier[]
      );
    },
    allScaffoldDistanceModifiers(): ScaffoldDistanceModifier[] {
      return SortItemsWithName(
        this.$store.state.scaffoldDistanceModifiers.fullList as ScaffoldDistanceModifier[]
      );
    },
    allScaffoldElevationModifiers(): ScaffoldElevationModifier[] {
      return SortItemsWithName(
        this.$store.state.scaffoldElevationModifiers.fullList as ScaffoldElevationModifier[]
      );
    },
    allScaffoldHeightModifiers(): ScaffoldHeightModifier[] {
      return SortItemsWithName(
        this.$store.state.scaffoldHeightModifiers.fullList as ScaffoldHeightModifier[]
      );
    },
    allBuildDismantleRatios(): BuildDismantleRatio[] {
      return SortItemsByStringProp(
        this.$store.state.buildDismantleRatios.fullList as BuildDismantleRatio[],
        "ratio"
      );
    },

    // *** DEFAULTS ***
    factor1: {
      get(): number | null | undefined {
        if (!this.environment.factor1) return this.environment.factor1;
        return this.environment.factor1 * 100;
      },
      set(val: number | null | undefined) {
        if (!val) this.environment.factor1 = val;
        else this.environment.factor1 = Number((val / 100).toFixed(2));
      }
    },
    factor2: {
      get(): number | null | undefined {
        if (!this.environment.factor2) return this.environment.factor2;
        return this.environment.factor2 * 100;
      },
      set(val: number | null | undefined) {
        if (!val) this.environment.factor2 = val;
        else this.environment.factor2 = Number((val / 100).toFixed(2));
      }
    },
    defaultFactor1: {
      get(): number | null | undefined {
        if (!this.environment.defaultFactor1) return this.environment.defaultFactor1;
        return this.environment.defaultFactor1 * 100;
      },
      set(val: number | null | undefined) {
        if (!val) this.environment.defaultFactor1 = val;
        else this.environment.defaultFactor1 = Number((val / 100).toFixed(2));
      }
    },
    defaultFactor2: {
      get(): number | null | undefined {
        if (!this.environment.defaultFactor2) return this.environment.defaultFactor2;
        return this.environment.defaultFactor2 * 100;
      },
      set(val: number | null | undefined) {
        if (!val) this.environment.defaultFactor2 = val;
        else this.environment.defaultFactor2 = Number((val / 100).toFixed(2));
      }
    },

    // *** LABOUR ***
    classifications(): Array<ClassificationWithSelected> {
      let returnValue = this.selectableClassifications;
      if (this.showOnlyIncludedClassifications)
        returnValue = returnValue.filter(x => x.overtimeSelected || x.doubletimeSelected);
      return returnValue;
    },

    overtimeSelectedClassifications(): ClassificationWithSelected[] {
      return this.selectableClassifications.filter(x => x.overtimeSelected);
    },
    overtimeSelectedClassificationIDs(): string[] {
      return this.overtimeSelectedClassifications.map(x => x.id!);
    },
    doubletimeSelectedClassifications(): ClassificationWithSelected[] {
      return this.selectableClassifications.filter(x => x.doubletimeSelected);
    },
    doubletimeSelectedClassificationIDs(): string[] {
      return this.doubletimeSelectedClassifications.map(x => x.id!);
    },

    searchedClassifications(): Array<ClassificationWithSelected> {
      // This is a hack because the classifications list won't give us back a list of what it currently
      // has found for searches; we accommodate this by running whatever custom search method
      // they have ourselves
      let customFilter: (value: any, search: string, item: any) => boolean = (this.$refs
        .classificationsDataTable as any)?.customFilter;
      if (this.classificationsTableSearch && customFilter) {
        return this.classifications.filter(
          x =>
            customFilter(x.name!, this.classificationsTableSearch, x) ||
            customFilter(x.description!, this.classificationsTableSearch, x)
        );
      } else {
        return this.classifications;
      }
    },

    /// Used for "Include" header checkbox to determine "checked" state
    allSearchedClassificationsOvertimeSelected(): boolean {
      return this.searchedClassifications.findIndex(x => !x.overtimeSelected) === -1;
    },

    /// Used for "Include" header checkbox to determine "indeterminate" state
    someSearchedClassificationsOvertimeSelected(): boolean {
      var searchedClassifications = this.searchedClassifications;
      return (
        searchedClassifications.findIndex(x => x.overtimeSelected) !== -1 &&
        searchedClassifications.findIndex(x => !x.overtimeSelected) !== -1
      );
    },

    /// Used for "Include" header checkbox to determine "checked" state
    allSearchedClassificationsDoubletimeSelected(): boolean {
      return this.searchedClassifications.findIndex(x => !x.doubletimeSelected) === -1;
    },

    /// Used for "Include" header checkbox to determine "indeterminate" state
    someSearchedClassificationsDoubletimeSelected(): boolean {
      var searchedClassifications = this.searchedClassifications;
      return (
        searchedClassifications.findIndex(x => x.doubletimeSelected) !== -1 &&
        searchedClassifications.findIndex(x => !x.doubletimeSelected) !== -1
      );
    }
  },
  watch: {
    dailySTValue: function(newValue, oldValue) {
      if (newValue == null) {
        // ST now takes all hours, clear out daily OT value
        this.dailyOTValue = 0;
      } else if (oldValue == null) {
        if (this.dailyOTValue == 0) this.dailyOTValue = null;
      } else {
        // ST changed from a number of hours to a different number of hours.  Do nothing.
      }
    },
    weeklySTValue: function(newValue, oldValue) {
      if (newValue == null) {
        // ST now takes all hours, clear out weekly OT value
        this.weeklyOTValue = 0;
      } else if (oldValue == null) {
        if (this.weeklyOTValue == 0.0) this.weeklyOTValue = null;
      } else {
        // ST changed from a number of hours to a different number of hours.  Do nothing.
      }
    },
    "environment.trackScaffoldVLF": function() {
      if (!this.environment.trackScaffoldVLF) {
        this.environment.walkdownVLFRequired = false;
        this.environment.workOrderActualVLFRequired = false;
      }
    },
    // SCAFFOLD ASSIGNMENTS
    "environment.automaticallyApproveScaffoldRequests": function() {
      // In case the flag is enabled with previous default data saved, confirm the contractor that was already selected still is selectable
      this.verifySelectedScaffoldAssignmentDefaults();
    },
    "environment.defaultWorkOrderAssignedContractorID": function() {
      this.verifySelectedScaffoldAssignmentDefaults();
    },
    // MAINTENANCE ASSIGNMENTS
    "environment.automaticallyApproveMaintenanceRequests": function() {
      // In case the flag is enabled with previous default data saved, confirm the contractor that was already selected still is selectable
      this.verifySelectedMaintenanceAssignmentDefaults();
    },
    "environment.defaultMaintenanceWorkOrderAssignedContractorID": function() {
      this.verifySelectedMaintenanceAssignmentDefaults();
    },
    // PAINT ASSIGNMENTS
    "environment.automaticallyApprovePaintRequests": function() {
      // In case the flag is enabled with previous default data saved, confirm the contractor that was already selected still is selectable
      this.verifySelectedPaintAssignmentDefaults();
    },
    "environment.defaultPaintWorkOrderAssignedContractorID": function() {
      this.verifySelectedPaintAssignmentDefaults();
    },
    // INSULATION ASSIGNMENTS
    "environment.automaticallyApproveInsulationRequests": function() {
      // In case the flag is enabled with previous default data saved, confirm the contractor that was already selected still is selectable
      this.verifySelectedInsulationAssignmentDefaults();
    },
    "environment.defaultInsulationWorkOrderAssignedContractorID": function() {
      this.verifySelectedInsulationAssignmentDefaults();
    },
    // COUNT SHEETS
    "environment.automaticallyApproveCountSheets": function() {
      // In case the flag is enabled with previous default data saved, confirm the contractor that was already selected still is selectable
      this.verifySelectedCountSheetDefault();
    }
  },
  methods: {
    fieldKeyDown(e: KeyboardEvent) {
      if (e.key == ".") e.preventDefault();
    },

    // *** LOCATION ***
    async changeLocation() {
      let location = await showLocationDialog({
        location: this.location,
        zoom: 12,
        minZoom: 8,
        boundsSize: null
      });
      if (!!location) {
        this.location = location;
      }
    },
    calculateThresholds(
      hours: number,
      otThreshold: number | null,
      dtThreshold: number | null
    ): { stHours: number; otHours: number; dtHours: number } {
      let stHours = 0;
      let otHours = 0;
      let dtHours = 0;

      if (otThreshold == null || otThreshold == undefined) {
        stHours = hours;
      } else {
        if (hours > otThreshold) {
          stHours = otThreshold;

          if (dtThreshold == null || dtThreshold == undefined) {
            otHours = hours - stHours;
          } else {
            if (hours > dtThreshold) {
              otHours = dtThreshold - otThreshold;
              dtHours = hours - (stHours + otHours);
            } else {
              otHours = hours - stHours;
            }
          }
        } else {
          stHours = hours;
        }
      }

      return {
        stHours: stHours,
        otHours: otHours,
        dtHours: dtHours
      };
    },
    getDailyTimeSummary(
      hasOT: boolean = true,
      hasDT: boolean = true
    ): { stHours: number; otHours: number; dtHours: number } {
      let dtThreshold =
        this.sanitizeNumber(
          this.computedBasicLabourThresholds.defaultDailyEmployeeDoubletimeHoursThreshold
        ) ?? null;

      let otThreshold =
        this.sanitizeNumber(
          this.computedBasicLabourThresholds.defaultDailyEmployeeOvertimeHoursThreshold
        ) ?? null;

      if (!hasDT) dtThreshold = null;
      if (!hasOT) {
        if (dtThreshold != null && dtThreshold != undefined) {
          // If it has double time, but no OT, then we just skip the DT by setting it to 0
          dtThreshold = 0;
        } else {
          // Otherwise, if there's no DT, then we remove the OT by removing the OT Threshold
          otThreshold = null;
        }
      }

      return this.calculateThresholds(this.dailyExampleHours, otThreshold, dtThreshold);
    },
    getWeeklyTimeSummary(): { stHours: number; otHours: number; dtHours: number } {
      let otThreshold =
        this.sanitizeNumber(
          this.computedBasicLabourThresholds.defaultEmployeeOvertimeHoursThreshold
        ) ?? null;

      let dtThreshold =
        this.sanitizeNumber(
          this.computedBasicLabourThresholds.defaultEmployeeDoubletimeHoursThreshold
        ) ?? null;

      return this.calculateThresholds(this.weeklyExampleHours, otThreshold, dtThreshold);
    },
    sanitizeNumber(
      number: string | number | undefined | null,
      allowZero: boolean = true
    ): number | undefined {
      if (number == undefined || number == null) return undefined;
      let val = Number(number);
      if (isNaN(val)) return undefined;
      if (!allowZero && val == 0) return undefined;
      return val;
    },
    getSTValue(
      otThreshold: string | number | null | undefined,
      dtThreshold: string | number | null | undefined
    ): number | null {
      otThreshold = this.sanitizeNumber(otThreshold);
      dtThreshold = this.sanitizeNumber(dtThreshold);
      if (!!otThreshold) return otThreshold;
      else if (!!dtThreshold) return dtThreshold;
      else return null;
    },
    getOTValue(
      otThreshold: string | number | null | undefined,
      dtThreshold: string | number | null | undefined
    ): number | null {
      otThreshold = this.sanitizeNumber(otThreshold);
      dtThreshold = this.sanitizeNumber(dtThreshold);
      if (!!otThreshold) {
        if (!!dtThreshold) return dtThreshold - otThreshold;
        else return null;
      } else {
        return 0.0;
      }
    },
    selectableCoordinators(
      contractorID: string | undefined | null
    ): GroupableSelectListOption<PersonWithName>[] {
      var coordinatorsForSelectedContractor = !!contractorID
        ? this.coordinatorsInContractor(contractorID)
        : [];

      var allProxyCoordinators: GroupableSelectListOption<PersonWithName>[] = [];
      var proxyContractors = this.allContractors.filter(
        x => x.id != contractorID && x.canActAsProxy == true
      );
      if (proxyContractors.length > 0) {
        proxyContractors.forEach(x => {
          var proxyCoordinatorsForContractor = this.proxyCoordinatorsForContractor(
            contractorID!,
            x.id!
          );
          if (proxyCoordinatorsForContractor.length == 0) return;
          if (allProxyCoordinators.length > 0) {
            allProxyCoordinators.push({
              divider: true
            });
          }
          allProxyCoordinators.push({
            header: x.name ?? ""
          });
          allProxyCoordinators = allProxyCoordinators.concat(proxyCoordinatorsForContractor);
        });
        if (!!coordinatorsForSelectedContractor?.length && !!allProxyCoordinators?.length) {
          allProxyCoordinators.push({ divider: true });
          let selectedContractor = this.allContractors.find(x => x.id == contractorID);
          var header =
            selectedContractor?.name ??
            `${this.$t("configuration.defaults.selected-default-contractor")}`;
          allProxyCoordinators.push({
            header: header
          });
        }
      }

      var selectableCoordinators = allProxyCoordinators.concat(coordinatorsForSelectedContractor);

      return selectableCoordinators;
    },

    selectableGeneralForemen(
      contractorID: string | undefined | null
    ): SelectListOption<PersonWithName>[] {
      var generalForemenPeopleForContractor = SortItemsWithName(
        this.allGeneralForemen.filter(
          x =>
            (this.curUserCanViewAllContractors && !x.contractorID) || x.contractorID == contractorID
        )
      );
      return generalForemenPeopleForContractor;
    },

    selectableForemen(contractorID: string | undefined | null): SelectListOption<PersonWithName>[] {
      var foremenPeopleForContractor = SortItemsWithName(
        this.allForemen.filter(
          x =>
            (this.curUserCanViewAllContractors && !x.contractorID) || x.contractorID == contractorID
        )
      );
      return foremenPeopleForContractor;
    },
    selectableStyles(currentStyle: string): SelectListOption<any> {
      return this.styles.map(x => ({
        value: x,
        text: x,
        disabled: x != currentStyle && this.inspectionTimes.findIndex(i => i.style == x) !== -1
      }));
    },
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadYards: "LOAD_YARDS",
      loadCostCodes: "LOAD_PROJECT_COST_CODES",
      loadClassifications: "LOAD_CLASSIFICATIONS",
      loadScaffoldTypeModifiers: "LOAD_SCAFFOLD_TYPE_MODIFIERS",
      loadScaffoldDistanceModifiers: "LOAD_SCAFFOLD_DISTANCE_MODIFIERS",
      loadScaffoldElevationModifiers: "LOAD_SCAFFOLD_ELEVATION_MODIFIERS",
      loadScaffoldHeightModifiers: "LOAD_SCAFFOLD_HEIGHT_MODIFIERS",
      loadBuildDismantleRatios: "LOAD_BUILD_DISMANTLE_RATIOS"
    }),
    // *** GLOBAL ***
    onSubmit(e: Event) {
      e.preventDefault();
      this.save();
    },

    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },

    validate(): boolean {
      this.detailsTabError = !((this.$refs.detailsform as HTMLFormElement)?.validate() ?? false);
      this.workflowTabError = !((this.$refs.workflowform as HTMLFormElement)?.validate() ?? true);
      this.labourTab.error = !((this.$refs.labourform as HTMLFormElement)?.validate() ?? true);
      return !(this.detailsTabError || this.workflowTabError || this.labourTab.error);
    },

    async save() {
      this.inlineMessage.message = null;
      if (!this.validate()) {
        var message = this.$t("configuration.error-message");
        if (this.detailsTabError) message += "\n\t- " + this.detailsTab.tabname;
        if (this.workflowTabError) message += "\n\t- " + this.workflowTab.tabname;
        if (this.labourTab.error) message += "\n\t- " + this.labourTab.tabname;

        this.inlineMessage.message = message;
        this.inlineMessage.type = "error";

        return false;
      }

      this.processing = true;
      this.saving = true;
      try {
        if (this.environment.enableScaffoldLocation) {
          this.environment.latitude = this.$parse.number(this.environment.latitude);
          this.environment.longitude = this.$parse.number(this.environment.longitude);
        } else {
          this.environment.latitude = undefined;
          this.environment.longitude = undefined;
          this.environment.erectWorkOrdersRequireScaffoldLocation = false;
        }

        if (!!this.environment.blendedLabourRate)
          this.environment.blendedLabourRate = +this.environment.blendedLabourRate;
        if (!!this.environment.factor1) this.environment.factor1 = +this.environment.factor1;
        if (!!this.environment.factor2) this.environment.factor2 = +this.environment.factor2;

        if (!!this.environment.defaultCrewSize)
          this.environment.defaultCrewSize = +this.environment.defaultCrewSize;
        if (!!this.environment.defaultFactor1)
          this.environment.defaultFactor1 = +this.environment.defaultFactor1;
        if (!!this.environment.defaultFactor2)
          this.environment.defaultFactor2 = +this.environment.defaultFactor2;

        if (!!this.environment.defaultMaxDailyEmployeeHours)
          this.environment.defaultMaxDailyEmployeeHours = +this.environment
            .defaultMaxDailyEmployeeHours;

        let computedBasicLabourThresholds = this.computedBasicLabourThresholds;
        this.environment.defaultEmployeeOvertimeHoursThreshold =
          this.sanitizeNumber(
            computedBasicLabourThresholds.defaultEmployeeOvertimeHoursThreshold
          ) ?? null;
        this.environment.defaultEmployeeDoubletimeHoursThreshold =
          this.sanitizeNumber(
            computedBasicLabourThresholds.defaultEmployeeDoubletimeHoursThreshold
          ) ?? null;

        this.environment.defaultDailyEmployeeOvertimeHoursThreshold =
          this.sanitizeNumber(
            computedBasicLabourThresholds.defaultDailyEmployeeOvertimeHoursThreshold
          ) ?? null;
        this.environment.defaultDailyEmployeeDoubletimeHoursThreshold =
          this.sanitizeNumber(
            computedBasicLabourThresholds.defaultDailyEmployeeDoubletimeHoursThreshold
          ) ?? null;

        await environmentService.updateItem(this.environment.id!, this.environment);
        this.$store.commit("SET_CUR_ENVIRONMENT", this.environment);

        await environmentService.saveEnvironmentDefaultInspectionTimes(this.inspectionTimes);
        await environmentService.saveEnvironmentDailyThresholdClassifications(
          this.overtimeSelectedClassificationIDs,
          this.doubletimeSelectedClassificationIDs
        );

        var snackbarPayload = {
          text: this.$t("configuration.snack-bar-updated-message"),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.saving = false;
        this.processing = false;
      }
    },

    // *** WORKFLOW ***
    // DOES NOT manage processing or error message logic
    async loadDefaultsReferenceData() {
      await Promise.all([
        this.loadContractors(),
        this.loadCoordinators(),
        this.loadGeneralForemen(),
        this.loadForemen(),
        this.loadYards(),
        this.loadCostCodes(),
        this.loadClassifications(),
        this.loadScaffoldTypeModifiers(),
        this.loadScaffoldHeightModifiers(),
        this.loadScaffoldElevationModifiers(),
        this.loadScaffoldDistanceModifiers(),
        this.loadBuildDismantleRatios()
      ]);
      this.inspectionTimes = (await environmentService.getEnvironmentDefaultInspectionTimes())
        .map(x => ({
          ...x,
          time: new Date(`${isoDateString(new Date())}T${isoTimeWithOffsetString(x.time)}`)
        }))
        .sort();
    },
    async loadContractors(): Promise<void> {
      let allContractors = await contractorService.getAll(false, null, null);

      this.allContractors = allContractors;
      this.scaffoldContractors = allContractors.filter(x => !!x.isScaffoldCompany);
      this.paintContractors = allContractors.filter(x => !!x.isPaintCompany);
      this.insulationContractors = allContractors.filter(x => !!x.isInsulationCompany);
      this.maintenanceContractors = allContractors.filter(x => !!x.isMaintenanceCompany);
    },

    // DOES NOT manage processing or error message logic
    async loadCoordinators(): Promise<void> {
      let coordinators = await personService.getAllCoordinators();
      this.allCoordinators = coordinators.map(x => {
        return {
          ...x,
          name: GetPersonName(x)
        };
      });
    },

    // DOES NOT manage processing or error message logic
    async loadGeneralForemen(): Promise<void> {
      let generalForemen = await personService.getAllGeneralForemen();
      this.allGeneralForemen = generalForemen.map(x => {
        return {
          ...x,
          name: GetPersonName(x)
        };
      });
    },

    // DOES NOT manage processing or error message logic
    async loadForemen(): Promise<void> {
      let foremen = await personService.getAllForemen();
      this.allForemen = foremen.map(x => {
        return {
          ...x,
          name: GetPersonName(x)
        };
      });
    },

    coordinatorsInContractor(contractorID: string) {
      var coordinatorsForContractor = SortItemsWithName(
        this.allCoordinators.filter(x => !!x.contractorID && x.contractorID == contractorID)
      );
      return coordinatorsForContractor;
    },

    proxyCoordinatorsForContractor(assignedContractorID: string, homeContractorID: string): any[] {
      // Since a proxy contractor is a proxy for all contractors, we don't need to restrict proxy contractors
      var proxyCoordinatorsInContractor = this.coordinatorsInContractor(homeContractorID);
      if (!proxyCoordinatorsInContractor?.length) return [];

      let proxyCoordinatorsForContractor = [] as any[];
      proxyCoordinatorsInContractor.forEach(coordinator => {
        if (!!coordinator.includesAllContractors && coordinator.includesAllContractors == true) {
          proxyCoordinatorsForContractor.push(coordinator);
          return;
        }
        if (!coordinator.contractorIDJson) return;

        let coordinatorVisibleContractorIDs = JSON.parse(coordinator.contractorIDJson) as string[];
        if (!coordinatorVisibleContractorIDs?.length) return;
        if (!coordinatorVisibleContractorIDs.includes(assignedContractorID)) return;

        proxyCoordinatorsForContractor.push(coordinator);
      });

      return proxyCoordinatorsForContractor;
    },

    verifySelectedScaffoldAssignmentDefaults() {
      let existingContractor = this.allContractors.find(
        x => x.id == this.environment.defaultWorkOrderAssignedContractorID
      );
      if (!existingContractor) this.environment.defaultWorkOrderAssignedContractorID = null;

      let existingCoordinator = this.selectableCoordinators(
        this.environment.defaultWorkOrderAssignedContractorID
      ).find(
        x => (x as PersonWithName)?.id == this.environment.defaultWorkOrderAssignedCoordinatorID
      );
      if (!existingCoordinator) this.environment.defaultWorkOrderAssignedCoordinatorID = null;

      let existingGeneralForeman = this.selectableGeneralForemen(
        this.environment.defaultWorkOrderAssignedContractorID
      ).find(
        x => (x as PersonWithName)?.id == this.environment.defaultWorkOrderAssignedGeneralForemanID
      );
      if (!existingGeneralForeman) this.environment.defaultWorkOrderAssignedGeneralForemanID = null;

      let existingForeman = this.selectableForemen(
        this.environment.defaultWorkOrderAssignedContractorID
      ).find(x => (x as PersonWithName)?.id == this.environment.defaultWorkOrderAssignedForemanID);
      if (!existingForeman) this.environment.defaultWorkOrderAssignedForemanID = null;
    },
    verifySelectedMaintenanceAssignmentDefaults() {
      let existingContractor = this.allContractors.find(
        x => x.id == this.environment.defaultMaintenanceWorkOrderAssignedContractorID
      );
      if (!existingContractor)
        this.environment.defaultMaintenanceWorkOrderAssignedContractorID = null;

      let existingCoordinator = this.selectableCoordinators(
        this.environment.defaultMaintenanceWorkOrderAssignedContractorID
      ).find(
        x =>
          (x as PersonWithName)?.id ==
          this.environment.defaultMaintenanceWorkOrderAssignedCoordinatorID
      );
      if (!existingCoordinator)
        this.environment.defaultMaintenanceWorkOrderAssignedCoordinatorID = null;

      let existingGeneralForeman = this.selectableGeneralForemen(
        this.environment.defaultMaintenanceWorkOrderAssignedContractorID
      ).find(
        x =>
          (x as PersonWithName)?.id ==
          this.environment.defaultMaintenanceWorkOrderAssignedGeneralForemanID
      );
      if (!existingGeneralForeman)
        this.environment.defaultMaintenanceWorkOrderAssignedGeneralForemanID = null;

      let existingForeman = this.selectableForemen(
        this.environment.defaultMaintenanceWorkOrderAssignedContractorID
      ).find(
        x =>
          (x as PersonWithName)?.id == this.environment.defaultMaintenanceWorkOrderAssignedForemanID
      );
      if (!existingForeman) this.environment.defaultMaintenanceWorkOrderAssignedForemanID = null;
    },
    verifySelectedPaintAssignmentDefaults() {
      let existingContractor = this.allContractors.find(
        x => x.id == this.environment.defaultPaintWorkOrderAssignedContractorID
      );
      if (!existingContractor) this.environment.defaultPaintWorkOrderAssignedContractorID = null;

      let existingCoordinator = this.selectableCoordinators(
        this.environment.defaultPaintWorkOrderAssignedContractorID
      ).find(
        x =>
          (x as PersonWithName)?.id == this.environment.defaultPaintWorkOrderAssignedCoordinatorID
      );
      if (!existingCoordinator) this.environment.defaultPaintWorkOrderAssignedCoordinatorID = null;

      let existingGeneralForeman = this.selectableGeneralForemen(
        this.environment.defaultPaintWorkOrderAssignedContractorID
      ).find(
        x =>
          (x as PersonWithName)?.id ==
          this.environment.defaultPaintWorkOrderAssignedGeneralForemanID
      );
      if (!existingGeneralForeman)
        this.environment.defaultPaintWorkOrderAssignedGeneralForemanID = null;

      let existingForeman = this.selectableForemen(
        this.environment.defaultPaintWorkOrderAssignedContractorID
      ).find(
        x => (x as PersonWithName)?.id == this.environment.defaultPaintWorkOrderAssignedForemanID
      );
      if (!existingForeman) this.environment.defaultPaintWorkOrderAssignedForemanID = null;
    },
    verifySelectedInsulationAssignmentDefaults() {
      let existingContractor = this.allContractors.find(
        x => x.id == this.environment.defaultInsulationWorkOrderAssignedContractorID
      );
      if (!existingContractor)
        this.environment.defaultInsulationWorkOrderAssignedContractorID = null;

      let existingCoordinator = this.selectableCoordinators(
        this.environment.defaultInsulationWorkOrderAssignedContractorID
      ).find(
        x =>
          (x as PersonWithName)?.id ==
          this.environment.defaultInsulationWorkOrderAssignedCoordinatorID
      );
      if (!existingCoordinator)
        this.environment.defaultInsulationWorkOrderAssignedCoordinatorID = null;

      let existingGeneralForeman = this.selectableGeneralForemen(
        this.environment.defaultInsulationWorkOrderAssignedContractorID
      ).find(
        x =>
          (x as PersonWithName)?.id ==
          this.environment.defaultInsulationWorkOrderAssignedGeneralForemanID
      );
      if (!existingGeneralForeman)
        this.environment.defaultInsulationWorkOrderAssignedGeneralForemanID = null;

      let existingForeman = this.selectableForemen(
        this.environment.defaultInsulationWorkOrderAssignedContractorID
      ).find(
        x =>
          (x as PersonWithName)?.id == this.environment.defaultInsulationWorkOrderAssignedForemanID
      );
      if (!existingForeman) this.environment.defaultInsulationWorkOrderAssignedForemanID = null;
    },
    verifySelectedCountSheetDefault() {
      let existingFromYard = this.allYards.find(
        x => x.id == this.environment.defaultCountSheetFromYardID
      );
      if (!existingFromYard) this.environment.defaultCountSheetFromYardID = null;
      let existingToYard = this.allYards.find(
        x => x.id == this.environment.defaultCountSheetToYardID
      );
      if (!existingToYard) this.environment.defaultCountSheetToYardID = null;
    },

    // *** LABOUR ***
    flipClassificationOvertimeSelected(item: ClassificationWithSelected) {
      item.overtimeSelected = !item.overtimeSelected;
    },

    flipSearchedClassificationsOvertimeSelected() {
      let selected = !this.allSearchedClassificationsOvertimeSelected;
      for (let classification of this.searchedClassifications) {
        if (classification.overtimeSelected !== selected) {
          classification.overtimeSelected = selected;
        }
      }
    },
    flipClassificationDoubletimeSelected(item: ClassificationWithSelected) {
      item.doubletimeSelected = !item.doubletimeSelected;
    },

    flipSearchedClassificationsDoubletimeSelected() {
      let selected = !this.allSearchedClassificationsDoubletimeSelected;
      for (let classification of this.searchedClassifications) {
        if (classification.doubletimeSelected !== selected) {
          classification.doubletimeSelected = selected;
        }
      }
    },

    // *** INSPECTIONS ***
    getTimeRangeSummaryForTime(item: InspectionTime) {
      var sortedTimes = this.inspectionTimes
        .slice()
        .sort((a, b) => a.time!.getTime() - b.time!.getTime());
      const startTime = stripDateFromLocalizedDateTime(item.time);

      // The end time of this range is the next start time.  If the item is the last start time, the index will be beyond the bounds of the array.  Use the first start time as its end time.
      let index = sortedTimes.indexOf(item) + 1;
      if (index >= sortedTimes.length) index = 0;
      const endTime = stripDateFromLocalizedDateTime(sortedTimes[index].time);
      return this.$t("configuration.inspections.inspection-time-range-summary", [
        startTime,
        endTime,
        Intl.DateTimeFormat().resolvedOptions().timeZone
      ]);
    },
    removeInspectionTime(item: InspectionTime) {
      const index = this.inspectionTimes.indexOf(item);
      if (index < 0) {
        return;
      }
      this.inspectionTimes.splice(index, 1);
    },
    async addInspectionTime() {
      let allowedStyles = this.selectableStyles("")
        .filter((x: SelectListOption<any>) => !x.disabled)
        .map((x: SelectListOption<any>) => x.value);
      let newTime = await openNewScaffoldInspectionTimeDialog(allowedStyles);
      if (!!newTime) {
        this.inspectionTimes.push(newTime);
      }
    },

    // *** IMPORTS ***
    async uploadImportFile() {
      this.inlineMessage.message = null;

      if (!this.importFile) return;

      this.processing = true;
      this.uploading = true;
      try {
        await environmentService.uploadEnvironmentBaseDataFile(this.importFile);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.uploading = false;
      }
    }
  },

  created: async function() {
    this.notifyNewBreadcrumb({
      text: this.$t("configuration.title"),
      to: "/configuration",
      resetHistory: true
    });

    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    this.setFilteringContext({
      context: this.$t("configuration.title"),
      parentalContext: null,
      selectedTab: this.firstTabKey
    });

    this.processing = true;
    try {
      await this.loadDefaultsReferenceData();

      let environment = this.$store.state.curEnvironment;
      if (!!environment) {
        this.environment = {
          ...environment,
          created: undefined,
          createdBy: undefined,
          updated: undefined,
          updatedBy: undefined
        };
      }
      this.dailySTValue = this.getSTValue(
        this.environment.defaultDailyEmployeeOvertimeHoursThreshold,
        this.environment.defaultDailyEmployeeDoubletimeHoursThreshold
      );
      this.dailyOTValue = this.getOTValue(
        this.environment.defaultDailyEmployeeOvertimeHoursThreshold,
        this.environment.defaultDailyEmployeeDoubletimeHoursThreshold
      );
      this.weeklySTValue = this.getSTValue(
        this.environment.defaultEmployeeOvertimeHoursThreshold,
        this.environment.defaultEmployeeDoubletimeHoursThreshold
      );
      this.weeklyOTValue = this.getOTValue(
        this.environment.defaultEmployeeOvertimeHoursThreshold,
        this.environment.defaultEmployeeDoubletimeHoursThreshold
      );

      this.selectableClassifications = (this.$store.state.classifications
        .fullList as Classification[]).map(x => {
        return {
          ...x,
          overtimeSelected: !!x.enableDailyOvertimeThreshold,
          doubletimeSelected: !!x.enableDailyDoubletimeThreshold,
          description: truncateWithEllipsis(stripHtml(x.description))
        } as ClassificationWithSelected;
      });
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

