import { mapMutations, mapActions, mapState } from "vuex";
import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import rules from "@fd/lib/vue/rules";
import { Part, ScaffoldBayLength } from "@fd/current/client/services";

type ScaffoldBayLengthWithDetails = ScaffoldBayLength & { archived: boolean };

export default FDVue.extend({
  name: "fd-scaffold-bay-length-existing",

  mixins: [errorHandling, rules],

  components: {
    "fd-subheader": () => import("@fd/lib/vue/components/layout/Subheader.vue"),
    "sp-part-selection-field": () => import("./components/SP.PartSelectionField.vue")
  },

  data: function() {
    return {
      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,

      slidein: false,

      scaffoldBayLength: {} as ScaffoldBayLengthWithDetails
    };
  },

  computed: {
    parts(): Part[] {
      return (this.$store.state.parts.fullList as Part[]).map(x => ({
        ...x,
        searchableData: `${x.publicID} ${x.name} ${x.description}`
      }));
    }
  },

  methods: {
    onSubmit(e: Event) {
      e.preventDefault();
      this.save(false);
    },

    // Method used in conjunction with the Save button.
    async save(closeOnComplete: boolean) {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";

      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        if (!this.scaffoldBayLength.archived) {
          this.scaffoldBayLength.archivedDate = null;
        } else if (this.scaffoldBayLength.archived && !this.scaffoldBayLength.archivedDate) {
          this.scaffoldBayLength.archivedDate = new Date(new Date().toUTCString());
        }

        await this.updateScaffoldBayLength({
          ...this.scaffoldBayLength,
          inches: !!this.scaffoldBayLength.inches ? +this.scaffoldBayLength.inches : undefined,
          id: this.$route.params.id
        });

        if (closeOnComplete) {
          this.$router.push("/estimatesetup");
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },
    // the following works with the delete "Action" button in the Datatable.
    async deleteItem() {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await this.deleteScaffoldBayLength({
          id: this.$route.params.id,
          name: this.scaffoldBayLength.name
        });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.$router.push("/estimatesetup");
      }
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      // TODO: Should this roll back state rather than rely on requerying?
      this.$router.push("/estimatesetup");
    },
    ...mapMutations({
      setScaffoldBayLength: "SET_SCAFFOLD_BAY_LENGTH",
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    ...mapActions({
      loadParts: "LOAD_PARTS",
      loadScaffoldBayLength: "LOAD_SCAFFOLD_BAY_LENGTH",
      updateScaffoldBayLength: "UPDATE_SCAFFOLD_BAY_LENGTH",
      deleteScaffoldBayLength: "DELETE_SCAFFOLD_BAY_LENGTH"
    })
  },

  watch: {
    scaffoldBayLength(newValue) {
      console.log(`watch.scaffoldBayLength`);
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/estimatesetup") {
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
        this.notifyNewBreadcrumb({
          text: this.$t("estimate-setup.title"),
          to: "/estimatesetup",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }

      this.notifyNewBreadcrumb({
        text: this.scaffoldBayLength.name,
        to: `/estimatesetup/scaffoldbaylengths/${this.$route.params.id}`,
        history: [
          {
            text: this.$t("scaffold-bays.bay-lengths.title"),
            disabled: true
          }
        ]
      });
    }
  },

  created: async function() {
    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/estimatesetup") {
      this.notifyNewBreadcrumb({
        text: this.$t("estimate-setup.title"),
        to: "/estimatesetup",
        resetHistory: true
      });
      // This is needed in order to salvage the "last breadcrumbs" in the store.
      this.$store.commit("NOTIFY_NAVIGATION_STARTED");
    }

    this.notifyNewBreadcrumb({
      text: this.$t("loading-dot-dot-dot"),
      disabled: true,
      history: [
        {
          text: this.$t("scaffold-bays.bay-lengths.title"),
          disabled: true
        }
      ]
    });

    this.processing = true;
    try {
      await this.loadParts();
      await this.loadScaffoldBayLength(this.$route.params.id);
      var scaffoldBayLength = this.$store.state.scaffoldBayLengths.fullList.find(
        (x: ScaffoldBayLength) => x.id == this.$route.params.id
      );
      this.scaffoldBayLength = {
        ...scaffoldBayLength,
        archived: !!scaffoldBayLength.archivedDate
      } as ScaffoldBayLengthWithDetails;
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

