import { PropType } from "vue";
import FDVue from "../../../../../lib/vue";
import VueI18n from "vue-i18n";
import { ContractorPurchaseOrder } from "../../../services";

type Rule = (value: any) => boolean | VueI18n.TranslateResult;

export default FDVue.extend({
  name: "sp-work-order-scope-details-form",
  props: {
    disabled: { type: Boolean, default: false },

    isClientWorkOrder: { type: Boolean, default: false },
    clientWorkOrderReferenceNumber: { type: String },
    clientWorkOrderReferenceNumberRules: { type: Array as PropType<Array<Rule>> },
    clientWorkOrderReason: { type: String },
    clientWorkOrderReasonRules: { type: Array as PropType<Array<Rule>> },

    isServiceOrder: { type: Boolean, default: false },
    serviceOrderReferenceNumber: { type: String },
    serviceOrderReferenceNumberRules: { type: Array as PropType<Array<Rule>> },
    serviceOrderReason: { type: String },
    serviceOrderReasonRules: { type: Array as PropType<Array<Rule>> },

    isChangeOrder: { type: Boolean, default: false },
    changeOrderReferenceNumber: { type: String },
    changeOrderReferenceNumberRules: { type: Array as PropType<Array<Rule>> },
    changeOrderReason: { type: String },
    changeOrderReasonRules: { type: Array as PropType<Array<Rule>> },

    isRework: { type: Boolean, default: false },
    reworkReferenceNumber: { type: String },
    reworkReferenceNumberRules: { type: Array as PropType<Array<Rule>> },
    reworkReason: { type: String },
    reworkReasonRules: { type: Array as PropType<Array<Rule>> },

    purchaseOrders: { type: Boolean, default: false },
    purchaseOrderID: { type: String },
    purchaseOrderIDRules: { type: Array as PropType<Array<Rule>> },

    existingTagNumber: { type: String, default: undefined },
    existingTagNumberRules: { type: Array as PropType<Array<Rule>> },

    selectablePurchaseOrders: {
      type: Array as PropType<Array<ContractorPurchaseOrder>>,
      default: () => []
    }
  },

  components: {
    "fd-subheader": () => import("@fd/lib/vue/components/layout/Subheader.vue")
  },

  methods: {
    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },
    validate() {
      console.log(`\t\t WorkOrderScopeDetailsForm.validate()`);
      let form = this.$refs.form;
      console.log(`\t\t\t form: ${form}`);
      let formValid = (form as HTMLFormElement)?.validate();
      console.log(`\t\t\t formValid: ${formValid}`);
      return formValid;
    }
  }
});

