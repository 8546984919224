import FDVue from "@fd/lib/vue";
import rules from "@fd/lib/vue/rules";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import { TranslateResult } from "vue-i18n";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import {
  Classification,
  ContractorWithTags,
  CrewWithEmployees,
  EmployeeTimeSummary,
  PersonWithDetails,
  ProjectLocation,
  projectLocationService,
  ScaffoldRequestTypes,
  TimesheetExplanationWithWorkOrderDetails,
  TimesheetStatus,
  TimesheetType,
  WorkOrderSearchResult,
  workOrderService,
  WorkOrderStatuses,
  WorkSubType,
  WorkSubTypeTimeSummary,
  WorkType
} from "../../../services";
import {
  SortTimesheetRows,
  TimesheetRow,
  TimesheetRowType,
  UpdatableTimesheetEntryWithDetails,
  UpdatableTimesheetWithTimesheetRows,
  TableHeader,
  SortWorkTypes,
  SortWorkSubTypes,
  ParseWorkSubTypeIDsFromRow,
  SortAllWorkSubTypes,
  areTimesheetRowsEqual,
  HashTable,
  TimesheetRowTimeValues,
  CalculateRowTotalTime,
  UpdateRowCorrections,
  FindRelatedRowForCorrection
} from "../../../utils/timesheet";
import userAccess from "../../../dataMixins/userAccess";
import {
  GetPersonName,
  PersonHasEquipmentClassification,
  PersonWithDetailsAndName,
  SortItemsWithName
} from "../../../utils/person";
import { mapActions, mapMutations } from "vuex";
import { VDataTable } from "@fd/lib/vue/types";
import {
  BasicSelectItem,
  GroupableSelectListOption,
  SelectListOption
} from "@fd/lib/vue/utility/select";
import tabbedView, { Tab } from "@fd/lib/vue/mixins/tabbedView";
import { showAdditionalDetailsDialog } from "../../../../../common/client/views/components/AdditionalDetailsDialog.vue";
import { stripTimeFromLocalizedDateTime } from "@fd/lib/client-util/datetime";
import { SortCrewEmployees } from "../dialogs/CrewDetailsBottomDialog.vue";
import { PropType } from "vue";
import { formatWorkOrderNumber } from "../../../utils/workorder";

type FormattedWorkOrder = WorkOrderSearchResult & { details: string; description: string };
type TransactionType = "equipment" | "indirect" | "generaldirect" | "workorder";
type PossibleNumber = number | string | null | undefined;
type ClassificationWithDisplayName = Classification & { displayName: string | undefined };

export default FDVue.extend({
  name: "sp-foreman-timesheet-form",
  inheritAttrs: false,
  mixins: [serviceErrorHandling, rules, tabbedView, userAccess],
  components: {
    "fd-async-search-box": () => import("@fd/lib/vue/components/AsyncSearchBox.vue"),
    "sp-timesheet-time-display": () => import("../SP.TimesheetTimeDisplay.vue"),
    "sp-timesheet-perdiem": () => import("./ForemanTimesheetPerdiem.vue")
  },
  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },
  props: {
    timesheet: {
      type: Object as PropType<UpdatableTimesheetWithTimesheetRows>,
      default: undefined
    },
    employeeTimeSummaries: { type: Array, default: undefined },
    readOnly: { type: Boolean, default: false },
    isInDialog: { type: Boolean, default: false },
    parentContext: { type: String, default: undefined },
    makeCorrections: { type: Boolean, default: false },
    usedInDialog: { type: Boolean, default: false },
    loading: { type: Boolean, default: false }
  },
  data: function() {
    return {
      // Variable to hold which expansion panel is currently open
      panel: 0,
      firstTabKey: `0`,
      workspaceTab: {
        tabname: this.$t("timesheets.existing.tabs.workspace"),
        key: "0",
        visible: true
      } as Tab,
      summaryTab: {
        tabname: this.$t("timesheets.existing.tabs.summary"),
        key: "1",
        visible: true
      } as Tab,

      allAreas: [] as ProjectLocation[],
      allSubAreas: [] as ProjectLocation[],
      selectedEmployeeID: null as string | null,
      selectedCrewID: null as string | null,

      availableWorkOrders: [] as FormattedWorkOrder[],
      selectedEntryType: null as TransactionType | null,
      selectedWorkOrders: [] as FormattedWorkOrder[],

      workOrderSearch: "",
      workOrderSearching: false,
      didWorkOrderSearch: false,
      timer: null as NodeJS.Timeout | null
    };
  },

  computed: {
    maxScreenHeightToIgnore(): number {
      return window.innerHeight - 300;
    },
    screenHeightAroundTableInDialog(): number {
      let topPadding = 24;
      let bottomPadding = 24;

      let titleBar = 58 + 64;
      let filterControls = 176;
      let tabs = 72;

      let openExpanderTitle = 64;
      let tableBottomPadding = 16;
      let closedExpanderTitle = 48;

      let actionsBar = 52;

      if (this.$vuetify.breakpoint.xsOnly) {
        filterControls = 260;
        titleBar = 58 + 88;
        actionsBar = 44;
      }

      return Math.min(
        this.maxScreenHeightToIgnore,
        topPadding +
          bottomPadding +
          titleBar +
          filterControls +
          tabs +
          openExpanderTitle +
          closedExpanderTitle +
          tableBottomPadding +
          actionsBar
      );
    },
    screenHeightAroundTable(): number {
      if (this.isInDialog) {
        return this.screenHeightAroundTableInDialog;
      }

      let topToolBar = 75;
      let topPadding = 12;
      let bottomPadding = 0;

      let titleBar = 64;
      let filterControls = 164;
      let tabs = 72;

      let openExpanderTitle = 64;
      let tableBottomPadding = 16;
      let closedExpanderTitle = 48;

      let actionsBar = 52;

      // At mobile breakpoints this becomes the tab bar, but it's the same height
      let footer = 52 + 20;

      if (this.isInDialog) {
        topPadding = 24;
        bottomPadding = 24;
        titleBar = 58 + 88;

        topToolBar = 0;
        footer = 0;
      }

      if (this.$vuetify.breakpoint.xlOnly) {
        filterControls = 68;
      } else if (this.$vuetify.breakpoint.smOnly) {
        actionsBar = 104;
      } else if (this.$vuetify.breakpoint.xsOnly) {
        filterControls = 260;
        if (this.isInDialog) {
          titleBar = 58 + 64;
          actionsBar = 44;
        } else {
          topToolBar = 96;
          actionsBar = 88;
        }
      }

      return Math.min(
        this.maxScreenHeightToIgnore,
        topToolBar +
          topPadding +
          bottomPadding +
          titleBar +
          filterControls +
          tabs +
          openExpanderTitle +
          closedExpanderTitle +
          tableBottomPadding +
          actionsBar +
          footer
      );
    },
    screenHeightAroundDirectTable(): number {
      return this.screenHeightAroundTable;
    },
    screenHeightAroundGeneralTable(): number {
      return this.screenHeightAroundTable;
    },
    screenHeightAroundIndirectTable(): number {
      let closedExpanderTitle = 48;
      return Math.min(
        this.maxScreenHeightToIgnore,
        this.screenHeightAroundTable - closedExpanderTitle
      );
    },
    screenHeightAroundEquipmentTable(): number {
      let closedExpanderTitle = 48;
      return Math.min(
        this.maxScreenHeightToIgnore,
        this.screenHeightAroundTable - closedExpanderTitle
      );
    },
    screenHeightAroundSummaryTable(): number {
      let openExpanderTitle = 64;
      let closedExpanderTitle = 48;
      let tableBottomPadding = 16;
      return Math.min(
        this.maxScreenHeightToIgnore,
        this.screenHeightAroundTable -
          (openExpanderTitle + closedExpanderTitle + tableBottomPadding)
      );
    },
    isProcessing(): boolean {
      return this.loading || this.processing;
    },
    currentTimesheetHasAnyCorrectionRows(): boolean {
      return (
        !!this.currentTimesheet &&
        this.currentTimesheet.timesheetRows.findIndex(x => !!x.isCorrectionRow) != -1
      );
    },
    canEditCurrentTimesheet(): boolean {
      return this.canEditTimesheet(this.currentTimesheet);
    },
    timeSummaries(): EmployeeTimeSummary[] | undefined {
      return this.employeeTimeSummaries as [EmployeeTimeSummary];
    },
    hasWorkOrderDirectRows(): boolean {
      let rows = this.allTimesheetRows.filter(
        x => x.rowType == TimesheetRowType.DirectWorkOrderRelated && !!x.workOrderID
      );
      return !!rows?.length;
    },
    workOrderDirectPanelNumber(): number {
      return 0;
      // let panelNumber = 0;
      // if (!this.hasWorkOrderDirectRows) panelNumber -= 1;
      // return panelNumber;
    },
    hasGeneralDirectRows(): boolean {
      let rows = this.allTimesheetRows.filter(x => x.rowType == TimesheetRowType.DirectGeneral);
      return !!rows?.length;
    },
    generalDirectPanelNumber(): number {
      return 1;
      // let panelNumber = 1;
      // if (!this.hasWorkOrderDirectRows) panelNumber -= 1;
      // if (!this.hasGeneralDirectRows) panelNumber -= 1;
      // return panelNumber;
    },
    hasIndirectRows(): boolean {
      let rows = this.allTimesheetRows.filter(x => x.rowType == TimesheetRowType.Indirect);
      return !!rows?.length;
    },
    indirectPanelNumber(): number {
      return 2;
    },
    hasEquipmentRows(): boolean {
      let rows = this.allTimesheetRows.filter(x => x.rowType == TimesheetRowType.Equipment);
      return !!rows?.length;
    },
    equipmentPanelNumber(): number {
      return 3;
    },
    allTimesheetRows(): TimesheetRow[] {
      return this.currentTimesheet?.timesheetRows ?? [];
    },
    indirectTableHeader(): string {
      return this.$t("timesheets.existing.indirect-table-header")
        .toString()
        .toUpperCase();
    },
    indirectTimesheetRows(): TimesheetRow[] {
      return this.allTimesheetRows.filter(
        x => !x.workOrderID && x.rowType == TimesheetRowType.Indirect
      );
    },
    equipmentTableHeader(): string {
      return this.$t("timesheets.existing.equipment-table-header")
        .toString()
        .toUpperCase();
    },
    equipmentTimesheetRows(): TimesheetRow[] {
      return this.allTimesheetRows.filter(
        x => !x.workOrderID && x.rowType == TimesheetRowType.Equipment
      );
    },
    generalizedDirectTimesheetRows(): TimesheetRow[] {
      return this.allTimesheetRows.filter(
        x => !x.workOrderID && x.rowType == TimesheetRowType.DirectGeneral
      );
    },
    workOrderTableHeader(): string {
      return this.$t("timesheets.existing.work-order-table-header")
        .toString()
        .toUpperCase();
    },
    workOrderTimesheetRows(): TimesheetRow[] {
      return this.allTimesheetRows.filter(x => !!x.workOrderID);
    },
    tabDefinitions(): Tab[] {
      return [this.summaryTab];
    },
    workTypeOptions(): any[] {
      // if (!this.currentTimesheet?.id) return [];

      let options = [] as BasicSelectItem[];
      if (this.currentTimesheet?.timesheetTypeID == TimesheetType.Indirect) {
        options.push({
          text: this.$t("timesheets.existing.indirect-label"),
          value: "indirect" as TransactionType
        });
      } else if (this.currentTimesheet?.timesheetTypeID == TimesheetType.Equipment) {
        options.push({
          text: this.$t("timesheets.existing.equipment-label"),
          value: "equipment" as TransactionType
        });
      } else {
        options.push({
          text: this.$t("timesheets.existing.generalized-direct-label"),
          value: "generaldirect" as TransactionType
        });
        options.push({
          text: this.$t("timesheets.existing.work-orders-label"),
          value: "workorder" as TransactionType
          // disabled: !this.availableWorkOrders?.length && !this.makeCorrections
        });
      }
      return options;
    },
    workOrderNumbersWithDetailWorkSubTypes(): any[] {
      if (!this.currentTimesheet?.id) return [];
      return [...new Set(this.currentTimesheet.explanations?.map(x => x.workOrderNumber))];
    },
    currentTimesheet(): UpdatableTimesheetWithTimesheetRows | undefined {
      return this.timesheet;
    },
    currentTimesheetIsEquipment(): boolean {
      return (this.currentTimesheet?.timesheetTypeID ?? 0) == TimesheetType.Equipment;
    },
    currentTimesheetIsReadonly(): boolean {
      return this.readOnly;
    },
    timesheetIsInDialog(): boolean {
      return this.usedInDialog;
    },
    canModifySelectedCrew(): boolean {
      if (!this.selectedCrewID) return false;
      return this.selectedCrew?.ownerID == this.curUserID || this.currentUserCanConfigureSettings;
    },
    // Used for both the table templates (sorting doesn't matter) and table headers (sorting DOES matter)
    allContractorWorkSubTypes(): WorkSubType[] {
      let contractorWorkSubTypes = [] as WorkSubType[];
      this.selectableWorkTypes.forEach(wt => {
        let subTypes = SortWorkSubTypes(
          this.allSelectableWorkSubTypes.filter(wst => wst.workTypeID == wt.id)
        );
        subTypes.forEach(wst => {
          contractorWorkSubTypes.push(wst);
        });
      });
      return contractorWorkSubTypes;
    },
    summaryWorkSubTypes(): WorkSubType[] {
      // Equipment timesheets don't have work sub types, and always
      if (this.currentTimesheetIsEquipment) return [];

      let summaryWorkSubTypes = [] as WorkSubType[];

      if (this.hasIndirectRows) {
        summaryWorkSubTypes = summaryWorkSubTypes.concat(this.indirectWorkSubTypes);
      }
      if (this.hasGeneralDirectRows) {
        summaryWorkSubTypes = summaryWorkSubTypes.concat(this.generalizedDirectWorkSubTypes);
      }
      if (this.hasWorkOrderDirectRows) {
        summaryWorkSubTypes = summaryWorkSubTypes.concat(this.workOrderWorkSubTypes);
      }

      return SortAllWorkSubTypes(summaryWorkSubTypes, this.allWorkTypes);
    },
    workOrderWorkSubTypes(): WorkSubType[] {
      return this.allContractorWorkSubTypes.filter(
        x => this.workSubTypeIsDirect(x.id!) && this.workSubTypeIsDirectAndWorkOrderRelated(x.id!)
      );
    },
    indirectWorkSubTypes(): WorkSubType[] {
      return this.allContractorWorkSubTypes.filter(x => this.workSubTypeIsIndirect(x.id!));
    },
    generalizedDirectWorkSubTypes(): WorkSubType[] {
      return this.allContractorWorkSubTypes.filter(
        x => this.workSubTypeIsDirect(x.id!) && !this.workSubTypeIsDirectAndWorkOrderRelated(x.id!)
      );
    },
    summaryTableHeaders(): TableHeader[] {
      let headers = [
        {
          value: "empty",
          sortable: false,
          class: "fd-table-icon-cell fd-table-frozen-column",
          cellClass: "fd-table-icon-cell fd-table-frozen-column"
        },
        {
          text: this.$t("timesheets.existing.employee-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "employeeName",
          class: "fd-table-frozen-column",
          cellClass: "fd-table-frozen-column"
        },
        {
          text: this.$t("timesheets.existing.employee-code-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "employeeCode",
          class: "fd-table-frozen-column fd-summary-employee-number-column",
          cellClass: "fd-table-frozen-column fd-summary-employee-number-column"
        },
        {
          text: (this.$vuetify.breakpoint.mdAndUp
            ? this.$t("timesheets.existing.classification-column-label")
            : this.$t("timesheets.existing.classification-column-label-short")) as
            | string
            | TranslateResult
            | undefined,
          value: "classificationDisplayName",
          class: "fd-table-frozen-column fd-summary-classification-column",
          cellClass: "fd-table-frozen-column fd-summary-classification-column"
        }
      ] as TableHeader[];

      if (!this.currentTimesheetIsEquipment) {
        headers = headers.concat([
          {
            text: this.$t("timesheets.existing.work-order-column-label") as
              | string
              | TranslateResult
              | undefined,
            value: "workOrderNumber"
          },
          {
            text: this.$t("timesheets.existing.area-column-label") as
              | string
              | TranslateResult
              | undefined,
            value: "areaName"
          },
          {
            text: this.$t("timesheets.existing.sub-area-column-label") as
              | string
              | TranslateResult
              | undefined,
            value: "subAreaName"
          }
        ] as TableHeader[]);

        for (let workSubType of this.summaryWorkSubTypes) {
          if (!workSubType.useWorkOrderCostCode && !workSubType.defaultCostCodeID?.length) {
            console.log(
              `${workSubType.name} - defaultCostCodeID: ${workSubType.defaultCostCodeID}, useWorkOrderCostCode: ${workSubType.useWorkOrderCostCode}`
            );
          }
          headers.push({
            text: workSubType.code ?? workSubType.name,
            value: workSubType.id,
            class: "fd-rotate-header-text"
          });
        }
      } else {
        headers.push({
          text: this.$t("timesheets.existing.equipment-days-column-label"),
          value: "equipmentDays",
          class: "fd-rotate-header-text"
        });
        headers.push({
          text: this.$t("timesheets.existing.equipment-quantity-column-label"),
          value: "equipmentQuantity",
          class: "fd-rotate-header-text"
        });
      }

      headers.push({
        text: this.$t("common.total"),
        value: "total",
        class: "text-end"
      });
      if (!!this.perDiemSubType) {
        headers.push({
          text: this.$t("common.per-diem"),
          value: "perdiem",
          class: "fd-table-column-text-end-override fd-restrict-table-entry-column-width-per-diem"
        });
      }
      headers.push({
        text: this.$t("common.action"),
        value: "action",
        class: "fd-action-cell",
        cellClass: "fd-action-cell"
      });
      return headers;
    },
    workOrderTableHeaders(): TableHeader[] {
      let headers = [
        {
          value: "empty",
          sortable: false,
          class: "fd-table-icon-cell fd-table-frozen-column",
          cellClass: "fd-table-icon-cell fd-table-frozen-column"
        },
        {
          text: this.$t("timesheets.existing.employee-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "employeeName",
          class: "fd-table-frozen-column fd-workorder-employee-name-column",
          cellClass: "fd-table-frozen-column fd-workorder-employee-name-column"
        },
        {
          text: this.$t("timesheets.existing.employee-code-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "employeeCode",
          class: "fd-table-between-frozen-columns",
          cellClass: "fd-table-between-frozen-columns"
        },
        {
          text: (this.$vuetify.breakpoint.mdAndUp
            ? this.$t("timesheets.existing.classification-column-label")
            : this.$t("timesheets.existing.classification-column-label-short")) as
            | string
            | TranslateResult
            | undefined,
          value: "classificationDisplayName",
          class: "fd-table-frozen-column fd-workorder-classification-column",
          cellClass: "fd-table-frozen-column fd-workorder-classification-column"
        },
        {
          text: this.$t("timesheets.existing.work-order-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "workOrderNumber"
        }
      ] as TableHeader[];

      for (let workSubType of this.workOrderWorkSubTypes) {
        if (!workSubType.useWorkOrderCostCode && !workSubType.defaultCostCodeID?.length) {
          console.log(
            `${workSubType.name} - defaultCostCodeID: ${workSubType.defaultCostCodeID}, useWorkOrderCostCode: ${workSubType.useWorkOrderCostCode}`
          );
        }
        headers.push({
          text: workSubType.code ?? workSubType.name,
          value: workSubType.id,
          class: "fd-rotate-header-text"
        });
      }

      headers.push({
        text: this.$t("common.total"),
        value: "total",
        class: "fd-table-column-text-end-override"
      });
      if (this.allowWorkOrderPerDiem) {
        headers.push({
          text: this.$t("common.per-diem"),
          value: "perdiem",
          class: "fd-table-column-text-end-override fd-restrict-table-entry-column-width-per-diem"
        });
      }
      headers.push({
        text: this.$t("common.action"),
        value: "action",
        class: "fd-action-cell",
        cellClass: "fd-action-cell"
      });
      return headers;
    },
    generalizedDirectTableHeaders(): TableHeader[] {
      let headers = [
        // {
        //   value: "empty",
        //   sortable: false,
        //   class: "fd-table-icon-cell",
        //   cellClass: "fd-table-icon-cell"
        // },
        {
          value: "icon",
          sortable: false,
          class: "fd-table-icon-cell fd-table-frozen-column",
          cellClass: "fd-table-icon-cell fd-table-frozen-column"
        },
        {
          text: this.$t("timesheets.existing.employee-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "employeeName",
          class: "fd-table-frozen-column fd-nonworkorder-employee-name-column",
          cellClass: "fd-table-frozen-column fd-nonworkorder-employee-name-column"
        },
        {
          text: this.$t("timesheets.existing.employee-code-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "employeeCode",
          class: "fd-table-between-frozen-columns",
          cellClass: "fd-table-between-frozen-columns"
        },
        {
          text: (this.$vuetify.breakpoint.mdAndUp
            ? this.$t("timesheets.existing.classification-column-label")
            : this.$t("timesheets.existing.classification-column-label-short")) as
            | string
            | TranslateResult
            | undefined,
          value: "classificationDisplayName",
          class: "fd-table-frozen-column fd-nonworkorder-classification-column",
          cellClass: "fd-table-frozen-column fd-nonworkorder-classification-column"
        },
        // {
        //   text: this.$t("timesheets.existing.work-order-column-label") as
        //     | string
        //     | TranslateResult
        //     | undefined,
        //   value: "workOrderNumber"
        // },
        {
          text: this.$t("timesheets.existing.area-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "areaName"
        },
        {
          text: this.$t("timesheets.existing.sub-area-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "subAreaName"
        }
      ] as TableHeader[];

      for (let workSubType of this.generalizedDirectWorkSubTypes) {
        if (!workSubType.useWorkOrderCostCode && !workSubType.defaultCostCodeID?.length) {
          console.log(
            `${workSubType.name} - defaultCostCodeID: ${workSubType.defaultCostCodeID}, useWorkOrderCostCode: ${workSubType.useWorkOrderCostCode}`
          );
        }
        headers.push({
          text: workSubType.code ?? workSubType.name,
          value: workSubType.id,
          class: "fd-rotate-header-text"
        });
      }

      headers.push({
        text: this.$t("common.total"),
        value: "total",
        class: "fd-table-column-text-end-override"
      });
      if (!!this.perDiemSubType) {
        headers.push({
          text: this.$t("common.per-diem"),
          value: "perdiem",
          class: "fd-table-column-text-end-override fd-restrict-table-entry-column-width-per-diem"
        });
      }
      headers.push({
        text: this.$t("common.action"),
        value: "action",
        class: "fd-action-cell",
        cellClass: "fd-action-cell"
      });
      return headers;
    },
    indirectTableHeaders(): TableHeader[] {
      let headers = [
        {
          value: "icon",
          sortable: false,
          class: "fd-table-icon-cell fd-table-frozen-column",
          cellClass: "fd-table-icon-cell fd-table-frozen-column"
        },
        {
          text: this.$t("timesheets.existing.employee-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "employeeName",
          class: "fd-table-frozen-column fd-indirect-employee-name-column",
          cellClass: "fd-table-frozen-column fd-indirect-employee-name-column"
        },
        {
          text: this.$t("timesheets.existing.employee-code-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "employeeCode",
          class: "fd-table-between-frozen-columns",
          cellClass: "fd-table-between-frozen-columns"
        },
        {
          text: (this.$vuetify.breakpoint.mdAndUp
            ? this.$t("timesheets.existing.classification-column-label")
            : this.$t("timesheets.existing.classification-column-label-short")) as
            | string
            | TranslateResult
            | undefined,
          value: "classificationDisplayName",
          class: "fd-table-frozen-column fd-indirect-classification-column",
          cellClass: "fd-table-frozen-column fd-indirect-classification-column"
        },
        // {
        //   text: this.$t("timesheets.existing.work-order-column-label") as
        //     | string
        //     | TranslateResult
        //     | undefined,
        //   value: "workOrderNumber"
        // },
        {
          text: this.$t("timesheets.existing.area-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "areaName"
        },
        {
          text: this.$t("timesheets.existing.sub-area-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "subAreaName"
        }
      ] as TableHeader[];

      for (let workSubType of this.indirectWorkSubTypes) {
        if (!workSubType.useWorkOrderCostCode && !workSubType.defaultCostCodeID?.length) {
          console.log(
            `${workSubType.name} - defaultCostCodeID: ${workSubType.defaultCostCodeID}, useWorkOrderCostCode: ${workSubType.useWorkOrderCostCode}`
          );
        }
        headers.push({
          text: workSubType.code ?? workSubType.name,
          value: workSubType.id,
          class: "fd-rotate-header-text"
        });
      }

      headers.push({
        text: this.$t("common.total"),
        value: "total",
        class: "fd-table-column-text-end-override"
      });
      if (!!this.perDiemSubType) {
        headers.push({
          text: this.$t("common.per-diem"),
          value: "perdiem",
          class: "fd-table-column-text-end-override fd-restrict-table-entry-column-width-per-diem"
        });
      }
      headers.push({
        text: this.$t("common.action"),
        value: "actions",
        class: "fd-action-cell",
        cellClass: "fd-action-cell"
      });
      return headers;
    },
    equipmentTableHeaders(): TableHeader[] {
      if (!this.equipmentSubType) return [];

      let headers = [
        {
          value: "icon",
          sortable: false,
          class: "fd-table-icon-cell fd-table-frozen-column",
          cellClass: "fd-table-icon-cell fd-table-frozen-column"
        },
        {
          text: this.$t("timesheets.existing.employee-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "employeeName",
          class: "fd-table-between-frozen-columns",
          cellClass: "fd-table-between-frozen-columns"
        },
        {
          text: this.$t("timesheets.existing.employee-code-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "employeeCode",
          class: "fd-table-frozen-column fd-equipment-employee-number-column",
          cellClass: "fd-table-frozen-column fd-equipment-employee-number-column"
        },
        {
          text: this.$t("timesheets.existing.classification-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "classificationDisplayName",
          class: "fd-table-frozen-column fd-equipment-classification-column",
          cellClass: "fd-table-frozen-column fd-equipment-classification-column"
        }
      ] as TableHeader[];

      headers.push({
        text: this.$t("timesheets.existing.equipment-days-column-label"),
        value: "equipmentDays",
        class: "fd-rotate-header-text"
      });
      headers.push({
        text: this.$t("timesheets.existing.equipment-quantity-column-label"),
        value: "equipmentQuantity",
        class: "fd-rotate-header-text"
      });

      headers.push({
        text: this.$t("common.total"),
        value: "total",
        class: "fd-table-column-text-end-override"
      });
      headers.push({
        text: this.$t("common.action"),
        value: "action",
        class: "fd-action-cell",
        cellClass: "fd-action-cell"
      });
      return headers;
    },
    unwatchedMethodNames(): string[] {
      return [
        "perDiemValueChanged",
        "getTimeValueForItem",
        "workSubTypeNameForExplanation",
        "canEditPerDiem",
        "rowCanEditWorkSubType",
        "countPerDiems",
        "sumRowTimeValues",
        "sumEquipmentDays",
        "sumEquipmentQuantity",
        "canEditTimesheet",
        "saveDialog",
        "submitDialog",
        "getFieldRef",
        "focusFieldForVisibleItemAtIndex",
        "selectPreviousField",
        "selectNextField",
        "enterPressed",
        "calculateTotalForItem",
        "calculateTotalForItems",
        "selectableWorkSubTypesForWorkTypeID",
        "clientWorkOrderNumberForGroup",
        "serviceOrderNumberForGroup"
      ];
    },
    contractor(): ContractorWithTags | undefined {
      let allContractors = this.$store.state.contractors.fullList as ContractorWithTags[];
      let contractor = allContractors.find(x => x.id == this.currentTimesheet?.contractorID);
      return contractor;
    },
    selectableAreas(): ProjectLocation[] {
      if (!!this.contractor?.includesAllAreas) {
        return SortItemsWithName(this.allAreas);
      }

      if (!this.contractor?.areaIDs?.length) return [];
      return this.allAreas.filter(x => this.contractor!.areaIDs!.includes(x.id!));
    },
    selectableWorkTypes(): WorkType[] {
      if (!this.contractor?.workTypeIDs?.length) return [];
      return SortWorkTypes(
        this.allWorkTypes.filter(
          x => !x.isPerDiem && !x.isEquipment && this.contractor?.workTypeIDs?.includes(x.id!)
        )
      );
    },
    allWorkTypes(): WorkType[] {
      return this.$store.state.workTypes.fullList as WorkType[];
    },
    allWorkSubTypes(): WorkSubType[] {
      return (this.$store.state.workSubTypes.fullList as WorkSubType[]).filter(
        x => !!this.workSubTypeIsConfiguredCorrectly(x)
      );
    },
    allowWorkOrderPerDiem(): boolean {
      return this.perDiemSubType?.isWorkOrderRelated == true;
    },
    perDiemSubType(): WorkSubType | undefined {
      if (!this.contractor?.employeesReceivePerDiem) return undefined;
      if (this.currentTimesheetIsEquipment) return undefined;

      let perDiemTypeID = this.allWorkTypes.find(x => !!x.isPerDiem)?.id;
      return this.allWorkSubTypes.find(x => x.workTypeID == perDiemTypeID);
    },
    perDiemSubTypeIsWorkOrderRelated(): boolean {
      return this.perDiemSubType?.isWorkOrderRelated ?? false;
    },
    equipmentSubType(): WorkSubType | undefined {
      if (!this.currentTimesheetIsEquipment) return undefined;

      let equipmentTypeID = this.allWorkTypes.find(x => !!x.isEquipment)?.id;
      return this.allWorkSubTypes.find(x => x.workTypeID == equipmentTypeID);
    },
    allSelectableWorkSubTypes(): WorkSubType[] {
      return this.selectableGroupedWorkSubType
        .filter(x => !!(x as WorkSubType))
        .map(x => x as WorkSubType);
    },
    selectableGroupedWorkSubType(): GroupableSelectListOption<WorkSubType>[] {
      if (!this.contractor?.workTypeIDs?.length) return [];

      let allPeople = this.$store.state.users.fullList as PersonWithDetails[];
      let entriesWithTime = [] as UpdatableTimesheetEntryWithDetails[];
      if (!!this.allTimesheetRows.length) {
        entriesWithTime = this.currentTimesheet!.getEntries(
          this.perDiemSubType,
          this.equipmentSubType,
          this.allWorkSubTypes,
          allPeople
        ).filter(x => !!x.regularTime);
      }

      let selectableGroupedWorkSubType = [] as GroupableSelectListOption<WorkSubType>[];
      this.selectableWorkTypes.forEach(workType => {
        let selectableSubTypes = this.selectableWorkSubTypesForWorkTypeID(workType.id);
        if (!selectableSubTypes?.length) return;

        selectableGroupedWorkSubType.push({ header: workType.name! });
        selectableGroupedWorkSubType = selectableGroupedWorkSubType.concat(
          selectableSubTypes.map(x => ({
            ...x,
            displayName: x.code ?? x.name,
            disabled: entriesWithTime.findIndex(e => e.workSubTypeID == x.id) !== -1
          }))
        );
      });
      return selectableGroupedWorkSubType;
    },
    selectedRowType(): TimesheetRowType {
      let rowType = TimesheetRowType.DirectWorkOrderRelated;
      if (this.selectedEntryType == "generaldirect") rowType = TimesheetRowType.DirectGeneral;
      else if (this.selectedEntryType == "indirect") rowType = TimesheetRowType.Indirect;
      else if (this.selectedEntryType == "equipment") rowType = TimesheetRowType.Equipment;
      return rowType;
    },
    selectableEmployees(): (PersonWithDetailsAndName & { disabled?: boolean })[] {
      let allPeople = this.$store.state.users.fullList as PersonWithDetails[];
      let selectableEmployees = allPeople.filter(
        x =>
          !PersonHasEquipmentClassification(x) &&
          !!x.contractorID &&
          x.contractorID == this.currentTimesheet?.contractorID
      );
      if (this.currentTimesheetIsEquipment) {
        selectableEmployees = allPeople.filter(
          x =>
            !!PersonHasEquipmentClassification(x) &&
            !!x.contractorID &&
            x.contractorID == this.currentTimesheet?.contractorID
        );
      }
      let sortedSelectableEmployees = SortItemsWithName(
        selectableEmployees.map(x => ({
          ...x,
          name: GetPersonName(x),
          nameWithCode: GetPersonName(x, true, true),
          disabled: this.allTimesheetRowsAlreadyExists(
            this.timesheet.isLocked,
            x.id,
            undefined,
            this.selectedRowType,
            this.selectedWorkOrders,
            !!this.selectedWorkOrders.length ? undefined : null, // If we have a work order selected, we don't care about the area.  If we don't, the check against empty area.
            !!this.selectedWorkOrders.length ? undefined : null // If we have a work order selected, we don't care about the subarea.  If we don't, the check against empty subarea.
          )
        }))
      );

      return sortedSelectableEmployees;
    },
    selectedEmployee(): PersonWithDetailsAndName | undefined {
      return this.selectableEmployees.find(x => x.id == this.selectedEmployeeID);
    },
    availableCrews(): CrewWithEmployees[] {
      let allCrews = this.$store.state.crews.fullList as CrewWithEmployees[];
      let availableCrews = allCrews.filter(
        x => x.contractorID == this.currentTimesheet?.contractorID
        // For now, we rely on the server to determine what crews are visible for this person, and we show ALL visible crews for this contractor
        // This means that admins with Configure Settings permission see all crews for all of this contractor's employees
        // && (!x.ownerID || x.ownerID == this.curUserID)
      );
      return availableCrews;
    },
    selectableCrews(): GroupableSelectListOption<CrewWithEmployees>[] {
      let selectableCrews = this.availableCrews.map(
        x =>
          ({
            ...x
          } as SelectListOption<CrewWithEmployees>)
      );

      let allPeople = (this.$store.state.users.fullList as PersonWithDetails[]).map(p => ({
        ...p,
        name: GetPersonName(p)
      }));
      let selectableCrewsByOwnerName = selectableCrews.reduce((a, b) => {
        let ownerName = allPeople.find(p => p.id == b.ownerID)?.name ?? "";
        let existingCrews = a[ownerName] ?? [];
        existingCrews.push(b);
        a[ownerName] = existingCrews;
        return a;
      }, {} as HashTable<SelectListOption<CrewWithEmployees>[]>);

      var curUserName = allPeople.find(p => p.id == this.curUserID)?.name ?? this.curUserID;
      let myCrews = SortItemsWithName(selectableCrewsByOwnerName[curUserName]);
      let unownedCrews = SortItemsWithName(selectableCrewsByOwnerName[""]);
      let otherCrewOwnerNames = Object.keys(selectableCrewsByOwnerName)
        .filter(x => x != "" && x != curUserName)
        .sort();

      let returnList = [] as GroupableSelectListOption<CrewWithEmployees>[];
      if (myCrews.length > 0) {
        returnList.push({ header: this.$t("timesheets.entries.my-crews") });
        myCrews.forEach(c => returnList.push(c));
        if (unownedCrews.length > 0 || otherCrewOwnerNames.length > 0)
          returnList.push({ divider: true });
      }

      if (unownedCrews.length > 0) {
        returnList.push({ header: this.$t("timesheets.entries.global-crews") });
        unownedCrews.forEach(c => returnList.push(c));
        if (otherCrewOwnerNames.length > 0) returnList.push({ divider: true });
      }

      if (otherCrewOwnerNames.length > 0) {
        for (let i = 0; i < otherCrewOwnerNames.length; i++) {
          let ownerName = otherCrewOwnerNames[i];
          let list = selectableCrewsByOwnerName[ownerName];
          if (list.length > 0) {
            returnList.push({ header: ownerName });
            list.forEach(c => returnList.push(c));
            if (i + 1 < otherCrewOwnerNames.length) returnList.push({ divider: true });
          }
        }
      }

      return returnList;
    },
    selectedCrew(): CrewWithEmployees | undefined {
      return this.availableCrews.find(x => x.id == this.selectedCrewID);
    },
    selectedEmployees(): PersonWithDetailsAndName[] {
      var selectedEmployeeIDs = [] as string[];
      if (!!this.selectedEmployeeID) selectedEmployeeIDs.push(this.selectedEmployeeID);

      let selectedCrew = this.selectedCrew;
      if (!!selectedCrew)
        selectedEmployeeIDs = selectedEmployeeIDs.concat(
          SortCrewEmployees(selectedCrew.employees).map(x => x.employeeID!)
        );

      let selectableEmployees = this.selectableEmployees;
      return selectedEmployeeIDs
        .filter(x => !!selectableEmployees.find(e => e.id! == x))
        .map(x => selectableEmployees.find(e => e.id! == x)!);
    },
    timesheetIsDeclined(): boolean {
      return this.currentTimesheet?.timesheetStatusID == TimesheetStatus.Declined;
    },
    timesheetDeclineComments(): string | undefined {
      return this.currentTimesheet?.lastStatusLog?.comments;
    },
    formattedDay(): string {
      return this.formatDate(this.currentTimesheet?.day);
    },
    workOrderSelectorNoDataText(): string | TranslateResult | undefined {
      if (this.selectedEntryType != "workorder")
        return this.$t("timesheets.existing.work-orders-not-selectable");
      if (this.workOrderSearching) return this.$t("loading-dot-dot-dot");
      if (!this.didWorkOrderSearch)
        return this.$t("timesheets.existing.no-work-orders", [this.formattedDay]);

      return undefined;
    }
  },

  watch: {
    timesheet() {
      this.loadDataForTimesheet();
      if (!this.selectedEntryType && this.workTypeOptions.length == 1)
        this.selectedEntryType = this.workTypeOptions[0].value;
    },
    workOrderSearch(newValue) {
      // If the user kept typing/changed the search before the service call, cancel the previous call in preparation of the new one
      if (this.timer) clearTimeout(this.timer);

      let restrictDay = false;
      let restrictAssignment = false;
      if (!newValue?.length) {
        // If we're searching with NO search value, re-search using the initial "Recommended" search value
        restrictDay = true;
        restrictAssignment = true;
      }

      var obj = this;
      this.workOrderSearching = true;
      // Delay the service call to allow the user to keep typing if they choose before making a server call
      this.timer = setTimeout(async function() {
        obj.processing = true;
        obj.$nextTick(async () => {
          try {
            await obj.loadWorkOrders(newValue, restrictDay, restrictAssignment);
          } finally {
            obj.timer = null;
            obj.didWorkOrderSearch = true;
            obj.processing = false;
            obj.workOrderSearching = false;
          }
        });
      }, 1500);
    }
  },

  methods: {
    crewIsSelectable(crew: CrewWithEmployees | undefined): boolean {
      if (!crew?.employees?.length) return false;

      var crewEmployeesWithoutRows = crew.employees.filter(
        ce =>
          !this.allTimesheetRowsAlreadyExists(
            this.timesheet.isLocked,
            ce.employeeID,
            undefined,
            this.selectedRowType,
            this.selectedWorkOrders,
            !!this.selectedWorkOrders.length ? undefined : null, // If we have a work order selected, we don't care about the area.  If we don't, the check against empty area.
            !!this.selectedWorkOrders.length ? undefined : null // If we have a work order selected, we don't care about the subarea.  If we don't, the check against empty subarea.
          )
      );
      return !!crewEmployeesWithoutRows?.length;
    },
    formatDate(date: string | Date | undefined | null): string {
      return stripTimeFromLocalizedDateTime(date);
    },
    addTimesheetRowRules(): any {
      return {
        selectedEntryType: [this.rules.required],
        selectedWorkOrders:
          this.selectedEntryType == "workorder" ? [this.rules.required] : undefined,
        selectedCrewID: !this.selectedEmployeeID ? [this.rules.required] : undefined,
        selectedEmployeeID: !this.selectedCrewID ? [this.rules.required] : undefined
      };
    },
    validTimeForDayRule(row: TimesheetRow): boolean | TranslateResult | string {
      let totalHours = this.totalTimeForEmployeeOnDay(row.employeeID);

      let errorMessage = "";
      if (totalHours < 0) {
        errorMessage = `${this.$t("timesheets.existing.too-few-hours-error-message")}`;
      } else if (totalHours > this.$store.state.curEnvironment.defaultMaxDailyEmployeeHours) {
        errorMessage = `${this.$t("timesheets.existing.too-many-hours-error-message")}`;
      } else {
        let negativeWstID = ParseWorkSubTypeIDsFromRow(row).find(x => {
          let totalHoursForSubType = this.totalTimeForEmployeeForWorkSubTypeOnDay(
            row.employeeID,
            x
          );
          return totalHoursForSubType < 0;
        });
        if (!!negativeWstID) {
          let workSubType = this.allWorkSubTypes.find(x => x.id == negativeWstID);
          errorMessage = `${this.$t(
            "timesheets.existing.too-few-work-sub-type-hours-error-message",
            [workSubType?.name]
          )}`;
        }
      }

      if (!!errorMessage) {
        if (!row.errorMessage.includes(errorMessage)) {
          row.errorMessage = errorMessage;
        }
        return errorMessage;
      } else {
        row.errorMessage = "";
        return true;
      }
    },
    timesheetRowRules(item: TimesheetRow): Array<Function | boolean | TranslateResult | string> {
      return [this.validTimeForDayRule(item)];
    },
    // This method determines if the row in the data-table should be colored to represent if it is "Urgent".
    timesheetRowClassName(item: any) {
      return item.isCorrectionRow ? "fd-correction-table-row-background" : "";
    },
    updateAllIndirectItemValues(workSubTypeID: string | null | undefined, value: PossibleNumber) {
      if (!workSubTypeID?.length) return;

      let rows = this.indirectTimesheetRows;
      let times = new TimesheetRowTimeValues(null, null, null, value);
      for (let row of rows) {
        row.times[workSubTypeID] = times;
        this.confirmEmployeePerDiemForRow(row);
        UpdateRowCorrections(row, this.allTimesheetRows);
      }
      this.checkWorkSubTypeAdditionalDetails(times, workSubTypeID, null, null);
    },
    updateAllEquipmentDayValues(value: PossibleNumber) {
      let rows = this.equipmentTimesheetRows;
      for (let row of rows) {
        row.equipmentDays = this.$parse.sanitizedNumber(value);
        UpdateRowCorrections(row, this.allTimesheetRows);
      }
    },
    updateAllEquipmentQuantityValues(value: PossibleNumber) {
      let rows = this.equipmentTimesheetRows;
      for (let row of rows) {
        row.equipmentQuantity = this.$parse.sanitizedNumber(value);
        UpdateRowCorrections(row, this.allTimesheetRows);
      }
    },
    updateAllNonWorkOrderItemValues(
      workSubTypeID: string | null | undefined,
      value: PossibleNumber
    ) {
      if (!workSubTypeID?.length) return;

      let rows = this.generalizedDirectTimesheetRows;
      let times = new TimesheetRowTimeValues(null, null, null, value);
      for (let row of rows) {
        row.times[workSubTypeID] = times;
        this.confirmEmployeePerDiemForRow(row);
        UpdateRowCorrections(row, this.allTimesheetRows);
      }
      this.checkWorkSubTypeAdditionalDetails(times, workSubTypeID, null, null);
    },
    updateAllWorkOrderItemValues(
      workOrderNumber: string | null | undefined,
      workSubTypeID: string | null | undefined,
      value: PossibleNumber
    ) {
      if (!workSubTypeID?.length) return;

      let rows = this.workOrderTimesheetRows.filter(
        x => (x.workOrderNumber ?? "") == workOrderNumber
      );
      var workOrderID = "" as string | null | undefined;
      let times = new TimesheetRowTimeValues(null, null, null, value);
      for (let row of rows) {
        row.times[workSubTypeID] = times;
        if (!workOrderID) workOrderID = row.workOrderID;
        this.confirmEmployeePerDiemForRow(row);
        UpdateRowCorrections(row, this.allTimesheetRows);
      }
      this.checkWorkSubTypeAdditionalDetails(times, workSubTypeID, workOrderID, workOrderNumber);
    },
    checkWorkSubTypeAdditionalDetails(
      newValue: TimesheetRowTimeValues,
      workSubTypeID: string | null | undefined,
      workOrderID: string | null | undefined,
      workOrderNumber: string | null | undefined
    ) {
      if (!workSubTypeID?.length) return;

      let wst = this.allWorkSubTypes.find(x => x.id == workSubTypeID);
      if (!wst?.requiresAdditionalDetails) return;

      let existingExplanation = this.currentTimesheet?.explanations?.find(
        x => x.workSubTypeID == workSubTypeID && x.workOrderID == (workOrderID ?? "")
      );

      if (!!newValue && newValue.totalTime > 0) {
        if (!existingExplanation) {
          this.showNewExplanationDialog(
            workOrderID ?? "",
            workOrderNumber ?? "",
            workSubTypeID,
            wst.name ?? ""
          );
        }
      } else {
        // Value was removed for this WST/WO combination
        // Check if there are any other entries with this combination
        if (!!existingExplanation) {
          let otherExistingRowsWithValue = this.allTimesheetRows.filter(
            x =>
              x.workOrderID == workOrderID &&
              !!x.times[workSubTypeID] &&
              x.times[workSubTypeID].totalTime > 0
          );
          if (!otherExistingRowsWithValue?.length) {
            let existingIndex = this.currentTimesheet!.explanations.indexOf(existingExplanation);
            this.currentTimesheet!.explanations.splice(existingIndex, 1);
          }
        }
      }
    },
    totalTimeForEmployeeForWorkSubType(
      employeeID: string | null | undefined,
      workSubTypeID: string | null | undefined
    ): number {
      if (!employeeID?.length || !workSubTypeID?.length) return 0;

      var total = 0;
      let employeeRows = this.allTimesheetRows.filter(x => x.employeeID == employeeID);
      for (let row of employeeRows) {
        let hours = this.$parse.sanitizedNumber(row.times[workSubTypeID]?.totalTime);
        total += hours;
      }
      return total;
    },
    totalTimeForEmployeeForWorkSubTypeOnDay(
      employeeID: string | null | undefined,
      workSubTypeID: string | null | undefined
    ): number {
      if (!employeeID?.length || !workSubTypeID?.length) return 0;

      let fullSummary = this.timeSummaries?.find(x => x.employeeID == employeeID);
      let summary = fullSummary?.workSubTypeTimeSummaries.find(
        x => x.workSubTypeID == workSubTypeID
      );
      let otherTotalRegularTime = summary?.totalRegularTime ?? 0;
      let totalRegularTime =
        otherTotalRegularTime + this.totalTimeForEmployeeForWorkSubType(employeeID, workSubTypeID);
      return totalRegularTime;
    },
    totalTimeForEmployee(employeeID: string | null | undefined): number {
      if (!employeeID?.length) return 0;

      var total = 0;
      let employeeRows = this.allTimesheetRows.filter(x => x.employeeID == employeeID);
      for (let row of employeeRows) {
        // Clear out all hours values for row to be removed to confirm if explanations also need to be removed
        ParseWorkSubTypeIDsFromRow(row).forEach(wstid => {
          let hours = this.$parse.sanitizedNumber(row.times[wstid]?.totalTime);
          total += hours;
        });
      }
      return total;
    },
    totalTimeForEmployeeOnDay(employeeID: string | null | undefined): number {
      if (!employeeID?.length) return 0;

      let summary = this.timeSummaries?.find(x => x.employeeID == employeeID);
      let otherTotalTimes = new TimesheetRowTimeValues(
        summary?.totalRegularTime,
        summary?.totalOverTime,
        summary?.totalDoubleTime
      );
      let totalTime = otherTotalTimes.totalTime + this.totalTimeForEmployee(employeeID);
      return totalTime;
    },
    confirmEmployeePerDiemForRow(row: TimesheetRow) {
      console.log(`confirmEmployeePerDiemForRow`);
      // If the timesheet is locked and this is a correction row, don't do any automatic logic
      if (row.isCorrectionRow) return;

      // Confirm the hours for this work sub type don't go below 0 or above 18
      let totalRegularTime = this.totalTimeForEmployeeOnDay(row.employeeID);
      let perdiemOtherTimesheetOwner = this.perDiemOwnerFromOtherTimesheet(row.employeeID);
      if (!!perdiemOtherTimesheetOwner || !this.contractor?.employeesReceivePerDiem) return;

      let allPeople = this.$store.state.users.fullList as PersonWithDetails[];
      let person = allPeople.find(x => x.id == row.employeeID);
      if (!person || !!person.disableAutomaticPerDiem) return;

      let existingPerDiemRow = this.existingUncorrectedPerDiemRowForEmployee(row.employeeID);

      let requiredHours = this.contractor.perDiemHoursRequirement ?? 0;
      if (totalRegularTime < requiredHours) {
        if (!!existingPerDiemRow) existingPerDiemRow.hasPerDiem = false;
        return;
      }

      if (!!existingPerDiemRow) return;

      // We don't have an already existing per diem row, so we either set the current row's per-diem to true, or make a new general row
      // If the perdiem is Work Order Related, we set the current row to true as it's not relevant whether this row is direct or general
      // If ther perdiem is NOT work order related, we look for an existing general row and either set it to true if it exists, or create one with perdiem true if not
      console.log(`\t perDiemSubTypeIsWorkOrderRelated: ${this.perDiemSubTypeIsWorkOrderRelated}`);
      let desiredRowType =
        this.currentTimesheet?.timesheetTypeID == TimesheetType.Indirect
          ? TimesheetRowType.Indirect
          : TimesheetRowType.DirectGeneral;
      if (this.perDiemSubTypeIsWorkOrderRelated || row.rowType == desiredRowType) {
        console.log(`\t\t Set Current Row Per Diem True`);
        row.hasPerDiem = true;
      } else {
        let existingIndirectRow = this.getExistingTimesheetRow(
          row.isCorrectionRow,
          row.employeeID,
          undefined,
          desiredRowType,
          null,
          undefined,
          undefined
        );
        if (!!existingIndirectRow) {
          existingIndirectRow.hasPerDiem = true;
        } else {
          console.log(`\t\t Add new row`);
          let newRow = this.addNonWorkOrderRelatedTimesheetRow(
            row.isCorrectionRow,
            row.employeeID,
            row.employeeName,
            row.employeeCode,
            row.employeeBadge,
            row.classificationID,
            desiredRowType,
            null,
            null
          );
          newRow.hasPerDiem = true;
        }
      }
    },
    workSubTypeHoursValueChanged(
      row: TimesheetRow,
      workSubTypeID: string | null | undefined,
      value: PossibleNumber
    ) {
      if (!workSubTypeID?.length) return;

      if (row.rowType == TimesheetRowType.Equipment) {
        console.error("Tried changing Equipment value by WST ID!");
        return;
      }

      let times = new TimesheetRowTimeValues(null, null, null, value);

      if (!!times.regularTime) times.regularTime = Number(times.regularTime?.toFixed(2));
      if (!!times.overTime) times.overTime = Number(times.overTime?.toFixed(2));
      if (!!times.doubleTime) times.doubleTime = Number(times.doubleTime?.toFixed(2));

      row.times[workSubTypeID] = times;

      // If the timesheet is locked and this is a correction row, don't do any automatic logic
      if (row.isCorrectionRow) return;

      this.confirmEmployeePerDiemForRow(row);
      UpdateRowCorrections(row, this.allTimesheetRows);

      this.checkWorkSubTypeAdditionalDetails(
        times,
        workSubTypeID,
        row.workOrderID,
        row.workOrderNumber
      );
    },
    perDiemValueChanged(row: TimesheetRow) {
      UpdateRowCorrections(row, this.allTimesheetRows);
    },
    async showNewExplanationDialog(
      workOrderID: string,
      workOrderNumber: string,
      workSubTypeID: string | null | undefined,
      workSubTypeName: string
    ) {
      if (!this.currentTimesheet?.id) return;

      let newExplanation = {
        timesheetID: this.currentTimesheet.id,
        workOrderID: workOrderID,
        workOrderNumber: workOrderNumber,
        workSubTypeID: workSubTypeID
      } as TimesheetExplanationWithWorkOrderDetails;

      let title = this.$t("timesheets.existing.additional-details-label", [
        `WO#${workOrderNumber}`
      ]);
      let label = workSubTypeName;
      let explanationText = await showAdditionalDetailsDialog(title, label);

      newExplanation.explanation = explanationText;
      this.currentTimesheet.explanations = this.currentTimesheet.explanations?.concat([
        newExplanation
      ]);
    },
    addIndirectRowRelatedToExistingRow(row: TimesheetRow) {
      if (row.rowType == TimesheetRowType.DirectWorkOrderRelated) return;
      if (row.rowType == TimesheetRowType.Equipment) return;
      if (!this.currentTimesheet) return;

      // If this person doesn't have a general indirect row, add that too
      let existingIndirectRow = this.timesheetRowAlreadyExists(
        row.isCorrectionRow,
        row.employeeID,
        row.classificationID ?? undefined,
        row.rowType,
        null,
        null,
        null
      );
      if (!existingIndirectRow) {
        this.addNonWorkOrderRelatedTimesheetRow(
          row.isCorrectionRow,
          row.employeeID,
          row.employeeName,
          row.employeeCode,
          row.employeeBadge,
          row.classificationID,
          row.rowType,
          null,
          null
        );
        this.currentTimesheet!.timesheetRows = SortTimesheetRows(
          this.currentTimesheet!.timesheetRows
        );
      }
    },
    getExistingTimesheetRow(
      isCorrectionRow: boolean,
      employeeID: string | undefined,
      classificationID: string | null | undefined,
      rowType: TimesheetRowType,
      workOrderID: string | null | undefined,
      areaID: string | null | undefined,
      subAreaID: string | null | undefined
    ): TimesheetRow | undefined {
      let comparisonRow = {
        isCorrectionRow: isCorrectionRow,
        employeeID: employeeID,
        classificationID: classificationID,
        rowType: rowType,
        workOrderID: workOrderID,
        areaID: areaID,
        subAreaID: subAreaID
      } as TimesheetRow;
      return this.allTimesheetRows?.find(t =>
        areTimesheetRowsEqual(t, comparisonRow, {
          ignoreClassification: classificationID == undefined,
          ignoreArea: areaID == undefined,
          ignoreSubArea: subAreaID == undefined
        })
      );
    },
    allTimesheetRowsAlreadyExists(
      isCorrectionRow: boolean,
      employeeID: string | undefined,
      classificationID: string | null | undefined,
      rowType: TimesheetRowType,
      workOrders: FormattedWorkOrder[],
      areaID: string | null | undefined,
      subAreaID: string | null | undefined
    ): boolean {
      if (!workOrders.length) {
        return !!this.getExistingTimesheetRow(
          isCorrectionRow,
          employeeID,
          classificationID,
          rowType,
          undefined,
          areaID,
          subAreaID
        );
      }

      let existingRows = [];
      for (let wo of workOrders) {
        let existingRow = this.getExistingTimesheetRow(
          isCorrectionRow,
          employeeID,
          classificationID,
          rowType,
          wo.id!,
          undefined,
          undefined
        );
        if (!!existingRow) existingRows.push(existingRow);
      }
      return existingRows.length == workOrders.length;
    },
    timesheetRowAlreadyExists(
      isCorrectionRow: boolean,
      employeeID: string | undefined,
      classificationID: string | null | undefined,
      rowType: TimesheetRowType,
      workOrderID: string | null | undefined,
      areaID: string | null | undefined,
      subAreaID: string | null | undefined
    ): boolean {
      return !!this.getExistingTimesheetRow(
        isCorrectionRow,
        employeeID,
        classificationID,
        rowType,
        workOrderID,
        areaID,
        subAreaID
      );
    },
    workOrderNumberTextForRow(row: TimesheetRow): string | TranslateResult | undefined {
      if (!!row.workOrderNumber) return this.$t("common.work-order-number", [row.workOrderNumber]);
      return undefined;
    },
    workOrderPlaceholderTextForRow(row: TimesheetRow): string | TranslateResult {
      if (row.rowType == TimesheetRowType.DirectGeneral)
        return this.$t("timesheets.existing.generalized-direct-work-order-placeholder");
      else if (row.rowType == TimesheetRowType.Equipment)
        return this.$t("timesheets.existing.equipment-work-order-placeholder");
      return this.$t("timesheets.existing.indirect-work-order-placeholder");
    },
    selectableSubAreasForRow(row: TimesheetRow | undefined | null): ProjectLocation[] {
      if (!row?.areaID) return [];
      return this.allSubAreas
        .filter(x => x.parentLocationID == row.areaID)
        .map(x => ({
          ...x,
          disabled:
            row.subAreaID != x.id &&
            this.timesheetRowAlreadyExists(
              row.isCorrectionRow,
              row.employeeID,
              undefined,
              row.rowType,
              row.workOrderID,
              row.areaID,
              x.id
            )
        }));
    },
    currentTimesheetExplanationsForWorkOrderNumber(
      woNumber: string
    ): TimesheetExplanationWithWorkOrderDetails[] {
      return (
        this.currentTimesheet?.explanations?.filter(x => (x.workOrderNumber ?? "") == woNumber) ??
        []
      );
    },
    workSubTypeNameForExplanation(
      explanation: TimesheetExplanationWithWorkOrderDetails
    ): string | null | undefined {
      let workSubType = this.allWorkSubTypes.find(x => x.id == explanation.workSubTypeID);
      return workSubType?.name;
    },
    allGroupsExpanded(tableIdentifier: string): boolean {
      let datatableRef = `${tableIdentifier}datatable`;
      let datatable = this.$refs[datatableRef] as VDataTable;
      if (!datatable) {
        // console.log(`datatable "${datatableRef}" not found`);
        return false;
      }
      let toggleRefs = Object.keys(this.$refs).filter(x =>
        x.startsWith(`${tableIdentifier}grouptoggle`)
      );
      let anyGroupsClosed = false;
      for (let ref of toggleRefs) {
        let groupName = ref.replace(`${tableIdentifier}grouptoggle`, "");
        let isOpen = datatable.openCache[groupName];
        if (!isOpen) {
          anyGroupsClosed = true;
          break;
        }
      }
      return !anyGroupsClosed;
    },
    validate(): boolean {
      let additionalDetailsValid =
        (this.$refs.additionaldetailsform as HTMLFormElement)?.validate() ?? true;
      let timesheetValid = (this.$refs.timesheetform as HTMLFormElement)?.validate() ?? true;
      return additionalDetailsValid && timesheetValid;
    },
    workSubTypeIsConfiguredCorrectly(workSubType: WorkSubType | undefined): boolean {
      if (!workSubType) return false;

      return (
        (!!workSubType.isWorkOrderRelated && !!workSubType.useWorkOrderCostCode) ||
        !!workSubType.defaultCostCodeID
      );
    },
    workSubTypeIsIndirect(workSubTypeID: string): boolean {
      let workSubType = this.allWorkSubTypes.find(x => x.id == workSubTypeID);
      if (!workSubType) return false;

      let workType = this.allWorkTypes.find(x => !x.isEquipment && x.id == workSubType?.workTypeID);
      if (!workType) return false;

      return !workType.isDirect ?? false;
    },
    workSubTypeIsEquipment(workSubTypeID: string): boolean {
      let workSubType = this.allWorkSubTypes.find(x => x.id == workSubTypeID);
      if (!workSubType) return false;

      let workType = this.allWorkTypes.find(
        x => !!x.isEquipment && x.id == workSubType?.workTypeID
      );
      if (!workType) return false;

      return workType.isEquipment ?? false;
    },
    workSubTypeIsDirect(workSubTypeID: string): boolean {
      let workSubType = this.allWorkSubTypes.find(x => x.id == workSubTypeID);
      if (!workSubType) return false;

      let workType = this.allWorkTypes.find(x => x.id == workSubType?.workTypeID);
      if (!workType) return false;

      return workType.isDirect ?? false;
    },
    workSubTypeIsDirectAndWorkOrderRelated(workSubTypeID: string): boolean {
      let workSubType = this.allWorkSubTypes.find(x => x.id == workSubTypeID);
      if (!workSubType) return false;

      let isDirect = this.workSubTypeIsDirect(workSubTypeID);
      let isWorkOrderRelated = workSubType.isWorkOrderRelated ?? false;
      return isDirect && isWorkOrderRelated;
    },
    rowIsEditable(item: TimesheetRow): boolean {
      if (!item.isCorrectionRow && this.currentTimesheetIsReadonly) return false;
      if (!!item.isCorrectionRow && !this.makeCorrections) return false;
      return true;
    },
    rowCanEditWorkSubType(row: TimesheetRow, workSubTypeID: string | null | undefined): boolean {
      if (!workSubTypeID?.length) return false;
      if (row.rowType == TimesheetRowType.Equipment) return false;
      if (!row.isCorrectionRow && this.currentTimesheetIsReadonly) return false;
      if (!!row.isCorrectionRow && !this.makeCorrections) return false;

      let workSubTypeIsDirect = this.workSubTypeIsDirect(workSubTypeID);
      let workSubTypeIsDirectAndWorkOrderRelated = this.workSubTypeIsDirectAndWorkOrderRelated(
        workSubTypeID
      );
      let hasWorkOrder = !!row.workOrderID?.length ?? false;
      let canEdit =
        (workSubTypeIsDirect && workSubTypeIsDirectAndWorkOrderRelated && hasWorkOrder) ||
        (workSubTypeIsDirect &&
          !workSubTypeIsDirectAndWorkOrderRelated &&
          row.rowType == TimesheetRowType.DirectGeneral) ||
        (!workSubTypeIsDirect && row.rowType == TimesheetRowType.Indirect);
      return canEdit;
    },
    // Finds the row (if exists) for an employee that has Per Diem turned on, and is not corrected to be off
    existingUncorrectedPerDiemRowForEmployee(employeeID: string): TimesheetRow | undefined {
      console.log(`existingUncorrectedPerDiemRowForEmployee`);
      return this.allTimesheetRows.find(
        // x => x.employeeID == employeeID && x.hasPerDiem && !x.relatedCorrectionRemovePerDiem
        x =>
          x.employeeID == employeeID &&
          x.hasPerDiem &&
          !x.removePerDiem &&
          !x.relatedCorrectionRemovePerDiem
      );
    },
    // If the employee has an uncorrected perdiem row that is NOT the specified row
    existingOtherUncorrectedPerDiemRowForEmployee(row: TimesheetRow): TimesheetRow | undefined {
      console.log(`existingOtherUncorrectedPerDiemRowForEmployee`);
      let existingPerDiemRowForEmployee = this.existingUncorrectedPerDiemRowForEmployee(
        row.employeeID
      );
      if (!existingPerDiemRowForEmployee) return undefined;
      return !areTimesheetRowsEqual(row, existingPerDiemRowForEmployee)
        ? existingPerDiemRowForEmployee
        : undefined;
    },
    hasExistingOtherUncorrectedPerDiemRowForEmployee(row: TimesheetRow): boolean {
      console.log(`hasExistingOtherUncorrectedPerDiemRowForEmployee`);
      return !!this.existingOtherUncorrectedPerDiemRowForEmployee(row);
    },
    // Whether the passed in row has a related row with HasPerDiem = true
    hasExistingRelatedPerDiemRowForRow(item: TimesheetRow): boolean {
      let relatedRow = FindRelatedRowForCorrection(item, this.allTimesheetRows);
      return !!relatedRow?.hasPerDiem;
    },
    existingOtherCorrectionPerDiemRow(row: TimesheetRow): boolean {
      let correctionPerDiemRow = this.allTimesheetRows.find(
        x =>
          x.employeeID == row.employeeID && (x.hasPerDiem || x.removePerDiem) && !!x.isCorrectionRow
      );
      return !!correctionPerDiemRow && !areTimesheetRowsEqual(row, correctionPerDiemRow);
    },
    // Only indirect work rows can have per diem entry
    isPerDiemApplicable(item: TimesheetRow): boolean {
      if (this.perDiemSubType?.isWorkOrderRelated == true)
        return item.rowType != TimesheetRowType.Equipment;

      return (
        !item.workOrderID &&
        (item.rowType == TimesheetRowType.DirectGeneral ||
          item.rowType == TimesheetRowType.Indirect)
      );
    },
    // Only one row can have a per diem per employee
    canEditPerDiem(item: TimesheetRow): boolean {
      console.log(`canEditPerDiem`);
      // Per Diem's can ONLY be associated to non-work-order timesheet rows
      if (!!item.workOrderID && !this.perDiemSubType?.isWorkOrderRelated) return false;
      // Only admins on indirect timesheets can edit per diems
      if (item.rowType == TimesheetRowType.Equipment) return false;

      let existingPerDiemRowForEmployee = this.existingUncorrectedPerDiemRowForEmployee(
        item.employeeID
      );
      if (!!existingPerDiemRowForEmployee) {
        // If there is an existing per diem row on this timesheet, check if it's the current row
        // If it is it's editable, if it's not it's not editable
        let existingPerDiemRowIsCurrentItem = areTimesheetRowsEqual(
          item,
          existingPerDiemRowForEmployee
        );
        return existingPerDiemRowIsCurrentItem;
      } else {
        // If there isn't an existing per diem row, it may exist on another timesheet.  Check the time summary
        let employeeSummary = this.timeSummaries?.find(x => x.employeeID == item.employeeID);
        let perdiemSummary = employeeSummary?.workSubTypeTimeSummaries.find(
          x => x.workSubTypeID == this.perDiemSubType?.id
        );
        return !perdiemSummary?.totalUnits;
      }
    },
    otherTimesheetEmployeeSummary(employeeID: string): EmployeeTimeSummary | undefined {
      return this.timeSummaries?.find(x => x.employeeID == employeeID);
    },
    otherTimesheetPerDiemSummaryForEmployee(
      employeeID: string
    ): WorkSubTypeTimeSummary | undefined {
      let perdiemSummary = this.otherTimesheetEmployeeSummary(
        employeeID
      )?.workSubTypeTimeSummaries.find(x => x.workSubTypeID == this.perDiemSubType?.id);
      return perdiemSummary;
    },
    hasPerDiemOnOtherTimesheet(employeeID: string): boolean {
      return !!this.otherTimesheetPerDiemSummaryForEmployee(employeeID)?.totalUnits;
    },
    perDiemOwnerFromOtherTimesheet(employeeID: string): string | undefined {
      console.log(`perDiemOwnerFromOtherTimesheet`);
      let existingPerDiemRowForEmployee = this.existingUncorrectedPerDiemRowForEmployee(employeeID);
      if (!existingPerDiemRowForEmployee) {
        return this.otherTimesheetEmployeeSummary(employeeID)?.perDiemTimesheetOwnerName;
      }

      return undefined;
    },
    otherPerDiemRowWorkOrderNumber(item: TimesheetRow) {
      return this.existingOtherUncorrectedPerDiemRowForEmployee(item)?.workOrderNumber;
    },
    perDiemReadonlyReason(item: TimesheetRow) {
      console.log(`perDiemReadonlyReason`);
      let perdiemOtherTimesheetOwner = this.perDiemOwnerFromOtherTimesheet(item.employeeID);
      if (!!perdiemOtherTimesheetOwner) {
        return this.$t("timesheets.per-diem-already-applied-chip-label");
      }
      let existingPerDiemRowForEmployee = this.existingUncorrectedPerDiemRowForEmployee(
        item.employeeID
      );
      if (!!existingPerDiemRowForEmployee) {
        // If there is an existing per diem row on this timesheet, check if it's the current row
        // If it is it's editable, if it's not it's not editable
        let existingPerDiemRowIsCurrentItem = areTimesheetRowsEqual(
          item,
          existingPerDiemRowForEmployee
        );

        // If the existing row isn't the current item, but is still the same WO, then we don't need a specific reason
        if (
          !existingPerDiemRowIsCurrentItem &&
          existingPerDiemRowForEmployee.workOrderID != item.workOrderID
        ) {
          if (existingPerDiemRowForEmployee.rowType == TimesheetRowType.DirectWorkOrderRelated) {
            return this.$t("timesheets.per-diem-other-work-order-applied-chip-label", [
              formatWorkOrderNumber(existingPerDiemRowForEmployee.workOrderNumber)
            ]);
          } else {
            return this.$t("timesheets.per-diem-general-applied-chip-label");
          }
        }
      }

      // Let the control use its default text
      return undefined;
    },
    canCorrectPerDiemInRow(row: TimesheetRow): boolean {
      if (!row.hasPerDiem) {
        let perDiemOnOtherTimesheet = !!this.perDiemOwnerFromOtherTimesheet(row.employeeID);
        if (perDiemOnOtherTimesheet) {
          return false;
        }
      }
      return true;
    },
    perDiemCorrectionItemsForRow(row: TimesheetRow) {
      let items = [
        { text: this.$t("timesheets.existing.correction-no-per-diem-change"), value: false }
      ];
      if (row.hasPerDiem) {
        items.push({
          text: this.$t("timesheets.existing.correction-add-per-diem"),
          value: true
        });
      } else {
        let perDiemOnOtherTimesheet = !!this.perDiemOwnerFromOtherTimesheet(row.employeeID);
        if (!perDiemOnOtherTimesheet) {
          let relatedRowHasPerDiem = this.hasExistingRelatedPerDiemRowForRow(row);
          if (relatedRowHasPerDiem) {
            items.push({
              text: this.$t("timesheets.existing.correction-remove-per-diem"),
              value: true
            });
          } else {
            items.push({
              text: this.$t("timesheets.existing.correction-add-per-diem"),
              value: true
            });
          }
        }
      }
      return items;
    },
    existingEquipmentRowForEmployee(employeeID: string): TimesheetRow | undefined {
      return this.allTimesheetRows.find(x => x.employeeID == employeeID && x.equipmentDays != 0);
    },
    existingOtherCorrectionEquipmentRow(row: TimesheetRow): boolean {
      let correctionEquipmentRow = this.allTimesheetRows.find(
        x => x.employeeID == row.employeeID && x.equipmentDays != 0 && !!x.isCorrectionRow
      );
      return !!correctionEquipmentRow && !areTimesheetRowsEqual(row, correctionEquipmentRow);
    },
    clientWorkOrderNumberForGroup(workOrderNumber: string): string {
      return (
        this.workOrderTimesheetRows.find(x => (x.workOrderNumber ?? "") == workOrderNumber)
          ?.workOrderClientWorkOrderNumber ?? ""
      );
    },
    serviceOrderNumberForGroup(workOrderNumber: string): string {
      return (
        this.workOrderTimesheetRows.find(x => (x.workOrderNumber ?? "") == workOrderNumber)
          ?.workOrderServiceOrderNumber ?? ""
      );
    },
    // Only indirect work rows can have per diem entry
    isEquipmentApplicable(item: TimesheetRow): boolean {
      return item.rowType == TimesheetRowType.Equipment;
    },
    // Only one row can have a per diem per employee
    canEditEquipment(row: TimesheetRow): boolean {
      if (!!row.workOrderID || row.rowType != TimesheetRowType.Equipment) return false;
      if (!row.isCorrectionRow && this.currentTimesheetIsReadonly) return false;
      if (!!row.isCorrectionRow && !this.makeCorrections) return false;

      return true;
    },
    canAddNewRowFromRow(row: TimesheetRow): boolean {
      let existingSimilarRowWithoutArea = this.timesheetRowAlreadyExists(
        row.isCorrectionRow,
        row?.employeeID,
        undefined,
        row?.rowType,
        row?.workOrderID,
        null,
        null
      );
      return !existingSimilarRowWithoutArea;
    },
    // Only one row can have a per diem per employee
    canEditNightShift(item: TimesheetRow): boolean {
      // Per Diem's can ONLY be associated to non-work-order timesheet rows
      if (!item.workOrderID) return false;
      return !this.currentTimesheetIsReadonly;
    },
    ...mapActions({
      loadContractors: "LOAD_CONTRACTORS",
      loadWorkTypes: "LOAD_WORK_TYPES",
      loadWorkSubTypes: "LOAD_WORK_SUB_TYPES",
      loadCostCodes: "LOAD_PROJECT_COST_CODES",
      loadEmployees: "LOAD_USERS",
      loadCrews: "LOAD_CREWS",
      loadClassifications: "LOAD_CLASSIFICATIONS"
    }),
    countPerDiems(items: TimesheetRow[]): number {
      let result = items.reduce(
        (a, b) => a + (!!b.hasPerDiem ? 1 : 0) - (!!b.removePerDiem ? 1 : 0),
        0
      );
      return result;
    },
    sumRowTimeValues(
      items: TimesheetRow[],
      propName: string | null | undefined
    ): TimesheetRowTimeValues | null | undefined {
      if (!propName?.length) return undefined;

      let result: TimesheetRowTimeValues = items.reduce(
        (a: TimesheetRowTimeValues, b: TimesheetRow) => a.adding(b.times[propName]),
        new TimesheetRowTimeValues()
      );
      return result;
    },
    sumEquipmentDays(items: TimesheetRow[]): string | undefined {
      let result = items.reduce((a, b) => a + this.$parse.sanitizedNumber(b.equipmentDays), 0);
      return !result ? undefined : result.toFixed(2);
    },
    sumEquipmentQuantity(items: TimesheetRow[]): string | undefined {
      let result = items.reduce((a, b) => a + this.$parse.sanitizedNumber(b.equipmentQuantity), 0);
      return !result ? undefined : result.toFixed(2);
    },
    toggleTableGroups(tableIdentifier: string, closed: boolean = true) {
      let toggleRefs = Object.keys(this.$refs).filter(x =>
        x.startsWith(`${tableIdentifier}grouptoggle`)
      );
      let datatable = this.$refs[`${tableIdentifier}datatable`] as VDataTable;
      for (let ref of toggleRefs) {
        let groupName = ref.replace(`${tableIdentifier}grouptoggle`, "");
        let isOpen = datatable.openCache[groupName];
        if ((closed && isOpen) || (!closed && !isOpen)) {
          datatable.openCache[groupName] = !datatable.openCache[groupName];
        }
      }
    },

    canEditTimesheet(timesheet: UpdatableTimesheetWithTimesheetRows | null | undefined) {
      if (!timesheet?.id) return false;

      let locked = timesheet.isLocked ?? false;
      let currentDay =
        new Date(timesheet.day!.toDateString()).getTime() ==
        new Date(new Date().toDateString()).getTime();
      return !locked && currentDay;
    },

    getTimesForItem(
      item: TimesheetRow,
      workSubTypeID: string | null | undefined
    ): TimesheetRowTimeValues | null | undefined {
      if (!workSubTypeID?.length) return undefined;
      return item.times[workSubTypeID];
    },
    getTimeValueForItem(
      item: TimesheetRow,
      workSubTypeID: string | null | undefined
    ): string | null | undefined {
      return this.getTimesForItem(item, workSubTypeID)?.summaryString;
    },
    calculateTotalForItem(item: TimesheetRow): TimesheetRowTimeValues {
      return CalculateRowTotalTime(item);
    },
    calculateTotalForItems(items: any[]): TimesheetRowTimeValues {
      let total: TimesheetRowTimeValues = items.reduce(
        (a: TimesheetRowTimeValues, b: any) => a.adding(this.calculateTotalForItem(b)),
        new TimesheetRowTimeValues()
      );
      return total;
    },

    // *** NAVIGATION ***
    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },
    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },
    addFormOnSubmit(e: Event) {
      e.preventDefault();
      this.addTimesheetRows(this.makeCorrections ?? false);
    },

    // *** ENTRY MANAGEMENT ***
    _addTimesheetRowUsingWorkOrder(
      isCorrectionRow: boolean,
      employeeID: string | undefined,
      employeeName: string | null | undefined,
      employeeCode: string | null | undefined,
      employeeBadge: string | null | undefined,
      employeeClassificationID: string | null | undefined,
      rowType: TimesheetRowType,
      workOrder: FormattedWorkOrder | null,
      areaID: string | null,
      subAreaID: string | null
    ): TimesheetRow {
      return this._addTimesheetRow(
        isCorrectionRow,
        employeeID,
        employeeName,
        employeeCode,
        employeeBadge,
        employeeClassificationID,
        rowType,
        workOrder?.id,
        `${workOrder?.internalNumber ?? ""}`,
        workOrder?.clientWorkOrderReferenceNumber,
        workOrder?.serviceOrderReferenceNumber,
        workOrder?.changeOrderReferenceNumber,
        workOrder?.reworkReferenceNumber,
        workOrder?.scaffoldID,
        workOrder?.costCodeID,
        areaID,
        subAreaID
      );
    },
    _addTimesheetRow(
      isCorrectionRow: boolean,
      employeeID: string | undefined,
      employeeName: string | null | undefined,
      employeeCode: string | null | undefined,
      employeeBadge: string | null | undefined,
      employeeClassificationID: string | null | undefined,
      rowType: TimesheetRowType,
      workOrderID: string | null | undefined,
      workOrderNumber: string | null | undefined,
      workOrderClientWorkOrderReferenceNumber: string | null | undefined,
      workOrderServiceOrderReferenceNumber: string | null | undefined,
      workOrderChangeOrderReferenceNumber: string | null | undefined,
      workOrderReworkReferenceNumber: string | null | undefined,
      workOrderScaffoldID: string | null | undefined,
      workOrderCostCodeID: string | null | undefined,
      areaID: string | null,
      subAreaID: string | null
    ): TimesheetRow {
      // console.log(
      //   `_addTimesheetRow isCorrectionRow: ${isCorrectionRow}, employeeName: ${employeeName}, rowType: ${rowType}, next number will be: ${this
      //     .currentTimesheet?.nextRowNumber ?? 1}`
      // );
      let area = this.allAreas.find(x => x.id == areaID);
      let subArea = this.allSubAreas.find(x => x.id == subAreaID);
      let classificationDisplayName = this.getClassificationDisplayNameForID(
        employeeClassificationID
      );
      let existingRow = this.getExistingTimesheetRow(
        isCorrectionRow,
        employeeID,
        employeeClassificationID ?? null,
        rowType,
        workOrderID,
        areaID,
        subAreaID
      );
      if (!!existingRow) return existingRow;
      let newRow = {
        rowType: rowType,
        isCorrectionRow: isCorrectionRow,
        rowNumber: this.currentTimesheet?.nextRowNumber ?? 1,
        employeeID: employeeID,
        employeeName: employeeName,
        employeeCode: employeeCode,
        employeeBadge: employeeBadge,
        classificationID: employeeClassificationID,
        classificationDisplayName: classificationDisplayName,
        workOrderID: workOrderID ?? "",
        workOrderNumber: workOrderNumber,
        workOrderClientWorkOrderNumber: workOrderClientWorkOrderReferenceNumber,
        workOrderServiceOrderNumber: workOrderServiceOrderReferenceNumber,
        workOrderChangeOrderNumber: workOrderChangeOrderReferenceNumber,
        workOrderReworkNumber: workOrderReworkReferenceNumber,
        scaffoldID: workOrderScaffoldID ?? "",
        areaID: areaID ?? "",
        areaName: area?.name,
        subAreaID: subAreaID ?? "",
        subAreaName: subArea?.name,
        workOrderCostCodeID: workOrderCostCodeID ?? "",
        hasPerDiem: false,
        removePerDiem: false,
        equipmentDays: 0,
        equipmentQuantity: rowType == TimesheetRowType.Equipment ? 1 : 0,
        errorMessage: "",
        times: {}
      } as TimesheetRow;
      // console.log(`New row added with number: #${newRow.rowNumber}`);

      // Here we find all available WSTs for this row type so we can prefill with null values
      // The purpose of this is to populate the row object with WST IDs as keys so they can be turned into empty Entries if necessary
      // Right now, we want drafts to save empty entries as a "working state", which will be removed when submitted
      let availableWSTs = [] as WorkSubType[];
      switch (rowType) {
        case TimesheetRowType.DirectGeneral:
          availableWSTs = this.generalizedDirectWorkSubTypes;
          break;
        case TimesheetRowType.DirectWorkOrderRelated:
          availableWSTs = this.workOrderWorkSubTypes;
          break;
        case TimesheetRowType.Indirect:
          availableWSTs = this.indirectWorkSubTypes;
          break;
        case TimesheetRowType.Equipment:
          availableWSTs = [];
          break;
      }
      for (let wst of availableWSTs) {
        newRow.times[wst.id!] = new TimesheetRowTimeValues();
      }

      this.currentTimesheet!.timesheetRows.push(newRow);
      UpdateRowCorrections(newRow, this.allTimesheetRows);

      this.$nextTick(() => {
        switch (rowType) {
          case TimesheetRowType.DirectGeneral:
            this.panel = this.generalDirectPanelNumber;
            break;
          case TimesheetRowType.DirectWorkOrderRelated:
            this.panel = this.workOrderDirectPanelNumber;
            break;
          case TimesheetRowType.Indirect:
            this.panel = this.indirectPanelNumber;
            break;
          case TimesheetRowType.Equipment:
            this.panel = this.equipmentPanelNumber;
            break;
          default:
            break;
        }
      });
      return newRow;
    },
    addTimesheetRowForWorkOrder(
      isCorrectionRow: boolean,
      e: PersonWithDetailsAndName,
      workOrder: FormattedWorkOrder
    ) {
      // If this person doesn't have a general indirect row, add that too
      // Pass in 'undefined' for area/subarea as we don't care which area the row is for (null would force it to be empty)
      let existingGeneralDirectRow = this.timesheetRowAlreadyExists(
        isCorrectionRow,
        e.id,
        undefined,
        TimesheetRowType.DirectGeneral,
        null,
        undefined,
        undefined
      );
      if (!existingGeneralDirectRow) {
        this.addNonWorkOrderRelatedTimesheetRow(
          isCorrectionRow,
          e.id,
          e.name,
          e.employeeCode,
          e.employeeBadge,
          e.classificationID,
          TimesheetRowType.DirectGeneral,
          null,
          null
        );
      }

      // Add the workorder timesheet row last so its section is opened isntead of the non-work order section
      this._addTimesheetRowUsingWorkOrder(
        isCorrectionRow,
        e.id,
        e.name,
        e.employeeCode,
        e.employeeBadge,
        e.classificationID,
        TimesheetRowType.DirectWorkOrderRelated,
        workOrder,
        workOrder.areaID ?? null,
        workOrder.subAreaID ?? null
      );
    },
    addNonWorkOrderRelatedTimesheetRow(
      isCorrectionRow: boolean,
      employeeID: string | undefined,
      employeeName: string | null | undefined,
      employeeCode: string | null | undefined,
      employeeBadge: string | null | undefined,
      employeeClassificationID: string | null | undefined,
      rowType: TimesheetRowType,
      areaID: string | null,
      subAreaID: string | null
    ): TimesheetRow {
      return this._addTimesheetRowUsingWorkOrder(
        isCorrectionRow,
        employeeID,
        employeeName,
        employeeCode,
        employeeBadge,
        employeeClassificationID,
        rowType,
        null,
        areaID,
        subAreaID
      );
    },
    async addTimesheetRows(isCorrectionRow: boolean) {
      if (!this.currentTimesheet) return;
      if (this.currentTimesheet.isLocked && !isCorrectionRow) return;

      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!(this.$refs.addform as HTMLFormElement).validate()) {
        return;
      }

      if (!this.selectedEntryType) return;
      if (!this.selectedEmployeeID && !this.selectedCrewID) return;
      if (!!this.selectedCrewID && !this.crewIsSelectable(this.selectedCrew)) return;
      if (this.selectedEntryType == "workorder" && !this.selectedWorkOrders.length) return;

      this.processing = true;
      try {
        // console.log(`addTimesheetRows selectedWorkOrders: ${this.selectedWorkOrders}`);
        let selectedEmployees = this.selectedEmployees;
        selectedEmployees.forEach(e => {
          let selectedWorkOrders = this.selectedWorkOrders;
          if (!!selectedWorkOrders.length) {
            selectedWorkOrders.forEach(wo => {
              this.addTimesheetRowForWorkOrder(isCorrectionRow, e, wo);
            });
          } else {
            let rowType = TimesheetRowType.Indirect;
            if (this.selectedEntryType == "generaldirect") rowType = TimesheetRowType.DirectGeneral;
            else if (this.selectedEntryType == "equipment") rowType = TimesheetRowType.Equipment;
            this.addNonWorkOrderRelatedTimesheetRow(
              isCorrectionRow,
              e.id,
              e.name,
              e.employeeCode,
              e.employeeBadge,
              e.classificationID,
              rowType,
              null,
              null
            );
          }
        });
        this.currentTimesheet.timesheetRows = SortTimesheetRows(
          this.currentTimesheet.timesheetRows
        );

        this.selectedEntryType = null;
        this.selectedWorkOrders = [];
        if (this.workTypeOptions.length == 1)
          this.selectedEntryType = this.workTypeOptions[0].value;
        this.selectedCrewID = null;
        this.selectedEmployeeID = null;

        this.$emit("rows-added");
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    removeTimesheetRow(row: TimesheetRow) {
      if (!this.currentTimesheet) return;
      const index = this.currentTimesheet.timesheetRows.indexOf(row);
      if (index < 0) {
        return;
      }
      // Clear out all hours values for row to be removed to confirm if explanations also need to be removed
      ParseWorkSubTypeIDsFromRow(row).forEach(wstid => {
        row.times[wstid] = new TimesheetRowTimeValues();
        this.workSubTypeHoursValueChanged(row, wstid, 0);
      });
      row.hasPerDiem = false;
      row.removePerDiem = false;
      this.perDiemValueChanged(row);

      this.currentTimesheet.timesheetRows.splice(index, 1);
    },

    // *** CALCULATED PROPERTIES ***
    selectableWorkSubTypesForWorkTypeID(workTypeID: string | null | undefined): WorkSubType[] {
      return SortWorkSubTypes(this.allWorkSubTypes.filter(x => x.workTypeID == workTypeID));
    },

    // *** LOADING ***
    async loadWorkOrders(
      workOrderSearchString: string | undefined,
      restrictDay: boolean,
      restrictAssignment: boolean
    ) {
      // If the current timesheet doesn't have an owner, there can't be any WOs associated so don't bother checking
      // Also, if the current timesheet is indirect it can't have WOs associated even if they exist
      if (
        !this.currentTimesheet?.ownerID ||
        this.currentTimesheet.timesheetTypeID != TimesheetType.Direct
      )
        return;

      if (!workOrderSearchString && !this.contractor?.id) {
        return;
      }

      let activeDay = restrictDay ? this.currentTimesheet.day : undefined;
      let assignedForeman = restrictAssignment
        ? (this.currentTimesheet.ownerID as string | undefined)
        : undefined;

      // console.log(` calling workOrderService.search(
      //   ${activeDay},
      //   ${workOrderSearchString},
      //   undefined,
      //   ${this.contractor?.id},
      //   undefined,
      //   ${assignedForeman}
      // )`);
      let workOrders = await workOrderService.search(
        activeDay,
        workOrderSearchString,
        undefined,
        this.contractor?.id,
        undefined,
        assignedForeman
      );
      this.availableWorkOrders = workOrders.map(x => {
        let formattedWorkOrder = {
          ...x,
          description: formatWorkOrderNumber(x.workOrderNumber),
          workOrderNumber: formatWorkOrderNumber(x.workOrderNumber),
          details: `${ScaffoldRequestTypes[x.scaffoldRequestType!]} (${
            WorkOrderStatuses[x.workOrderStatus!]
          })`,
          requestTypeName: `${ScaffoldRequestTypes[x.scaffoldRequestType!]}`,
          workOrderStatusName: `${WorkOrderStatuses[x.workOrderStatus!]}`,
          startDateString: !!x.startDate ? this.formatDate(x.startDate) : "",
          completedDateString: !!x.completedDate ? this.formatDate(x.completedDate) : ""
        } as FormattedWorkOrder;
        return formattedWorkOrder;
      });
    },
    // DOES NOT manage processing or error message logic
    async loadAreas(): Promise<void> {
      let areas = await projectLocationService.getVisibleAreas();
      this.allAreas = areas;
    },

    // DOES NOT manage processing or error message logic
    async loadSubAreas(): Promise<void> {
      let subAreas = await projectLocationService.getVisibleSubAreas();
      this.allSubAreas = subAreas;
    },

    async loadDataForTimesheet() {
      if (!this.currentTimesheet) {
        return;
      }
      this.processing = true;
      try {
        // Load the initial list with "suggested" restricted work orders
        await this.loadWorkOrders(undefined, true, true);
        this.$nextTick(() => {
          this.toggleTableGroups("summary", true);
          if (this.hasWorkOrderDirectRows) this.panel = this.workOrderDirectPanelNumber;
          else if (this.hasGeneralDirectRows) this.panel = this.generalDirectPanelNumber;
          else if (this.hasIndirectRows) this.panel = this.indirectPanelNumber;
          else if (this.hasEquipmentRows) this.panel = this.equipmentPanelNumber;
        });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async loadData() {
      this.processing = true;
      try {
        await Promise.all([
          this.loadAreas(),
          this.loadSubAreas(),
          this.loadContractors(),
          this.loadWorkTypes(),
          this.loadWorkSubTypes(),
          this.loadCostCodes(),
          this.loadEmployees(),
          this.loadCrews(),
          this.loadClassifications()
        ]);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    getClassificationDisplayNameForID(
      classificationID: string | null | undefined
    ): string | undefined {
      let classification = (this.$store.state.classifications.fullList as Classification[]).find(
        x => x.id == classificationID
      );
      return classification?.alias ?? classification?.name;
    },
    classificationInUseForEmployee(
      classificationID: string | null | undefined,
      employeeID: string | null | undefined,
      rowType: TimesheetRowType
    ): boolean {
      if (!this.currentTimesheet?.timesheetRows?.length) return false;

      // console.log(
      //   `classificationInUseForEmployee classificationID: ${classificationID} (Rows: ${this.currentTimesheet.timesheetRows.length})`
      // );
      let index = this.currentTimesheet.timesheetRows.findIndex(x => {
        let matches =
          x.employeeID == employeeID &&
          x.classificationID == classificationID &&
          x.rowType == rowType;
        // console.log(`\t\t Row: classificationID: ${x.classificationID}, matches: ${matches}`);
        return matches;
      });
      // console.log(`\t index: ${index}`);
      return index != -1;
    },
    classificationsForRow(row: TimesheetRow): Classification[] {
      let allPeople = this.$store.state.users.fullList as PersonWithDetails[];
      let person = allPeople.find(x => x.id == row.employeeID);
      if (!person?.classificationIDs?.length) return [];

      let allClassifications = this.$store.state.classifications.fullList as Classification[];
      let selectableClassifications = allClassifications.filter(
        x => x.id == person?.classificationID || person?.classificationIDs?.includes(x.id!)
      );
      return selectableClassifications;
    },
    groupedClassificationsForRow(
      row: TimesheetRow
    ): GroupableSelectListOption<ClassificationWithDisplayName>[] {
      let selectableClassifications = this.classificationsForRow(row);
      let ungrouped = selectableClassifications
        .map(
          x =>
            ({
              ...x,
              displayName: x.alias ?? x.name,
              disabled: this.classificationInUseForEmployee(x.id, row.employeeID, row.rowType)
            } as SelectListOption<ClassificationWithDisplayName>)
        )
        .sort((a, b) => {
          let aName = a.displayName!.toLocaleLowerCase();
          let bName = b.displayName!.toLocaleLowerCase();
          if (aName < bName) return -1;
          else if (aName > bName) return 1;
          else return 0;
        });

      let groupedClassifications: GroupableSelectListOption<ClassificationWithDisplayName>[] = [];

      let usedClassifications = ungrouped.filter(x => !!x.disabled);
      let unusedClassifications = ungrouped.filter(x => !x.disabled);

      if (!!unusedClassifications.length) {
        groupedClassifications.push({
          header: this.$t("timesheets.existing.classification-select-new-row-group-label")
        });
        groupedClassifications.push({ divider: true });
        groupedClassifications = groupedClassifications.concat(unusedClassifications);
      }
      if (!!usedClassifications.length) {
        groupedClassifications.push({
          header: this.$t("timesheets.existing.classification-select-in-use-group-label")
        });
        groupedClassifications.push({ divider: true });
        groupedClassifications = groupedClassifications.concat(usedClassifications);
      }

      return groupedClassifications;
    },
    classificationSelected(row: TimesheetRow, newValue: Classification) {
      if (newValue.id == row.classificationID) return;

      let allPeople = this.$store.state.users.fullList as PersonWithDetails[];
      let person = allPeople.find(x => x.id == row.employeeID);

      if (
        !!person?.classificationID?.length &&
        !this.classificationInUseForEmployee(newValue.id, person?.id, row.rowType)
      ) {
        this._addTimesheetRow(
          false,
          row.employeeID,
          row.employeeName,
          row.employeeCode,
          row.employeeBadge,
          newValue.id,
          row.rowType,
          row.workOrderID,
          row.workOrderNumber,
          row.workOrderClientWorkOrderNumber,
          row.workOrderServiceOrderNumber,
          row.workOrderChangeOrderNumber,
          row.workOrderServiceOrderNumber,
          row.scaffoldID,
          row.workOrderCostCodeID,
          row.areaID ?? null,
          row.subAreaID ?? null
        );
      }
    },

    // *** INLINE NAVIGATION ***
    getDataTable(tableIdentifier: string) {
      return this.$refs[`${tableIdentifier}datatable`] as VDataTable;
    },
    getFieldRef(tableIdentifier: string, fieldName: string | null | undefined, item: TimesheetRow) {
      let field = fieldName!.replace("-", "").replace("-", "");
      let employeeID = item.employeeID!.replace("-", "").replace("-", "");
      let areaID = item.areaID?.replace("-", "").replace("-", "") ?? "";
      let subAreaID = item.subAreaID?.replace("-", "").replace("-", "") ?? "";
      let correctionType = item.isCorrectionRow ? "correction" : "entry";
      return `${tableIdentifier}_${field}_${employeeID}_${areaID}_${subAreaID}_${correctionType}`;
    },
    focusFieldForVisibleItemAtIndex(
      tableIdentifier: string,
      fieldName: string | null | undefined,
      index: number,
      visibleItems: TimesheetRow[]
    ) {
      if (!visibleItems.length) return;

      if (index < 0) index = 0;
      if (index >= visibleItems.length) index = visibleItems.length - 1;
      let item = visibleItems[index];

      let itemFieldRef = this.getFieldRef(tableIdentifier, fieldName, item);
      let itemField = this.$refs[itemFieldRef] as any;
      if (!!itemField["length"]) itemField = itemField[0];
      this.$nextTick(() => {
        itemField?.focus();
      });
    },
    async selectPreviousField(
      e: Event,
      tableIdentifier: string,
      fieldName: string | null | undefined,
      item: TimesheetRow
    ) {
      e.preventDefault();

      let datatable = this.getDataTable(tableIdentifier);
      let visibleItems = datatable.internalCurrentItems;
      let currentItemIndex = visibleItems.indexOf(item);
      if (currentItemIndex <= 0) {
        let _this = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          _this.focusFieldForVisibleItemAtIndex(
            tableIdentifier,
            fieldName,
            datatable.computedItemsPerPage,
            visibleItems
          );
        });
        return;
      }

      let previousIndex = currentItemIndex - 1;
      this.focusFieldForVisibleItemAtIndex(tableIdentifier, fieldName, previousIndex, visibleItems);
    },
    async selectNextField(
      e: Event,
      tableIdentifier: string,
      fieldName: string | null | undefined,
      item: TimesheetRow
    ) {
      e.preventDefault();
      let datatable = this.getDataTable(tableIdentifier);
      let visibleItems = datatable.internalCurrentItems;
      let currentItemIndex = visibleItems.indexOf(item);
      if (currentItemIndex >= visibleItems.length - 1) {
        let _this = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          _this.focusFieldForVisibleItemAtIndex(tableIdentifier, fieldName, 0, visibleItems);
        });
        return;
      }

      let nextIndex = currentItemIndex + 1;
      this.focusFieldForVisibleItemAtIndex(tableIdentifier, fieldName, nextIndex, visibleItems);
    },
    async enterPressed(
      e: KeyboardEvent,
      tableIdentifier: string,
      fieldName: string | null | undefined,
      item: TimesheetRow
    ) {
      if (e.shiftKey) await this.selectPreviousField(e, tableIdentifier, fieldName, item);
      else await this.selectNextField(e, tableIdentifier, fieldName, item);
    },

    ...mapMutations({
      setFilteringContext: "SET_FILTERING_CONTEXT"
    })
  },

  mounted: async function() {
    await this.loadData();
    await this.loadDataForTimesheet();
    if (this.workTypeOptions.length == 1) this.selectedEntryType = this.workTypeOptions[0].value;
  },

  created: async function() {
    this.setFilteringContext({
      context: "foreman-timesheet-form",
      parentalContext: this.parentContext,
      selectedTab: `tab-${this.firstTabKey}`
    });
  }
});

