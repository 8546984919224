import FDVue from "../../../../../lib/vue";
import { mapActions } from "vuex";
import dialogSupport, { createDialog } from "../../../../../lib/vue/mixins/dialogSupport";
import {
  ContractorNorm,
  ScaffoldElevationModifier,
  ScaffoldRequestTypes,
  ScaffoldTypeModifier,
  WorkOrderNormResponse,
  WorkOrderNormResponseWithDetails,
  WorkOrderWithAllDetails,
  walkdownReferenceDataService,
  workOrderNormResponseService,
  workOrderService
} from "../../../services";
import { FDColumnDirective } from "../../../../../lib/vue/utility/dataTable";
import { SortItemsWithName } from "../../../utils/person";

type WorkOrderNormsEntryDialogResult = string | boolean;
type NormWithResponse = ContractorNorm & {
  answeredDescription: string | undefined;
  quantity: number | null | undefined;
  hours: number | null | undefined;
  text: string | null | undefined;
};

const WorkOrderNormsEntryDialog = FDVue.extend({
  name: "sp-work-order-norms-entry-dialog",

  mixins: [dialogSupport],

  directives: {
    fdColumn: FDColumnDirective
  },

  components: {
    "sp-build-sheet-form": () => import("../forms/SP.BuildSheetForm.vue")
  },

  data: function() {
    return {
      workOrder: {} as WorkOrderWithAllDetails,
      allScaffoldTypes: [] as ScaffoldTypeModifier[],
      allScaffoldElevations: [] as ScaffoldElevationModifier[],
      totalTimesheetHours: 0,
      normsWithResponses: [] as NormWithResponse[],
      responses: [] as WorkOrderNormResponseWithDetails[],

      hazardStatusResult: undefined
    };
  },
  computed: {
    unwatchedMethodNames(): string[] {
      return ["open", "getFieldRef"];
    }
  },
  watch: {},

  methods: {
    ...mapActions({
      loadStoreNorms: "LOAD_CONTRACTOR_NORMS"
    }),
    async loadScaffoldTypes(): Promise<void> {
      this.allScaffoldTypes = SortItemsWithName(
        await walkdownReferenceDataService.getActiveScaffoldTypeModifiers()
      );
      if (
        !!this.allScaffoldTypes?.length &&
        !this.workOrder.buildSheetScaffoldTypeModifierID?.length
      ) {
        this.workOrder.buildSheetScaffoldTypeModifierID = this.allScaffoldTypes[0].id;
      }
    },
    async loadScaffoldElevations(): Promise<void> {
      this.allScaffoldElevations = SortItemsWithName(
        await walkdownReferenceDataService.getAllScaffoldElevationModifiers()
      );
      if (
        !!this.allScaffoldElevations?.length &&
        !this.workOrder.buildSheetScaffoldElevationModifierID?.length
      ) {
        this.workOrder.buildSheetScaffoldElevationModifierID = this.allScaffoldElevations[0].id;
      }
    },
    async loadTimesheeTrackedHoursForNorms() {
      this.totalTimesheetHours = await workOrderService.getTotalDirectTimeHoursForWorkOrder(
        this.workOrder.id!
      );
    },
    async loadNorms() {
      let contractorID = this.workOrder.assignedContractorID;
      if (!contractorID?.length) return;

      await Promise.all([
        this.loadStoreNorms({
          forcedArchivedState: false
        }),
        this.loadNormResponses(),
        this.loadTimesheeTrackedHoursForNorms()
      ]);

      let allNorms = this.$store.state.contractorNorms.fullList as ContractorNorm[];
      let activeNorms = allNorms.filter(x => !!x.isActive);
      let normsWithResponses = activeNorms
        .filter(x => x.contractorID == contractorID)
        .sort((a, b) => (a.displayOrder ?? 0) - (b.displayOrder ?? 0))
        .map((n: ContractorNorm) => {
          let response = this.responses.find(r => r.contractorNormID == n.id);
          return {
            ...n,
            answeredDescription: response?.normDescription,
            quantity: response?.quantity ?? 0,
            hours: response?.hours ?? 0.0,
            text: response?.text
          } as NormWithResponse;
        });

      let answeredNormIDs = normsWithResponses
        .filter(x => !!x.answeredDescription?.length)
        .map(x => x.id);
      let unusedResponses = this.responses.filter(
        r => !answeredNormIDs.includes(r.contractorNormID)
      );
      if (unusedResponses.length) {
        let count = normsWithResponses.length;
        let oldNormsWithResponses = unusedResponses
          .sort((a, b) => (a.normDisplayOrder ?? 0) - (b.normDisplayOrder ?? 0))
          .map(
            r =>
              ({
                id: r.contractorNormID,
                displayOrder: count + (r.normDisplayOrder ?? 0),
                answeredDescription: r.normDescription,
                quantity: r.quantity,
                hours: r.hours,
                text: r.text
              } as NormWithResponse)
          );
        normsWithResponses = normsWithResponses.concat(oldNormsWithResponses);
      }
      this.normsWithResponses = normsWithResponses;
    },
    async loadNormResponses() {
      this.responses = await workOrderNormResponseService.getResponsesForWorkOrder(
        this.workOrder.id!
      );
    },
    async loadData() {
      this.inlineMessage.message = "";
      this.processing = true;
      try {
        await Promise.all([
          this.loadScaffoldTypes(),
          this.loadScaffoldElevations(),
          this.loadNorms()
        ]);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async open(workOrder: WorkOrderWithAllDetails): Promise<WorkOrderNormsEntryDialogResult> {
      this.workOrder = workOrder;
      this.loadData();
      this.optOutOfErrorHandling();
      return await this.showDialog!();
    },

    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },
    async saveDialog() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      this.processing = true;
      try {
        let data = this.normsWithResponses
          .filter(
            x =>
              this.$parse.sanitizedNumber(x.quantity) != 0 ||
              this.$parse.sanitizedNumber(x.hours) != 0 ||
              !!x.text?.length
          )
          .map(
            x =>
              ({
                workOrderID: this.workOrder.id!,
                contractorNormID: x.id,
                normDisplayOrder: this.$parse.number(x.displayOrder),
                normDescription: x.description,
                quantity: this.$parse.number(x.quantity),
                hours: this.$parse.number(x.hours),
                text: x.text
              } as WorkOrderNormResponse)
          );
        await workOrderNormResponseService.updateWorkOrderNormResponses(
          this.workOrder.id!,
          this.$parse.sanitizedNumber(this.workOrder.buildSheetLength),
          this.$parse.sanitizedNumber(this.workOrder.buildSheetWidth),
          this.$parse.sanitizedNumber(this.workOrder.buildSheetHeight),
          this.$parse.sanitizedNumber(this.workOrder.buildSheetDeckLevels),
          this.workOrder.buildSheetHazardLevel,
          this.workOrder.buildSheetConfinedSpace ?? false,
          this.workOrder.buildSheetPpeRequired ?? false,
          this.workOrder.buildSheetNotes,
          this.workOrder.buildSheetScaffoldTypeModifierID,
          this.workOrder.buildSheetScaffoldElevationModifierID,
          this.$parse.sanitizedNumber(this.workOrder.currentScaffoldVlf),
          this.$parse.sanitizedNumber(this.workOrder.vlfChangeAmount),
          data
        );
        this.closeDialog!(true);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    }
  }
});

export default WorkOrderNormsEntryDialog;

export async function openWorkOrderNormsEntryDialog(
  workOrder: WorkOrderWithAllDetails
): Promise<WorkOrderNormsEntryDialogResult> {
  let dialog = createDialog(WorkOrderNormsEntryDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open(workOrder);
}

