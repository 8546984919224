import { Tag, SupplierWithTags, PartWithTags } from "./index";

// TODO: Properties are optional here only because we've defined our items too loosely
export interface TaggableItem {
  tagIDs?: string[] | null | undefined;
  name?: string | null | undefined;
}

// TODO: These are optional here only because we've defined our global objects too loosely
export interface ItemWithSupplier {
  supplierIDs?: string[] | null;
  name?: string | null | undefined;
}

// TODO: These are optional here only because we've defined our global objects too loosely
export interface ItemWithContractor {
  contractorID?: string | null;
  name?: string | null | undefined;
}

export function filterByTags<T extends TaggableItem>(
  tagsSelectedForFiltering: Tag[],
  taggableItems: T[]
): T[] {
  if (tagsSelectedForFiltering.length) {
    let returnValueParts = taggableItems.filter(x => {
      if (!x.tagIDs) return false;
      for (let tag of tagsSelectedForFiltering) {
        if (x.tagIDs.indexOf(tag.id!) !== -1) return true;
      }
      return false;
    });
    // Make sure to sort the returned list by "Name" ascending
    //return returnValueParts.sort((a, b) => { return (a.name!.toLowerCase() < b.name!.toLowerCase()) ? -1 : (a.name!.toLowerCase() > b.name!.toLowerCase()) ? 1 : 0; });
    // CANNOT guarantee that the object being sorted has a property of "name". The "User" object is an example of this.
    return returnValueParts;
  } else {
    return taggableItems;
  }
}

export function filterBySuppliers<T extends ItemWithSupplier>(
  supplierIDsSelectedForFiltering: string[],
  supplierableItems: T[],
  supplierFilterIncludesUnset: boolean = false
): T[] {
  //If Any Supplier objects have been selected by the user for filtering.
  if (supplierIDsSelectedForFiltering.length) {
    //If the items that has a supplierID associated to is within the selected Suppliers return it
    let returnValueItems = supplierableItems.filter(x => {
      // If supplier objects to filter by are passed in, then we don't want any items with 0 suppliers
      // Unless the supplierFilterIncludesUnset is true
      if (!x.supplierIDs?.length) return supplierFilterIncludesUnset;
      for (let supplierID of supplierIDsSelectedForFiltering) {
        if (x.supplierIDs.indexOf(supplierID) != -1) return true;
      }
      return false;
    });
    // Make sure to sort the returned list by "Name" ascending
    return returnValueItems.sort((a, b) => {
      return a.name!.toLowerCase() < b.name!.toLowerCase()
        ? -1
        : a.name!.toLowerCase() > b.name!.toLowerCase()
        ? 1
        : 0;
    });
  } else {
    return supplierableItems;
  }
}

export function filterByContractors<T extends ItemWithContractor>(
  contractorsSelectedForFiltering: string[],
  contractorableItems: T[]
): T[] {
  //If Any Contractor objects have been selected by the user for filtering.
  if (contractorsSelectedForFiltering.length) {
    //If the items that has a contractorID associated to it is within the selected Contractors return it
    let returnValueItems = contractorableItems.filter(x => {
      if (!x.contractorID) return false;
      for (let contractor of contractorsSelectedForFiltering) {
        if (x.contractorID == contractor) return true;
      }
      return false;
    });
    //Cannot sort this returned list on "Name" since for objects like "Users" there is no "name" property.
    return returnValueItems;
  } else {
    return contractorableItems;
  }
}

export function getTagsInUse<T extends TaggableItem>(tags: Tag[], taggableItems: T[]): Tag[] {
  let returnValueTags = tags.filter(x => {
    let returnValuePartIndex = taggableItems.findIndex(y => {
      let returnValueTagIndex = y.tagIDs?.indexOf(x.id!);
      return returnValueTagIndex !== undefined && returnValueTagIndex !== -1;
    });
    return returnValuePartIndex !== -1;
  });
  // Make sure to sort the returned list by "Name" ascending
  //return returnValueTags.sort((a, b) => { return (a.name && b.name && (a.name?.toLowerCase() < b.name.toLowerCase())) ? -1 : (a.name && b.name && (a.name.toLowerCase() > b.name.toLowerCase())) ? 1 : 0; });
  // CANNOT guarantee that the object being sorted has a property of "name". The "User" object is an example of this.
  return returnValueTags;
}

export function getSuppliersInUse<T extends ItemWithSupplier>(
  suppliers: SupplierWithTags[],
  supplierableItems: T[]
): SupplierWithTags[] {
  let returnValueSuppliers = suppliers.filter(x => {
    let returnValueItemIndex = supplierableItems.findIndex(y => {
      return y.supplierIDs?.indexOf(x.id!) !== -1;
    });
    return returnValueItemIndex !== -1;
  });
  // Make sure to sort the returned list by "Name" ascending
  return returnValueSuppliers.sort((a, b) => {
    return a.name && b.name && a.name.toLowerCase() < b.name.toLowerCase()
      ? -1
      : a.name && b.name && a.name.toLowerCase() > b.name.toLowerCase()
      ? 1
      : 0;
  });
}

