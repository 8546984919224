import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import rules from "@fd/lib/vue/rules";
import { mapActions } from "vuex";
import { stripTimeFromLocalizedDateTime } from "@fd/lib/client-util/datetime";
import { VDataTable } from "@fd/lib/vue/types";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import {
  Classification,
  contractorService,
  ContractorWithTags,
  CrewWithEmployees,
  TimesheetEntryWithDetails,
  timesheetService,
  TimesheetStatus,
  PersonWithDetails,
  ProjectLocation,
  projectLocationService,
  WorkOrderWithAllDetails,
  WorkSubType,
  WorkType,
  TimesheetExplanationWithWorkOrderDetails,
  TimesheetWithChildren,
  EmployeeTimeSummary,
  WorkSubTypeTimeSummary
} from "@fd/current/client/services";
import {
  GetPersonName,
  PersonHasEquipmentClassification,
  PersonWithDetailsAndName,
  SortItemsWithName
} from "@fd/current/client/utils/person";
import { TranslateResult } from "vue-i18n";
import {
  CalculateRowTotalTime,
  HashTable,
  ParseWorkSubTypeIDsFromRow,
  SortTimesheetRows,
  SortWorkTypes,
  TimesheetRow,
  TimesheetRowTimeValues,
  TimesheetRowType,
  UpdatableTimesheetEntryWithDetails,
  UpdatableTimesheetWithTimesheetRows,
  UpdateRowCorrections,
  areTimesheetRowsEqual
} from "../../../utils/timesheet";
import userAccess from "../../../dataMixins/userAccess";
import { GroupableSelectListOption, SelectListOption } from "@fd/lib/vue/utility/select";
import {
  createNewCrew,
  SortCrewEmployees,
  updateExistingCrew
} from "./CrewDetailsBottomDialog.vue";
import { showAdditionalDetailsBottomDialog } from "../../../../../common/client/views/components/AdditionalDetailsBottomDialog.vue";
import { formatWorkOrderNumber } from "../../../utils/workorder";

function CompareWorkSubTypes(a: WorkSubType, b: WorkSubType): number {
  let aOrder = a.order ?? 0;
  let bOrder = b.order ?? 0;
  if (aOrder != bOrder) return aOrder - bOrder;

  let aName = a.name?.toLocaleLowerCase() ?? "";
  let bName = b.name?.toLocaleLowerCase() ?? "";
  if (aName < bName) return -1;
  else if (aName > bName) return 1;
  return 0;
}
function SortWorkSubTypes(items: WorkSubType[] | null | undefined): WorkSubType[] {
  if (!items) return [];
  return items.sort(CompareWorkSubTypes);
}
type ClassificationWithDisplayName = Classification & { displayName: string | undefined };
type PossibleNumber = number | string | null | undefined;
type TableHeader = {
  text: string | TranslateResult | undefined | null;
  value: string | undefined;
  align?: "start" | "center" | "end";
  sortable?: boolean;
  filterable?: boolean;
  groupable?: boolean;
  divider?: boolean;
  class?: string | string[];
  cellClass?: string | string[];
  width?: string | number;
  filter?: (value: any, search: string, item: any) => boolean;
  sort?: (a: any, b: any) => number;
};
let today = new Date(new Date().toDateString());
const LabourEntryDialog = FDVue.extend({
  name: "sp-labour-entry-dialog",
  mixins: [dialogSupport, rules, userAccess],
  components: {
    "sp-timesheet-time-display": () => import("../SP.TimesheetTimeDisplay.vue"),
    "sp-timesheet-perdiem": () => import("../forms/ForemanTimesheetPerdiem.vue")
  },
  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  data: function() {
    return {
      tablepage: 1,
      canEditOtherDaysTimesheets: true,

      timeSummaries: [] as EmployeeTimeSummary[],
      workOrder: {} as WorkOrderWithAllDetails,
      workOrderDateList: [
        {
          text: stripTimeFromLocalizedDateTime(today),
          value: today
        }
      ] as {
        text: string;
        value: Date;
        hasTimesheet?: boolean;
      }[],
      currentTimesheet: null as UpdatableTimesheetWithTimesheetRows | null,
      timesheetsWithoutEntries: [] as TimesheetWithChildren[],
      timesheets: [] as UpdatableTimesheetWithTimesheetRows[],
      selectedDay: today,
      contractor: {} as ContractorWithTags,
      allAreas: [] as ProjectLocation[],
      allSubAreas: [] as ProjectLocation[],
      selectedEmployeeID: null as string | null,
      selectedCrewID: null as string | null,

      allWorkTypes: [] as WorkType[],
      usableWorkTypes: [] as WorkType[],
      allWorkSubTypes: [] as WorkSubType[],
      usableWorkSubTypes: [] as WorkSubType[],
      perDiemType: undefined as WorkType | undefined,
      perDiemSubType: undefined as WorkSubType | undefined,
      equipmentType: undefined as WorkType | undefined,
      equipmentSubType: undefined as WorkSubType | undefined
    };
  },

  computed: {
    perDiemSubTypeIsWorkOrderRelated(): boolean {
      return this.perDiemSubType?.isWorkOrderRelated ?? false;
    },
    currentTimesheetWorkOrderRows() {
      return (
        this.currentTimesheet?.timesheetRows.filter(x => x.workOrderID == this.workOrder.id!) ?? []
      );
    },
    timesheetExplanations() {
      if (!this.currentTimesheet?.id) return [];
      return this.currentTimesheet.explanations?.filter(x => x.workOrderID == this.workOrder.id);
    },
    timesheetStatusChipText(): string {
      return this.$t(
        `timesheets.status.${
          !this.currentTimesheet || this.currentTimesheet.isNew
            ? 0
            : this.currentTimesheet.timesheetStatusID
        }`
      )
        .toString()
        .toUpperCase();
    },
    workOrderNumbersWithDetailWorkSubTypes(): any[] {
      if (!this.currentTimesheet?.id) return [];
      return [...new Set(this.timesheetExplanations?.map(x => x.workOrderNumber))];
    },
    currentTimesheetIsDeclined(): boolean {
      return this.currentTimesheet?.timesheetStatusID == TimesheetStatus.Declined;
    },
    currentTimesheetDeclineComments(): string | undefined {
      return this.currentTimesheet?.lastStatusLog?.comments;
    },
    currentTimesheetIsReadonly(): boolean {
      return !this.canEditTimesheet(this.currentTimesheet);
    },
    timesheetIsSubmitted(): boolean {
      return this.currentTimesheet?.timesheetStatusID == TimesheetStatus.Submitted;
    },
    timesheetIsApproved(): boolean {
      return this.currentTimesheet?.timesheetStatusID == TimesheetStatus.Approved;
    },
    timesheetIsCancelled(): boolean {
      return this.currentTimesheet?.timesheetStatusID == TimesheetStatus.Cancelled;
    },
    canSave(): boolean {
      let timesheetsToSave = this.timesheets.filter(x => this.canSaveTimesheet(x));
      return timesheetsToSave.length > 0;
    },
    canModifySelectedCrew(): boolean {
      if (!this.selectedCrewID) return false;
      return this.selectedCrew?.ownerID == this.curUserID || this.currentUserCanConfigureSettings;
    },
    minDate(): Date | undefined | null {
      let startDate = this.workOrder.startDate;
      if (!startDate) startDate = this.workOrder.requestSubmittedOn;
      return !!startDate ? new Date(new Date(startDate).toDateString()) : undefined;
    },
    maxDate(): Date {
      let maxDate = !!this.workOrder.completedDate ? this.workOrder.completedDate : new Date();
      return new Date(maxDate.toDateString());
    },
    newTimesheetEntries(): UpdatableTimesheetEntryWithDetails[] {
      if (!this.currentTimesheet?.timesheetRows) return [];

      let allPeople = this.$store.state.users.fullList as PersonWithDetails[];
      return this.currentTimesheet.getNewEntries(
        this.perDiemSubType,
        this.equipmentSubType,
        this.allWorkSubTypes,
        allPeople
      );
    },
    modifiedTimesheetEntries(): UpdatableTimesheetEntryWithDetails[] {
      if (!this.currentTimesheet?.timesheetRows) return [];

      let allPeople = this.$store.state.users.fullList as PersonWithDetails[];
      return this.currentTimesheet.getModifiedEntries(
        this.perDiemSubType,
        this.equipmentSubType,
        this.allWorkSubTypes,
        allPeople
      );
    },
    removedTimesheetEntries(): TimesheetEntryWithDetails[] {
      if (!this.currentTimesheet?.timesheetRows) return [];

      let allPeople = this.$store.state.users.fullList as PersonWithDetails[];
      return this.currentTimesheet.getRemovedEntries(
        this.perDiemSubType,
        this.equipmentSubType,
        this.allWorkSubTypes,
        allPeople
      );
    },
    tableHeaders(): TableHeader[] {
      let headers = [
        {
          text: this.$t("timesheets.existing.employee-column-label") as
            | string
            | TranslateResult
            | undefined,
          value: "employeeName",
          class: "fd-table-frozen-column fd-employee-name-column",
          cellClass: "fd-table-frozen-column fd-employee-name-column"
        },
        {
          text: (this.$vuetify.breakpoint.mdAndUp
            ? this.$t("timesheets.existing.classification-column-label")
            : this.$t("timesheets.existing.classification-column-label-short")) as
            | string
            | TranslateResult
            | undefined,
          value: "classificationDisplayName",
          class: "fd-table-frozen-column fd-classification-column",
          cellClass: "fd-table-frozen-column fd-classification-column"
        }
      ] as TableHeader[];

      for (let workSubType of this.usableWorkSubTypes) {
        if (!workSubType.useWorkOrderCostCode && !workSubType.defaultCostCodeID?.length) {
          console.log(
            `${workSubType.name} - defaultCostCodeID: ${workSubType.defaultCostCodeID}, useWorkOrderCostCode: ${workSubType.useWorkOrderCostCode}`
          );
        }
        headers.push({
          text: workSubType.code ?? workSubType.name,
          value: workSubType.id,
          class: "fd-rotate-header-text"
        });
      }

      headers.push({
        text: this.$t("common.total"),
        value: "total",
        class: "text-end fd-table-column-text-end-override"
      });
      headers.push({
        text: this.$t("timesheets.day-total"),
        value: "daytotal",
        class: "text-end fd-table-column-text-end-override"
      });
      if (!!this.perDiemSubType) {
        headers.push({
          text: this.$t("common.per-diem"),
          value: "perdiem",
          class: "fd-table-column-text-end-override fd-restrict-table-entry-column-width-per-diem"
        });
      }
      headers.push({
        text: this.$t("common.action"),
        value: "action",
        class: "fd-action-cell",
        cellClass: "fd-action-cell"
      });
      return headers;
    },
    unwatchedMethodNames(): string[] {
      return [
        "areTimesheetRowsEqual",
        "classificationsForRow",
        "classificationInUseForEmployee",
        "classificationChanged",
        "formatWorkOrderNumber",
        "ParseWorkSubTypeIDsFromRow",
        "sumRowTimeValues",
        "currentTimesheetExplanationsForWorkOrderNumber",
        "workSubTypeNameForExplanation",
        "workSubTypeHoursValueChanged",
        "otherTimesheetEmployeeSummary",
        "otherTimesheetPerDiemSummaryForEmployee",
        "perDiemValueChanged",
        "checkWorkSubTypeAdditionalDetails",
        "showNewExplanationDialog",
        "dateIsToday",
        "canEditTimesheet",
        "saveDialog",
        "getFieldRef",
        "focusFieldForVisibleItemAtIndex",
        "selectPreviousField",
        "selectNextField",
        "enterPressed",
        "loadTimesheets",
        "loadSelectedDayTimesheetDetails",
        "calculateTotalForItem",
        "calculateTotalForItems",
        "addTimesheetRowRules",
        "addTimesheetRow",
        "addTimesheetRows",
        "addTimesheetRowsInBackground",
        "selectableWorkSubTypesForWorkTypeID",
        "updateAllValues",
        "employeeHasOtherTime",
        "totalTimeForEmployeeOnDay",
        "totalTimeForEmployeeForWorkSubTypeOnDay",
        "timesheetRowRules",
        "validTimeForDayRule",
        "totalTimeForEmployeeForWorkSubType",
        "getTimesForItem",
        "getTimeValueForItem",
        "existingUncorrectedPerDiemRowForEmployee",
        "existingOtherUncorrectedPerDiemRowForEmployee",
        "hasExistingOtherUncorrectedPerDiemRowForEmployee",
        "otherPerDiemRowWorkOrderNumber",
        "hasPerDiemOnOtherTimesheet",
        "perDiemOwnerFromOtherTimesheet",
        "perDiemReadonlyReason",
        "confirmEmployeePerDiemForRow",
        "canEditPerDiem"
      ];
    },
    itemsPerPage(): number {
      return 10;
    },
    itemsPerPageOptions(): number[] {
      return [5, 10, 25, -1];
    },
    formattedSelectedDay(): string {
      return stripTimeFromLocalizedDateTime(this.selectedDay);
    },

    selectableEmployees(): SelectListOption<PersonWithDetailsAndName>[] {
      let allEmployees = this.$store.state.users.fullList as PersonWithDetails[];
      let filteredEmployees = allEmployees
        .filter(
          x =>
            !PersonHasEquipmentClassification(x) &&
            !!x.contractorID &&
            x.contractorID == this.workOrder.assignedContractorID
        )
        .map(
          x =>
            ({
              ...x,
              name: GetPersonName(x),
              nameWithCode: GetPersonName(x, true, true),
              disabled:
                this.currentTimesheet?.timesheetRows?.find(
                  t => t.employeeID == x.id && t.workOrderID == this.workOrder.id
                ) != undefined
            } as SelectListOption<PersonWithDetailsAndName>)
        );
      let selectableEmployees = SortItemsWithName(filteredEmployees);

      return selectableEmployees;
    },
    selectedEmployee(): PersonWithDetailsAndName | undefined {
      let selectableEmployees = this.selectableEmployees;
      return selectableEmployees.find(x => x.id == this.selectedEmployeeID);
    },
    availableCrews(): CrewWithEmployees[] {
      let allCrews = this.$store.state.crews.fullList as CrewWithEmployees[];
      let availableCrews = allCrews.filter(
        x => x.contractorID == this.currentTimesheet?.contractorID
        // For now, we rely on the server to determine what crews are visible for this person, and we show ALL visible crews for this contractor
        // This means that admins with Configure Settings permission see all crews for all of this contractor's employees
        // && (!x.ownerID || x.ownerID == this.curUserID)
      );
      return availableCrews;
    },
    selectableCrews(): GroupableSelectListOption<CrewWithEmployees>[] {
      let availableCrews = this.availableCrews;
      let selectableCrews = availableCrews.map(
        x =>
          ({
            ...x
          } as SelectListOption<CrewWithEmployees>)
      );

      let allPeople = (this.$store.state.users.fullList as PersonWithDetails[]).map(p => ({
        ...p,
        name: GetPersonName(p)
      }));
      let selectableCrewsByOwnerName = selectableCrews.reduce((a, b) => {
        let ownerName = allPeople.find(p => p.id == b.ownerID)?.name ?? "";
        if (!ownerName.length && !!b.ownerID?.length) {
          ownerName = this.$t("common.unknown") as string;
        }
        let existingCrews = a[ownerName] ?? [];
        existingCrews.push(b);
        a[ownerName] = existingCrews;
        return a;
      }, {} as HashTable<SelectListOption<CrewWithEmployees>[]>);

      var curUserName = allPeople.find(p => p.id == this.curUserID)?.name ?? this.curUserID;
      let myCrews = SortItemsWithName(selectableCrewsByOwnerName[curUserName]);
      let unownedCrews = SortItemsWithName(selectableCrewsByOwnerName[""]);
      let otherCrewOwnerNames = Object.keys(selectableCrewsByOwnerName)
        .filter(x => x != "" && x != curUserName)
        .sort();

      let returnList = [] as GroupableSelectListOption<CrewWithEmployees>[];
      if (myCrews.length > 0) {
        returnList.push({ header: this.$t("timesheets.entries.my-crews") });
        myCrews.forEach(c => returnList.push(c));
        if (unownedCrews.length > 0 || otherCrewOwnerNames.length > 0)
          returnList.push({ divider: true });
      }

      if (unownedCrews.length > 0) {
        returnList.push({ header: this.$t("timesheets.entries.global-crews") });
        unownedCrews.forEach(c => returnList.push(c));
        if (otherCrewOwnerNames.length > 0) returnList.push({ divider: true });
      }

      if (otherCrewOwnerNames.length > 0) {
        for (let i = 0; i < otherCrewOwnerNames.length; i++) {
          let ownerName = otherCrewOwnerNames[i];
          let list = selectableCrewsByOwnerName[ownerName];
          if (list.length > 0) {
            returnList.push({ header: ownerName });
            list.forEach(c => returnList.push(c));
            if (i + 1 < otherCrewOwnerNames.length) returnList.push({ divider: true });
          }
        }
      }

      return returnList;
    },
    selectedCrew(): CrewWithEmployees | undefined {
      let availableCrews = this.availableCrews;
      return availableCrews.find(x => x.id == this.selectedCrewID);
    },
    selectedEmployees(): PersonWithDetailsAndName[] {
      var selectedEmployeeIDs = [] as string[];
      if (!!this.selectedEmployeeID) selectedEmployeeIDs.push(this.selectedEmployeeID);

      let selectedCrew = this.selectedCrew;
      if (!!selectedCrew)
        selectedEmployeeIDs = selectedEmployeeIDs.concat(
          SortCrewEmployees(selectedCrew.employees)?.map(x => x.employeeID!) ?? []
        );

      let selectableEmployees = this.selectableEmployees;
      return selectedEmployeeIDs
        .filter(x => !!selectableEmployees.find(e => e.id! == x))
        .map(x => selectableEmployees.find(e => e.id! == x)!);
    }
  },

  watch: {
    timesheetsWithoutEntries(newValue, oldValue) {
      // console.log(`*** timesheetsWithoutEntries changed ${oldValue} -> ${newValue}`);
    },
    selectedDay(newValue, oldValue) {
      if (newValue.getTime() == oldValue.getTime()) return;

      let currentTimesheet = this.timesheets.find(
        x =>
          x.day!.getTime() == this.selectedDay.getTime() &&
          x.contractorID == this.workOrder.assignedContractorID!
      );
      if (currentTimesheet) {
        this.currentTimesheet = currentTimesheet;
      } else {
        this.reloadSelectedDayTimesheetDetails();
      }
    }
  },

  methods: {
    currentTimesheetExplanationsForWorkOrderNumber(
      woNumber: string
    ): TimesheetExplanationWithWorkOrderDetails[] {
      return (
        this.currentTimesheet?.explanations?.filter(x => (x.workOrderNumber ?? "") == woNumber) ??
        []
      );
    },
    workSubTypeNameForExplanation(
      explanation: TimesheetExplanationWithWorkOrderDetails
    ): string | null | undefined {
      if (!explanation.workSubTypeID) return undefined;

      let workSubType = this.allWorkSubTypes.find(x => x.id == explanation.workSubTypeID);
      return workSubType?.name;
    },
    checkWorkSubTypeAdditionalDetails(
      newValue: TimesheetRowTimeValues,
      workSubTypeID: string | null | undefined,
      workOrderNumber: string | null | undefined
    ) {
      if (!workSubTypeID?.length) return;

      let workOrderID: string | null | undefined = this.workOrder.id!;
      let wst = this.allWorkSubTypes.find(x => x.id == workSubTypeID);
      if (!wst?.requiresAdditionalDetails) return;

      let existingExplanation = this.currentTimesheet?.explanations?.find(
        x => x.workSubTypeID == workSubTypeID && x.workOrderID == workOrderID
      );
      if (!!newValue && newValue.totalTime > 0) {
        if (!existingExplanation) {
          this.showNewExplanationDialog(
            workOrderID ?? "",
            workOrderNumber ?? "",
            workSubTypeID,
            wst.name ?? ""
          );
        }
      } else {
        // Value was removed for this WST/WO combination
        // Check if there are any other entries with this combination
        if (!!existingExplanation) {
          let otherExistingRowsWithValue = this.currentTimesheet?.timesheetRows.filter(
            x =>
              x.workOrderID == workOrderID &&
              !!x.times[workSubTypeID] &&
              x.times[workSubTypeID].totalTime > 0
          );
          if (!otherExistingRowsWithValue?.length) {
            let existingIndex = this.currentTimesheet!.explanations.indexOf(existingExplanation);
            this.currentTimesheet!.explanations.splice(existingIndex, 1);
          }
        }
      }
    },
    formatWorkOrderNumber(workOrderNumber: any) {
      return formatWorkOrderNumber(workOrderNumber);
    },
    async showNewExplanationDialog(
      workOrderID: string,
      workOrderNumber: string,
      workSubTypeID: string,
      workSubTypeName: string
    ) {
      if (!this.currentTimesheet) return;

      let newExplanation = {
        timesheetID: this.currentTimesheet.id,
        workOrderID: workOrderID,
        workOrderNumber: workOrderNumber,
        workSubTypeID: workSubTypeID
      } as TimesheetExplanationWithWorkOrderDetails;

      let title = this.$t("timesheets.existing.additional-details-label", [
        this.formatWorkOrderNumber(workOrderNumber)
      ]);
      let label = workSubTypeName;
      let explanationText = await showAdditionalDetailsBottomDialog(
        { title, label },
        this.$refs.content as Vue
      );

      newExplanation.explanation = explanationText;
      this.currentTimesheet.explanations = this.currentTimesheet.explanations?.concat([
        newExplanation
      ]);
    },
    perDiemValueChanged(row: TimesheetRow) {
      UpdateRowCorrections(row, this.currentTimesheet!.timesheetRows);
    },
    workSubTypeHoursValueChanged(
      row: TimesheetRow,
      workSubTypeID: string | null | undefined,
      value: PossibleNumber
    ) {
      if (!workSubTypeID?.length) return;

      let times = new TimesheetRowTimeValues(null, null, null, value);

      if (!!times.regularTime) times.regularTime = Number(times.regularTime?.toFixed(2));
      if (!!times.overTime) times.overTime = Number(times.overTime?.toFixed(2));
      if (!!times.doubleTime) times.doubleTime = Number(times.doubleTime?.toFixed(2));

      row.times[workSubTypeID] = times;
      // row.totalTime = this.calculateTotalForItem(row);

      this.checkWorkSubTypeAdditionalDetails(times, workSubTypeID, row.workOrderNumber);
      this.confirmEmployeePerDiemForRow(row);
    },
    otherTimesheetEmployeeSummary(employeeID: string): EmployeeTimeSummary | undefined {
      return this.timeSummaries?.find(x => x.employeeID == employeeID);
    },
    otherTimesheetPerDiemSummaryForEmployee(
      employeeID: string
    ): WorkSubTypeTimeSummary | undefined {
      let perdiemSummary = this.otherTimesheetEmployeeSummary(
        employeeID
      )?.workSubTypeTimeSummaries.find(x => x.workSubTypeID == this.perDiemSubType?.id);
      return perdiemSummary;
    },
    existingUncorrectedPerDiemRowForEmployee(employeeID: string): TimesheetRow | undefined {
      return this.currentTimesheet?.timesheetRows.find(
        x =>
          x.employeeID == employeeID &&
          x.hasPerDiem &&
          !x.removePerDiem &&
          !x.relatedCorrectionRemovePerDiem
      );
    },
    // If the employee has an uncorrected perdiem row that is NOT the specified row
    existingOtherUncorrectedPerDiemRowForEmployee(row: TimesheetRow): TimesheetRow | undefined {
      let existingPerDiemRowForEmployee = this.existingUncorrectedPerDiemRowForEmployee(
        row.employeeID
      );
      if (!existingPerDiemRowForEmployee) return undefined;
      return !areTimesheetRowsEqual(row, existingPerDiemRowForEmployee)
        ? existingPerDiemRowForEmployee
        : undefined;
    },
    hasExistingOtherUncorrectedPerDiemRowForEmployee(row: TimesheetRow): boolean {
      return !!this.existingOtherUncorrectedPerDiemRowForEmployee(row);
    },
    otherPerDiemRowWorkOrderNumber(item: TimesheetRow) {
      return this.existingOtherUncorrectedPerDiemRowForEmployee(item)?.workOrderNumber;
    },
    hasPerDiemOnOtherTimesheet(item: TimesheetRow): boolean {
      let employeeSummary = this.timeSummaries?.find(x => x.employeeID == item.employeeID);
      let perdiemSummary = employeeSummary?.workSubTypeTimeSummaries.find(
        x => x.workSubTypeID == this.perDiemSubType?.id
      );
      return !!perdiemSummary?.totalUnits;
    },
    perDiemOwnerFromOtherTimesheet(employeeID: string): string | undefined {
      let existingPerDiemRowForEmployee = this.existingUncorrectedPerDiemRowForEmployee(employeeID);
      if (!existingPerDiemRowForEmployee) {
        return this.otherTimesheetEmployeeSummary(employeeID)?.perDiemTimesheetOwnerName;
      }

      return undefined;
    },
    perDiemReadonlyReason(item: TimesheetRow) {
      let perdiemOtherTimesheetOwner = this.perDiemOwnerFromOtherTimesheet(item.employeeID);
      if (!!perdiemOtherTimesheetOwner) {
        return this.$t("timesheets.per-diem-already-applied-chip-label");
      }
      let existingPerDiemRowForEmployee = this.existingUncorrectedPerDiemRowForEmployee(
        item.employeeID
      );
      if (!!existingPerDiemRowForEmployee) {
        // If there is an existing per diem row on this timesheet, check if it's the current row
        // If it is it's editable, if it's not it's not editable
        let existingPerDiemRowIsCurrentItem = areTimesheetRowsEqual(
          item,
          existingPerDiemRowForEmployee
        );

        // If the existing row isn't the current item, but is still the same WO, then we don't need a specific reason
        if (
          !existingPerDiemRowIsCurrentItem &&
          existingPerDiemRowForEmployee.workOrderID != item.workOrderID
        ) {
          if (existingPerDiemRowForEmployee.rowType == TimesheetRowType.DirectWorkOrderRelated) {
            return this.$t("timesheets.per-diem-other-work-order-applied-chip-label", [
              formatWorkOrderNumber(existingPerDiemRowForEmployee.workOrderNumber)
            ]);
          } else {
            return this.$t("timesheets.per-diem-general-applied-chip-label");
          }
        }
      }

      // Let the control use its default text
      return undefined;
    },
    confirmEmployeePerDiemForRow(row: TimesheetRow) {
      // console.log(`confirmEmployeePerDiemForRow`);
      // If the timesheet is locked and this is a correction row, don't do any automatic logic
      if (row.isCorrectionRow) return;

      // Confirm the hours for this work sub type don't go below 0 or above 18
      let totalRegularTime = this.totalTimeForEmployeeOnDay(row).totalTime;
      let perdiemOtherTimesheetOwner = this.perDiemOwnerFromOtherTimesheet(row.employeeID);
      if (!!perdiemOtherTimesheetOwner || !this.contractor?.employeesReceivePerDiem) return;

      let allPeople = this.$store.state.users.fullList as PersonWithDetails[];
      let person = allPeople.find(x => x.id == row.employeeID);
      if (!person || !!person.disableAutomaticPerDiem) return;

      let existingPerDiemRow = this.existingUncorrectedPerDiemRowForEmployee(row.employeeID);

      let requiredHours = this.contractor.perDiemHoursRequirement ?? 0;
      if (totalRegularTime < requiredHours) {
        // If there isn't enough total time, the only thing we care about is turning off any existing per diem rows
        if (!!existingPerDiemRow) existingPerDiemRow.hasPerDiem = false;
        return;
      }

      // We know there's enough total time to have per diem
      // If the per diem row already exists, we don't need to do anything
      if (!!existingPerDiemRow) return;

      // We want a row with perdiem, but one doesn't exist.
      // Therefore we need to deal with a DirectGeneral row, and turn its per diem on

      if (this.perDiemSubTypeIsWorkOrderRelated || row.rowType == TimesheetRowType.DirectGeneral) {
        // If the row being updated is already directGeneral, update its own per diem
        row.hasPerDiem = true;
      } else {
        let existingIndirectRow = this.currentTimesheet?.timesheetRows.find(
          x => x.employeeID == row.employeeID && !x.workOrderID
        );

        if (!!existingIndirectRow) {
          // A non-perdiem generaldirect row already exists.  Turn on its per diem
          existingIndirectRow.hasPerDiem = true;
        } else {
          // Create a new generaldirect row, with per diem on
          let newRow = this.addTimesheetRow(
            row.employeeID,
            row.employeeName,
            row.employeeCode,
            row.employeeBadge,
            row.classificationID,
            TimesheetRowType.DirectGeneral,
            false
          );
          if (!!newRow) newRow.hasPerDiem = true;
        }
      }
    },
    canEditPerDiem(item: TimesheetRow) {
      // Per Diem's can ONLY be associated to non-work-order timesheet rows
      if (!!item.workOrderID && !this.perDiemSubType?.isWorkOrderRelated) return false;

      let existingPerDiemRowForEmployee = this.existingUncorrectedPerDiemRowForEmployee(
        item.employeeID
      );
      if (!!existingPerDiemRowForEmployee) {
        // If there is an existing per diem row on this timesheet, check if it's the current row
        // If it is it's editable, if it's not it's not editable
        let existingPerDiemRowIsCurrentItem =
          item.classificationID == existingPerDiemRowForEmployee.classificationID &&
          item.isCorrectionRow == existingPerDiemRowForEmployee.isCorrectionRow &&
          item.workOrderID == existingPerDiemRowForEmployee.workOrderID &&
          item.rowType == existingPerDiemRowForEmployee.rowType &&
          (item.areaID ?? "") == (existingPerDiemRowForEmployee.areaID ?? "") &&
          (item.subAreaID ?? "") == (existingPerDiemRowForEmployee.subAreaID ?? "");
        return existingPerDiemRowIsCurrentItem;
      } else {
        // If there isn't an existing per diem row, it may exist on another timesheet.  Check the time summary
        let employeeSummary = this.timeSummaries?.find(x => x.employeeID == item.employeeID);
        let perdiemSummary = employeeSummary?.workSubTypeTimeSummaries.find(
          x => x.workSubTypeID == this.perDiemSubType?.id
        );
        return !perdiemSummary?.totalUnits;
      }
    },
    totalTimeForEmployeeForWorkSubType(
      employeeID: string,
      workSubTypeID: string
    ): TimesheetRowTimeValues {
      // console.log(`totalTimeForEmployeeForWorkSubType`);
      let times = new TimesheetRowTimeValues();
      if (!this.currentTimesheet) return times;

      let employeeRows = this.currentTimesheet.timesheetRows.filter(
        x => x.employeeID == employeeID
      );
      for (let row of employeeRows) {
        let rowTimes = row.times[workSubTypeID];
        times = times.adding(rowTimes);
      }
      return times;
    },
    totalTimeForEmployeeForWorkSubTypeOnDay(
      employeeID: string,
      workSubTypeID: string
    ): TimesheetRowTimeValues {
      let fullSummary = this.timeSummaries?.find(x => x.employeeID == employeeID);
      let summary = fullSummary?.workSubTypeTimeSummaries.find(
        x => x.workSubTypeID == workSubTypeID
      );
      let otherTimes = new TimesheetRowTimeValues(
        summary?.totalRegularTime,
        summary?.totalOverTime,
        summary?.totalDoubleTime
      );
      let totalTimes = otherTimes.adding(
        this.totalTimeForEmployeeForWorkSubType(employeeID, workSubTypeID)
      );
      return totalTimes;
    },
    employeeHasOtherTime(employeeID: string): boolean {
      return !!this.timeSummaries?.find(x => x.employeeID == employeeID);
    },
    totalTimeForEmployeeOnDay(item: TimesheetRow): TimesheetRowTimeValues {
      let employeeRows = this.currentTimesheet?.timesheetRows.filter(
        x => x.employeeID == item.employeeID
      ) ?? [item];
      let times = new TimesheetRowTimeValues();
      for (let row of employeeRows) {
        times = times.adding(row.totalTime);
      }
      if (!this.timeSummaries?.length) return times;

      let summary = this.timeSummaries.find(x => x.employeeID == item.employeeID);
      if (!summary) return times;

      let otherTimes = new TimesheetRowTimeValues(
        summary.totalRegularTime,
        summary.totalOverTime,
        summary.totalDoubleTime
      );
      return otherTimes.adding(times);
    },
    validTimeForDayRule(row: TimesheetRow): boolean | TranslateResult | string {
      // console.log(`validTimeForDayRule`);
      let totalHours = this.totalTimeForEmployeeOnDay(row).totalTime;

      let errorMessage = "";
      if (totalHours < 0) {
        errorMessage = `${this.$t("timesheets.existing.too-few-hours-error-message")}`;
      } else if (totalHours > this.$store.state.curEnvironment.defaultMaxDailyEmployeeHours) {
        errorMessage = `${this.$t("timesheets.existing.too-many-hours-error-message")}`;
      } else {
        let negativeWstID = ParseWorkSubTypeIDsFromRow(row).find(x => {
          let totalHoursForSubType = this.totalTimeForEmployeeForWorkSubTypeOnDay(row.employeeID, x)
            .totalTime;
          return totalHoursForSubType < 0;
        });
        if (!!negativeWstID) {
          let workSubType = this.allWorkSubTypes.find(x => x.id == negativeWstID);
          errorMessage = `${this.$t(
            "timesheets.existing.too-few-work-sub-type-hours-error-message",
            [workSubType?.name]
          )}`;
        }
      }

      if (!!errorMessage) {
        if (!row.errorMessage.includes(errorMessage)) {
          row.errorMessage = errorMessage;
        }
        return errorMessage;
      } else {
        row.errorMessage = "";
        return true;
      }
    },
    timesheetRowRules(item: TimesheetRow): Array<Function | boolean | TranslateResult | string> {
      // console.log(`timesheetRowRules`);
      return [this.validTimeForDayRule(item)];
    },
    updateAllValues(
      workOrderNumber: string,
      workSubTypeID: string | null | undefined,
      value: PossibleNumber
    ) {
      // console.log(`updateAllValues`);
      if (!workSubTypeID?.length) return;
      if (!this.currentTimesheet) return;

      let rows = this.currentTimesheetWorkOrderRows.filter(
        x => (x.workOrderNumber ?? "") == (workOrderNumber ?? "")
      );

      let times = new TimesheetRowTimeValues(null, null, null, value);
      for (let row of rows) {
        row.times[workSubTypeID] = times;
        // row.totalTime = this.calculateTotalForItem(row);
        this.confirmEmployeePerDiemForRow(row);
      }
      this.checkWorkSubTypeAdditionalDetails(times, workSubTypeID, workOrderNumber);
    },
    ...mapActions({
      loadWorkTypes: "LOAD_WORK_TYPES",
      loadWorkSubTypes: "LOAD_WORK_SUB_TYPES",
      loadCostCodes: "LOAD_PROJECT_COST_CODES",
      loadEmployees: "LOAD_USERS",
      loadCrews: "LOAD_CREWS",
      loadClassifications: "LOAD_CLASSIFICATIONS"
    }),
    sumRowTimeValues(
      items: TimesheetRow[],
      propName: string | null | undefined
    ): TimesheetRowTimeValues | null | undefined {
      if (!propName?.length) return undefined;

      let result: TimesheetRowTimeValues = items.reduce(
        (a: TimesheetRowTimeValues, b: TimesheetRow) => a.adding(b.times[propName]),
        new TimesheetRowTimeValues()
      );
      return result;
    },
    canEditTimesheet(timesheet: UpdatableTimesheetWithTimesheetRows | null) {
      if (!timesheet) return false;

      let locked = timesheet.isLocked ?? false;
      let currentDay =
        new Date(timesheet.day!.toDateString()).getTime() ==
        new Date(new Date().toDateString()).getTime();
      return !locked && (currentDay || this.canEditOtherDaysTimesheets);
    },

    getTimesForItem(
      item: TimesheetRow,
      workSubTypeID: string | null | undefined
    ): TimesheetRowTimeValues | null | undefined {
      if (!workSubTypeID?.length) return undefined;
      return item.times[workSubTypeID];
    },
    getTimeValueForItem(
      item: TimesheetRow,
      workSubTypeID: string | null | undefined
    ): string | null | undefined {
      return this.getTimesForItem(item, workSubTypeID)?.summaryString;
    },
    calculateTotalForItem(item: TimesheetRow): TimesheetRowTimeValues {
      return CalculateRowTotalTime(item);
    },
    calculateTotalForItems(items: TimesheetRow[]): TimesheetRowTimeValues {
      let total: TimesheetRowTimeValues = items.reduce(
        (a: TimesheetRowTimeValues, b: any) => a.adding(this.calculateTotalForItem(b)),
        new TimesheetRowTimeValues()
      );
      return total;
    },

    addTimesheetRowRules(): any {
      return {
        selectedCrewID: !this.selectedEmployeeID ? [this.rules.required] : undefined,
        selectedEmployeeID: !this.selectedCrewID ? [this.rules.required] : undefined
      };
    },

    async open(workOrder: WorkOrderWithAllDetails) {
      this.workOrder = workOrder;
      if (!!this.workOrder?.completedDate) {
        if (this.workOrder.completedDate.getTime() < this.selectedDay.getTime()) {
          this.selectedDay = new Date(this.workOrder.completedDate.toDateString());
        }
      }
      this.loadData();
      this.optOutOfErrorHandling();
      return await this.showDialog!();
    },

    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },
    async addNewCrew() {
      this.optOutOfErrorHandling();
      let newCrewID = await createNewCrew(
        this.contractor.id!,
        this.curUserID,
        undefined,
        this.$refs.content as Vue
      );
      if (newCrewID) {
        this.selectedCrewID = newCrewID as string;
        this.addTimesheetRows();
      }
    },
    async editCrew() {
      this.optOutOfErrorHandling();
      if (!this.selectedCrew) return;
      await updateExistingCrew(this.selectedCrew, this.$refs.content as Vue);
    },
    async deleteCrew() {
      this.optOutOfErrorHandling();
      if (!this.selectedCrew?.id) return;
      this.processing = true;
      try {
        await this.$store.dispatch("DELETE_CREW", this.selectedCrew);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    addTimesheetRow(
      employeeID: string | undefined,
      employeeName: string | null | undefined,
      employeeCode: string | null | undefined,
      employeeBadge: string | null | undefined,
      employeeClassificationID: string | null | undefined,
      rowType: TimesheetRowType,
      includeWorkOrderDetails: boolean
    ): TimesheetRow | undefined {
      this.optOutOfErrorHandling();
      if (!this.currentTimesheet) return undefined;

      if (this.currentTimesheet.isLocked) return undefined;
      // console.log(
      //   `addTimesheetRow employeeName: ${employeeName} next number will be: ${this
      //     .currentTimesheet?.nextRowNumber ?? 1}`
      // );
      let classificationDisplayName = this.getClassificationDisplayNameForID(
        employeeClassificationID
      );

      let newRow = new TimesheetRow({
        rowType: rowType,
        isCorrectionRow: false,
        rowNumber: this.currentTimesheet?.nextRowNumber ?? 1,
        workOrderClientWorkOrderNumber: !includeWorkOrderDetails
          ? undefined
          : this.workOrder.clientWorkOrderReferenceNumber,
        workOrderChangeOrderNumber: !includeWorkOrderDetails
          ? undefined
          : this.workOrder.changeOrderReferenceNumber,
        workOrderReworkNumber: !includeWorkOrderDetails
          ? undefined
          : this.workOrder.reworkReferenceNumber,
        employeeID: employeeID,
        employeeName: employeeName,
        employeeCode: employeeCode,
        employeeBadge: employeeBadge,
        classificationID: employeeClassificationID,
        classificationDisplayName: classificationDisplayName,
        workOrderID: !includeWorkOrderDetails ? undefined : this.workOrder.id,
        workOrderNumber: !includeWorkOrderDetails
          ? undefined
          : `${this.workOrder.internalNumber ?? ""}`,
        scaffoldID: !includeWorkOrderDetails ? undefined : this.workOrder.scaffoldID ?? "",
        // Area and Sub Area are relevant even for indirect/general rows
        areaID: this.workOrder.areaID ?? "",
        subAreaID: this.workOrder.subAreaID ?? "",
        workOrderCostCodeID: !includeWorkOrderDetails ? undefined : this.workOrder.costCodeID ?? "",
        errorMessage: "",
        times: {}
      });
      // console.log(
      //   `New row added with number: #${newRow.rowNumber}, type: ${newRow.rowType}, workOrderID: ${newRow.workOrderID}`
      // );

      // Here we find all available WSTs for this row type so we can prefill with null values
      // The purpose of this is to populate the row object with WST IDs as keys so they can be turned into empty Entries if necessary

      for (let wst of this.usableWorkSubTypes) {
        newRow.times[wst.id!] = new TimesheetRowTimeValues();
      }

      this.currentTimesheet!.timesheetRows.push(newRow);

      return newRow;
    },
    async addTimesheetRows() {
      this.optOutOfErrorHandling();
      if (!this.currentTimesheet) return;

      if (this.currentTimesheet.isLocked) return;

      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!(this.$refs.addform as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      try {
        let selectedEmployees = this.selectedEmployees;

        for (let e of selectedEmployees) {
          // Because we're adding rows using either crews or people, we only care if a person already has ANY row
          let existingRow = this.currentTimesheet.timesheetRows?.find(
            x => x.employeeID == e.id && x.workOrderID == this.workOrder.id
          );
          if (!!existingRow) return;

          this.addTimesheetRow(
            e.id,
            e.name,
            e.employeeCode,
            e.employeeBadge,
            e.classificationID,
            TimesheetRowType.DirectWorkOrderRelated,
            true
          );
        }

        this.currentTimesheet.timesheetRows = SortTimesheetRows(
          this.currentTimesheet.timesheetRows
        );

        this.selectedCrewID = null;
        this.selectedEmployeeID = null;

        let employeeIDs = [...new Set(this.currentTimesheet.timesheetRows?.map(x => x.employeeID))];
        if (!!employeeIDs?.length) {
          this.timeSummaries = await timesheetService.getEmployeeTimeSummariesForEmployeesFromOtherTimesheetsOnDay(
            employeeIDs,
            this.currentTimesheet.day ?? this.selectedDay,
            this.currentTimesheet.id!
          );
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    removeTimesheetRow(row: TimesheetRow) {
      if (!this.currentTimesheet) return;

      const index = this.currentTimesheet.timesheetRows.indexOf(row);
      if (index < 0) {
        return;
      }
      this.currentTimesheet.timesheetRows.splice(index, 1);
      row.times = {};
      // row.totalTime = null;
      for (let workSubType of this.usableWorkSubTypes) {
        this.checkWorkSubTypeAdditionalDetails(
          new TimesheetRowTimeValues(),
          workSubType.id!,
          row.workOrderNumber
        );
      }

      this.confirmEmployeePerDiemForRow(row);
    },

    async saveModifiedTimesheets(): Promise<boolean> {
      let allPeople = this.$store.state.users.fullList as PersonWithDetails[];

      let timesheetsToSave = this.timesheets.filter(x =>
        this.canSaveTimesheet(x, this.allWorkSubTypes, allPeople)
      );
      if (timesheetsToSave.length == 0) {
        this.processing = false;
        var snackbarPayload = {
          text: this.$t("timesheets.entries.no-changes-to-save-message"),
          type: "info",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        return false;
      }

      // Check all timesheets for any error entries
      for (let timesheet of timesheetsToSave) {
        var errorEntries = timesheet
          .getEntries(this.perDiemSubType, this.equipmentSubType, this.allWorkSubTypes, allPeople)
          .filter(x => !x.regularTime && !x.overTime && !x.doubleTime && !x.units);

        if (errorEntries.length) {
          this.inlineMessage.message = this.$t(
            "timesheets.entries.entries-missing-data-for-day-error-message",
            [stripTimeFromLocalizedDateTime(timesheet.day)]
          );
          return false;
        }
      }

      for (let timesheet of timesheetsToSave) {
        if (timesheet.isNew) {
          let newID = await timesheetService.addItem(timesheet);
          timesheet.id = newID;
          timesheet
            .getNewEntries(
              this.perDiemSubType,
              this.equipmentSubType,
              this.allWorkSubTypes,
              allPeople
            )
            .forEach(x => (x.timesheetID = newID));
          timesheet.explanations?.forEach(x => (x.timesheetID = newID));
        }
        if (
          timesheet.checkHasNewEntries(
            this.perDiemSubType,
            this.equipmentSubType,
            this.allWorkSubTypes,
            allPeople
          )
        ) {
          await timesheetService.addEntriesToTimesheetWithID(
            timesheet.id!,
            timesheet.getSanitizedNewEntries(
              this.perDiemSubType,
              this.equipmentSubType,
              this.allWorkSubTypes,
              allPeople
            )
          );
        }
        if (
          timesheet.checkHasModifiedEntries(
            this.perDiemSubType,
            this.equipmentSubType,
            this.allWorkSubTypes,
            allPeople
          )
        ) {
          await timesheetService.updateEntriesForTimesheetWithID(
            timesheet.id!,
            timesheet.getModifiedExistingEntryData(
              this.perDiemSubType,
              this.equipmentSubType,
              this.allWorkSubTypes,
              allPeople
            )
          );
        }
        if (
          timesheet.checkHasRemovedEntries(
            this.perDiemSubType,
            this.equipmentSubType,
            this.allWorkSubTypes,
            allPeople
          )
        ) {
          await timesheetService.removeEntriesFromTimesheetWithID(
            timesheet.id!,
            timesheet.getRemovedEntryIDs(
              this.perDiemSubType,
              this.equipmentSubType,
              this.allWorkSubTypes,
              allPeople
            )
          );
        }
        if (timesheet.explanationsModified) {
          await timesheetService.updateExplanationsForTimesheetWithID(
            timesheet.id!,
            timesheet.explanations ?? []
          );
        }
      }

      return true;
    },

    canSaveTimesheet(
      timesheet: UpdatableTimesheetWithTimesheetRows | null | undefined,
      allWorkSubTypes: WorkSubType[] | undefined = undefined,
      allPeople: PersonWithDetails[] | undefined = undefined
    ): boolean {
      if (!allWorkSubTypes)
        allWorkSubTypes = this.$store.state.workSubTypes.fullList as WorkSubType[];
      if (!allPeople) allPeople = this.$store.state.users.fullList as PersonWithDetails[];
      return (
        !!timesheet &&
        this.canEditTimesheet(timesheet) &&
        timesheet.checkIsDirty(
          this.perDiemSubType,
          this.equipmentSubType,
          allWorkSubTypes,
          allPeople
        )
      );
    },
    validateAdditionalDetails(): boolean {
      return (this.$refs.additionaldetailsform as HTMLFormElement)?.validate() ?? true;
    },
    async saveDialog() {
      // First reset the inline message if there are any.
      this.inlineMessage.message = "";
      if (!this.validateAdditionalDetails()) {
        return;
      }
      this.processing = true;
      try {
        if (!(await this.saveModifiedTimesheets())) {
          return;
        }
        this.closeDialog!(true);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    // DOES NOT manage processing or error message logic
    async loadAreas(): Promise<void> {
      let areas = await projectLocationService.getVisibleAreas();
      this.allAreas = areas;
    },

    // DOES NOT manage processing or error message logic
    async loadSubAreas(): Promise<void> {
      let subAreas = await projectLocationService.getVisibleSubAreas();
      this.allSubAreas = subAreas;
    },
    // Calls `loadSelectedDayTimesheetDetails` but wrapped in processing and erro handling
    async reloadSelectedDayTimesheetDetails() {
      this.optOutOfErrorHandling();
      this.processing = true;
      try {
        await this.loadSelectedDayTimesheetDetails();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async loadTimesheetSummaries() {
      // console.log(`loadTimesheetSummaries`);
      if (!this.currentTimesheet) {
        // console.log(`timeSummaries NOT loaded - no timesheet selected`);
        this.timeSummaries = [];
        return;
      }

      this.timeSummaries = await timesheetService.getEmployeeTimeSummariesForTimesheetWithID(
        this.currentTimesheet.id!,
        true
      );
      // console.log(`timeSummaries loaded: ${this.timeSummaries}`);
    },
    async loadTimesheets() {
      // console.log(`loadTimesheets`);
      this.timesheetsWithoutEntries = await timesheetService.getTimesheetsForLabourEntryDialog(
        this.workOrder.foremanID!,
        this.minDate ?? this.maxDate,
        this.maxDate
      );
      this.timesheets = [];
      this.populateWorkOrderDateList();
    },
    dateIsToday(date: Date): boolean {
      return date.getTime() == today.getTime();
    },
    populateWorkOrderDateList() {
      let minDate = new Date((this.minDate ?? this.maxDate).valueOf());
      let maxDate = new Date(this.maxDate.valueOf());
      // console.log(`workOrderDateList min: ${minDate}, max: ${maxDate}`);
      let date = minDate;
      let dates = [] as {
        text: string;
        value: Date;
        disabled?: boolean;
        timesheetIsSubmitted?: boolean;
        timesheetIsApproved?: boolean;
        timesheetIsCancelled?: boolean;
        hasTimesheet?: boolean;
      }[];
      while (date.getTime() <= maxDate.getTime()) {
        // console.log(`    ${date} less than max date`);
        let timesheet = this.timesheetsWithoutEntries.find(t => t.day!.getTime() == date.getTime());
        // console.log(`    timesheet: ${JSON.stringify(timesheet)}`);
        // If there isn't a timesheet for this day, or the timesheet exists but is editable, add the date to the list

        var dateEditable =
          !timesheet ||
          timesheet.timesheetStatusID == TimesheetStatus.New ||
          timesheet.timesheetStatusID == TimesheetStatus.Declined;
        let timesheetIsSubmitted = timesheet?.timesheetStatusID == TimesheetStatus.Submitted;
        let timesheetIsApproved = timesheet?.timesheetStatusID == TimesheetStatus.Approved;
        let timesheetIsCancelled = timesheet?.timesheetStatusID == TimesheetStatus.Cancelled;
        // if (
        //   !!timesheet &&
        //   !(
        //     timesheet.timesheetStatusID == TimesheetStatus.New ||
        //     timesheet.timesheetStatusID == TimesheetStatus.Declined
        //   )
        // ) {
        //   // console.log(`    Existing timesheet not editable.  Ignore date.`);
        //   date.setDate(date.getDate() + 1);
        //   continue;
        // }

        // console.log(`    Adding date to list.`);
        dates.push({
          value: new Date(date.valueOf()),
          text: stripTimeFromLocalizedDateTime(date),
          disabled: !dateEditable,
          timesheetIsSubmitted,
          timesheetIsApproved,
          timesheetIsCancelled,
          hasTimesheet: !!timesheet
        });

        date.setDate(date.getDate() + 1);
      }
      // console.log(`  dates: ${dates}`);
      // Reverse the dates so the latest date is on top of the list
      this.workOrderDateList = dates.sort((a, b) => {
        return b.value.getTime() - a.value.getTime();
      });
    },
    async loadSelectedDayTimesheetDetails() {
      // console.log(`loadSelectedDayTimesheetDetails selectedDay: ${this.selectedDay}`);
      let timesheet = this.timesheetsWithoutEntries.find(x => x.day == this.selectedDay);
      // console.log(`\ttimesheet for day: ${JSON.stringify(timesheet)}`);
      if (!timesheet) {
        timesheet = await timesheetService.getByOwnerIDAndDate(
          this.workOrder.foremanID!,
          this.selectedDay,
          false
        );
        if (!!timesheet) {
          this.timesheetsWithoutEntries.push(timesheet);
          // console.log(`\tadded existing timesheet to list: ${JSON.stringify(timesheet)}`);
        }
      }
      if (!timesheet) {
        let ownerName = this.workOrder.foremanName;
        // if (!ownerName?.length) {
        //   let allPeople = this.$store.state.users.fullList as PersonWithDetails[];
        //   ownerName = GetPersonName(allPeople.find(x => x.id == this.workOrder.foremanID));
        // }
        timesheet = {
          ownerID: this.workOrder.foremanID!,
          ownerName: ownerName,
          contractorID: this.workOrder.assignedContractorID,
          contractorName: this.contractor.name,
          day: this.selectedDay,
          timesheetStatusID: TimesheetStatus.New
        } as TimesheetWithChildren;
        this.timesheetsWithoutEntries.push(timesheet);
        // console.log(`\tadded new timesheet to list: ${JSON.stringify(timesheet)}`);
      }
      let entries = [] as TimesheetEntryWithDetails[];
      if (!!timesheet.id) {
        entries = (await timesheetService.getEntriesForTimesheetID(timesheet.id)).filter(
          // We want all entries here so we can do per diem calculations, which aren't work order related
          // The filtering by work order ID happens in `currentTimesheetWorkOrderRows`
          x => !!x.workSubTypeID
        );
      }

      let timesheetWithEntries = new UpdatableTimesheetWithTimesheetRows(
        timesheet,
        entries,
        this.allWorkTypes,
        this.allWorkSubTypes
      );
      timesheetWithEntries.timesheetRows = SortTimesheetRows(timesheetWithEntries.timesheetRows);
      this.currentTimesheet = timesheetWithEntries;
      this.timesheets.push(timesheetWithEntries);
      await this.loadTimesheetSummaries();
    },
    async loadData() {
      this.optOutOfErrorHandling();
      this.processing = true;
      try {
        await Promise.all([
          this.loadAreas(),
          this.loadSubAreas(),
          this.loadWorkTypes(),
          this.loadWorkSubTypes(),
          this.loadCostCodes(),
          this.loadEmployees(),
          this.loadCrews(),
          this.loadClassifications()
        ]);

        this.allWorkTypes = this.$store.state.workTypes.fullList as WorkType[];
        this.allWorkSubTypes = this.$store.state.workSubTypes.fullList as WorkSubType[];
        this.perDiemType = this.allWorkTypes.find(x => !!x.isPerDiem);
        this.perDiemSubType = this.allWorkSubTypes.find(x => x.workTypeID == this.perDiemType?.id);
        this.equipmentType = this.allWorkTypes.find(x => !!x.isEquipment);
        this.equipmentSubType = this.allWorkSubTypes.find(
          x => x.workTypeID == this.equipmentType?.id
        );
        await this.loadTimesheets();
        await this.loadTimesheetSummaries();
        this.contractor = await contractorService.getByID(this.workOrder.assignedContractorID!);

        this.usableWorkTypes = SortWorkTypes(
          this.allWorkTypes.filter(
            x =>
              !x.isPerDiem &&
              !x.isEquipment &&
              x.isDirect &&
              this.contractor.workTypeIDs?.includes(x.id!)
          )
        );
        let usableWorkSubTypes = [] as WorkSubType[];
        this.usableWorkTypes.forEach(workType => {
          let selectableSubTypes = SortWorkSubTypes(
            this.allWorkSubTypes.filter(
              x =>
                x.workTypeID == workType.id &&
                this.workSubTypeIsDirect(x.id!) &&
                this.workSubTypeIsDirectAndWorkOrderRelated(x.id!)
            )
          );
          if (!selectableSubTypes?.length) return;

          usableWorkSubTypes = usableWorkSubTypes.concat(selectableSubTypes);
        });
        this.usableWorkSubTypes = usableWorkSubTypes;

        await this.loadSelectedDayTimesheetDetails();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    getClassificationDisplayNameForID(
      classificationID: string | null | undefined
    ): string | undefined {
      let classification = (this.$store.state.classifications.fullList as Classification[]).find(
        x => x.id == classificationID
      );
      return classification?.alias ?? classification?.name;
    },
    classificationInUseForEmployee(
      classificationID: string | null | undefined,
      employeeID: string | null | undefined,
      rowType: TimesheetRowType
    ): boolean {
      if (!this.currentTimesheetWorkOrderRows.length) return false;

      let index = this.currentTimesheetWorkOrderRows.findIndex(x => {
        let matches =
          x.employeeID == employeeID &&
          x.classificationID == classificationID &&
          x.rowType == rowType;
        return matches;
      });
      return index != -1;
    },
    classificationsForRow(
      row: TimesheetRow
    ): GroupableSelectListOption<ClassificationWithDisplayName>[] {
      let allPeople = this.$store.state.users.fullList as PersonWithDetails[];
      let person = allPeople.find(x => x.id == row.employeeID);
      if (!person?.classificationIDs?.length) return [];

      let allClassifications = this.$store.state.classifications.fullList as Classification[];
      let selectableClassifications = allClassifications.filter(
        x => x.id == person?.classificationID || person?.classificationIDs?.includes(x.id!)
      );
      let ungrouped = selectableClassifications
        .map(
          x =>
            ({
              ...x,
              displayName: x.alias ?? x.name,
              disabled: this.classificationInUseForEmployee(x.id, row.employeeID, row.rowType)
            } as SelectListOption<ClassificationWithDisplayName>)
        )
        .sort((a, b) => {
          let aName = a.displayName!.toLocaleLowerCase();
          let bName = b.displayName!.toLocaleLowerCase();
          if (aName < bName) return -1;
          else if (aName > bName) return 1;
          else return 0;
        });

      let groupedClassifications: GroupableSelectListOption<ClassificationWithDisplayName>[] = [];

      let usedClassifications = ungrouped.filter(x => !!x.disabled);
      let unusedClassifications = ungrouped.filter(x => !x.disabled);

      if (!!unusedClassifications.length) {
        groupedClassifications.push({
          header: this.$t("timesheets.existing.classification-select-new-row-group-label")
        });
        groupedClassifications.push({ divider: true });
        groupedClassifications = groupedClassifications.concat(unusedClassifications);
      }
      if (!!usedClassifications.length) {
        groupedClassifications.push({
          header: this.$t("timesheets.existing.classification-select-in-use-group-label")
        });
        groupedClassifications.push({ divider: true });
        groupedClassifications = groupedClassifications.concat(usedClassifications);
      }

      return groupedClassifications;
    },
    classificationSelected(row: TimesheetRow, newValue: Classification) {
      if (newValue.id == row.classificationID) return;

      let allPeople = this.$store.state.users.fullList as PersonWithDetails[];
      let person = allPeople.find(x => x.id == row.employeeID);

      if (
        !!person?.classificationID?.length &&
        !this.classificationInUseForEmployee(newValue.id, person?.id, row.rowType)
      ) {
        this.addTimesheetRow(
          row.employeeID,
          row.employeeName,
          row.employeeCode,
          row.employeeBadge,
          newValue.id,
          TimesheetRowType.DirectWorkOrderRelated,
          true
        );
      }
    },

    workSubTypeIsDirect(workSubTypeID: string): boolean {
      let workSubType = this.allWorkSubTypes.find(x => x.id == workSubTypeID);
      if (!workSubType) return false;

      let workType = this.allWorkTypes.find(x => x.id == workSubType?.workTypeID);
      if (!workType) return false;

      return workType.isDirect ?? false;
    },
    workSubTypeIsDirectAndWorkOrderRelated(workSubTypeID: string): boolean {
      let workSubType = this.allWorkSubTypes.find(x => x.id == workSubTypeID);
      if (!workSubType) return false;

      let isDirect = this.workSubTypeIsDirect(workSubTypeID);
      let isWorkOrderRelated = workSubType.isWorkOrderRelated ?? false;
      return isDirect && isWorkOrderRelated;
    },

    // *** INLINE NAVIGATION ***
    getDataTableForItem() {
      return this.$refs.datatable as VDataTable;
    },
    getFieldRef(fieldName: string | null | undefined, item: TimesheetRow) {
      let field = fieldName?.replace("-", "").replace("-", "") ?? "";
      let id = item.employeeID!.replace("-", "").replace("-", "");
      return `${field}_${id}`;
    },
    focusFieldForVisibleItemAtIndex(
      fieldName: string | null | undefined,
      index: number,
      visibleItems: TimesheetRow[]
    ) {
      if (!visibleItems.length) return;

      if (index < 0) index = 0;
      if (index >= visibleItems.length) index = visibleItems.length - 1;
      let item = visibleItems[index];

      let itemFieldRef = this.getFieldRef(fieldName, item);
      let itemField = this.$refs[itemFieldRef] as any;
      if (!!itemField["length"]) itemField = itemField[0];
      this.$nextTick(() => {
        // console.log(`itemField: ${itemField}`);
        itemField?.focus();
      });
    },
    async selectPreviousField(fieldName: string | null | undefined, item: TimesheetRow) {
      let datatable = this.getDataTableForItem();
      let visibleItems = datatable.internalCurrentItems;
      let currentItemIndex = visibleItems.indexOf(item);
      if (currentItemIndex <= 0) {
        if (this.tablepage <= 1) return;
        this.tablepage -= 1;
        let self = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          self.focusFieldForVisibleItemAtIndex(
            fieldName,
            datatable.computedItemsPerPage,
            visibleItems
          );
        });
        return;
      }

      let previousIndex = currentItemIndex - 1;
      this.focusFieldForVisibleItemAtIndex(fieldName, previousIndex, visibleItems);
    },
    async selectNextField(fieldName: string | null | undefined, item: TimesheetRow) {
      if (!this.currentTimesheet) return;

      let datatable = this.getDataTableForItem();
      let visibleItems = datatable.internalCurrentItems;
      let currentItemIndex = visibleItems.indexOf(item);
      if (currentItemIndex >= visibleItems.length - 1) {
        let maxPage =
          datatable.computedItemsPerPage <= 0
            ? 1
            : Math.ceil(this.currentTimesheetWorkOrderRows.length / datatable.computedItemsPerPage);

        if (this.tablepage >= maxPage) return;
        this.tablepage += 1;

        let self = this;
        // Wait a tick to allow the table's page change to update its current items
        this.$nextTick(() => {
          self.focusFieldForVisibleItemAtIndex(fieldName, 0, visibleItems);
        });
        return;
      }

      let nextIndex = currentItemIndex + 1;
      this.focusFieldForVisibleItemAtIndex(fieldName, nextIndex, visibleItems);
    },
    async enterPressed(e: KeyboardEvent, fieldName: string | null | undefined, item: TimesheetRow) {
      if (e.shiftKey) await this.selectPreviousField(fieldName, item);
      else await this.selectNextField(fieldName, item);
    }
  }
});

export default LabourEntryDialog;

export async function openLabourEntryDialog(
  workOrder: WorkOrderWithAllDetails
): Promise<string | boolean> {
  let dialog = createDialog(LabourEntryDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open(workOrder);
}

