import FDVue from "@fd/lib/vue";
import serviceErrorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";

export default FDVue.extend({
  name: "fd-work-package-selection-dialog",
  inheritAttrs: false,
  props: {
    cy: { type: String, default: "fd-work-package-selection-dialog" },
    openLabel: { default: "IWP", type: String },
    closeLabel: { default: "Close", type: String },
    fieldLabel: { default: "Search IWP", type: String },
    placeholder: { default: "Search for an IWP here...", type: String },

    loading: { default: false, type: Boolean },
    disabled: { default: false, type: Boolean },

    refreshItems: Function,
    items: Array,
    value: {
      default: () => [],
      type: [Object, Array]
    }
  },

  mixins: [serviceErrorHandling],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  components: {
    "fd-async-search-box": () => import("@fd/lib/vue/components/AsyncSearchBox.vue")
  },

  data: () => ({
    sheet: false,
    search: "",
    hasLoaded: false,
    timer: null as NodeJS.Timeout | null,
    // Table Footer page size options
    itemsPerPage: 8,
    itemsPerPageOptions: [8]
  }),

  computed: {
    allItems(): any[] {
      var allItems = [...this.items].concat([...this.value]);
      var distinctItems = [...new Set(allItems)];
      return distinctItems;
    },
    showTable(): boolean {
      if (this.allItems.length > 0) return true;
      return !!this.search?.length;
    }
  },

  watch: {
    search(this: any, newValue: string) {
      this.hasLoaded = false;
      // If the user kept typing/changed the search before the service call, cancel the previous call in preparation of the new one
      if (this.timer) clearTimeout(this.timer);

      var obj = this;
      // Delay the service call to allow the user to keep typing if they choose before making a server call
      this.timer = setTimeout(async function() {
        // obj.loading = true;
        try {
          if (!!obj.refreshItems) await obj.refreshItems(newValue);
          else obj.$emit("refreshItems", newValue);
        } finally {
          obj.timer = null;
          // obj.loading = false;
          obj.hasLoaded = true;
        }
      }, 1500);
    }
  },

  methods: {
    close() {
      this.sheet = !this.sheet;
      this.$emit("closed");
    },
    isItemSelected(item: any): boolean {
      return !!item.id && !!this.value && this.value.map((x: any) => x.id ?? "").includes(item.id!);
    },
    flipItemSelected(item: any) {
      if (this.isItemSelected(item)) {
        this.removeItem(item);
      } else {
        this.addItem(item);
      }
    },
    addItem(item: any) {
      var selectedItems = [...this.value];
      if (!selectedItems.length || !selectedItems.includes(item)) {
        selectedItems.push(item);
        this.$emit("input", selectedItems);
      }
    },
    removeItem(item: any) {
      var selectedItems = [...this.value];
      if (!!selectedItems.length && selectedItems.includes(item)) {
        selectedItems.splice(selectedItems.indexOf(item), 1);
        this.$emit("input", selectedItems);
      }
    }
  }
});

