import { TranslateResult } from "vue-i18n";
import {
  Timesheet,
  TimesheetEntry,
  TimesheetEntryWithDetails,
  TimesheetStatus,
  TimesheetStatusLogWithDetails,
  TimesheetWithDetails,
  PersonWithDetails,
  SummarizedTimesheetPermissions,
  WorkSubType,
  WorkType,
  TimesheetExplanationWithWorkOrderDetails,
  TimesheetExplanation,
  Contractor,
  TimesheetType
} from "../services";

function SanitizeNumericValue(val: number | string | undefined | null): number | null {
  return !!val ? +val : null;
}
function CompareWorkTypes(a: WorkType, b: WorkType): number {
  let aOrder = a.order ?? 0;
  let bOrder = b.order ?? 0;
  if (aOrder != bOrder) return aOrder - bOrder;

  let aName = a.name?.toLocaleLowerCase() ?? "";
  let bName = b.name?.toLocaleLowerCase() ?? "";
  if (aName < bName) return -1;
  else if (aName > bName) return 1;
  return 0;
}
export function SortWorkTypes(items: WorkType[] | null | undefined): WorkType[] {
  if (!items) return [];
  return items.sort(CompareWorkTypes);
}
function CompareWorkSubTypes(a: WorkSubType, b: WorkSubType): number {
  let aOrder = a.order ?? 0;
  let bOrder = b.order ?? 0;
  if (aOrder != bOrder) return aOrder - bOrder;

  let aName = a.name?.toLocaleLowerCase() ?? "";
  let bName = b.name?.toLocaleLowerCase() ?? "";
  if (aName < bName) return -1;
  else if (aName > bName) return 1;
  return 0;
}
/// Sorts a list of Work Sub Types by order and name.  To be used only with work sub types all under the same work type
export function SortWorkSubTypes(items: WorkSubType[] | null | undefined): WorkSubType[] {
  if (!items) return [];
  return items.sort(CompareWorkSubTypes);
}
/// Sorts of a list of Work Sub Types under multiple work types.  Sorts work types, then sorts sub types for each work type individually
export function SortAllWorkSubTypes(
  items: WorkSubType[] | null | undefined,
  parents: WorkType[] | null | undefined
): WorkSubType[] {
  if (!items?.length) return [];
  if (!parents?.length) return items.sort(CompareWorkSubTypes);

  let sortedParents = SortWorkTypes(parents);
  let allSortedItems = [] as WorkSubType[];
  for (let parent of sortedParents) {
    let itemsForParent = SortWorkSubTypes(items.filter(x => x.workTypeID == parent.id));
    allSortedItems = allSortedItems.concat(itemsForParent);
  }
  return allSortedItems;
}
export type TableHeader = {
  text: string | TranslateResult | undefined | null;
  value: string | undefined;
  align?: "start" | "center" | "end";
  sortable?: boolean;
  filterable?: boolean;
  groupable?: boolean;
  divider?: boolean;
  class?: string | string[];
  cellClass?: string | string[];
  width?: string | number;
  filter?: (value: any, search: string, item: any) => boolean;
  sort?: (a: any, b: any) => number;
};
export interface HashTable<T> {
  [key: string]: T;
}
export enum TimesheetRowType {
  DirectWorkOrderRelated = 1,
  DirectGeneral = 2,
  Indirect = 3,
  Equipment = 4
}
export class TimesheetRowTimeValues {
  regularTime = null as number | null;
  overTime = null as number | null;
  doubleTime = null as number | null;
  adding(other: TimesheetRowTimeValues | null | undefined): TimesheetRowTimeValues {
    return new TimesheetRowTimeValues(
      (this.regularTime ?? 0) + (other?.regularTime ?? 0),
      (this.overTime ?? 0) + (other?.overTime ?? 0),
      (this.doubleTime ?? 0) + (other?.doubleTime ?? 0)
    );
  }
  constructor(
    regularTime: number | null = null,
    overTime: number | null = null,
    doubleTime: number | null = null,
    rawValue?: string | number | null | undefined
  ) {
    this.regularTime = regularTime;
    this.overTime = overTime;
    this.doubleTime = doubleTime;

    if (rawValue == null || rawValue == undefined) return;
    if (typeof rawValue == "string" && (rawValue as string).length == 0) return;

    if (!isNaN(Number(rawValue))) {
      // If just a number is typed in this field, we parse it as regular time
      this.regularTime = Number(rawValue);
    } else {
      // A basic number was NOT entered, try to parse out based on "[#]R[#]O[#]D"
      let segmentStart = 0;
      let combinedValue = `${rawValue}`.toLowerCase();

      let rIndex = combinedValue.indexOf("r");
      if (rIndex == -1) rIndex = combinedValue.indexOf("s");
      if (rIndex >= 0) {
        let regularTimeString = combinedValue.substring(segmentStart, rIndex);
        let regularTime = Number(regularTimeString);
        if (!isNaN(regularTime)) this.regularTime = regularTime;
        segmentStart = rIndex + 1;
      }

      let oIndex = combinedValue.indexOf("o");
      if (oIndex >= 0) {
        let overTimeString = combinedValue.substring(segmentStart, oIndex);
        let overTime = Number(overTimeString);
        if (!isNaN(overTime)) this.overTime = overTime;
        segmentStart = oIndex + 1;
      }

      let dIndex = combinedValue.indexOf("d");
      if (dIndex >= 0) {
        let doubleTimeString = combinedValue.substring(segmentStart, dIndex);
        let doubleTime = Number(doubleTimeString);
        if (!isNaN(doubleTime)) this.doubleTime = doubleTime;
        segmentStart = dIndex + 1;
      }
    }
  }
  get showAdvanced(): boolean {
    return !!this.overTime || !!this.doubleTime;
  }
  get summaryString(): string | null {
    if (
      (this.overTime == null || this.overTime == 0) &&
      (this.doubleTime == null || this.doubleTime == 0)
    ) {
      return this.regularTime == null ? null : this.regularTime.toFixed(2);
    }

    let values = [];

    if (!!this.regularTime && this.regularTime != 0) values.push(`${this.regularTime}s`);
    if (!!this.overTime && this.overTime != 0) values.push(`${this.overTime}o`);
    if (!!this.doubleTime && this.doubleTime != 0) values.push(`${this.doubleTime}d`);

    return values.join(" ");
  }
  get totalTime(): number {
    return (this.regularTime ?? 0) + (this.overTime ?? 0) + (this.doubleTime ?? 0);
  }
}
export type TimesheetRowTimes = {
  [key: string]: TimesheetRowTimeValues;
};
export function CalculateRowTotalTime(row: TimesheetRow): TimesheetRowTimeValues {
  return CalculateRowTimes(row, row.times);
}
export function CalculateRowRelatedCorrectionTotalTime(row: TimesheetRow): TimesheetRowTimeValues {
  return CalculateRowTimes(row, row.relatedCorrectionTimes);
}
export function CalculateRowTimes(
  row: TimesheetRow,
  times: TimesheetRowTimes | undefined
): TimesheetRowTimeValues {
  if (!times) return new TimesheetRowTimeValues();

  let rowSubTypeIDs = ParseWorkSubTypeIDsFromRow(row);
  let total = rowSubTypeIDs.reduce(
    (a: TimesheetRowTimeValues, b: string) => a.adding(times[b]),
    new TimesheetRowTimeValues()
  );
  return total;
}
export class TimesheetRow {
  get totalTime(): TimesheetRowTimeValues {
    return CalculateRowTimes(this, this.times);
  }
  get relatedCorrectionTotalTime(): TimesheetRowTimeValues | undefined {
    if (!this.relatedCorrectionTimes) return undefined;
    return CalculateRowTimes(this, this.relatedCorrectionTimes);
  }
  get effectiveTime(): TimesheetRowTimeValues {
    return this.totalTime.adding(this.relatedCorrectionTotalTime);
  }
  get effectiveHasPerDiem(): boolean {
    return this.hasPerDiem && !this.removePerDiem && !this.relatedCorrectionRemovePerDiem;
  }
  get effectiveRemovePerDiem(): boolean {
    return this.removePerDiem && !this.hasPerDiem && !this.relatedCorrectionHasPerDiem;
  }
  get effectiveEquipmentQuantity(): number {
    return (this.equipmentQuantity ?? 0) + (this.relatedCorrectionEquipmentQuantity ?? 0);
  }
  get effectiveEquipmentDays(): number {
    return (this.equipmentDays ?? 0) + (this.relatedCorrectionEquipmentDays ?? 0);
  }
  constructor(values: {
    timesheetNumber?: string | null | undefined;
    rowType: TimesheetRowType;
    rowNumber?: number;
    contractorName?: string | null | undefined;
    employeeID?: string | null | undefined;
    employeeName?: string | null | undefined;
    employeeCode?: string | null | undefined;
    employeeBadge?: string | null | undefined;
    scaffoldID?: string | undefined;
    areaID?: string | undefined;
    subAreaID?: string | undefined;
    workOrderID?: string | null | undefined;
    workOrderNumber?: string | null | undefined;
    workOrderCostCodeID?: string | null | undefined;
    workOrderClientWorkOrderNumber?: string | null | undefined;
    workOrderChangeOrderNumber?: string | null | undefined;
    workOrderReworkNumber?: string | null | undefined;
    workOrderServiceOrderNumber?: string | null | undefined;
    classificationID?: string | null | undefined;
    classificationDisplayName?: string | null | undefined;
    errorMessage?: string | null | undefined;
    isCorrectionRow?: boolean;
    hasPerDiem?: boolean;
    relatedCorrectionHasPerDiem?: boolean;
    removePerDiem?: boolean;
    relatedCorrectionRemovePerDiem?: boolean;
    equipmentDays?: number;
    relatedCorrectionEquipmentDays?: number;
    equipmentQuantity?: number;
    relatedCorrectionEquipmentQuantity?: number;
    times?: TimesheetRowTimes;
    relatedCorrectionTimes?: TimesheetRowTimes;
  }) {
    this.timesheetNumber = values.timesheetNumber;
    this.rowType = values.rowType;
    this.rowNumber = values.rowNumber ?? 0;

    this.contractorName = values.contractorName;
    this.employeeID = values.employeeID ?? "";
    this.employeeName = values.employeeName;
    this.employeeCode = values.employeeCode;
    this.employeeBadge = values.employeeBadge;

    this.scaffoldID = values.scaffoldID;
    this.areaID = values.areaID;
    this.subAreaID = values.subAreaID;

    this.workOrderID = values.workOrderID;
    this.workOrderNumber = values.workOrderNumber;
    this.workOrderCostCodeID = values.workOrderCostCodeID;

    this.workOrderClientWorkOrderNumber = values.workOrderClientWorkOrderNumber;
    this.workOrderChangeOrderNumber = values.workOrderChangeOrderNumber;
    this.workOrderReworkNumber = values.workOrderReworkNumber;
    this.workOrderServiceOrderNumber = values.workOrderServiceOrderNumber;

    this.classificationID = values.classificationID;
    this.classificationDisplayName = values.classificationDisplayName;

    this.errorMessage = values.errorMessage ?? "";

    this.isCorrectionRow = values.isCorrectionRow ?? false;

    this.hasPerDiem = values.hasPerDiem ?? false;
    this.relatedCorrectionHasPerDiem = values.relatedCorrectionHasPerDiem ?? false;

    this.removePerDiem = values.removePerDiem ?? false;
    this.relatedCorrectionRemovePerDiem = values.relatedCorrectionRemovePerDiem ?? false;

    this.equipmentDays = values.equipmentDays ?? 0;
    this.relatedCorrectionEquipmentDays = values.relatedCorrectionEquipmentDays;

    this.equipmentQuantity = values.equipmentQuantity ?? 0;
    this.relatedCorrectionEquipmentQuantity = values.relatedCorrectionEquipmentQuantity;

    this.times = values.times ?? {};
    this.relatedCorrectionTimes = values.relatedCorrectionTimes;
  }
  timesheetNumber: string | null | undefined;
  contractorName: string | null | undefined;
  employeeID: string;
  employeeName: string | null | undefined;
  employeeCode?: string | null | undefined;
  employeeBadge?: string | null | undefined;
  classificationID: string | null | undefined;
  classificationDisplayName?: string | null | undefined;
  rowType: TimesheetRowType;
  rowNumber: number;
  workOrderID: string | null | undefined;
  workOrderNumber: string | null | undefined;
  workOrderClientWorkOrderNumber: string | null | undefined;
  workOrderServiceOrderNumber: string | null | undefined;
  workOrderChangeOrderNumber: string | null | undefined;
  workOrderReworkNumber: string | null | undefined;
  workOrderPurchaseOrderID: string | null | undefined;
  workOrderExistingTagNumber: string | null | undefined;
  scaffoldID: string | null | undefined;
  workOrderCostCodeID: string | null | undefined;
  areaID: string | null | undefined;
  areaName: string | null | undefined;
  errorMessage: string;
  subAreaID: string | null | undefined;
  subAreaName: string | null | undefined;
  isCorrectionRow: boolean;
  hasPerDiem: boolean;
  relatedCorrectionHasPerDiem: boolean;
  removePerDiem: boolean;
  relatedCorrectionRemovePerDiem: boolean;
  equipmentDays: number | null | undefined;
  relatedCorrectionEquipmentDays: number | null | undefined;
  equipmentQuantity: number | null | undefined;
  relatedCorrectionEquipmentQuantity: number | null | undefined;
  times: TimesheetRowTimes;
  relatedCorrectionTimes: TimesheetRowTimes | undefined;
}
export function areTimesheetRowsEqual(
  a: {
    isCorrectionRow: boolean;
    employeeID: string | undefined;
    classificationID: string | null | undefined;
    rowType: TimesheetRowType;
    workOrderID: string | null | undefined;
    areaID: string | null | undefined;
    subAreaID: string | null | undefined;
  },
  b: {
    isCorrectionRow: boolean;
    employeeID: string | undefined;
    classificationID: string | null | undefined;
    rowType: TimesheetRowType;
    workOrderID: string | null | undefined;
    areaID: string | null | undefined;
    subAreaID: string | null | undefined;
  },
  options: {
    ignoreClassification?: boolean;
    ignoreArea?: boolean;
    ignoreSubArea?: boolean;
    relatedForCorrection?: boolean;
  } = {}
): boolean {
  return (
    ((!!options.relatedForCorrection && a.isCorrectionRow != b.isCorrectionRow) ||
      (!options.relatedForCorrection && a.isCorrectionRow == b.isCorrectionRow)) &&
    a.rowType == b.rowType &&
    (a.employeeID ?? "") == (b.employeeID ?? "") &&
    (!!options.ignoreClassification || (a.classificationID ?? "") == (b.classificationID ?? "")) &&
    (a.workOrderID ?? "") == (b.workOrderID ?? "") &&
    (!!options.ignoreArea || (a.areaID ?? "") == (b.areaID ?? "")) &&
    (!!options.ignoreSubArea || (a.subAreaID ?? "") == (b.subAreaID ?? ""))
  );
}
export function ParseWorkSubTypeIDsFromRow(row: TimesheetRow): string[] {
  // We need an entry for each work sub type with a value for this row
  // These work sub types are added as keys to the object, but we don't want to create entries for the non-wst keys (like employee, sub area, etc.)
  return ParseWorkSubTypeIDsFromRowTimes(row.times);
}
export function ParseWorkSubTypeIDsFromRowTimes(times: TimesheetRowTimes): string[] {
  // We need an entry for each work sub type with a value for this row
  // These work sub types are added as keys to the object, but we don't want to create entries for the non-wst keys (like employee, sub area, etc.)
  return Object.keys(times);
}
function CompareExplanationArrays(
  rawArray1: TimesheetExplanation[] | undefined,
  rawArray2: TimesheetExplanation[] | undefined
) {
  // console.log(`CompareExplanationArrays`);
  if (rawArray1?.length != rawArray2?.length) {
    return false;
  }

  var compareExplanations = (a: TimesheetExplanation, b: TimesheetExplanation) => {
    let workOrderIDA = a.workOrderID?.toLowerCase() ?? "";
    let workOrderIDB = b.workOrderID?.toLowerCase() ?? "";
    if (workOrderIDA != workOrderIDB) {
      if (workOrderIDA < workOrderIDB) return -1;
      else return 1;
    }

    let workSubTypeIDA = a.workSubTypeID?.toLowerCase() ?? "";
    let workSubTypeIDB = b.workSubTypeID?.toLowerCase() ?? "";
    if (workSubTypeIDA != workSubTypeIDB) {
      if (workSubTypeIDA < workSubTypeIDB) return -1;
      else return 1;
    }

    let explanationA = a.explanation?.toLowerCase() ?? "";
    let explanationB = b.explanation?.toLowerCase() ?? "";
    if (explanationA != explanationB) {
      if (explanationA < explanationB) return -1;
      else return 1;
    }

    return 0;
  };

  let array1 = (rawArray1 ?? []).slice().sort(compareExplanations);
  let array2 = (rawArray2 ?? []).slice().sort(compareExplanations);

  for (var i = 0; i < array1.length; i++) {
    if (
      array1[i].workOrderID != array2[i].workOrderID ||
      array1[i].workSubTypeID != array2[i].workSubTypeID ||
      array1[i].explanation != array2[i].explanation
    ) {
      // console.log(`  ${array1[i].workOrderID} != ${array2[i].workOrderID} ||
      // ${array1[i].workSubTypeID} != ${array2[i].workSubTypeID} ||
      // ${array1[i].explanation} != ${array2[i].explanation}`);
      return false;
    }
    // console.log(`  ${array1[i].workOrderID} EQUALS ${array2[i].workOrderID} &&
    //   ${array1[i].workSubTypeID} EQUALS ${array2[i].workSubTypeID} &&
    //   ${array1[i].explanation} EQUALS ${array2[i].explanation}`);
  }

  return true;
}
function CompareTimesheetRows(a: TimesheetRow, b: TimesheetRow): number {
  let aRowNumber = a.rowNumber ?? 0;
  let bRowNumber = b.rowNumber ?? 0;
  if (aRowNumber != bRowNumber) {
    return aRowNumber - bRowNumber;
  }

  let aName = a.employeeName!.toLocaleLowerCase();
  let bName = b.employeeName!.toLocaleLowerCase();
  if (aName != bName) {
    if (aName < bName) return -1;
    else if (aName > bName) return 1;
  }

  let aWorkOrderNumber = !isNaN(Number(a.workOrderNumber)) ? Number(a.workOrderNumber!) : 0;
  let aHasWorkOrder = aWorkOrderNumber > 0;
  let bWorkOrderNumber = !isNaN(Number(b.workOrderNumber)) ? Number(b.workOrderNumber!) : 0;
  let bHasWorkOrder = bWorkOrderNumber > 0;
  if (aHasWorkOrder != bHasWorkOrder) {
    if (aHasWorkOrder) return -1;
    else return 1;
  }

  if (aWorkOrderNumber != bWorkOrderNumber) {
    return aWorkOrderNumber - bWorkOrderNumber;
  }

  let aHasArea = !!a.areaID;
  let aAreaID = a.areaID ?? "";
  let aAreaName = a.areaName?.toLowerCase() ?? "";
  let bHasArea = !!b.areaID;
  let bAreaID = b.areaID ?? "";
  let bAreaName = b.areaName?.toLowerCase() ?? "";

  if (aHasArea != bHasArea) {
    if (aHasArea) return -1;
    else return 1;
  }

  if (aAreaID != bAreaID) {
    if (aAreaName > bAreaName) return -1;
    else return 1;
  }

  let aHasSubArea = !!a.subAreaID;
  let aSubAreaID = a.subAreaID ?? "";
  let aSubAreaName = a.subAreaName?.toLowerCase() ?? "";
  let bHasSubArea = !!b.subAreaID;
  let bSubAreaID = b.subAreaID ?? "";
  let bSubAreaName = b.subAreaName?.toLowerCase() ?? "";

  if (aHasSubArea != bHasSubArea) {
    if (aHasSubArea) return -1;
    else return 1;
  }

  if (aSubAreaID != bSubAreaID) {
    if (aSubAreaName > bSubAreaName) return -1;
    else return 1;
  }

  let aIsCorrectionRow = a.isCorrectionRow;
  let bIsCorrectionRow = b.isCorrectionRow;
  if (aIsCorrectionRow != bIsCorrectionRow) {
    if (aIsCorrectionRow) return 1;
    else return -1;
  }

  return 0;
}
export function SortTimesheetRows(rows: TimesheetRow[]): TimesheetRow[] {
  // return rows;
  return rows.sort(CompareTimesheetRows);
}
function updateEntryValuesFromRow(
  entryForRow: UpdatableTimesheetEntryWithDetails,
  row: TimesheetRow,
  timeOptions: {
    times?: TimesheetRowTimeValues | null | undefined;
    perDiem?: boolean;
    equipment?: boolean;
  }
) {
  if (!!timeOptions.equipment) {
    entryForRow.doubleTime = row.equipmentDays;
    entryForRow.units = row.equipmentQuantity;
  }
  if (!!timeOptions.perDiem) {
    if (row.hasPerDiem) entryForRow.units = 1;
    else if (row.removePerDiem) entryForRow.units = -1;
  }
  if (!!timeOptions.times) {
    entryForRow.regularTime = timeOptions.times.regularTime;
    entryForRow.overTime = timeOptions.times.overTime;
    entryForRow.doubleTime = timeOptions.times.doubleTime;
  }

  // The user may have either changed the PD from one WO to another, or to/from a WO to/from a General row
  // In this case, we don't want a new entry, we just want to update the existing entry.
  entryForRow.workOrderID = row.workOrderID ?? null;
  entryForRow.workOrderNumber = row.workOrderNumber ?? "";

  if (!entryForRow.scaffoldID && !!row.scaffoldID) entryForRow.scaffoldID = row.scaffoldID;
  if (!entryForRow.areaID && !!row.areaID) entryForRow.areaID = row.areaID;
  if (!entryForRow.areaName && !!row.areaName) entryForRow.areaName = row.areaName;
  if (!entryForRow.subAreaID && !!row.subAreaID) entryForRow.subAreaID = row.subAreaID;
  if (!entryForRow.subAreaName && !!row.subAreaName) entryForRow.subAreaName = row.subAreaName;

  entryForRow.rowNumber = row.rowNumber;
  entryForRow.classificationID = row.classificationID;
}
function ConvertTimesheetRowsToTimesheetEntries(
  timesheet: UpdatableTimesheetWithTimesheetRows,
  perDiemSubType: WorkSubType | undefined,
  equipmentSubType: WorkSubType | undefined,
  workSubTypes: WorkSubType[],
  people: PersonWithDetails[],
  includeEmptyEntries: boolean
): UpdatableTimesheetEntryWithDetails[] {
  if (!timesheet.timesheetRows) return [];
  let entries = [] as UpdatableTimesheetEntryWithDetails[];
  // console.log(`ConvertTimesheetRowsToTimesheetEntries`);
  for (let row of timesheet.timesheetRows) {
    // console.log(
    //   `\t row: ${row.employeeName}, total: ${row.totalTime?.totalTime}, PD: ${row.hasPerDiem}, WO: ${row.workOrderNumber} (${row.workOrderID})`
    // );
    if (!!perDiemSubType && (row.hasPerDiem || row.removePerDiem)) {
      // console.log(`\t\t Per Diem Changed`);
      // Add an entry with 1 unit related to the Per Diem work type
      let perDiemSubTypeID = perDiemSubType.id;
      let existingPerDiemEntry = timesheet.initialEntries.find(
        x =>
          x.employeeID == row.employeeID &&
          x.workSubTypeID == perDiemSubTypeID &&
          (row.isCorrectionRow ?? false) == x.isCorrectionEntry &&
          (x.workOrderNumber ?? "") == (row.workOrderNumber ?? "")
      );
      // console.log(
      //   `\t\t existingPerDiemEntry?: ${!!existingPerDiemEntry} employeeID: ${
      //     row.employeeID
      //   }, workSubTypeID: ${perDiemSubTypeID}, isCorrectionRow: ${row.isCorrectionRow ?? false}`
      // );
      if (!!existingPerDiemEntry) {
        let entryForRow = new UpdatableTimesheetEntryWithDetails(existingPerDiemEntry);
        updateEntryValuesFromRow(entryForRow, row, { perDiem: true });
        // console.log(
        //   `\t\t  EXISTING PER DIEM ENTRY\n\t\t\t  ${row.employeeName} (${perDiemSubType.name}) isNew: ${entryForRow.isNew} orig: ${entryForRow.loadedUnits}, value: ${entryForRow.units},\n\t\t\t  workOrder: ${entryForRow.workOrderNumber} (${entryForRow.workOrderID}), subArea: ${entryForRow.subAreaName} (${entryForRow.subAreaID})`
        // );
        entries.push(entryForRow);
      } else {
        let costCodeID = perDiemSubType.useWorkOrderCostCode
          ? row.workOrderCostCodeID
          : perDiemSubType.defaultCostCodeID;
        let entryForRow = new UpdatableTimesheetEntryWithDetails({
          timesheetID: timesheet.id,
          isCorrectionEntry: row.isCorrectionRow,
          rowNumber: row.rowNumber,
          employeeID: row.employeeID,
          workOrderID: row.workOrderID,
          workOrderNumber: row.workOrderNumber,
          workOrderClientWorkOrderNumber: row.workOrderClientWorkOrderNumber,
          workOrderServiceOrderNumber: row.workOrderServiceOrderNumber,
          workOrderChangeOrderNumber: row.workOrderChangeOrderNumber,
          workOrderReworkNumber: row.workOrderReworkNumber,
          workOrderPurchaseOrderID: row.workOrderPurchaseOrderID,
          workOrderExistingTagNumber: row.workOrderExistingTagNumber,
          scaffoldID: row.scaffoldID,
          areaID: row.areaID,
          areaName: row.areaName,
          subAreaID: row.subAreaID,
          subAreaName: row.subAreaName,
          workTypeID: perDiemSubType.workTypeID,
          workSubTypeID: perDiemSubTypeID,
          classificationID:
            row.classificationID ?? people.find(x => x.id == row.employeeID)!.classificationID,
          costCodeID: costCodeID,
          units: !!row.removePerDiem ? -1 : 1,
          employeeName: row.employeeName,
          employeeCode: row.employeeCode,
          employeeBadge: row.employeeBadge,
          workSubTypeName: perDiemSubType.name
        } as TimesheetEntryWithDetails);
        // console.log(
        //   `\t\t  NEW PER DIEM ENTRY\n\t\t\t  ${row.employeeName} (${perDiemSubType.name}) isNew: ${entryForRow.isNew} orig: ${entryForRow.loadedUnits}, value: ${entryForRow.units},\n\t\t\t  workOrder: ${entryForRow.workOrderNumber} (${entryForRow.workOrderID}), subArea: ${entryForRow.subAreaName} (${entryForRow.subAreaID})`
        // );
        entries.push(entryForRow);
      }
    }
    if (
      timesheet.timesheetTypeID == TimesheetType.Equipment &&
      !!equipmentSubType &&
      (includeEmptyEntries || (row.equipmentDays !== undefined && row.equipmentDays != 0))
    ) {
      // Add an entry with 1 unit related to the Per Diem work type
      let equipmentSubTypeID = equipmentSubType.id;
      let existingEquipmentEntry = timesheet.initialEntries.find(
        x =>
          x.employeeID == row.employeeID &&
          x.workSubTypeID == equipmentSubTypeID &&
          row.isCorrectionRow == x.isCorrectionEntry
      );
      if (!!existingEquipmentEntry) {
        let entryForRow = new UpdatableTimesheetEntryWithDetails(existingEquipmentEntry);
        updateEntryValuesFromRow(entryForRow, row, { equipment: true });
        // console.log(
        //   `ConvertTimesheetRowsToTimesheetEntries EXISTING PER DIEM ROW\n${row.employeeName} (${equipmentSubType.name}) isNew: ${entryForRow.isNew} orig: ${entryForRow.loadedUnits}, value: ${entryForRow.units}, workOrder: ${entryForRow.workOrderNumber}, subArea: ${entryForRow.subAreaName} (${entryForRow.subAreaID})`
        // );
        entries.push(entryForRow);
      } else {
        let costCodeID = equipmentSubType.useWorkOrderCostCode
          ? row.workOrderCostCodeID
          : equipmentSubType.defaultCostCodeID;
        let entryForRow = new UpdatableTimesheetEntryWithDetails({
          timesheetID: timesheet.id,
          isCorrectionEntry: row.isCorrectionRow,
          rowNumber: row.rowNumber,
          employeeID: row.employeeID,
          workTypeID: equipmentSubType.workTypeID,
          workSubTypeID: equipmentSubTypeID,
          classificationID:
            row.classificationID ?? people.find(x => x.id == row.employeeID)!.classificationID,
          costCodeID: costCodeID,
          employeeName: row.employeeName,
          employeeCode: row.employeeCode,
          employeeBadge: row.employeeBadge,
          workSubTypeName: equipmentSubType.name
        } as TimesheetEntryWithDetails);

        entryForRow.doubleTime = row.equipmentDays;
        entryForRow.units = row.equipmentQuantity;

        // console.log(
        //   `ConvertTimesheetRowsToTimesheetEntries NEW PER DIEM ROW\n${row.employeeName} (${equipmentSubType.name}) isNew: ${entryForRow.isNew} orig: ${entryForRow.loadedUnits}, value: ${entryForRow.units}, workOrder: ${entryForRow.workOrderNumber}, subArea: ${entryForRow.subAreaName} (${entryForRow.subAreaID})`
        // );
        entries.push(entryForRow);
      }
    }

    let rowSubTypeIDs = ParseWorkSubTypeIDsFromRow(row);
    for (let wstID of rowSubTypeIDs) {
      // We need an entry for each work sub type with a value for this row
      // These work sub types are added as keys to the object, but we don't want to create entries for the non-wst keys (like employee, sub area, etc.)
      // if (TimesheetRowKeys.includes(wstID)) continue;
      if (wstID == perDiemSubType?.id || wstID == equipmentSubType?.id) continue;

      // Each key is actually a workSubTypeID, and its value is the "RegularTime" value.
      // Ignore any WSTs without a time value
      let times = row.times[wstID];
      // let value = Number(row.times[wstID]);
      // if (isNaN(value)) value = 0;

      if (!includeEmptyEntries && (!times || times.totalTime == 0)) continue;

      // Each timesheet row is a combination of: Employee, workorder, subarea.  Therefore an existing entry would have the same of these 3 values, plus the same WST
      let existingEntry = timesheet.initialEntries.find(
        x =>
          x.isCorrectionEntry == row.isCorrectionRow &&
          x.employeeID == row.employeeID &&
          x.classificationID == row.classificationID &&
          x.workSubTypeID == wstID &&
          (row.workOrderID ?? "") == (x.workOrderID ?? "") &&
          (row.areaID ?? "") == (x.areaID ?? "") &&
          (row.subAreaID ?? "") == (x.subAreaID ?? "")
      );

      if (!!existingEntry) {
        let entryForRow = new UpdatableTimesheetEntryWithDetails(existingEntry);
        updateEntryValuesFromRow(entryForRow, row, { times });
        // console.log(
        //   `ConvertTimesheetRowsToTimesheetEntries UPDATE EXISTING\n${row.employeeName} (${entryForRow.workSubTypeName}) isNew: ${entryForRow.isNew} orig: ${entryForRow.loadedRegularTime}, value: ${entryForRow.regularTime}, workOrder: ${entryForRow.workOrderNumber}, subArea: ${entryForRow.subAreaName} (${entryForRow.subAreaID})`
        // );
        entries.push(entryForRow);
      } else {
        let subType = workSubTypes.find(x => x.id == wstID)!;
        let costCodeID = subType.useWorkOrderCostCode
          ? row.workOrderCostCodeID
          : subType.defaultCostCodeID;
        if (!costCodeID?.length) {
          console.error(
            `COST CODE NOT FOUND - employeeName=${row.employeeName}, workOrderNumber=${row.workOrderNumber}, subTypeName=${subType.name}, WOCC: ${row.workOrderCostCodeID}, Default CC: ${subType.defaultCostCodeID}, value: ${times.summaryString}`
          );
        }
        let entryForRow = new UpdatableTimesheetEntryWithDetails({
          timesheetID: timesheet.id,
          isCorrectionEntry: row.isCorrectionRow,
          rowNumber: row.rowNumber,
          employeeID: row.employeeID,
          workOrderID: row.workOrderID,
          workOrderNumber: row.workOrderNumber,
          workOrderClientWorkOrderNumber: row.workOrderClientWorkOrderNumber,
          workOrderServiceOrderNumber: row.workOrderServiceOrderNumber,
          workOrderChangeOrderNumber: row.workOrderChangeOrderNumber,
          workOrderReworkNumber: row.workOrderReworkNumber,
          workOrderPurchaseOrderID: row.workOrderPurchaseOrderID,
          workOrderExistingTagNumber: row.workOrderExistingTagNumber,
          scaffoldID: row.scaffoldID,
          areaID: row.areaID,
          areaName: row.areaName,
          subAreaID: row.subAreaID,
          subAreaName: row.subAreaName,
          workTypeID: subType.workTypeID,
          workSubTypeID: wstID,
          classificationID:
            row.classificationID ?? people.find(x => x.id == row.employeeID)!.classificationID,
          costCodeID: costCodeID,
          // regularTime: value,
          employeeName: row.employeeName,
          employeeCode: row.employeeCode,
          employeeBadge: row.employeeBadge,
          workSubTypeName: subType.name
        } as TimesheetEntryWithDetails);

        entryForRow.regularTime = times.regularTime;
        entryForRow.overTime = times.overTime;
        entryForRow.doubleTime = times.doubleTime;

        // console.log(
        //   `ConvertTimesheetRowsToTimesheetEntries CREATE NEW ENTRY\n${row.employeeName} (${subType.name}) isNew: ${entryForRow.isNew} orig: ${entryForRow.loadedRegularTime}, value: ${entryForRow.regularTime}, workOrder: ${entryForRow.workOrderNumber}, subArea: ${entryForRow.subAreaName} (${entryForRow.subAreaID})`
        // );
        entries.push(entryForRow);
      }
    }
  }

  return entries;
}
export function ConvertTimesheetEntriesToTimesheetRows(
  timesheetType: TimesheetType | undefined,
  allEntries: TimesheetEntryWithDetails[] | undefined,
  workTypes: WorkType[],
  workSubTypes: WorkSubType[]
) {
  if (!allEntries) return [];

  let perDiemTypeID = workTypes.find(x => !!x.isPerDiem)?.id;
  let perDiemSubType = workSubTypes.find(x => x.workTypeID == perDiemTypeID);
  let perDiemSubTypeID = perDiemSubType?.id;

  let equipmentTypeID = workTypes.find(x => !!x.isEquipment)?.id;
  let equipmentSubType = workSubTypes.find(x => x.workTypeID == equipmentTypeID);
  let equipmentSubTypeID = equipmentSubType?.id;

  let directWorkTypeIDs = workTypes
    .filter(x => !!x.isDirect && !x.isPerDiem && !x.isEquipment)
    .map(x => x.id!);
  let directWorkOrderRelatedWorkSubTypeIDs = workSubTypes
    .filter(x => directWorkTypeIDs.includes(x.workTypeID!) && !!x.isWorkOrderRelated)
    .map(x => x.id!);
  let directGeneralWorkSubTypeIDs = workSubTypes
    .filter(x => directWorkTypeIDs.includes(x.workTypeID!) && !x.isWorkOrderRelated)
    .map(x => x.id!);
  let indirectWorkTypeIDs = workTypes
    .filter(x => !x.isDirect && !x.isPerDiem && !x.isEquipment)
    .map(x => x.id!);
  let indirectWorkSubTypeIDs = workSubTypes
    .filter(x => indirectWorkTypeIDs.includes(x.workTypeID!))
    .map(x => x.id!);

  if (!!perDiemSubTypeID) {
    if (
      timesheetType == TimesheetType.Indirect &&
      !indirectWorkSubTypeIDs.includes(perDiemSubTypeID)
    ) {
      indirectWorkSubTypeIDs.push(perDiemSubTypeID);
    }
    if (
      timesheetType == TimesheetType.Direct &&
      !directGeneralWorkSubTypeIDs.includes(perDiemSubTypeID)
    ) {
      directGeneralWorkSubTypeIDs.push(perDiemSubTypeID);
    }
  }

  let rows = [] as TimesheetRow[];
  for (let entry of allEntries) {
    if (!entry.workTypeID || !entry.workSubTypeID) continue;

    var rowTypeForEntry = TimesheetRowType.Indirect;
    if (entry.workSubTypeID == equipmentSubTypeID) {
      rowTypeForEntry = TimesheetRowType.Equipment;
    } else if (!!entry.workOrderID) {
      rowTypeForEntry = TimesheetRowType.DirectWorkOrderRelated;
    } else if (directGeneralWorkSubTypeIDs.includes(entry.workSubTypeID!)) {
      rowTypeForEntry = TimesheetRowType.DirectGeneral;
    }

    let existingRow = rows.find(
      row =>
        row.isCorrectionRow == entry.isCorrectionEntry &&
        row.employeeID == entry.employeeID &&
        row.classificationID == entry.classificationID &&
        row.rowType == rowTypeForEntry &&
        (row.workOrderID ?? "") == (entry.workOrderID ?? "") &&
        (row.areaID ?? "") == (entry.areaID ?? "") &&
        (row.subAreaID ?? "") == (entry.subAreaID ?? "")
    );
    if (!existingRow) {
      existingRow = new TimesheetRow({
        contractorName: entry.contractorName,
        employeeName: entry.employeeName,
        isCorrectionRow: entry.isCorrectionEntry ?? false,
        rowNumber: entry.rowNumber,
        rowType: rowTypeForEntry,
        employeeID: entry.employeeID!,
        workOrderID: entry.workOrderID,
        hasPerDiem: false,
        removePerDiem: false,
        equipmentDays: 0,
        equipmentQuantity: rowTypeForEntry == TimesheetRowType.Equipment ? 1 : 0
      });
      rows.push(existingRow);
    }

    if (!existingRow.contractorName) existingRow.contractorName = entry.contractorName;
    if (!existingRow.workOrderNumber) existingRow.workOrderNumber = entry.workOrderNumber ?? "";
    if (!existingRow.workOrderClientWorkOrderNumber)
      existingRow.workOrderClientWorkOrderNumber = entry.workOrderClientWorkOrderNumber ?? "";
    if (!existingRow.workOrderServiceOrderNumber)
      existingRow.workOrderServiceOrderNumber = entry.workOrderServiceOrderNumber ?? "";
    if (!existingRow.workOrderChangeOrderNumber)
      existingRow.workOrderChangeOrderNumber = entry.workOrderChangeOrderNumber ?? "";
    if (!existingRow.workOrderReworkNumber)
      existingRow.workOrderReworkNumber = entry.workOrderReworkNumber ?? "";
    if (!existingRow.workOrderPurchaseOrderID)
      existingRow.workOrderPurchaseOrderID = entry.workOrderPurchaseOrderID ?? "";
    if (!existingRow.workOrderExistingTagNumber)
      existingRow.workOrderExistingTagNumber = entry.workOrderExistingTagNumber ?? "";
    if (!existingRow.scaffoldID) existingRow.scaffoldID = entry.scaffoldID;
    if (!existingRow.areaID) existingRow.areaID = entry.areaID;
    if (!existingRow.areaName) existingRow.areaName = entry.areaName;
    if (!existingRow.subAreaID) existingRow.subAreaID = entry.subAreaID;
    if (!existingRow.subAreaName) existingRow.subAreaName = entry.subAreaName;
    if (!existingRow.employeeName) existingRow.employeeName = entry.employeeName;
    if (!existingRow.employeeCode) existingRow.employeeCode = entry.employeeCode;
    if (!existingRow.employeeBadge) existingRow.employeeBadge = entry.employeeBadge;
    if (!existingRow.classificationID) existingRow.classificationID = entry.classificationID;
    if (!existingRow.classificationDisplayName)
      existingRow.classificationDisplayName = entry.classificationAlias ?? entry.classificationName;

    let isPerDiemType = entry.workSubTypeID.toLowerCase() == perDiemSubTypeID?.toLowerCase();
    let isEquipmentType = entry.workSubTypeID.toLowerCase() == equipmentSubTypeID?.toLowerCase();
    if (isPerDiemType) {
      existingRow.hasPerDiem = !!entry.units && entry.units > 0 ? true : false;
      existingRow.removePerDiem =
        entry.isCorrectionEntry && !!entry.units && entry.units < 0 ? true : false;
    } else if (isEquipmentType) {
      existingRow.equipmentDays = entry.doubleTime ?? 0;
      existingRow.equipmentQuantity = entry.units ?? 1;
    } else {
      if (!!existingRow.times[entry.workSubTypeID]) {
        existingRow.times[entry.workSubTypeID]!.regularTime = entry.regularTime ?? 0;
        existingRow.times[entry.workSubTypeID]!.doubleTime = entry.doubleTime ?? 0;
        existingRow.times[entry.workSubTypeID]!.overTime = entry.overTime ?? 0;
      } else {
        existingRow.times[entry.workSubTypeID] = new TimesheetRowTimeValues(
          entry.regularTime ?? 0,
          entry.overTime ?? 0,
          entry.doubleTime ?? 0
        );
      }

      // existingRow.totalTime = new TimesheetRowTimeValues(
      //   entry.regularTime ?? 0,
      //   entry.overTime ?? 0,
      //   entry.doubleTime ?? 0
      // ).adding(existingRow.totalTime);
    }
  }

  for (let row of rows) {
    switch (row.rowType) {
      case TimesheetRowType.Indirect:
        for (let wstID of indirectWorkSubTypeIDs) {
          if (wstID == perDiemSubTypeID) continue;
          if (!row.times[wstID]) {
            // console.log(`  ${row.employeeName} ${wstID} = null`);
            row.times[wstID] = new TimesheetRowTimeValues();
          }
        }
        break;
      case TimesheetRowType.DirectWorkOrderRelated:
        for (let wstID of directWorkOrderRelatedWorkSubTypeIDs) {
          if (wstID == perDiemSubTypeID) continue;
          if (!row.times[wstID]) row.times[wstID] = new TimesheetRowTimeValues();
        }
        break;
      case TimesheetRowType.DirectGeneral:
        for (let wstID of directGeneralWorkSubTypeIDs) {
          if (wstID == perDiemSubTypeID) continue;
          if (!row.times[wstID]) row.times[wstID] = new TimesheetRowTimeValues();
        }
        break;
    }
  }

  // We now want to match up the correction rows with the rows they are correcting
  // This is purely for display purposes, so we can show the user what their "effective" result is based on the corrections made
  let correctionRows = rows.filter(x => !!x.isCorrectionRow);
  for (let correctionRow of correctionRows) {
    UpdateRowCorrections(correctionRow, rows);
  }
  return rows;
}
export function FindRelatedRowForCorrection(
  row: TimesheetRow,
  rows: TimesheetRow[]
): TimesheetRow | undefined {
  let relatedRows = rows.filter(x => areTimesheetRowsEqual(x, row, { relatedForCorrection: true }));
  if (!relatedRows.length) return;
  return relatedRows[0];
}
export function UpdateRowCorrections(modifiedRow: TimesheetRow, rows: TimesheetRow[]) {
  let relatedRow = FindRelatedRowForCorrection(modifiedRow, rows);
  if (!relatedRow) return;

  if (!relatedRow) return;

  relatedRow.relatedCorrectionTimes = modifiedRow.times;
  relatedRow.relatedCorrectionHasPerDiem = modifiedRow.hasPerDiem;
  relatedRow.relatedCorrectionRemovePerDiem = modifiedRow.removePerDiem;
  relatedRow.relatedCorrectionEquipmentDays = modifiedRow.equipmentDays;
  relatedRow.relatedCorrectionEquipmentQuantity = modifiedRow.equipmentQuantity;

  modifiedRow.relatedCorrectionTimes = relatedRow.times;
  modifiedRow.relatedCorrectionHasPerDiem = relatedRow.hasPerDiem;
  modifiedRow.relatedCorrectionRemovePerDiem = relatedRow.removePerDiem;
  modifiedRow.relatedCorrectionEquipmentDays = relatedRow.equipmentDays;
  modifiedRow.relatedCorrectionEquipmentQuantity = relatedRow.equipmentQuantity;
}

type TimesheetWithTimesheetRows = Timesheet & {
  timesheetRows: TimesheetRow[];
  isLocked: boolean;
};
export class UpdatableTimesheetWithTimesheetRows implements TimesheetWithTimesheetRows {
  id = undefined as string | undefined;
  timesheetNumber: number | undefined;
  lemID: string | null | undefined;
  ownerID = undefined as string | undefined;
  contractorID = undefined as string | undefined;
  day = undefined as Date | undefined;
  isNightShift: boolean | undefined;

  timesheetTypeID: TimesheetType | undefined;
  timesheetStatusID = undefined as TimesheetStatus | undefined;
  submittedBy: string | null | undefined;
  submittedTo: string | null | undefined;
  submittedOn: Date | null | undefined;
  reviewDeclineReason: string | undefined;
  reviewCancelReason: string | undefined;
  reviewStatusChangeTime: Date | undefined;
  reviewApprovalIsAutomatic: boolean | undefined;
  reviewApprovedBy: string | null | undefined;

  created = undefined as Date | undefined;
  createdBy = undefined as string | null | undefined;
  updated = undefined as Date | null | undefined;
  updatedBy = undefined as string | null | undefined;
  archivedDate = undefined as Date | null | undefined;

  ownerName = "" as string;
  contractorName = "" as string;

  statusLogs = [] as TimesheetStatusLogWithDetails[] | undefined;
  lastStatusLog = {} as TimesheetStatusLogWithDetails | undefined;

  currentUserPermissions = {} as SummarizedTimesheetPermissions;

  initialEntries = [] as TimesheetEntryWithDetails[];
  timesheetRows = [] as TimesheetRow[];

  explanations: Array<TimesheetExplanationWithWorkOrderDetails>;
  initialExplanations: Array<TimesheetExplanationWithWorkOrderDetails>;

  loadedIsNightShift: boolean | undefined;

  get baseTimesheet(): Timesheet {
    return {
      id: this.id,
      timesheetNumber: this.timesheetNumber,
      lemID: this.lemID,
      ownerID: this.ownerID,
      contractorID: this.contractorID,
      day: this.day,
      isNightShift: this.isNightShift,
      timesheetTypeID: this.timesheetTypeID,
      timesheetStatusID: this.timesheetStatusID,
      submittedBy: this.submittedBy,
      submittedTo: this.submittedTo,
      submittedOn: this.submittedOn,
      reviewDeclineReason: this.reviewDeclineReason,
      reviewCancelReason: this.reviewCancelReason,
      reviewStatusChangeTime: this.reviewStatusChangeTime,
      reviewApprovalIsAutomatic: this.reviewApprovalIsAutomatic,
      reviewApprovedBy: this.reviewApprovedBy,
      created: undefined,
      createdBy: undefined,
      updated: undefined,
      updatedBy: undefined,
      archivedDate: undefined
    };
  }
  constructor(
    timesheet: Timesheet & {
      currentUserPermissions: SummarizedTimesheetPermissions;
      statusLogs: Array<TimesheetStatusLogWithDetails> | undefined;
      explanations: Array<TimesheetExplanationWithWorkOrderDetails> | undefined;
      lastStatusLog: TimesheetStatusLogWithDetails;
      contractorName: string;
      ownerName: string;
    },
    entries: TimesheetEntryWithDetails[],
    workTypes: WorkType[],
    workSubTypes: WorkSubType[]
  ) {
    this.id = timesheet.id;
    this.lemID = timesheet.lemID;
    this.timesheetNumber = timesheet.timesheetNumber;

    this.ownerID = timesheet.ownerID;
    this.contractorID = timesheet.contractorID;
    this.day = timesheet.day;
    this.isNightShift = timesheet.isNightShift;

    this.timesheetTypeID = timesheet.timesheetTypeID;
    this.timesheetStatusID = timesheet.timesheetStatusID;
    this.submittedBy = timesheet.submittedBy;
    this.submittedTo = timesheet.submittedTo;
    this.submittedOn = timesheet.submittedOn;
    this.reviewDeclineReason = timesheet.reviewDeclineReason;
    this.reviewCancelReason = timesheet.reviewCancelReason;
    this.reviewStatusChangeTime = timesheet.reviewStatusChangeTime;
    this.reviewApprovalIsAutomatic = timesheet.reviewApprovalIsAutomatic;
    this.reviewApprovedBy = timesheet.reviewApprovedBy;

    this.created = timesheet.created;
    this.createdBy = timesheet.createdBy;
    this.updated = timesheet.updated;
    this.updatedBy = timesheet.updatedBy;
    this.archivedDate = timesheet.archivedDate;
    this.ownerName = timesheet.ownerName;
    this.contractorName = timesheet.contractorName;

    this.statusLogs = timesheet.statusLogs;
    this.lastStatusLog = timesheet.lastStatusLog;

    this.currentUserPermissions = timesheet.currentUserPermissions;

    this.loadedIsNightShift = timesheet.isNightShift;

    this.initialEntries = entries;
    this.timesheetRows = SortTimesheetRows(
      ConvertTimesheetEntriesToTimesheetRows(this.timesheetTypeID, entries, workTypes, workSubTypes)
    );
    let timesheetNumberString = `00000${timesheet.timesheetNumber}`.slice(-5);
    this.timesheetRows.forEach(row => (row.timesheetNumber = timesheetNumberString));

    let explanations = timesheet.explanations ?? [];
    this.initialExplanations = explanations?.map(x => ({ ...x }));
    this.explanations = explanations;
  }
  synchronizeTimesheetRows(
    perDiemSubType: WorkSubType | undefined,
    workTypes: WorkType[],
    workSubTypes: WorkSubType[],
    people: PersonWithDetails[],
    includeEmptyEntries: boolean = false
  ) {
    let equipmentTypeID = workTypes.find(x => !!x.isEquipment)?.id;
    let equipmentSubType = workSubTypes.find(x => x.workTypeID == equipmentTypeID);
    let entries = this.getEntries(
      perDiemSubType,
      equipmentSubType,
      workSubTypes,
      people,
      includeEmptyEntries
    );
    this.timesheetRows = SortTimesheetRows(
      ConvertTimesheetEntriesToTimesheetRows(this.timesheetTypeID, entries, workTypes, workSubTypes)
    );
  }

  get nextRowNumber(): number {
    let rows = this.timesheetRows;
    if (!rows?.length) return 1;

    let rowNumbers = rows.map(x => x.rowNumber ?? 0);
    return Math.max(...rowNumbers) + 1;
  }

  get isLocked() {
    return (
      this.timesheetStatusID == TimesheetStatus.Approved ||
      this.timesheetStatusID == TimesheetStatus.Submitted ||
      this.timesheetStatusID == TimesheetStatus.Cancelled
    );
  }
  get isNew() {
    return !this.id?.length;
  }
  /// True if the properties of the timesheet itself have been modified
  get isModified() {
    return this.isNightShiftModified;
  }
  get modifiedData() {
    let isNightShift = this.isNightShiftModified ? this.isNightShift : undefined;
    return {
      isNightShift: isNightShift
    } as Timesheet;
  }
  get isNightShiftModified() {
    return this.loadedIsNightShift != this.isNightShift;
  }
  get explanationsModified() {
    return !CompareExplanationArrays(this.explanations, this.initialExplanations);
  }

  /// True if the timesheet AND/OR CHILD ENTRIES have any changes to be saved, including being new
  checkIsDirty(
    perDiemSubType: WorkSubType | undefined,
    equipmentSubType: WorkSubType | undefined,
    workSubTypes: WorkSubType[],
    people: PersonWithDetails[],
    includeEmptyEntries: boolean = false
  ) {
    return (
      this.isNew ||
      this.isNightShiftModified ||
      this.explanationsModified ||
      this.checkHasRemovedEntries(
        perDiemSubType,
        equipmentSubType,
        workSubTypes,
        people,
        includeEmptyEntries
      ) ||
      this.checkHasModifiedEntries(
        perDiemSubType,
        equipmentSubType,
        workSubTypes,
        people,
        includeEmptyEntries
      ) ||
      this.checkHasNewEntries(
        perDiemSubType,
        equipmentSubType,
        workSubTypes,
        people,
        includeEmptyEntries
      )
    );
  }
  getEntries(
    perDiemSubType: WorkSubType | undefined,
    equipmentSubType: WorkSubType | undefined,
    workSubTypes: WorkSubType[],
    people: PersonWithDetails[],
    includeEmptyEntries: boolean = false
  ) {
    return ConvertTimesheetRowsToTimesheetEntries(
      this,
      perDiemSubType,
      equipmentSubType,
      workSubTypes,
      people,
      includeEmptyEntries
    );
  }
  getSanitizedEntries(
    perDiemSubType: WorkSubType | undefined,
    equipmentSubType: WorkSubType | undefined,
    workSubTypes: WorkSubType[],
    people: PersonWithDetails[],
    includeEmptyEntries: boolean = false
  ) {
    return this.getEntries(
      perDiemSubType,
      equipmentSubType,
      workSubTypes,
      people,
      includeEmptyEntries
    ).map(x => ({
      ...x,
      regularTime: SanitizeNumericValue(x.regularTime),
      overTime: SanitizeNumericValue(x.overTime),
      doubleTime: SanitizeNumericValue(x.doubleTime),
      units: SanitizeNumericValue(x.units)
    }));
  }
  checkHasNewEntries(
    perDiemSubType: WorkSubType | undefined,
    equipmentSubType: WorkSubType | undefined,
    workSubTypes: WorkSubType[],
    people: PersonWithDetails[],
    includeEmptyEntries: boolean = false
  ) {
    return (
      this.getEntries(
        perDiemSubType,
        equipmentSubType,
        workSubTypes,
        people,
        includeEmptyEntries
      ).findIndex(x => x.isNew) !== -1
    );
  }
  getNewEntries(
    perDiemSubType: WorkSubType | undefined,
    equipmentSubType: WorkSubType | undefined,
    workSubTypes: WorkSubType[],
    people: PersonWithDetails[],
    includeEmptyEntries: boolean = false
  ): UpdatableTimesheetEntryWithDetails[] {
    return this.getEntries(
      perDiemSubType,
      equipmentSubType,
      workSubTypes,
      people,
      includeEmptyEntries
    ).filter(x => x.isNew);
  }
  getSanitizedNewEntries(
    perDiemSubType: WorkSubType | undefined,
    equipmentSubType: WorkSubType | undefined,
    workSubTypes: WorkSubType[],
    people: PersonWithDetails[],
    includeEmptyEntries: boolean = false
  ): UpdatableTimesheetEntryWithDetails[] {
    return this.getNewEntries(
      perDiemSubType,
      equipmentSubType,
      workSubTypes,
      people,
      includeEmptyEntries
    ).map(
      x =>
        ({
          ...x,
          ...x.modifiedData,
          id: undefined,
          created: undefined
        } as UpdatableTimesheetEntryWithDetails)
    );
  }
  checkHasModifiedEntries(
    perDiemSubType: WorkSubType | undefined,
    equipmentSubType: WorkSubType | undefined,
    workSubTypes: WorkSubType[],
    people: PersonWithDetails[],
    includeEmptyEntries: boolean = false
  ) {
    return (
      this.getEntries(
        perDiemSubType,
        equipmentSubType,
        workSubTypes,
        people,
        includeEmptyEntries
      ).findIndex(x => !x.isNew && x.isDirty) !== -1
    );
  }
  getModifiedEntries(
    perDiemSubType: WorkSubType | undefined,
    equipmentSubType: WorkSubType | undefined,
    workSubTypes: WorkSubType[],
    people: PersonWithDetails[],
    includeEmptyEntries: boolean = false
  ) {
    return this.getEntries(
      perDiemSubType,
      equipmentSubType,
      workSubTypes,
      people,
      includeEmptyEntries
    ).filter(x => !x.isNew && x.isDirty);
  }
  getModifiedExistingEntryData(
    perDiemSubType: WorkSubType | undefined,
    equipmentSubType: WorkSubType | undefined,
    workSubTypes: WorkSubType[],
    people: PersonWithDetails[],
    includeEmptyEntries: boolean = false
  ) {
    return this.getModifiedEntries(
      perDiemSubType,
      equipmentSubType,
      workSubTypes,
      people,
      includeEmptyEntries
    ).map(
      x =>
        ({
          ...x.modifiedData,
          id: x.id,
          timesheetID: x.timesheetID
        } as TimesheetEntryWithDetails)
    );
  }
  checkHasRemovedEntries(
    perDiemSubType: WorkSubType | undefined,
    equipmentSubType: WorkSubType | undefined,
    workSubTypes: WorkSubType[],
    people: PersonWithDetails[],
    includeEmptyEntries: boolean = false
  ) {
    return (
      this.getRemovedEntries(
        perDiemSubType,
        equipmentSubType,
        workSubTypes,
        people,
        includeEmptyEntries
      ).length > 0
    );
  }
  getRemovedEntries(
    perDiemSubType: WorkSubType | undefined,
    equipmentSubType: WorkSubType | undefined,
    workSubTypes: WorkSubType[],
    people: PersonWithDetails[],
    includeEmptyEntries: boolean = false
  ) {
    let currentEntries = this.getEntries(
      perDiemSubType,
      equipmentSubType,
      workSubTypes,
      people,
      includeEmptyEntries
    );
    let currentEntryIDs = currentEntries.filter(x => !!x.id).map(x => x.id!);
    let removedEntries = this.initialEntries.filter(x => !currentEntryIDs.includes(x.id!));
    return removedEntries;
  }
  getRemovedEntryIDs(
    perDiemSubType: WorkSubType | undefined,
    equipmentSubType: WorkSubType | undefined,
    workSubTypes: WorkSubType[],
    people: PersonWithDetails[],
    includeEmptyEntries: boolean = false
  ) {
    return this.getRemovedEntries(
      perDiemSubType,
      equipmentSubType,
      workSubTypes,
      people,
      includeEmptyEntries
    ).map(x => x.id!);
  }
}
type TimesheetWithEntries = TimesheetWithDetails & {
  entries: UpdatableTimesheetEntryWithDetails[];
  removedEntryIDs: string[];
  isLocked: boolean;
};
export class UpdatableTimesheetWithEntries implements TimesheetWithEntries {
  id = undefined as string | undefined;
  lemID: string | null | undefined;
  timesheetNumber: number | undefined;
  associatedToLemNumber: string;
  hasEntriesMissingCostCode: boolean | null;

  ownerID = undefined as string | undefined;
  contractorID = undefined as string | undefined;
  day = undefined as Date | undefined;
  isNightShift: boolean | undefined;
  loadedIsNightShift: boolean | undefined;

  timesheetTypeID: TimesheetType | undefined;
  timesheetStatusID = undefined as TimesheetStatus | undefined;
  submittedBy: string | null | undefined;
  submittedTo: string | null | undefined;
  submittedOn: Date | null | undefined;
  reviewDeclineReason: string | undefined;
  reviewCancelReason: string | undefined;
  reviewStatusChangeTime: Date | undefined;
  reviewApprovalIsAutomatic: boolean | undefined;
  reviewApprovedBy: string | null | undefined;

  created = undefined as Date | undefined;
  createdBy = undefined as string | null | undefined;
  updated = undefined as Date | null | undefined;
  updatedBy = undefined as string | null | undefined;
  archivedDate = undefined as Date | null | undefined;

  creatorID: string | null;
  creatorName: string;
  ownerName = "" as string;
  ownerClassificationName: string;
  ownerClassificationAlias: string;
  contractorName = "" as string;
  submittedByName: string;
  submittedToName: string;
  reviewApprovedByName: string;

  entryCount = null as number | null;
  totalRegularTime = null as number | null;
  totalOverTime = null as number | null;
  totalDoubleTime = null as number | null;
  totalUnits = null as number | null;

  entries = [] as UpdatableTimesheetEntryWithDetails[];
  removedEntryIDs = [] as string[];

  explanations: Array<TimesheetExplanationWithWorkOrderDetails>;

  statusLogs = [] as TimesheetStatusLogWithDetails[];
  lastStatusLog = {} as TimesheetStatusLogWithDetails;

  currentUserPermissions = {} as SummarizedTimesheetPermissions;

  constructor(
    timesheet: TimesheetWithDetails,
    entries?: UpdatableTimesheetEntryWithDetails[],
    removedEntryIDs?: string[]
  ) {
    this.id = timesheet.id;
    this.lemID = timesheet.lemID;
    this.timesheetNumber = timesheet.timesheetNumber;
    this.associatedToLemNumber = timesheet.associatedToLemNumber;
    this.hasEntriesMissingCostCode = timesheet.hasEntriesMissingCostCode;

    this.ownerID = timesheet.ownerID;
    this.contractorID = timesheet.contractorID;
    this.day = timesheet.day;
    this.isNightShift = timesheet.isNightShift;

    this.timesheetTypeID = timesheet.timesheetTypeID;
    this.timesheetStatusID = timesheet.timesheetStatusID;
    this.submittedBy = timesheet.submittedBy;
    this.submittedTo = timesheet.submittedTo;
    this.submittedOn = timesheet.submittedOn;
    this.reviewDeclineReason = timesheet.reviewDeclineReason;
    this.reviewCancelReason = timesheet.reviewCancelReason;
    this.reviewStatusChangeTime = timesheet.reviewStatusChangeTime;
    this.reviewApprovalIsAutomatic = timesheet.reviewApprovalIsAutomatic;
    this.reviewApprovedBy = timesheet.reviewApprovedBy;

    this.created = timesheet.created;
    this.createdBy = timesheet.createdBy;
    this.updated = timesheet.updated;
    this.updatedBy = timesheet.updatedBy;
    this.archivedDate = timesheet.archivedDate;

    this.creatorID = timesheet.creatorID;
    this.creatorName = timesheet.creatorName;
    this.ownerName = timesheet.ownerName;
    this.ownerClassificationName = timesheet.ownerClassificationName;
    this.ownerClassificationAlias = timesheet.ownerClassificationAlias;
    this.contractorName = timesheet.contractorName;
    this.submittedByName = timesheet.submittedByName;
    this.submittedToName = timesheet.submittedToName;
    this.reviewApprovedByName = timesheet.reviewApprovedByName;

    this.entryCount = timesheet.entryCount;
    this.totalRegularTime = timesheet.totalRegularTime;
    this.totalOverTime = timesheet.totalOverTime;
    this.totalDoubleTime = timesheet.totalDoubleTime;
    this.totalUnits = timesheet.totalUnits;

    this.statusLogs = timesheet.statusLogs;
    this.lastStatusLog = timesheet.lastStatusLog;

    this.currentUserPermissions = timesheet.currentUserPermissions;

    this.loadedIsNightShift = timesheet.isNightShift;

    this.entries = entries ?? [];
    this.removedEntryIDs = removedEntryIDs ?? [];

    this.explanations = timesheet.explanations;
  }
  get isLocked() {
    return (
      this.timesheetStatusID == TimesheetStatus.Approved ||
      this.timesheetStatusID == TimesheetStatus.Cancelled ||
      this.timesheetStatusID == TimesheetStatus.Submitted
    );
  }
  get isNew() {
    return !this.id?.length;
  }
  get hasRemovedEntries() {
    return this.removedEntryIDs.length > 0;
  }
  get hasModifiedEntries() {
    return this.entries.findIndex(x => x.isDirty) !== -1;
  }
  get hasNewEntries() {
    return this.entries.findIndex(x => x.isNew) !== -1;
  }
  get modifiedExistingEntryData() {
    return this.entries
      .filter(x => x.isDirty)
      .map(
        x =>
          ({
            ...x.modifiedData,
            id: x.id,
            timesheetID: x.timesheetID
          } as TimesheetEntryWithDetails)
      );
  }
  get isNightShiftModified() {
    return this.loadedIsNightShift != this.isNightShift;
  }
  get isDirty() {
    // console.log(
    //   `isDirty isNew: ${this.isNew}, hasRemovedEntries: ${this.hasRemovedEntries}, hasModifiedEntries: ${this.hasModifiedEntries}, hasNewEntries: ${this.hasNewEntries}`
    // );
    return (
      this.isNew ||
      this.isNightShiftModified ||
      this.hasRemovedEntries ||
      this.hasModifiedEntries ||
      this.hasNewEntries
    );
  }
}
export class UpdatableTimesheetEntryWithDetails implements TimesheetEntryWithDetails {
  contractorName: string;
  employeeName: string;
  employeeCode: string;
  employeeBadge: string;
  workOrderNumber: string;
  workOrderClientWorkOrderNumber: string;
  workOrderServiceOrderNumber: string;
  workOrderChangeOrderNumber: string;
  workOrderReworkNumber: string;
  workOrderPurchaseOrderID: string | null;
  workOrderExistingTagNumber: string;
  workOrderType: number | null;
  scaffoldNumber: number | null;
  areaName: string;
  subAreaName: string;
  workTypeName: string;
  workSubTypeName: string;
  classificationName: string;
  classificationAlias: string;
  costCodeName: string;
  costCodeOverrideName: string;
  id = undefined as string | undefined;
  timesheetID = undefined as string | undefined;
  isCorrectionEntry: boolean | undefined;
  rowNumber: number | undefined;
  workOrderID = undefined as string | null | undefined;
  scaffoldID = undefined as string | null | undefined;
  employeeID = undefined as string | undefined;
  areaID = undefined as string | null | undefined;
  subAreaID = undefined as string | null | undefined;
  workTypeID = undefined as string | null | undefined;
  workSubTypeID = undefined as string | null | undefined;
  classificationID = undefined as string | null | undefined;
  costCodeID = undefined as string | null | undefined;
  costCodeIDOverride: string | null | undefined;

  regularTime = undefined as number | null | undefined;
  originalRegularTime = undefined as number | null | undefined;
  overTime = undefined as number | null | undefined;
  originalOverTime = undefined as number | null | undefined;
  doubleTime = undefined as number | null | undefined;
  originalDoubleTime = undefined as number | null | undefined;
  units = undefined as number | null | undefined;
  originalUnits = undefined as number | null | undefined;

  overridden: boolean | undefined;
  overriddenBy: string | null | undefined;

  created = undefined as Date | undefined;
  createdBy = undefined as string | null | undefined;
  updated = undefined as Date | null | undefined;
  updatedBy = undefined as string | null | undefined;
  archivedDate = undefined as Date | null | undefined;

  loadedAreaID = undefined as string | null | undefined;
  loadedSubAreaID = undefined as string | null | undefined;
  loadedWorkTypeID = undefined as string | null | undefined;
  loadedWorkSubTypeID = undefined as string | null | undefined;
  loadedCostCodeID = undefined as string | null | undefined;
  loadedCostCodeIDOverride: string | null | undefined;
  loadedRegularTime = undefined as number | null | undefined;
  loadedOriginalRegularTime = undefined as number | null | undefined;
  loadedOverTime = undefined as number | null | undefined;
  loadedOriginalOverTime = undefined as number | null | undefined;
  loadedDoubleTime = undefined as number | null | undefined;
  loadedOriginalDoubleTime = undefined as number | null | undefined;
  loadedUnits = undefined as number | null | undefined;
  loadedOriginalUnits = undefined as number | null | undefined;
  loadedRowNumber = undefined as number | null | undefined;
  loadedOverridden: boolean | undefined;
  loadedOverriddenBy: string | null | undefined;
  loadedWorkOrderID = undefined as string | null | undefined;

  constructor(entry: TimesheetEntryWithDetails) {
    this.contractorName = entry.contractorName;
    this.employeeName = entry.employeeName;
    this.employeeCode = entry.employeeCode;
    this.employeeBadge = entry.employeeBadge;
    this.workOrderNumber = entry.workOrderNumber;
    this.workOrderClientWorkOrderNumber = entry.workOrderClientWorkOrderNumber;
    this.workOrderServiceOrderNumber = entry.workOrderServiceOrderNumber;
    this.workOrderChangeOrderNumber = entry.workOrderChangeOrderNumber;
    this.workOrderReworkNumber = entry.workOrderReworkNumber;
    this.workOrderPurchaseOrderID = entry.workOrderPurchaseOrderID;
    this.workOrderExistingTagNumber = entry.workOrderExistingTagNumber;
    this.workOrderType = entry.workOrderType;
    this.scaffoldNumber = entry.scaffoldNumber;
    this.areaName = entry.areaName;
    this.subAreaName = entry.subAreaName;
    this.workTypeName = entry.workTypeName;
    this.workSubTypeName = entry.workSubTypeName;
    this.classificationName = entry.classificationName;
    this.classificationAlias = entry.classificationAlias;
    this.costCodeName = entry.costCodeName;
    this.costCodeOverrideName = entry.costCodeOverrideName;
    this.id = entry.id;
    this.timesheetID = entry.timesheetID;
    this.isCorrectionEntry = entry.isCorrectionEntry;
    this.rowNumber = entry.rowNumber;
    this.workOrderID = entry.workOrderID;
    this.scaffoldID = entry.scaffoldID;
    this.employeeID = entry.employeeID;
    this.areaID = entry.areaID;
    this.subAreaID = entry.subAreaID;
    this.workTypeID = entry.workTypeID;
    this.workSubTypeID = entry.workSubTypeID;
    this.classificationID = entry.classificationID;
    this.costCodeID = entry.costCodeID;
    this.costCodeIDOverride = entry.costCodeIDOverride;
    this.regularTime = entry.regularTime;
    this.originalRegularTime = entry.originalRegularTime;
    this.overTime = entry.overTime;
    this.originalOverTime = entry.originalOverTime;
    this.doubleTime = entry.doubleTime;
    this.originalDoubleTime = entry.originalDoubleTime;
    this.units = entry.units;
    this.originalUnits = entry.originalUnits;

    this.overridden = entry.overridden;
    this.overriddenBy = entry.overriddenBy;

    this.created = entry.created;
    this.createdBy = entry.createdBy;
    this.updated = entry.updated;
    this.updatedBy = entry.updatedBy;
    this.archivedDate = entry.archivedDate;

    this.loadedAreaID = entry.areaID;
    this.loadedSubAreaID = entry.subAreaID;
    this.loadedWorkTypeID = entry.workTypeID;
    this.loadedWorkSubTypeID = entry.workSubTypeID;
    this.loadedCostCodeID = entry.costCodeID;
    this.loadedCostCodeIDOverride = entry.costCodeIDOverride;
    this.loadedWorkOrderID = entry.workOrderID;
    this.loadedRegularTime = entry.regularTime;
    this.loadedOriginalRegularTime = entry.originalRegularTime;
    this.loadedOverTime = entry.overTime;
    this.loadedOriginalOverTime = entry.originalOverTime;
    this.loadedDoubleTime = entry.doubleTime;
    this.loadedOriginalDoubleTime = entry.originalDoubleTime;
    this.loadedUnits = entry.units;
    this.loadedOriginalUnits = entry.originalUnits;
    this.loadedRowNumber = entry.rowNumber;
    this.loadedOverridden = entry.overridden;
    this.loadedOverriddenBy = entry.overriddenBy;
  }
  get employeeWorkOrder() {
    return `${this.workOrderNumber ?? 0}_${this.employeeName}`;
  }
  sanitizeNumericValue(val: number | string | undefined | null): number | null {
    return SanitizeNumericValue(val);
  }
  get isOverriddenModified() {
    return this.loadedOverridden != this.overridden;
  }
  get isOverriddenByModified() {
    return (
      this.loadedOverriddenBy != this.overriddenBy ||
      (this.isNew && this.overriddenBy !== undefined)
    );
  }
  get isAreaModified() {
    return this.loadedAreaID != this.areaID || (this.isNew && this.areaID !== undefined);
  }
  get isSubAreaModified() {
    return this.loadedSubAreaID != this.subAreaID || (this.isNew && this.subAreaID !== undefined);
  }
  get workTypeModified() {
    return this.loadedWorkTypeID != this.workTypeID || (this.isNew && !!this.workTypeID);
  }
  get workSubTypeModified() {
    return this.loadedWorkSubTypeID != this.workSubTypeID || (this.isNew && !!this.workSubTypeID);
  }
  get costCodeModified() {
    return this.loadedCostCodeID != this.costCodeID || (this.isNew && !!this.costCodeID);
  }
  get isCostCodeOverrideModified() {
    return (
      this.loadedCostCodeIDOverride != this.costCodeIDOverride ||
      (this.isNew && !!this.costCodeIDOverride)
    );
  }
  get workOrderModified() {
    return this.loadedCostCodeID != this.workOrderID || (this.isNew && !!this.workOrderID);
  }
  get regularTimeModified() {
    return (
      this.sanitizeNumericValue(this.loadedRegularTime) !=
        this.sanitizeNumericValue(this.regularTime) ||
      (this.isNew && !!this.regularTime && this.regularTime != 0)
    );
  }
  get originalRegularTimeModified() {
    return (
      this.sanitizeNumericValue(this.loadedOriginalRegularTime) !=
        this.sanitizeNumericValue(this.originalRegularTime) ||
      (this.isNew && !!this.originalRegularTime && this.originalRegularTime != 0)
    );
  }
  get overTimeModified() {
    return (
      this.sanitizeNumericValue(this.loadedOverTime) != this.sanitizeNumericValue(this.overTime) ||
      (this.isNew && !!this.overTime && this.overTime != 0)
    );
  }
  get originalOverTimeModified() {
    return (
      this.sanitizeNumericValue(this.loadedOriginalOverTime) !=
        this.sanitizeNumericValue(this.originalOverTime) ||
      (this.isNew && !!this.originalOverTime && this.originalOverTime != 0)
    );
  }
  get doubleTimeModified() {
    return (
      this.sanitizeNumericValue(this.loadedDoubleTime) !=
        this.sanitizeNumericValue(this.doubleTime) ||
      (this.isNew && !!this.doubleTime && this.doubleTime != 0)
    );
  }
  get originalDoubleTimeModified() {
    return (
      this.sanitizeNumericValue(this.loadedOriginalDoubleTime) !=
        this.sanitizeNumericValue(this.originalDoubleTime) ||
      (this.isNew && !!this.originalDoubleTime && this.originalDoubleTime != 0)
    );
  }
  get unitsModified() {
    return (
      this.sanitizeNumericValue(this.loadedUnits) != this.sanitizeNumericValue(this.units) ||
      (this.isNew && !!this.units && this.units != 0)
    );
  }
  get originalUnitsModified() {
    return (
      this.sanitizeNumericValue(this.loadedOriginalUnits) !=
        this.sanitizeNumericValue(this.originalUnits) ||
      (this.isNew && !!this.originalUnits && this.originalUnits != 0)
    );
  }
  get rowNumberModified() {
    return (
      this.loadedRowNumber != this.rowNumber ||
      (this.isNew && !!this.rowNumber && this.rowNumber != 0)
    );
  }
  get isNew() {
    return !this.id || this.id.startsWith("new");
  }
  get isDirty() {
    return (
      this.isOverriddenModified ||
      this.isOverriddenByModified ||
      this.isAreaModified ||
      this.isSubAreaModified ||
      this.workTypeModified ||
      this.workSubTypeModified ||
      this.costCodeModified ||
      this.isCostCodeOverrideModified ||
      this.workOrderModified ||
      this.regularTimeModified ||
      this.originalRegularTimeModified ||
      this.overTimeModified ||
      this.originalOverTimeModified ||
      this.doubleTimeModified ||
      this.originalDoubleTimeModified ||
      this.unitsModified ||
      this.originalUnitsModified ||
      this.rowNumberModified
    );
  }
  get modifiedData() {
    let overridden = this.isOverriddenModified ? this.overridden : undefined;
    let overriddenBy = this.isOverriddenByModified ? this.overriddenBy : undefined;
    let areaID = this.isAreaModified ? this.areaID : undefined;
    let subAreaID = this.isSubAreaModified ? this.subAreaID : undefined;
    let workTypeID = this.workTypeModified ? this.workTypeID : undefined;
    let workSubTypeID = this.workSubTypeModified ? this.workSubTypeID : undefined;
    let costCodeID = this.costCodeModified ? this.costCodeID : undefined;
    let costCodeIDOverride = this.isCostCodeOverrideModified ? this.costCodeIDOverride : undefined;
    let workOrderID = this.workOrderModified ? this.workOrderID : undefined;
    let regularTime = this.regularTimeModified
      ? this.sanitizeNumericValue(this.regularTime)
      : undefined;
    let originalRegularTime = this.originalRegularTimeModified
      ? this.sanitizeNumericValue(this.originalRegularTime)
      : undefined;
    let overTime = this.overTimeModified ? this.sanitizeNumericValue(this.overTime) : undefined;
    let originalOverTime = this.originalOverTimeModified
      ? this.sanitizeNumericValue(this.originalOverTime)
      : undefined;
    let doubleTime = this.doubleTimeModified
      ? this.sanitizeNumericValue(this.doubleTime)
      : undefined;
    let originalDoubleTime = this.originalDoubleTimeModified
      ? this.sanitizeNumericValue(this.originalDoubleTime)
      : undefined;
    let units = this.unitsModified ? this.sanitizeNumericValue(this.units) : undefined;
    let originalUnits = this.originalUnitsModified
      ? this.sanitizeNumericValue(this.originalUnits)
      : undefined;
    let rowNumber = this.rowNumberModified ? this.rowNumber : undefined;
    return {
      overridden: overridden,
      overriddenBy: overriddenBy,
      areaID: areaID,
      subAreaID: subAreaID,
      workTypeID: workTypeID,
      workSubTypeID: workSubTypeID,
      costCodeID: costCodeID,
      costCodeIDOverride: costCodeIDOverride,
      workOrderID: workOrderID,
      regularTime: regularTime,
      originalRegularTime: originalRegularTime,
      overTime: overTime,
      originalOverTime: originalOverTime,
      doubleTime: doubleTime,
      originalDoubleTime: originalDoubleTime,
      units: units,
      originalUnits: originalUnits,
      rowNumber: rowNumber
    };
  }
  get formattedRegularTime() {
    return !!this.regularTime && this.regularTime != 0 ? this.regularTime.toFixed(2) : undefined;
  }
  get formattedOverTime() {
    return !!this.overTime && this.overTime != 0 ? this.overTime.toFixed(2) : undefined;
  }
  get formattedDoubleTime() {
    return !!this.doubleTime && this.doubleTime != 0 ? this.doubleTime.toFixed(2) : undefined;
  }
  get formattedUnits() {
    return !!this.units && this.units != 0 ? this.units.toFixed(2) : undefined;
  }
}

