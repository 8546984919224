import FDVue from "@fd/lib/vue";
import { mapMutations } from "vuex";
import errorHandling from "@fd/lib/vue/mixins/serviceErrorHandling";
import rules from "@fd/lib/vue/rules";
import {
  ContractorWithTags,
  Discipline,
  personService,
  ProjectLocation,
  Tag,
  WalkdownStatuses,
  WorkOrderStatuses,
  WalkdownWithRequestDetails,
  WorkOrderWithAllDetails,
  scaffoldRequestService,
  Person,
  noteService,
  NoteWithSenderDetails,
  Environment
} from "../services";
import {
  contractorService,
  disciplineService,
  projectLocationService,
  walkdownService,
  workOrderService
} from "../services";
import userAccess from "../dataMixins/userAccess";
import draggable from "vuedraggable";
import { filterByTags } from "../services/taggableItems";
import ServiceError from "@fd/lib/client-util/serviceError";
import { showAdditionalDetailsDialog } from "../../../common/client/views/components/AdditionalDetailsDialog.vue";
import { openWalkdownEditDialog } from "./components/dialogs/WalkdownDialog.vue";
import fileHandling, { ImageInfo, FileData } from "@fd/lib/vue/mixins/fileHandling";
import { openWorkOrderDetailsDialog } from "./components/WorkOrderDetailsDialog.vue";
import {
  FormattedWorkOrder,
  ScreenContext,
  FormattedWorkOrderFromWorkOrderWithAllDetails,
  sortFormattedWorkOrderArray,
  addFileDataToWorkOrder
} from "./components/KanbanItem.vue";
import { openAttachmentsDialog } from "./components/AttachmentsDialog.vue";
import { valueInArray } from "@fd/lib/client-util/array";
import {
  HasContractorName,
  JoinNameValues,
  PeopleFromSelectableList,
  PersonWithDetailsAndName,
  SelectablePerson
} from "../utils/person";
import { showCountSheetDialog } from "./components/dialogs/CountSheetDialog.vue";
import { showWorkOrderHistoryDialog } from "./components/dialogs/WorkOrderHistoryDialog.vue";
import { openLabourEntryDialog } from "./components/dialogs/LabourEntryDialog.vue";
import { openForemanTimesheetDialog } from "./components/dialogs/ForemanTimesheetDialog.vue";
import { openWorkOrderNotesDialog } from "./components/dialogs/WorkOrderNotesDialog.vue";
import { openWorkOrderNormsEntryDialog } from "./components/dialogs/WorkOrderNormsEntryDialog.vue";
import { showLocationDialog } from "./components/dialogs/SP.LocationDialog.vue";

export default FDVue.extend({
  name: "fd-to-do-list",

  mixins: [errorHandling, userAccess, rules, fileHandling],

  directives: {},

  components: {
    draggable,
    "fd-kanban-item": () => import("./components/KanbanItem.vue")
  },

  data: function() {
    return {
      // Used to track the the auto-reload for the table data
      screenLoaded: false,
      reloadTimer: null as NodeJS.Timeout | null,
      dataReloadMinutes: 5,
      imageList: [] as ImageInfo[] | undefined,
      allowAddImage: false,
      viewingItem: null as FormattedWorkOrder | null,

      allWorkOrders: [] as FormattedWorkOrder[],
      allNotes: [] as NoteWithSenderDetails[],

      allContractors: [] as ContractorWithTags[],
      allDisciplines: [] as Discipline[],
      currentUserDisciplines: [] as Discipline[],
      allAreas: [] as ProjectLocation[],
      allSubAreas: [] as ProjectLocation[],
      allForemen: [] as Person[],

      // If the user tried to add a file using the photo viewer, this name gets filled in to tell the user that it was added to the files list instead of photos
      newSavedFileName: ""
    };
  },

  computed: {
    showTimesheetActionButton(): boolean {
      return (
        this.$store.state.curEnvironment.enableTimesheets &&
        this.$store.state.curEnvironment.enableLabourEntry &&
        this.selectedWorkItemType == "workOrder" &&
        !!this.selectedForemanID &&
        this.currentUserCanViewTimesheets
      );
    },
    showWorkOrderHistoryActionButton(): boolean {
      return this.selectedWorkItemType == "workOrder" && !!this.selectedForemanID;
    },
    /*
      XS: Show it - without text and with the 'small-history' class
      SM: Show it - without text and with the 'small-history' class
      MD < Mobile (960-1194): Show it - without text but regular size
      MD > Mobile (1195-1263): Show it.  If the drawer is open, we hide the text to save space (but without the 'small-history' class)
      LG: Show it.  If the drawer is open, we hide the text to save space (but without the 'small-history' class)
      XL: Show the History button full size, always with text
    */
    useSmallActionButtons(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    hideActionButtonText(): boolean {
      return (
        this.$vuetify.breakpoint.mobile ||
        (this.$vuetify.breakpoint.lgAndDown && !!this.$store.state.drawer)
      );
    },
    showActionButtonText(): boolean {
      return !this.hideActionButtonText;
      // return (
      //   (!this.$vuetify.breakpoint.mobile && !this.$store.state.drawer) ||
      //   this.$vuetify.breakpoint.xl
      // );
    },
    isLabourEntryEnabled(): boolean {
      return this.$store.state.curEnvironment.enableLabourEntry;
    },
    isBuildSheetEntryEnabled(): boolean {
      return this.$store.state.curEnvironment.enableNorms ?? false;
    },
    workOrderCount(): string {
      var count =
        this.allWorkOrders?.filter(
          x =>
            x.workOrderStatus == WorkOrderStatuses.InScheduling ||
            x.workOrderStatus == WorkOrderStatuses.OnHold ||
            x.workOrderStatus == WorkOrderStatuses.Started
        )?.length ?? 0;
      return count < 100 ? `${count}` : "99+";
    },
    walkdownCount(): string {
      var count =
        this.allWorkOrders?.filter(x => x.workOrderStatus == WorkOrderStatuses.Walkdown)?.length ??
        0;
      return count < 100 ? `${count}` : "99+";
    },
    todo: {
      get(): FormattedWorkOrder[] {
        var items = [];
        if (this.selectedWorkItemType == "workOrder") {
          items = this.filteredWorkOrders.filter(x => this.isToDoWorkOrder(x));
        } else {
          items = this.filteredWorkOrders.filter(x => this.isToDoWalkdown(x));
        }
        return sortFormattedWorkOrderArray<FormattedWorkOrder>(items);
      },
      set(val: FormattedWorkOrder[]) {
        if (this.selectedWorkItemType == "workOrder") {
          var workOrders = val as FormattedWorkOrder[];
          var workOrder = workOrders.find(x => !this.isToDoWorkOrder(x));
          if (!!workOrder) {
            if (!workOrder.currentUserPermissions.canEditStatus) {
              var snackbarPayload = {
                text: this.$t("to-do-list.cannot-change-work-order-status", [
                  workOrder.internalNumber
                ]),
                type: "error",
                undoCallback: null
              };
              this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
            } else if (
              workOrder.workOrderStatus == WorkOrderStatuses.Completed ||
              workOrder.workOrderStatus == WorkOrderStatuses.Cancelled
            ) {
              var snackbarPayload = {
                text: this.$t("to-do-list.cannot-reinstate-work-order", [workOrder.internalNumber]),
                type: "error",
                undoCallback: null
              };
              this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
            } else if (!!workOrder.progress && workOrder.progress > 0) {
              var snackbarPayload = {
                text: this.$t("to-do-list.work-order-already-in-progress", [
                  workOrder.internalNumber
                ]),
                type: "error",
                undoCallback: null
              };
              this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
            } else if (workOrder.isTimeEntryCompleted) {
              this.$store.dispatch("SHOW_SNACKBAR", {
                text: this.$t("to-do-list.work-order-has-recorded-time", [
                  workOrder.internalNumber
                ]),
                type: "error",
                undoCallback: null
              });
            } else {
              this.saveWorkOrderField(
                workOrder,
                "workOrderStatus",
                WorkOrderStatuses.InScheduling,
                false
              );
            }
          }
        } else {
          var walkdowns = val as FormattedWorkOrder[];
          var walkdownWorkOrder = walkdowns.find(x => !this.isToDoWalkdown(x));
          if (!!walkdownWorkOrder) {
            if (
              !!walkdownWorkOrder.walkdown &&
              (walkdownWorkOrder.walkdown.walkdownStatus == WalkdownStatuses.Submitted ||
                walkdownWorkOrder.walkdown.walkdownStatus == WalkdownStatuses.Approved ||
                walkdownWorkOrder.walkdown.walkdownStatus == WalkdownStatuses.Cancelled)
            ) {
              this.$store.dispatch("SHOW_SNACKBAR", {
                text: this.$t("to-do-list.cannot-reinstate-walk-down", [
                  walkdownWorkOrder.internalNumber
                ]),
                type: "error",
                undoCallback: null
              });
            } else if (
              !!walkdownWorkOrder.walkdown &&
              walkdownWorkOrder.walkdown.walkdownStatus == WalkdownStatuses.Declined
            ) {
              this.$store.dispatch("SHOW_SNACKBAR", {
                text: this.$t("to-do-list.walk-down-is-declined-message", [
                  walkdownWorkOrder.internalNumber
                ]),
                type: "error",
                undoCallback: null
              });
            } else {
              this.saveWalkdownField(walkdownWorkOrder, "walkdownStatus", WalkdownStatuses.Pending);
            }
          }
        }

        val.forEach((x, idx) => {
          x.order = idx;
        });
      }
    },
    started: {
      get(): FormattedWorkOrder[] {
        if (this.selectedWorkItemType == "workOrder") {
          return sortFormattedWorkOrderArray(
            this.filteredWorkOrders.filter(x => this.isStartedWorkOrder(x))
          );
        } else {
          return sortFormattedWorkOrderArray(
            this.filteredWorkOrders.filter(x => this.isStartedWalkdown(x))
          );
        }
      },
      set(val: FormattedWorkOrder[]) {
        if (this.selectedWorkItemType == "workOrder") {
          var workOrders = val as FormattedWorkOrder[];
          var workOrder = workOrders.find(x => !this.isStartedWorkOrder(x));
          if (!!workOrder) {
            if (
              !workOrder.currentUserPermissions.canEditProgress ||
              !workOrder.currentUserPermissions.canEditStatus
            ) {
              var snackbarPayload = {
                text: this.$t("to-do-list.cannot-change-work-order-status", [
                  workOrder.internalNumber
                ]),
                type: "error",
                undoCallback: null
              };
              this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
            } else if (
              workOrder.workOrderStatus == WorkOrderStatuses.Completed ||
              workOrder.workOrderStatus == WorkOrderStatuses.Cancelled
            ) {
              var snackbarPayload = {
                text: this.$t("to-do-list.cannot-reinstate-work-order", [workOrder.internalNumber]),
                type: "error",
                undoCallback: null
              };
              this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
            } else {
              this.saveWorkOrderField(
                workOrder,
                "workOrderStatus",
                WorkOrderStatuses.Started,
                false
              );
            }
          }
        } else {
          var walkdowns = val as FormattedWorkOrder[];
          var walkdownWorkOrder = walkdowns.find(x => !this.isStartedWalkdown(x));
          if (!!walkdownWorkOrder) {
            if (
              !!walkdownWorkOrder.walkdown &&
              (walkdownWorkOrder.walkdown.walkdownStatus == WalkdownStatuses.Submitted ||
                walkdownWorkOrder.walkdown.walkdownStatus == WalkdownStatuses.Approved ||
                walkdownWorkOrder.walkdown.walkdownStatus == WalkdownStatuses.Cancelled)
            ) {
              var snackbarPayload = {
                text: this.$t("to-do-list.cannot-reinstate-walk-down", [
                  walkdownWorkOrder.internalNumber
                ]),
                type: "error",
                undoCallback: null
              };
              this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
            } else {
              this.saveWalkdownField(walkdownWorkOrder, "walkdownStatus", WalkdownStatuses.Draft);
            }
          }
        }

        val.forEach((x, idx) => {
          x.order = idx;
        });
      }
    },
    completed: {
      get(): FormattedWorkOrder[] {
        if (this.selectedWorkItemType == "workOrder") {
          return sortFormattedWorkOrderArray(
            this.filteredWorkOrders.filter(
              x =>
                !!x.workOrderStatus &&
                (x.workOrderStatus == WorkOrderStatuses.CompletionPendingAdministration ||
                  x.workOrderStatus == WorkOrderStatuses.Completed ||
                  x.workOrderStatus == WorkOrderStatuses.Cancelled)
            )
          );
        } else {
          return sortFormattedWorkOrderArray(
            this.filteredWorkOrders.filter(
              x => !!x.workOrderStatus && x.workOrderStatus == WorkOrderStatuses.Estimated
            )
          );
        }
      },
      set(val: FormattedWorkOrder[]) {
        if (this.selectedWorkItemType == "workOrder") {
          var workOrders = val as FormattedWorkOrder[];
          var workOrder = workOrders.find(
            x =>
              x.workOrderStatus != WorkOrderStatuses.CompletionPendingAdministration &&
              x.workOrderStatus != WorkOrderStatuses.Completed &&
              x.workOrderStatus != WorkOrderStatuses.Cancelled
          );
          if (!!workOrder) {
            if (
              !workOrder.currentUserPermissions.canEditProgress ||
              !workOrder.currentUserPermissions.canEditStatus
            ) {
              var snackbarPayload = {
                text: this.$t("to-do-list.cannot-change-work-order-status", [
                  workOrder.internalNumber
                ]),
                type: "error",
                undoCallback: null
              };
              this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
            } else {
              this.saveWorkOrderField(
                workOrder,
                "workOrderStatus",
                WorkOrderStatuses.Completed,
                true
              );
            }
          }
        } else {
          var walkdowns = val as FormattedWorkOrder[];
          var walkdownWorkOrder = walkdowns.find(
            x =>
              !!x.walkdown.walkdownStatus &&
              x.walkdown.walkdownStatus != WalkdownStatuses.Submitted &&
              x.walkdown.walkdownStatus != WalkdownStatuses.Approved && // We don't show approved, but we need to make sure they're not moved here
              x.walkdown.walkdownStatus != WalkdownStatuses.Cancelled
          );
          if (!!walkdownWorkOrder) {
            if (walkdownWorkOrder.walkdown.walkdownStatus == WalkdownStatuses.Pending) {
              var snackbarPayload = {
                text: this.$t("to-do-list.cannot-submit-unstarted-walk-down", [
                  walkdownWorkOrder.internalNumber
                ]),
                type: "error",
                undoCallback: null
              };
              this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
            } else {
              this.saveWalkdownField(
                walkdownWorkOrder,
                "walkdownStatus",
                WalkdownStatuses.Submitted
              );
            }
          }
        }

        val.forEach((x, idx) => {
          x.order = idx;
        });
      }
    },

    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        handle: ".draggable",
        ghostClass: "ghost"
      };
    },

    unwatchedMethodNames(): string[] {
      return [
        "notesCountForItem",
        "viewNotesForItem",
        "openWorkOrderHistoryDialog",
        "openWalkdownDialog",
        "showItemSummary",
        "isToDoWorkOrder",
        "isStartedWorkOrder",
        "isToDoWalkdown",
        "isStartedWalkdown"
      ];
    },

    filteredWorkOrders(): FormattedWorkOrder[] {
      var filteredWorkOrders = this.allWorkOrders;
      var utcNow = new Date(
        new Date().toUTCString().substring(0, new Date().toUTCString().length - 4)
      );
      filteredWorkOrders = filteredWorkOrders.filter(
        x => !x.archivedDate || x.archivedDate.getTime() / 60000 > utcNow.getTime() / 60000
      );
      if (!!this.selectedContractors?.length) {
        filteredWorkOrders = filteredWorkOrders.filter(x =>
          valueInArray(x.assignedContractorID, this.selectedContractors)
        );
      }
      if (!!this.selectedDisciplines?.length) {
        filteredWorkOrders = filteredWorkOrders.filter(x =>
          valueInArray(x.disciplineID, this.selectedDisciplines)
        );
      }
      if (!!this.selectedAreas?.length) {
        filteredWorkOrders = filteredWorkOrders.filter(x =>
          valueInArray(x.areaID, this.selectedAreas)
        );
      }
      if (!!this.selectedSubAreas?.length) {
        filteredWorkOrders = filteredWorkOrders.filter(x =>
          valueInArray(x.subAreaID, this.selectedSubAreas)
        );
      }
      if (!!this.tablesearch?.length) {
        filteredWorkOrders = filteredWorkOrders.filter(x =>
          `${x.id} ${x.internalNumber} ${x.scaffoldNumber} ${x.scaffoldExternalReferenceNumber} ${
            x.existingTagNumber
          } ${x.requestTypeName} ${x.areaName} ${x.subAreaName} ${x.detailedWorkDescription} ${
            !!x.workOrderStatus ? WorkOrderStatuses[x.workOrderStatus] : ""
          } ${this.$t("scaffold-requests.types." + (x.scaffoldRequestType ?? 0).toString())}`
            .toLowerCase()
            .includes(this.tablesearch.toLowerCase())
        );
      }
      return filterByTags(this.tagsSelectedForFiltering, filteredWorkOrders);
    },

    selectedForemanID: {
      get() {
        let selectedForemanIDs = this.$store.state.filters.find(
          (x: any) => x.context == "todolist"
        )!.peopleForFiltering;
        return !!selectedForemanIDs?.length ? selectedForemanIDs[0] : null;
      },
      set(val) {
        if (val == this.selectedForemanID) return;

        let selectedForemanIDs = [];
        if (!!val) selectedForemanIDs.push(val);
        this.$store.commit("SET_PEOPLE_FOR_FILTERING", selectedForemanIDs);

        if (!this.screenLoaded) return;
        this.allWorkOrders = [];
        this.reloadTableData();
      }
    },

    selectedWorkItemType: {
      get(): ScreenContext {
        return this.$store.state.filters.find((x: any) => x.context == "todolist")!
          .contextForFiltering;
      },
      set(val: ScreenContext) {
        var current = this.$store.state.filters.find((x: any) => x.context == "todolist")!
          .contextForFiltering;
        if (val == current) return;

        this.$store.commit("SET_CONTEXT_FOR_FILTERING", val);
      }
    },

    canViewContractorFilter(): boolean {
      return this.curUserCanViewAllContractors || this.curUserContractorIDs.length != 1;
    },

    canViewDisciplinesFilter(): boolean {
      return !!this.currentUserDisciplines && this.currentUserDisciplines.length != 1;
    },

    canViewPersonFilter(): boolean {
      // Hide the person filter if the current user is the only person in the list
      let people = PeopleFromSelectableList(this.visibleForemen);
      var isInForemenList = people.find(x => x.id == this.curUserID);
      var hidePersonFilter = isInForemenList && people.length == 1;
      return !hidePersonFilter;
    },

    tagsInUse(): Tag[] {
      return this.$store.getters.getSortedInUseTags(this.allWorkOrders);
    },

    tagsSelectedForFiltering: {
      get() {
        return this.$store.state.filters.find((x: any) => x.context == "todolist")!
          .tagsForFiltering;
      },
      set(val) {
        this.$store.commit("SET_TAGS_FOR_FILTERING", val);
      }
    },

    tablesearch: {
      get(): string {
        return this.$store.state.filters.find((x: any) => x.context == "todolist")!
          .searchStringForFiltering;
      },
      set(val: string) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    },

    visibleForemen(): SelectablePerson[] {
      let visibleForemenWithoutDetails = !this.selectedContractors?.length
        ? this.allForemen
        : this.allForemen.filter(x => valueInArray(x.contractorID, this.selectedContractors));
      let visibleForemenWithDetails = visibleForemenWithoutDetails
        .map(x => {
          return {
            ...x,
            name: JoinNameValues(x.firstName, x.lastName),
            contractorName: this.allContractors.find(c => c.id == x.contractorID)?.name
          } as PersonWithDetailsAndName & HasContractorName;
        })
        .sort((a, b) => {
          let contractorA = (a.contractorName ?? "").toLowerCase();
          let contractorB = (b.contractorName ?? "").toLowerCase();
          if (contractorA < contractorB) return -1;
          else if (contractorA > contractorB) return 1;

          let nameA = (a.name ?? "").toLowerCase();
          let nameB = (b.name ?? "").toLowerCase();
          if (nameA < nameB) return -1;
          else if (nameA > nameB) return 1;

          return 0;
        });

      var contractorNames = visibleForemenWithDetails
        .map(x => x.contractorName)
        .reduce((distinctNames: string[], contractorName: string) => {
          if (!distinctNames.includes(contractorName)) distinctNames.push(contractorName);

          return distinctNames;
        }, []);

      if (contractorNames.length > 1) {
        // console.log(`Total Foremen: ${visibleForemenWithoutDetails.length}`);
        // console.log(`Total Contractors: ${contractorNames.length}`);
        let contractorGroupedSelectableItems = [] as SelectablePerson[];
        contractorNames.forEach((name, index) => {
          let foremenForContractor = visibleForemenWithDetails.filter(
            x => x.contractorName == name
          );
          // console.log(`foremen for ${name}: ${foremenForContractor.length}`);
          if (!foremenForContractor?.length) return;

          if (index > 0) {
            contractorGroupedSelectableItems.push({
              divider: true
            });
          }
          if (!!name) {
            contractorGroupedSelectableItems.push({
              header: name
            });
          }

          contractorGroupedSelectableItems = contractorGroupedSelectableItems.concat(
            foremenForContractor
          );
        });
        return contractorGroupedSelectableItems;
      } else {
        return visibleForemenWithDetails;
      }
    },

    selectableDisciplines(): Discipline[] {
      let selectableDisciplineIDs = this.allWorkOrders
        .filter(x => !!x.disciplineID)
        .map(x => x.disciplineID!);
      selectableDisciplineIDs = [...new Set(selectableDisciplineIDs)];
      return this.allDisciplines.filter(x => selectableDisciplineIDs.includes(x.id!));
    },

    selectedDisciplines: {
      get(): string[] {
        var selectedDisciplines = this.$store.state.filters.find(
          (x: any) => x.context == "todolist"
        )!.disciplinesForFiltering as string[];
        selectedDisciplines = selectedDisciplines.filter(x =>
          valueInArray(
            x,
            this.selectableDisciplines.map(x => x.id!)
          )
        );
        return selectedDisciplines;
      },
      set(val: string[]) {
        this.$store.commit("SET_DISCIPLINES_FOR_FILTERING", val);
      }
    },

    selectableAreas(): ProjectLocation[] {
      let selectableAreaIDs = this.allWorkOrders.filter(x => !!x.areaID).map(x => x.areaID!);
      selectableAreaIDs = [...new Set(selectableAreaIDs)];

      return this.allAreas.filter(x => valueInArray(x.id, selectableAreaIDs));
    },

    selectedAreas: {
      get(): string[] {
        var selectedAreas = this.$store.state.filters.find((x: any) => x.context == "todolist")!
          .areasForFiltering as string[];
        selectedAreas = selectedAreas.filter(x =>
          valueInArray(
            x,
            this.selectableAreas.map(x => x.id!)
          )
        );
        return selectedAreas;
      },
      set(val: string[]) {
        this.$store.commit("SET_AREAS_FOR_FILTERING", val);
      }
    },

    selectableSubAreas(): ProjectLocation[] {
      let selectableSubAreaIDs = this.allWorkOrders
        .filter(x => !!x.subAreaID)
        .map(x => x.subAreaID!);
      selectableSubAreaIDs = [...new Set(selectableSubAreaIDs)];

      let selectedAreaIDs = this.selectedAreas;
      return this.allSubAreas.filter(
        x =>
          valueInArray(x.parentLocationID, selectedAreaIDs) &&
          valueInArray(x.id, selectableSubAreaIDs)
      );
    },

    selectedSubAreas: {
      get(): string[] {
        var selectedSubAreas = this.$store.state.filters.find((x: any) => x.context == "todolist")!
          .subAreasForFiltering as string[];
        selectedSubAreas = selectedSubAreas.filter(x =>
          valueInArray(
            x,
            this.selectableSubAreas.map(x => x.id!)
          )
        );
        return selectedSubAreas;
      },
      set(val: string[]) {
        this.$store.commit("SET_SUB_AREAS_FOR_FILTERING", val);
      }
    },

    selectableContractors(): ContractorWithTags[] {
      let selectableContractorIDs = this.allForemen.map(x => x.contractorID);
      selectableContractorIDs = [...new Set(selectableContractorIDs)];
      return this.allContractors
        .filter(x => selectableContractorIDs.includes(x.id))
        .sort((a, b) => {
          let nameA = (a.name ?? "").toLowerCase();
          let nameB = (b.name ?? "").toLowerCase();
          if (nameA < nameB) return -1;
          else if (nameA > nameB) return 1;

          return 0;
        });
    },

    selectedContractors: {
      get() {
        return this.$store.state.filters.find((x: any) => x.context == "todolist")!
          .contractorsForFiltering;
      },
      set(val) {
        this.$store.commit("SET_CONTRACTORS_FOR_FILTERING", val);
      }
    },
    imageViewerInfoMessage: {
      get() {
        if (!this.newSavedFileName?.length) return undefined;
        return this.$t("attachments.file-not-photo-alert-message", [this.newSavedFileName]);
      },
      set(val: string) {
        if (!val?.length) this.newSavedFileName = "";
      }
    }
  },

  watch: {},

  methods: {
    /*** GLOBAL ***/
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),

    async reloadTableData() {
      this.inlineMessage.message = "";
      this.processing = true;
      try {
        await Promise.all([this.loadWorkOrders()]);

        this.inlineMessage.message = "";
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    async loadWorkOrders() {
      if (!this.selectedForemanID) {
        this.allWorkOrders = [];
        return;
      }

      var workOrders = [] as WorkOrderWithAllDetails[];
      // console.log("loading work orders for foreman with id " + this.selectedForemanID);
      workOrders = await workOrderService.getWorkOrdersForTodoListForForeman(
        this.selectedForemanID!
      );
      var formattedWorkOrders = workOrders.map(x =>
        FormattedWorkOrderFromWorkOrderWithAllDetails(x)
      );
      var allWorkOrders = sortFormattedWorkOrderArray(formattedWorkOrders);
      allWorkOrders.forEach((x, idx) => {
        x.order = idx;
      });
      this.allWorkOrders = allWorkOrders;

      let workOrderIDs = allWorkOrders.map(x => x.id!);
      this.allNotes = await noteService.getForWorkOrders(workOrderIDs);
    },

    async openWorkOrderHistoryDialog() {
      await showWorkOrderHistoryDialog(this.selectedForemanID, "todolist");
      this.setFilteringContext({
        context: "todolist"
      });
    },

    async showForemanTimesheetDialog() {
      if (!this.selectedForemanID) return;
      await openForemanTimesheetDialog(this.selectedForemanID, null, "todolist");
      this.setFilteringContext({
        context: "todolist"
      });
    },

    async openWalkdownDialog(item: FormattedWorkOrder) {
      if (await openWalkdownEditDialog(item, item.walkdown)) {
        this.processing = true;
        try {
          var loadedItem = await workOrderService.getWorkOrderByID(item.id!);

          // The following fields might have been updated via business logic when doing the update
          item.workOrderStatus = loadedItem.workOrderStatus;
          item.workOrderStatusName = loadedItem.workOrderStatusName;
          item.workOrderStatusDetail = loadedItem.workOrderStatusDetail;
        } catch (error) {
          this.handleError(error as Error);
        } finally {
          this.processing = false;
        }
      }
    },

    notesCountForItem(item: FormattedWorkOrder): number {
      var notesCountForItem = this.allNotes.filter(x => x.noteThreadID == item.noteThreadID).length;

      if (!!item.notes?.length) {
        notesCountForItem += 1;
      }
      return notesCountForItem;
    },
    async viewNotesForItem(item: FormattedWorkOrder) {
      let notesForItem = this.allNotes.filter(x => x.noteThreadID == item.noteThreadID);
      let newNotes = await openWorkOrderNotesDialog(item, notesForItem);
      this.allNotes.push(...newNotes);
    },

    async viewAttachmentsForItem(item: FormattedWorkOrder) {
      await openAttachmentsDialog(item, { attachments: item.attachments });
    },

    async viewImagesForItem(item: FormattedWorkOrder) {
      if (!item.photos) item.photos = [];

      this.viewingItem = item;
      this.allowAddImage = item.currentUserPermissions.canEditAnything;
      // console.log(`allowAddImage: ${this.allowAddImage}`);
      this.imageList = item.photos.map(
        x =>
          ({
            name: x.name,
            source: `/services/FormidableDesigns.Services.V1.ScaffoldRequestService.DownloadScaffoldRequestFile?requestId=${item.scaffoldRequestID}&fileName=${x.name}`
          } as ImageInfo)
      );
    },

    async addImage() {
      if (!this.allowAddImage) return;
      (this.$refs.addFileButton as any).click();
    },

    async selectNewFile(originalFile: File) {
      this.newSavedFileName = "";

      this.processing = true;
      let allFiles = this.viewingItem?.nonPhotoFiles?.concat(this.viewingItem?.photos ?? []);
      var fileData = await this.optimizedFileDataForUpload(originalFile, allFiles);
      if (!fileData) {
        this.processing = false;
        return;
      }
      this.processing = false;
      await this.saveNewFileData(fileData);
    },
    async saveNewFileData(fileData: FileData | undefined) {
      if (!fileData) return;
      if (!this.viewingItem?.scaffoldRequestID) return;

      this.processing = true;
      try {
        await scaffoldRequestService.uploadScaffoldRequestFile(
          this.viewingItem.scaffoldRequestID!,
          fileData.name,
          fileData.file as Blob
        );

        addFileDataToWorkOrder(this.viewingItem, fileData);
        this.newSavedFileName = fileData.isPhoto ? "" : fileData.name.toUpperCase();

        var snackbarPayload = {
          text: this.$t("scaffold-requests.existing-scaffold-request.save-file-success", [
            fileData.name
          ]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        this.viewImagesForItem(this.viewingItem);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    isToDoWorkOrder(item: FormattedWorkOrder): boolean {
      return (
        item.workOrderStatus == WorkOrderStatuses.InScheduling ||
        (item.workOrderStatus == WorkOrderStatuses.OnHold && (!item.progress || item.progress == 0))
      );
    },

    isStartedWorkOrder(item: FormattedWorkOrder): boolean {
      return (
        !!item.workOrderStatus &&
        (item.workOrderStatus == WorkOrderStatuses.Started ||
          (item.workOrderStatus == WorkOrderStatuses.OnHold &&
            !!item.progress &&
            item.progress > 0))
      );
    },

    isToDoWalkdown(item: FormattedWorkOrder): boolean {
      return (
        item.workOrderStatus == WorkOrderStatuses.Walkdown &&
        (!item.walkdown?.walkdownStatus || item.walkdown.walkdownStatus == WalkdownStatuses.Pending)
      );
    },

    isStartedWalkdown(item: FormattedWorkOrder): boolean {
      return (
        item.workOrderStatus == WorkOrderStatuses.Walkdown &&
        !!item.walkdown &&
        (item.walkdown.walkdownStatus == WalkdownStatuses.Draft ||
          item.walkdown.walkdownStatus == WalkdownStatuses.Declined)
      );
    },

    async showItemSummary(item: FormattedWorkOrder) {
      // console.log(`showItemSummary item: ${item}`);

      await openWorkOrderDetailsDialog(
        {
          ...item,
          requestType: item.scaffoldRequestType,
          requestSubType: item.scaffoldRequestSubType
        },
        item.walkdown,
        false
      );
    },

    async showWorkOrderCountSheetDialog(item: FormattedWorkOrder) {
      await showCountSheetDialog(item, item.scaffoldID!);
    },

    async showLabourEntryDialog(item: FormattedWorkOrder) {
      if (!this.currentUserCanViewTimesheets) return;

      if (await openLabourEntryDialog(item)) {
      }
    },

    async showNormsEntryDialog(item: FormattedWorkOrder) {
      if (await openWorkOrderNormsEntryDialog(item)) {
      }
    },
    async showScaffoldLocationEntryDialog(item: FormattedWorkOrder) {
      let scaffoldLocation = undefined;
      if (!!item.scaffoldLatitude && !!item.scaffoldLongitude) {
        scaffoldLocation = {
          lat: item.scaffoldLatitude,
          lng: item.scaffoldLongitude,
          colour: null
        };
      }
      let location = await showLocationDialog({ location: scaffoldLocation });
      if (!!location) {
        await this.saveItemField(item, "scaffoldLocation", location, false);
      }
    },

    // DOES NOT manage processing or error message logic
    async loadDisciplines(): Promise<void> {
      let disciplines = await disciplineService.getAll(false, null, null);
      this.allDisciplines = disciplines;
    },

    // DOES NOT manage processing or error message logic
    async loadCurrentUserDisciplines(): Promise<void> {
      let disciplines = await disciplineService.getByPersonID(this.curUserID);
      this.currentUserDisciplines = disciplines;
    },

    // DOES NOT manage processing or error message logic
    async loadAreas(): Promise<void> {
      let areas = await projectLocationService.getVisibleAreas();
      this.allAreas = areas;
    },

    // DOES NOT manage processing or error message logic
    async loadSubAreas(): Promise<void> {
      let subAreas = await projectLocationService.getVisibleSubAreas();
      this.allSubAreas = subAreas;
    },

    // DOES NOT manage processing or error message logic
    async loadForemen(): Promise<void> {
      this.allForemen = await personService.getVisibleForemen();

      let foremen = this.allForemen;
      if (!this.selectedForemanID) {
        if (foremen.length == 1) {
          this.selectedForemanID = foremen[0].id!;
        } else {
          var currentUserForeman = foremen.find(x => x.id == this.curUserID);
          this.selectedForemanID = currentUserForeman?.id!;
        }
      }
    },

    async toggleWorkOrderOnHold(item: FormattedWorkOrder, onHold: boolean) {
      if (item.workOrderStatus == WorkOrderStatuses.OnHold && onHold) return;
      if (item.workOrderStatus != WorkOrderStatuses.OnHold && !onHold) return;

      var newStatus = WorkOrderStatuses.OnHold;
      if (!onHold) {
        newStatus = item.progress == 0 ? WorkOrderStatuses.InScheduling : WorkOrderStatuses.Started;
      }
      await this.saveWorkOrderField(item, "workOrderStatus", newStatus, false);
    },

    async updateWorkOrderProgress(item: FormattedWorkOrder, progress: number) {
      // If the user is setting this to 100%, the WO is going to be marked as completed and archived
      // As such, we want to present the undo option
      await this.saveWorkOrderField(item, "progress", progress, progress >= 100);
    },

    async archiveItem(item: FormattedWorkOrder) {
      var utcNow = new Date(
        new Date().toUTCString().substring(0, new Date().toUTCString().length - 4)
      );
      await this.saveItemField(item, "archivedDate", utcNow, true);
    },

    async saveItemField(
      item: FormattedWorkOrder,
      fieldName: string,
      value: any,
      presentUndo: boolean
    ): Promise<boolean> {
      if (item.type == "walkdown") {
        return await this.saveWalkdownField(item, fieldName, value, presentUndo);
      } else if (item.type == "workOrder") {
        return await this.saveWorkOrderField(item, fieldName, value, presentUndo);
      }

      return false;
    },

    async saveWorkOrderField(
      item: FormattedWorkOrder,
      fieldName: string,
      value: any,
      presentUndo: boolean
    ): Promise<boolean> {
      // console.log(`saveWorkOrderField: set ${fieldName} to ${value}`);
      var canMakeChange = true;

      if (fieldName == "progress" && !item.currentUserPermissions.canEditProgress)
        canMakeChange = false;
      if (
        fieldName == "workOrderStatus" &&
        !item.currentUserPermissions.canEditProgress &&
        (value == WorkOrderStatuses.Started || value == WorkOrderStatuses.Completed)
      )
        canMakeChange = false;
      if (
        fieldName == "workOrderStatus" &&
        value != WorkOrderStatuses.Cancelled &&
        !item.currentUserPermissions.canEditStatus
      )
        canMakeChange = false;
      if (
        fieldName == "workOrderStatus" &&
        value == WorkOrderStatuses.Cancelled &&
        !item.currentUserPermissions.canCancel
      )
        canMakeChange = false;

      if (
        (fieldName == "scaffoldLocation" ||
          fieldName == "scaffoldLatitude" ||
          fieldName == "scaffoldLongitude") &&
        !this.$store.state.curEnvironment.enableScaffoldLocation
      ) {
        canMakeChange = false;
      }

      if (!canMakeChange) {
        // Change the value to something else and then back to its current to force a rebind
        var previous = item.workOrderStatus;
        item.workOrderStatus = undefined;
        this.$nextTick(() => {
          item.workOrderStatus = previous;
        });
        return false;
      }

      // Capture the original value. This allows us to set the value of the client-side item immediately while still being able to revert it on error
      var originalValue = (item as any)[fieldName];
      if (fieldName == "scaffoldLocation") {
        originalValue = { lat: item.scaffoldLatitude, lng: item.scaffoldLongitude };
      }

      this.inlineMessage.message = null;
      this.processing = true;
      try {
        var updatedItem = {
          id: item.id,
          [fieldName]: value
        } as WorkOrderWithAllDetails;
        if (fieldName == "scaffoldLocation") {
          updatedItem.scaffoldLatitude = value.lat;
          updatedItem.scaffoldLongitude = value.lng;
        }

        if (fieldName != "archivedDate") {
          updatedItem.archivedDate = item.archivedDate; // Status changes rely on this to be passed in, and this property is not checked directly for modified
        }
        if (fieldName == "workOrderStatus") {
          var details = "" as string | undefined;
          if (value == WorkOrderStatuses.OnHold || value == WorkOrderStatuses.Cancelled) {
            var title = this.$t("scheduler.status-reason");
            if (value == WorkOrderStatuses.OnHold) title = this.$t("scheduler.on-hold-reason");
            if (value == WorkOrderStatuses.Cancelled)
              title = this.$t("scheduler.cancellation-reason");

            details = await showAdditionalDetailsDialog(title, this.$t("common.reason"), [
              this.rules.required
            ]);

            // If details is undefined the dialog was cancelled
            if (!details) {
              // Change the value to something else and then back to its current to force a rebind
              var previous = item.workOrderStatus;
              item.workOrderStatus = undefined;
              this.$nextTick(() => {
                // console.log(`setting status back to ${previous}`);
                item.workOrderStatus = previous;
              });
              return false;
            }
          }
          updatedItem.workOrderStatusDetail = details;
        }

        // We've cached the item's originalValue above to use in case of error
        // So we can set the item's value here so the UI updates while the call happens
        (item as any)[fieldName] = value;
        if (fieldName == "scaffoldLocation") {
          item.scaffoldLatitude = value.lat;
          item.scaffoldLongitude = value.lng;
        }
        var loadedItem = await workOrderService.updateItem(
          item.id!,
          updatedItem,
          `ToDoList.saveWorkOrderField.${fieldName}`
        );

        // The following fields might have been updated via business logic when doing the update
        item.workOrderStatus = loadedItem.workOrderStatus;
        if (fieldName == "workOrderStatus") {
          item.workOrderStatusName = WorkOrderStatuses[loadedItem.workOrderStatus!];
        }
        item.workOrderStatusDetail = loadedItem.workOrderStatusDetail;
        item.archivedDate = loadedItem.archivedDate;
        item.progress = loadedItem.progress;

        let undoCallback = null;
        if (presentUndo) {
          undoCallback = async () => {
            this.saveWorkOrderField(item, fieldName, originalValue, false);
          };
        }
        let snackbarPayload = {
          text: this.$t("scheduler.save-success", [item.internalNumber]),
          type: "success",
          undoCallback: undoCallback
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        return true;
      } catch (error) {
        // Change the value to something else and then back to its original value to force a rebind to undo the change the user made
        (item as any)[fieldName] = undefined;
        this.$nextTick(() => {
          (item as any)[fieldName] = originalValue;
        });

        // If the error is a 422 (meaning server validation failed) show a snackbar with the appropriate error message
        // If it's anything, handle as normal
        if ((error as any).statusCode == 422) {
          let snackbarPayload = {
            text: this.$t(`error-messages.${(error as ServiceError).message}`, [
              item.internalNumber
            ]),
            type: "error",
            undoCallback: null
          };
          this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        } else {
          this.handleError(error as Error);
        }
        return false;
      } finally {
        this.processing = false;
      }
    },

    async saveWalkdownField(
      item: FormattedWorkOrder,
      fieldName: string,
      value: any,
      presentUndo: boolean = false
    ): Promise<boolean> {
      var walkdown = item.walkdown;
      // console.log(`saveWalkdownField: set ${fieldName} to ${value}`);
      if (!walkdown) {
        walkdown = (await walkdownService.startNewWalkdown(item.id!)) as WalkdownWithRequestDetails;
        item.walkdown = walkdown;
      }
      // Capture the original value. This allows us to set the value of the client-side item immediately while still being able to revert it on error
      var originalValue = (walkdown as any)[fieldName];

      this.inlineMessage.message = null;
      this.processing = true;
      try {
        var updatedItem = {
          id: walkdown.id,
          workOrderID: walkdown.workOrderID ?? item.id!,
          [fieldName]: value
        } as WalkdownWithRequestDetails;

        if (!!walkdown) (walkdown as any)[fieldName] = value;
        if (fieldName == "walkdownStatus" && value == WalkdownStatuses.Submitted) {
          item.workOrderStatus = WorkOrderStatuses.Estimated;
        }

        await walkdownService.updateItem(walkdown.id!, updatedItem);

        var loadedItem = await workOrderService.getWorkOrderByID(item.id!);
        // // The following fields might have been updated via business logic when doing the update
        item.walkdown = loadedItem.walkdown;
        item.archivedDate = loadedItem.archivedDate;
        item.workOrderStatus = loadedItem.workOrderStatus;
        item.workOrderStatusName = loadedItem.workOrderStatusName;

        if (
          loadedItem.walkdown.walkdownStatus == WalkdownStatuses.Approved &&
          !loadedItem.plannedWorkStartDate
        ) {
          // Because this was a save walkdown field, we know that it was an active walkdown
          // As such, it's it not supposed to be an assigned work order, we set the current status to 0 so it's hidden from the screen
          (item as any).workOrderStatus = -1;
        }

        let undoCallback = null;
        if (presentUndo) {
          undoCallback = async () => {
            this.saveWalkdownField(item, fieldName, originalValue);
          };
        }
        let snackbarPayload = {
          text: this.$t("scheduler.save-success", [item.internalNumber]),
          type: "success",
          undoCallback: undoCallback
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        return true;
      } catch (error) {
        // Change the value to something else and then back to its original value to force a rebind to undo the change the user made
        (item.walkdown as any)[fieldName] = undefined;
        this.$nextTick(() => {
          (item.walkdown as any)[fieldName] = originalValue;
          if (fieldName == "walkdownStatus" && value == WalkdownStatuses.Submitted) {
            item.workOrderStatus = WorkOrderStatuses.Walkdown;
          }
        });

        // If the error is a 422 (meaning server validation failed) show a snackbar with the appropriate error message
        // If it's anything, handle as normal
        if ((error as any).statusCode == 422) {
          let snackbarPayload = {
            text: this.$t(`error-messages.${(error as ServiceError).message}`, [
              item.internalNumber
            ]),
            type: "error",
            undoCallback: null
          };
          this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        } else {
          this.handleError(error as Error);
        }
        return false;
      } finally {
        this.processing = false;
      }
    }
  },

  beforeDestroy() {
    if (this.reloadTimer) {
      clearTimeout(this.reloadTimer);
    }
  },

  created: async function() {
    var requestorFilter: ScreenContext = "walkdown";

    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    this.setFilteringContext({
      context: "todolist",
      parentalContext: null,
      searchStringForFiltering: "",
      tagsForFiltering: [],
      statusesForFiltering: [],
      contractorsForFiltering: [],
      disciplinesForFiltering: [],
      areasForFiltering: [],
      subAreasForFiltering: [],
      contextForFiltering: requestorFilter,
      peopleForFiltering: []
    });

    this.notifyNewBreadcrumb({
      text: this.$t("to-do-list.title"),
      to: "/todolist",
      resetHistory: true
    });

    this.processing = true;
    try {
      // The loadForemen logic requires contractors to be loaded
      this.allContractors = await contractorService.getAll(false, null, null);
      await Promise.all([
        this.loadDisciplines(),
        this.loadCurrentUserDisciplines(),
        this.loadAreas(),
        this.loadSubAreas(),
        this.loadForemen()
      ]);
      this.processing = true;

      await this.reloadTableData();
      this.screenLoaded = true;
    } catch (error) {
      if ((error as any).statusCode == 403) {
        this.inlineMessage.message = "";
      } else {
        this.handleError(error as Error);
      }
    } finally {
      this.processing = false;
    }
  }
});

