import FDVue from "@fd/lib/vue";
import { mapActions } from "vuex";
import fileHandling, {
  canOpenFileInNewWindow,
  componentsFromFileName,
  confirmUniqueName,
  FileData
} from "@fd/lib/vue/mixins/fileHandling";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import store from "../../store";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import {
  WorkPackageWithNameCode,
  ParseScaffoldRequestWithExtraDetails,
  scaffoldRequest,
  ScaffoldRequestWithChildIDsAndArchived
} from "../../dataMixins/scaffoldRequest";
import {
  openActiveWorkForScaffoldDialog,
  WorkForScaffoldDetails
} from "./ActiveWorkForScaffoldDialog.vue";

type Keyword = Tag;

import {
  Tag,
  workOrderService,
  ScaffoldRequestTypes,
  scaffoldRequestTypeHelper,
  scaffoldRequestSubTypeHelper,
  ScaffoldDesignWithDetails,
  scaffoldDesignService,
  projectLocationService,
  ProjectLocation,
  ExternalLink,
  externalLinkService,
  scaffoldService,
  ScaffoldSearchResult,
  ContractorPurchaseOrder,
  contractorService,
  ContractorWithTags,
  Discipline,
  disciplineService,
  Person,
  personService,
  ScaffoldRequestTypeDetails,
  ScaffoldRequestSubTypeWithParent,
  PersonWithParsedChildIDs,
  ScaffoldRequestSubTypes,
  SummarizedScaffoldRequestPermissions,
  Environment
} from "../../services";

import { scaffoldRequestService, workPackageService } from "../../services";
import { WorkForScaffoldDetailsFromScaffoldRequest } from "../../dataMixins/scaffoldRequestList";
import {
  ParseWorkOrderWithAllDetails,
  WorkForScaffoldDetailsFromWorkOrder
} from "../../dataMixins/workOrderList";
import { Attachment } from "../../dataMixins/attachment";
import { openExternalLinkDetails } from "./ExternalLinkDialog.vue";
import { showTextPromptDialog } from "../../../../common/client/views/components/TextPromptDialog.vue";
import { filterByContractors } from "../../services/taggableItems";
import { GetPersonName, HasName, SortItemsWithName } from "../../utils/person";
import { BasicSelectItem } from "@fd/lib/vue/utility/select";
import { valueInArray } from "@fd/lib/client-util/array";

type FormattedScaffoldDesign = ScaffoldDesignWithDetails & {
  formattedScaffoldNumber: string;
};

const ScaffoldRequestNewDialog = FDVue.extend({
  name: "sp-scaffold-request-new-dialog",

  mixins: [dialogSupport, fileHandling, scaffoldRequest],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  components: {
    "fd-chip-selector": () => import("@fd/lib/vue/components/ChipItemSelector.vue"),
    "fd-async-search-box": () => import("@fd/lib/vue/components/AsyncSearchBox.vue"),
    "fd-add-file-button": () => import("@fd/lib/vue/components/AddFileButton.vue"),
    "fd-subheader": () => import("@fd/lib/vue/components/layout/Subheader.vue"),
    "fd-work-package-selector": () => import("./WorkPackageSelectionDialog.vue"),
    "sp-work-order-scope-details-form": () => import("./forms/SP.WorkOrderScopeDetailsForm.vue")
  },

  data: function() {
    return {
      category: RequestTypeCategory.Scaffold,

      /*** GLOBAL ****/
      step: 1,
      lastStep: 6,

      // Form data errors
      basicStepError: false,
      // scopeStepError: false,
      notesStepError: false,
      optionsStepError: false,
      photosStepError: false,
      attachmentsStepError: false,
      basicStep: 1,
      scopeStep: 2,
      photosStep: 3,
      notesStep: 4,
      optionsStep: 5,
      attachmentsStep: 6,

      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,
      submitting: false,

      /*** DATA ***/
      // Reference
      allRequestTypes: [] as ScaffoldRequestTypeDetails[],
      allRequestSubTypes: [] as ScaffoldRequestSubTypeWithParent[],
      requestingContractors: [] as ContractorWithTags[],
      allContractors: [] as ContractorWithTags[],
      allRequestors: [] as (PersonWithParsedChildIDs & HasName)[],
      allDisciplines: [] as Discipline[],
      allVisibleAreas: [] as ProjectLocation[],
      allVisibleSubAreas: [] as ProjectLocation[],

      /*** TAG NUMBER ***/
      availableScaffolds: [] as ScaffoldSearchResult[],
      selectedScaffold: null as ScaffoldSearchResult | null,
      // Determines whether the area/subarea/etc. have been set via scaffold selection
      dataSetByScaffold: false,

      /*** Design NUMBER ***/
      availableDesigns: [] as FormattedScaffoldDesign[],
      selectedDesign: null as FormattedScaffoldDesign | null,
      // Determines whether the area/subarea/etc. have been set via ScaffoldDesign selection
      dataSetByDesign: false,

      /*** KEYWORDS ***/
      selectedKeywords: [] as Keyword[],

      /*** IWPs ***/
      availableIWPs: [] as WorkPackageWithNameCode[],
      selectedIWPs: [] as WorkPackageWithNameCode[],

      // Main entity
      scaffoldRequest: {
        workPackageIDs: [],
        tagIDs: [],
        currentUserPermissions: {} as SummarizedScaffoldRequestPermissions,
        id: undefined,
        internalRequestNumber: undefined,
        requestorID: undefined,
        workOrderNumber: undefined,
        messageThreadID: undefined,
        noteThreadID: undefined,
        submittedBy: undefined,
        submittedOn: undefined,
        scaffoldRequestStatus: undefined,
        scaffoldRequestStatusDetail: undefined,
        scaffoldRequestApprovalIsAutomatic: false,
        workOrderStatus: undefined,
        requestType: undefined,
        requestSubType: undefined,
        scaffoldDesignID: undefined,
        scaffoldID: undefined,
        scaffoldNumber: undefined,
        jobID: undefined,
        requestingContractorID: undefined,
        disciplineID: undefined,
        requestingEmployeeID: undefined,
        requiredDate: undefined,
        approvedRequiredDate: undefined,
        requiredUntilDate: undefined,
        areaID: undefined,
        subAreaID: undefined,
        specificWorkLocation: undefined,
        detailedWorkDescription: undefined,
        siteContact: undefined,
        notes: undefined,
        hasDayShift: false,
        hasNightShift: false,
        hasEnergizedEquipment: false,
        requiresConfinedSpacePermit: false,
        requiresLockoutProcedure: false,
        isPlanned: false,
        isUrgent: false,
        isUrgentDetail: undefined,
        isWalkdownRequired: false,
        isClientWorkOrder: false,
        clientWorkOrderReferenceNumber: undefined,
        clientWorkOrderReason: undefined,
        isChangeOrder: false,
        changeOrderReferenceNumber: undefined,
        changeOrderReason: undefined,
        isRework: false,
        reworkReferenceNumber: undefined,
        reworkReason: undefined,
        isServiceOrder: false,
        serviceOrderReferenceNumber: undefined,
        serviceOrderReason: undefined,
        purchaseOrderID: undefined,
        existingTagNumber: undefined,
        assignedContractorID: undefined,
        coordinatorID: undefined,
        generalForemanID: undefined,
        foremanID: undefined,
        tagIDJson: undefined,
        archivedDate: undefined,
        created: undefined,
        createdBy: undefined,
        updated: undefined,
        updatedBy: undefined,
        archived: false
      } as ScaffoldRequestWithChildIDsAndArchived,

      // *** PHOTOS & ATTACHMENTS ***
      touchedFileName: "",
      showPhotoTabAttachmentAlert: false,
      showAttachmentTabPhotoAlert: false,
      tablesearchfiles: "",
      allFiles: [] as FileData[],
      externalLinks: [] as ExternalLink[],

      /*** IMAGE EDIT ****/
      newFileData: undefined as FileData | undefined,
      editingFileData: undefined as FileData | undefined
    };
  },

  computed: {
    //#region "Global"
    unwatchedMethodNames() {
      return ["getScopeStepRule"];
    },
    requestIsForScaffold(): boolean {
      return this.category == RequestTypeCategory.Scaffold;
    },
    requestIsForMaintenance(): boolean {
      return this.category == RequestTypeCategory.Maintenance;
    },
    requestIsForPaint(): boolean {
      return this.category == RequestTypeCategory.Paint;
    },
    requestIsForInsulation(): boolean {
      return this.category == RequestTypeCategory.Insulation;
    },
    //#endregion

    //#region "Details"
    canSelectContractor(): boolean {
      return !!this.requestingContractors && this.requestingContractors.length > 1;
    },
    availableKeywords(): Keyword[] {
      return this.$store.getters.sortedEnabledTags;
    },
    requestTypes(): BasicSelectItem[] {
      return this.allRequestTypes.map(x => ({ text: x.displayName, value: x.value }));
    },
    requestSubTypes(): BasicSelectItem[] {
      return this.allRequestSubTypes
        .filter(x => x.parentRequestType == this.scaffoldRequest.requestType)
        .map(x => ({ text: x.displayName, value: x.value }));
    },
    areas(): ProjectLocation[] {
      let contractorID = this.scaffoldRequest.requestingContractorID;
      if (!contractorID) return [];

      let selectedContractor = this.requestingContractors.find(x => x.id == contractorID);
      if (!selectedContractor) return [];

      var areas = this.allVisibleAreas.filter(
        x =>
          !!selectedContractor?.includesAllAreas || valueInArray(x.id, selectedContractor?.areaIDs)
      );
      if (!this.scaffoldRequest.areaID && areas.length == 1) {
        this.$nextTick(() => {
          this.scaffoldRequest.areaID = areas[0].id;
        });
      }
      return areas;
    },
    subAreas(): ProjectLocation[] {
      let areaID = this.scaffoldRequest.areaID;
      if (!areaID) return [];

      var subAreas = this.allVisibleSubAreas.filter(x => {
        return x.parentLocationID == areaID;
      });

      if (!this.scaffoldRequest.subAreaID && subAreas.length == 1) {
        this.$nextTick(() => {
          this.scaffoldRequest.subAreaID = subAreas[0].id;
        });
      }

      return subAreas;
    },
    disciplines(): Discipline[] {
      let contractorID = this.scaffoldRequest.requestingContractorID;
      if (!contractorID) return [];

      let selectedContractor = this.requestingContractors.find(x => x.id == contractorID);
      if (!selectedContractor) return [];

      let disciplines = this.allDisciplines.filter(
        x =>
          !!selectedContractor?.includesAllDisciplines ||
          valueInArray(x.id, selectedContractor?.disciplineIDs)
      );

      if (!this.scaffoldRequest.disciplineID && disciplines.length == 1) {
        this.$nextTick(() => {
          this.scaffoldRequest.disciplineID = disciplines[0].id;
        });
      }

      return disciplines;
    },
    selectedRequestingContractorName(): string | undefined {
      return this.requestingContractors.find(
        x => x.id == this.scaffoldRequest.requestingContractorID
      )?.name;
    },
    requestors(): Person[] {
      if (!this.scaffoldRequest.requestingContractorID || !this.scaffoldRequest.disciplineID) {
        return [];
      }

      let requestors = this.allRequestors.filter(
        x =>
          !!x.contractorID &&
          x.contractorID == this.scaffoldRequest.requestingContractorID &&
          (!x.disciplineIDs?.length ||
            valueInArray(this.scaffoldRequest.disciplineID, x.disciplineIDs))
      );

      if (!this.scaffoldRequest.requestingEmployeeID) {
        let curUserRequestor = requestors.find(r => r.id == this.curUserID);
        if (!!curUserRequestor) {
          this.scaffoldRequest.requestingEmployeeID = curUserRequestor.id;
        } else {
          if (requestors.length == 1) {
            this.$nextTick(() => {
              this.scaffoldRequest.requestingEmployeeID = requestors[0].id;
            });
          }
        }
      }

      return requestors;
    },
    //#endregion

    //#region "Scope"
    missingRequiredClientWorkOrderData(): boolean {
      return (
        !!this.scaffoldRequest.isClientWorkOrder &&
        !this.scaffoldRequest.clientWorkOrderReferenceNumber?.length
      );
    },
    missingRequiredChangeOrderData(): boolean {
      return (
        !!this.scaffoldRequest.isChangeOrder &&
        !this.scaffoldRequest.changeOrderReferenceNumber?.length
      );
    },
    missingRequiredReworkData(): boolean {
      return !!this.scaffoldRequest.isRework && !this.scaffoldRequest.reworkReferenceNumber?.length;
    },
    missingRequiredServiceOrderData(): boolean {
      return (
        !!this.scaffoldRequest.isServiceOrder &&
        !this.scaffoldRequest.serviceOrderReferenceNumber?.length
      );
    },
    missingRequiredPurchaseOrderData(): boolean {
      let enablePurchaseOrders = this.$store.state.curEnvironment.enablePurchaseOrders;
      if (!enablePurchaseOrders) {
        this.scaffoldRequest.purchaseOrderID = null;
        return false;
      }

      if (!!this.scaffoldRequest.purchaseOrderID?.length) {
        // Verify the selected purchase order is still in the selectable list, since the contractor may have changed.
        this.scaffoldRequest.purchaseOrderID = this.selectablePurchaseOrders.find(
          x => x.id == this.scaffoldRequest.purchaseOrderID
        )?.id;
      }
      return !this.scaffoldRequest.purchaseOrderID?.length;
    },
    scopeStepError(): boolean {
      return (
        this.missingRequiredClientWorkOrderData ||
        this.missingRequiredChangeOrderData ||
        this.missingRequiredReworkData ||
        this.missingRequiredServiceOrderData ||
        this.missingRequiredPurchaseOrderData
      );
    },
    allPurchaseOrders(): ContractorPurchaseOrder[] {
      let allPurchaseOrders = this.$store.state.contractorPurchaseOrders
        .fullList as ContractorPurchaseOrder[];
      let sortedPurchaseOrders = allPurchaseOrders.sort(
        (a, b) => (a.displayOrder ?? 0) - (b.displayOrder ?? 0)
      );
      return sortedPurchaseOrders;
    },
    selectablePurchaseOrders(): ContractorPurchaseOrder[] {
      let requestingContractorID = this.allContractors.find(
        x => x.id == this.scaffoldRequest.requestingContractorID
      )?.id;
      if (!requestingContractorID?.length) return [];

      return filterByContractors([requestingContractorID], this.allPurchaseOrders);
    },
    //#endregion

    //#region "Photos"
    photoFiles(): FileData[] {
      return this.allFiles.filter(x => x.isPhoto);
    },
    //#endregion

    //#region "Notes"
    //#endregion

    //#region "Options"
    requestCanHaveWalkdown(): boolean {
      return this.requestIsForScaffold;
    },
    defaultScaffoldRequestRequiresWalkDown(): boolean {
      if (!this.scaffoldRequest) return false;
      let curEnvironment = this.$store.state.curEnvironment as Environment;
      if (!curEnvironment) return false;

      let requiresWalkdown = undefined;
      if (this.scaffoldRequest.requestType == ScaffoldRequestTypes.Erect)
        requiresWalkdown = curEnvironment.defaultErectScaffoldRequestRequiresWalkDown;
      else if (this.scaffoldRequest.requestType == ScaffoldRequestTypes.Modify)
        requiresWalkdown = curEnvironment.defaultModifyScaffoldRequestRequiresWalkDown;
      else if (this.scaffoldRequest.requestType == ScaffoldRequestTypes.Dismantle)
        requiresWalkdown = curEnvironment.defaultDismantleScaffoldRequestRequiresWalkDown;

      return requiresWalkdown ?? false;
    },
    //#endregion

    //#region "Attachments"
    nonPhotoAttachments(): Attachment[] {
      let attachments = [] as Attachment[];

      this.allFiles.forEach(file => {
        attachments.push({
          type: "file",
          name: file.name,
          isPhoto: file.isPreviewable ?? false,
          isPreviewable: file.isPreviewable ?? false,
          canOpenInNew: canOpenFileInNewWindow(file.name),
          file: file
        });
      });

      this.externalLinks.forEach(link => {
        attachments.push({
          type: "link",
          name: link.name!,
          isPhoto: false,
          isPreviewable: false,
          canOpenInNew: true,
          link: link
        });
      });

      return attachments.filter(x => !x.isPhoto);
    }
    //#endregion
  },

  methods: {
    ...mapActions({
      loadPurchaseOrders: "LOAD_CONTRACTOR_PURCHASE_ORDERS"
    }),
    async loadData() {
      this.optOutOfErrorHandling();

      this.processing = true;

      try {
        let services = [
          this.loadVisibleAreas(),
          this.loadVisibleSubAreas(),
          this.loadRequestingContractors(),
          this.loadAllContractors(),
          this.loadDisciplines(),
          this.loadRequestors(),
          this.$store.dispatch("LOAD_TAGS"),
          this.loadRequestTypes(),
          this.loadRequestSubTypes()
        ];

        if (this.$store.state.curEnvironment?.enablePurchaseOrders == true) {
          services.push(this.loadPurchaseOrders());
        }

        await Promise.all(services);

        if (this.requestTypes.length == 1) {
          this.scaffoldRequest.requestType = this.requestTypes[0].value;
        }

        if (!!this.$store.state.curEnvironment) {
          this.scaffoldRequest.isWalkdownRequired =
            this.requestCanHaveWalkdown && this.defaultScaffoldRequestRequiresWalkDown;
        }
        this.scaffoldRequest.requestingContractorID = this.curUserAccess.homeContractorID;

        if (this.$store.state.curEnvironment?.enablePurchaseOrders == true) {
          if (this.selectablePurchaseOrders.length == 1) {
            this.scaffoldRequest.purchaseOrderID = this.selectablePurchaseOrders[0].id;
          }
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async open(category: RequestTypeCategory) {
      this.optOutOfErrorHandling();
      this.category = category;
      this.loadData();
      return await this.showDialog!();
    },
    getScopeStepRule(): Array<Function> {
      return [() => !this.scopeStepError];
    },
    async openWorkDetailsForScaffoldDialog(): Promise<boolean> {
      this.processing = true;
      var allRequests = (
        await scaffoldRequestService.getSubmittedRequestsForScaffoldTag(
          this.scaffoldRequest.scaffoldNumber ?? 0
        )
      ).map(x => ParseScaffoldRequestWithExtraDetails(x));
      var convertedRequests = allRequests.map(x => WorkForScaffoldDetailsFromScaffoldRequest(x));

      var allWorkOrders = (
        await workOrderService.getActiveWorkOrdersForScaffoldTag(
          null,
          this.scaffoldRequest.scaffoldNumber ?? 0
        )
      ).map(x => ParseWorkOrderWithAllDetails(x));
      let convertedWorkOrders = allWorkOrders.map(x => WorkForScaffoldDetailsFromWorkOrder(x));

      var allScaffoldWork: WorkForScaffoldDetails[] = convertedRequests.concat(convertedWorkOrders);
      let dismantleScaffoldWork = allScaffoldWork.filter(
        x => x.requestType == ScaffoldRequestTypes.Dismantle
      );

      let result = true;
      if (dismantleScaffoldWork.length > 0) {
        console.log("WO's Scaffold Has Other WOs.  Show Popup!");
        result = await openActiveWorkForScaffoldDialog(
          this.scaffoldRequest.scaffoldNumber,
          allScaffoldWork,
          undefined,
          false,
          true,
          true
        );
      }
      this.processing = false;
      return result;
    },
    // *** GLOBAL ***
    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDraft();
    },

    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },

    validate(): boolean {
      this.basicStepError = !(this.$refs.basicform as HTMLFormElement).validate();
      this.notesStepError = !(this.$refs.notesform as HTMLFormElement).validate();
      this.optionsStepError = !(this.$refs.optionsform as HTMLFormElement).validate();
      this.photosStepError = !(this.$refs.photosform as HTMLFormElement).validate();
      this.attachmentsStepError =
        !!(this.$refs.attachmentsform as HTMLFormElement) &&
        !(this.$refs.attachmentsform as HTMLFormElement).validate();

      (this.$refs.scopechangeform as any)?.validate();

      return !(
        this.basicStepError ||
        this.scopeStepError ||
        this.notesStepError ||
        this.optionsStepError ||
        this.photosStepError ||
        this.attachmentsStepError
      );
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    async submitRequest() {
      this.inlineMessage.message = "";

      this.processing = true;
      this.submitting = true;
      try {
        await this.updateIsDraft(false);
        let submitted = await this.saveData(true);

        if (submitted) {
          var snackbarPayload = {
            text: this.$t("scaffold-requests.new.save-success"),
            type: "success",
            undoCallback: null
          };
          store.dispatch("SHOW_SNACKBAR", snackbarPayload);

          this.closeDialog!(true);
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.submitting = false;
        this.processing = false;
      }
    },

    //Method used in conjunction with new view dialog.
    async saveDraft() {
      this.inlineMessage.message = "";

      this.processing = true;
      this.saving = true;
      try {
        await this.updateIsDraft(true);
        if (!(await this.saveData(false))) {
          this.processing = false;
          this.saving = false;
          return;
        }

        var snackbarPayload = {
          text: this.$t("scaffold-requests.new.save-success"),
          type: "success",
          undoCallback: null
        };
        store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        this.closeDialog!(true);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    async saveData(isBeingSubmitted: boolean): Promise<boolean> {
      if (!this.validate()) {
        var message = this.$t("scaffold-requests.new.error-message");
        if (this.basicStepError)
          message += "\n\t- " + this.$t("scaffold-requests.new.steps.details");
        if (this.scopeStepError)
          message += "\n\t- " + this.$t("scaffold-requests.new.steps.scope-change");
        if (this.notesStepError) message += "\n\t- " + this.$t("scaffold-requests.new.steps.notes");
        if (this.optionsStepError)
          message += "\n\t- " + this.$t("scaffold-requests.new.steps.options");
        if (this.photosStepError)
          message += "\n\t- " + this.$t("scaffold-requests.new.steps.photos");

        this.inlineMessage.message = message;
        this.inlineMessage.type = "error";

        return false;
      }
      // Save web request
      // The following are numeric values entered as strings due to being text field entry.
      if (this.scaffoldRequest.scaffoldID)
        this.scaffoldRequest.scaffoldID = this.scaffoldRequest.scaffoldID;

      this.scaffoldRequest.tagIDs =
        this.selectedKeywords.length > 0 ? this.selectedKeywords.map(x => x.id!) : undefined;

      // Get the list of selected IWP IDs
      this.scaffoldRequest.workPackageIDs = this.selectedIWPs.map(iwp => iwp.id!);

      if (!!this.selectedScaffold) {
        this.scaffoldRequest.scaffoldID = this.selectedScaffold.id;
        this.scaffoldRequest.scaffoldNumber = this.selectedScaffold.internalNumber;
      } else {
        this.scaffoldRequest.scaffoldID = undefined;
      }

      var newRequestID = await scaffoldRequestService.addScaffoldRequest(
        this.scaffoldRequest,
        isBeingSubmitted
      );
      this.scaffoldRequest.id = newRequestID;

      if (this.allFiles.length) {
        for (let index = 0; index < this.allFiles.length; index++) {
          const file = this.allFiles[index];
          await scaffoldRequestService.uploadScaffoldRequestFile(
            newRequestID,
            file.name,
            file.file as Blob
          );
        }
      }
      if (this.externalLinks.length) {
        for (let index = 0; index < this.externalLinks.length; index++) {
          const newLink = this.externalLinks[index];
          await externalLinkService.addItem({
            scaffoldRequestID: newRequestID,
            name: newLink.name,
            address: newLink.address
          } as ExternalLink);
        }
      }
      return true;
    },

    //#region DATA LOADING
    // DOES NOT manage processing or error message logic
    async loadRequestTypes(): Promise<void> {
      if (this.category == RequestTypeCategory.Maintenance) {
        this.allRequestTypes = await scaffoldRequestTypeHelper.getAllMaintenanceRequestTypeDetails();
      } else if (this.category == RequestTypeCategory.Paint) {
        this.allRequestTypes = await scaffoldRequestTypeHelper.getAllPaintRequestTypeDetails();
      } else if (this.category == RequestTypeCategory.Insulation) {
        this.allRequestTypes = await scaffoldRequestTypeHelper.getAllInsulationRequestTypeDetails();
      } else {
        this.allRequestTypes = await scaffoldRequestTypeHelper.getAllScaffoldRequestTypeDetails();
      }
    },

    // DOES NOT manage processing or error message logic
    async loadRequestSubTypes(): Promise<void> {
      if (this.category == RequestTypeCategory.Maintenance) {
        this.allRequestSubTypes = await scaffoldRequestSubTypeHelper.getAllMaintenanceRequestSubTypesWithParent();
      } else if (this.category == RequestTypeCategory.Paint) {
        this.allRequestSubTypes = await scaffoldRequestSubTypeHelper.getAllPaintRequestSubTypesWithParent();
      } else if (this.category == RequestTypeCategory.Insulation) {
        this.allRequestSubTypes = await scaffoldRequestSubTypeHelper.getAllInsulationRequestSubTypesWithParent();
      } else {
        this.allRequestSubTypes = await scaffoldRequestSubTypeHelper.getAllScaffoldRequestSubTypesWithParent();
      }
    },

    // DOES NOT manage processing or error message logic
    async loadVisibleAreas(): Promise<void> {
      let areas = await projectLocationService.getVisibleAreas();
      this.allVisibleAreas = SortItemsWithName(areas);
    },

    // DOES NOT manage processing or error message logic
    async loadVisibleSubAreas(): Promise<void> {
      let subAreas = await projectLocationService.getVisibleSubAreas();
      this.allVisibleSubAreas = SortItemsWithName(subAreas);
    },

    // DOES NOT manage processing or error message logic
    async loadRequestingContractors(): Promise<void> {
      let requestingContractors = await await contractorService.getScaffoldRequestingContractors();
      this.requestingContractors = SortItemsWithName(requestingContractors);
    },
    async loadAllContractors() {
      let allContractors = await contractorService.getAll(false, null, null);
      this.allContractors = SortItemsWithName(allContractors);
    },

    // DOES NOT manage processing or error message logic
    async loadDisciplines(): Promise<void> {
      let disciplines = await disciplineService.getAll(false, null, null);
      this.allDisciplines = SortItemsWithName(disciplines);
    },

    // DOES NOT manage processing or error message logic
    async loadRequestors(): Promise<void> {
      let requestors = await personService.getAllActiveRequestors();
      this.allRequestors = SortItemsWithName(
        requestors.map(x => {
          return {
            ...x,
            name: GetPersonName(x)
          };
        })
      );
    },
    //#endregion

    // *** DETAILS ***
    async loadScaffolds(searchString: string) {
      if (!searchString?.length) this.availableScaffolds = [];
      else {
        let scaffolds = await scaffoldService.searchAll(searchString, false);
        this.availableScaffolds = scaffolds.map(x => {
          return {
            ...x,
            description: this.getScaffoldDescription(x),
            details: this.getScaffoldDetails(x),
            search: `${x.internalNumber} ${x.existingRequestNumber} ${
              x.externalReferenceNumber
            } ${x.subAreaName ?? x.areaName}`
          } as ScaffoldSearchResult;
        });
      }
    },
    async loadScaffoldDesigns() {
      if (!!this.availableDesigns?.length) return;

      let designs = await scaffoldDesignService.getAllReleased();
      this.availableDesigns = designs.map(
        d =>
          ({
            ...d,
            formattedScaffoldNumber: `T-${`00000${d.scaffoldNumber}`.slice(-5)}`
          } as FormattedScaffoldDesign)
      );
    },

    // *** IWPs ***
    async loadWorkPackages(searchString: string) {
      if (!searchString?.length) this.availableIWPs = [];
      else {
        let allIWPs = await workPackageService.searchAll(searchString);
        this.availableIWPs = allIWPs.map(x => {
          return {
            ...x,
            nameCode: `${x.name} | ${x.activityID}`
          };
        });
      }
    },

    // *** FILES ***
    // *** ATTACHMENTS ***
    // Attachments - Catch the generic "Attachment" objects and pass along to link or file-specific actions
    async openAttachment(item: Attachment) {
      if (!item.canOpenInNew) return;

      if (!!item.file && item.canOpenInNew) {
        await this.openFileInNewWindow(item.file);
      } else if (!!item.link) {
        let url = item.link.address;
        window.open(url, "_blank");
      }
    },
    async openFileInNewWindow(fileData: FileData) {
      let currentProcessing = this.processing;
      this.processing = true;
      if (!fileData.file) {
        return;
      }
      let url = URL.createObjectURL(fileData.file);
      window.open(url, "_blank");
      this.processing = currentProcessing;
    },
    async editAttachment(item: Attachment) {
      if (!!item.link) {
        await this.editLink(item.link);
      } else if (!!item.file && item.file.isPreviewable) {
        await this.editFile(item.file);
      } else if (!!item.file) {
        await this.editNameForFile(item.file);
      }
    },
    async editNameForFile(fileData: FileData) {
      let components = componentsFromFileName(fileData.name);
      let newName = await showTextPromptDialog({
        title: this.$t("attachments.edit-file-name-title"),
        label: this.$t("common.name"),
        rules: [this.rules.required],
        text: components.name
      });
      if (!!newName?.length && newName.toLowerCase() != components.name.toLowerCase()) {
        let newFileName = `${newName}.${components.extension}`;
        var originalFileName = fileData.name;
        if (newFileName.toLowerCase() == originalFileName.toLowerCase()) return;

        var uniqueFileName = confirmUniqueName(newFileName, this.allFiles);

        fileData.name = uniqueFileName;
        this.touchedFileName = fileData.name;

        this.editingFileData = undefined;
      }
    },
    async deleteAttachment(item: Attachment) {
      if (!!item.link) {
        await this.deleteLink(item.link);
      } else if (!!item.file) {
        await this.removeFile(item.file);
      }
    },

    // Links
    // Method to open the dialog for when the user wishes to add a new External Link.
    async openNewExternalLinkDialog() {
      let newLink = await openExternalLinkDetails();
      if (!!newLink) {
        await this.saveNewExternalLink(newLink);
      }
    },
    async saveNewExternalLink(newLink: ExternalLink) {
      newLink.scaffoldRequestID = this.scaffoldRequest.id;
      this.externalLinks.push(newLink);

      this.showAttachmentTabPhotoAlert = false;
      this.showPhotoTabAttachmentAlert = false;

      this.touchedFileName = newLink.name ?? "";
    },
    async editLink(link: ExternalLink) {
      let editedLink = await openExternalLinkDetails(link);
      if (!!editedLink) {
        link.name = editedLink.name;
        link.address = editedLink.address;

        this.touchedFileName = link.name ?? "";
      }
    },
    async deleteLink(link: ExternalLink) {
      this.externalLinks.splice(this.externalLinks.indexOf(link), 1);

      this.touchedFileName = link.name ?? "";
    },

    // Files & Photos
    async selectFile() {
      (this.$refs.addFileButton as any).click();
    },
    async selectNewFile(originalFile: File | null | undefined) {
      if (!originalFile) return;
      var fileData = await this.optimizedFileDataForUpload(originalFile, this.allFiles);
      if (!fileData) return;

      if (fileData.isPreviewable) {
        this.newFileData = fileData;
        this.imageName = fileData.name;
        this.editImageSource = this.covertFileToDataURL(fileData.file);
      } else {
        this.allFiles.push(fileData);

        this.touchedFileName = fileData.name;
        this.showPhotoTabAttachmentAlert = this.step == this.photosStep && !fileData.isPhoto;
        this.showAttachmentTabPhotoAlert =
          this.step == this.attachmentsStep && fileData.isPhoto == true;
      }
    },
    editFile(fileData: FileData | null | undefined) {
      if (!fileData || !fileData.isPreviewable) return;

      this.editingFileData = fileData;
      this.imageName = fileData.name;
      this.editImageSource = this.covertFileToDataURL(fileData.file);
    },
    async handleEdit(res: File, fileName: string | undefined) {
      this.editImageSource = undefined;
      this.imageName = "";

      if (!!this.newFileData) {
        this.newFileData.file = res;
        if (!!fileName) this.newFileData.name = confirmUniqueName(fileName, this.allFiles);

        this.allFiles.push(this.newFileData);

        this.touchedFileName = this.newFileData.name;
        this.showPhotoTabAttachmentAlert =
          this.step == this.photosStep && !this.newFileData.isPhoto;
        this.showAttachmentTabPhotoAlert =
          this.step == this.attachmentsStep && this.newFileData.isPhoto == true;

        this.newFileData = undefined;
      } else if (!!this.editingFileData) {
        var originalFileName = this.editingFileData.name;

        var allFilesWithoutEditedFileData = this.allFiles.slice();
        allFilesWithoutEditedFileData.splice(
          allFilesWithoutEditedFileData.indexOf(this.editingFileData),
          1
        );
        var uniqueFileName = confirmUniqueName(
          fileName ?? originalFileName,
          allFilesWithoutEditedFileData
        );

        this.editingFileData.name = uniqueFileName;
        this.editingFileData.file = res;

        this.touchedFileName = this.editingFileData.name;
        this.showPhotoTabAttachmentAlert =
          this.step == this.photosStep && !this.editingFileData.isPhoto;
        this.showAttachmentTabPhotoAlert =
          this.step == this.attachmentsStep && this.editingFileData.isPhoto == true;

        this.editingFileData = undefined;
      }
    },
    viewFile(fileData: FileData) {
      this.imageName = fileData.name;
      this.imageSource = this.covertFileToDataURL(fileData.file);
    },
    removeFile(fileData: FileData) {
      var fileIndex = this.allFiles.indexOf(fileData);
      if (fileIndex == undefined) return;

      this.allFiles.splice(fileIndex, 1);
      this.touchedFileName = fileData.name;
      this.showAttachmentTabPhotoAlert = false;
      this.showPhotoTabAttachmentAlert = false;
    }
  },

  watch: {
    search(this: any, newValue: string) {
      this.hasLoaded = false;
      // If the user kept typing/changed the search before the service call, cancel the previous call in preparation of the new one
      if (this.timer) clearTimeout(this.timer);

      if (!newValue?.length) {
        this.searchItems = [];
        return;
      }

      var obj = this;
      // Delay the service call to allow the user to keep typing if they choose before making a server call
      this.timer = setTimeout(async function() {
        obj.loading = true;
        try {
          obj.loadWorkPackages(newValue);
        } finally {
          obj.timer = null;
          obj.loading = false;
          obj.hasLoaded = true;
        }
      }, 1500);
    },
    selectedScaffold: async function(newValue, oldValue) {
      if (!!oldValue && !newValue) {
        this.scaffoldRequest.scaffoldID = undefined;
        this.scaffoldRequest.areaID = undefined;
        this.scaffoldRequest.subAreaID = undefined;
        this.scaffoldRequest.specificWorkLocation = undefined;
        this.dataSetByScaffold = false;
      } else if (!!newValue) {
        let scaffold: ScaffoldSearchResult = newValue;
        this.scaffoldRequest.scaffoldID = scaffold.id;
        this.scaffoldRequest.scaffoldNumber = scaffold.internalNumber;
        var proceed = await this.openWorkDetailsForScaffoldDialog();
        if (!proceed) {
          this.scaffoldRequest.scaffoldID = undefined;
          let self = this;
          this.$nextTick(() => {
            self.selectedScaffold = null;
          });
          return;
        }
        this.scaffoldRequest.areaID = scaffold.areaID;
        this.scaffoldRequest.subAreaID = scaffold.subAreaID;
        this.scaffoldRequest.specificWorkLocation = scaffold.specificWorkLocation;
        this.dataSetByScaffold = true;
        this.dataSetByDesign = false;
      }
    },
    selectedDesign: async function(newValue, oldValue) {
      if (!!oldValue && !newValue) {
        this.scaffoldRequest.scaffoldID = undefined;
        this.scaffoldRequest.scaffoldDesignID = undefined;
        this.scaffoldRequest.areaID = undefined;
        this.scaffoldRequest.subAreaID = undefined;
        this.scaffoldRequest.specificWorkLocation = undefined;
        this.dataSetByDesign = false;
      } else if (!!newValue) {
        let design: ScaffoldDesignWithDetails = newValue;
        this.scaffoldRequest.scaffoldID = design.scaffoldID;
        this.scaffoldRequest.scaffoldDesignID = design.id;

        this.scaffoldRequest.areaID = this.allVisibleAreas.find(x => x.id == design.areaID)?.id;
        this.scaffoldRequest.subAreaID = this.allVisibleSubAreas.find(
          x => x.id == design.subAreaID
        )?.id;
        this.scaffoldRequest.specificWorkLocation = design.specificWorkLocation;
        this.dataSetByDesign = true;
        this.dataSetByScaffold = false;
      }
    },
    "scaffoldRequest.requestType": function(newValue, oldValue) {
      // If there was a selected value, confirm it's in the new data.  If not, clear out the value
      if (newValue == 1) this.selectedScaffold = null;

      // The request type has changed, reset the `isWalkdownRequired` based on the environment settings.
      // This may "undo" a user turning on walkdown for an optional type, but we need to make sure it doesn't get stuck on when moving away from a walkdown-mandatory type
      this.scaffoldRequest.isWalkdownRequired = this.defaultScaffoldRequestRequiresWalkDown;
    },
    "scaffoldRequest.areaID": function(newValue, oldValue) {
      // If there was a selected value, confirm it's in the new data.  If not, clear out the value
      if (
        !valueInArray(
          this.scaffoldRequest.subAreaID,
          this.subAreas.map(x => x.id)
        )
      ) {
        this.scaffoldRequest.subAreaID = null;
      }
    },
    "scaffoldRequest.requestingContractorID": function(newValue, oldValue) {
      // If there was a selected value, confirm it's in the new data.  If not, clear out the value
      if (
        !valueInArray(
          this.scaffoldRequest.disciplineID,
          this.disciplines.map(x => x.id)
        )
      ) {
        this.scaffoldRequest.disciplineID = null;
      }

      // If there was a selected value, confirm it's in the new data.  If not, clear out the value
      if (
        !valueInArray(
          this.scaffoldRequest.requestingEmployeeID,
          this.requestors.map(x => x.id)
        )
      ) {
        this.scaffoldRequest.requestingEmployeeID = undefined;
      }

      // If there was a selected value, confirm it's in the new data.  If not, clear out the value
      if (
        !valueInArray(
          this.scaffoldRequest.areaID,
          this.areas.map(x => x.id)
        )
      ) {
        this.scaffoldRequest.areaID = null;
      }
      if (
        !valueInArray(
          this.scaffoldRequest.subAreaID,
          this.subAreas.map(x => x.id)
        )
      ) {
        this.scaffoldRequest.subAreaID = null;
      }
      if (this.$store.state.curEnvironment?.enablePurchaseOrders == true) {
        if (
          !!this.scaffoldRequest.purchaseOrderID &&
          this.selectablePurchaseOrders.findIndex(
            x => x.id == this.scaffoldRequest.purchaseOrderID
          ) == -1
        ) {
          this.scaffoldRequest.purchaseOrderID = null;
        }

        if (!this.scaffoldRequest.purchaseOrderID && this.selectablePurchaseOrders.length == 1) {
          this.scaffoldRequest.purchaseOrderID = this.selectablePurchaseOrders[0].id;
        }
      }
    },
    "scaffoldRequest.disciplineID": function(newValue, oldValue) {
      // If there was a selected value, confirm it's in the new data.  If not, clear out the value
      if (
        !valueInArray(
          this.scaffoldRequest.requestingEmployeeID,
          this.requestors.map(x => x.id)
        )
      ) {
        this.scaffoldRequest.requestingEmployeeID = undefined;
      }
    }
  },

  created: async function() {}
});

export default ScaffoldRequestNewDialog;

export enum RequestTypeCategory {
  Scaffold,
  Maintenance,
  Paint,
  Insulation
}
export async function createNewScaffoldRequest(
  category: RequestTypeCategory,
  options?: {}
): Promise<boolean> {
  let dialog = createDialog(ScaffoldRequestNewDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open(category);
}

