import FDVue from "@fd/lib/vue";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import { mapActions, mapMutations } from "vuex";
import {
  addDaysToDate,
  addMonthsToDate,
  stripTimeFromLocalizedDateTime
} from "../../../lib/client-util/datetime";
import userAccess from "../dataMixins/userAccess";
import { jobService, JobWithData, Tag, WorkOrderWithNames } from "../services";
import { filterByTags } from "../services/taggableItems";
import archivedDataList from "../dataMixins/archivedDataList";

export type FormattedJob = JobWithData & {
  formattedInternalNumber: string;
  formattedWorkOrderNumber: string;
  latestWorkOrder: WorkOrderWithNames | undefined;
  latestWorkOrderAreaName: string | null | undefined;
  latestWorkOrderSubAreaName: string | null | undefined;
  latestWorkOrderStarted: Date | null | undefined;
  latestWorkOrderCompleted: Date | null | undefined;
  archived: boolean;
};
export function FormatJobData(job: JobWithData): FormattedJob {
  let latestWorkOrder = GetLatestWorkOrderInList(job.relatedWorkOrders);

  return {
    ...job,
    formattedInternalNumber: `00000${job.internalNumber}`.slice(-5),
    formattedWorkOrderNumber: !!latestWorkOrder
      ? "WO-" + `00000${latestWorkOrder.internalNumber}`.slice(-5)
      : "",
    latestWorkOrder: latestWorkOrder,
    latestWorkOrderAreaName: latestWorkOrder?.areaName,
    latestWorkOrderSubAreaName: latestWorkOrder?.subAreaName,
    latestWorkOrderStarted: latestWorkOrder?.startDate,
    latestWorkOrderCompleted: latestWorkOrder?.completedDate,
    archived: !!job.archivedDate
  };
}
function GetLatestWorkOrderInList(
  list: WorkOrderWithNames[] | undefined
): WorkOrderWithNames | undefined {
  if (!list?.length) return undefined;
  return list.sort((a, b) => {
    let aCompleted = a.completedDate ?? new Date(2999, 12, 31);
    let bCompleted = b.completedDate ?? new Date(2999, 12, 31);
    if (aCompleted.getTime() != bCompleted.getTime())
      return bCompleted.getTime() - aCompleted.getTime();

    let aStarted = a.startDate ?? new Date(2999, 12, 31);
    let bStarted = b.startDate ?? new Date(2999, 12, 31);
    if (aStarted.getTime() != bStarted.getTime()) return bStarted.getTime() - aStarted.getTime();

    return 0;
  })[0];
}

export default FDVue.extend({
  name: "fd-paint-jobs-list",
  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },
  mixins: [archivedDataList, userAccess],
  data: function() {
    return {
      // Used to track the the auto-reload for the table data
      reloadTimer: null as NodeJS.Timeout | null,
      dataReloadMinutes: 5,

      tablesearch: "",
      allJobs: [] as FormattedJob[]
    };
  },
  computed: {
    jobs(): FormattedJob[] {
      let jobs = filterByTags(this.tagsSelectedForFiltering, this.allJobs);
      return jobs;
    },
    tagsInUse(): Tag[] {
      return this.$store.getters.getSortedInUseTags(this.allJobs);
    },

    tagsSelectedForFiltering: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.tagsForFiltering;
      },
      set(val) {
        this.$store.commit("SET_TAGS_FOR_FILTERING", val);
      }
    }
  },
  methods: {
    ...mapActions({}),
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    async loadData() {
      if (this.reloadTimer) {
        clearTimeout(this.reloadTimer);
      }
      this.allJobs = (
        await jobService.getPaintJobList(
          false,
          this.showArchived,
          this.showArchivedFromDate,
          this.showArchivedToDate
        )
      ).map(x => FormatJobData(x));
      let _this = this;
      this.reloadTimer = setTimeout(async function() {
        _this.reloadTableData();
      }, _this.dataReloadMinutes * 60 * 1000);
    },
    async reloadTableData() {
      this.processing = true;
      try {
        await this.loadData();
        this.inlineMessage.message = "";
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    formatDate(date: Date | string | null | undefined): string {
      return stripTimeFromLocalizedDateTime(date);
    }
  },
  created: async function() {
    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    var toDate = addDaysToDate(null, 0);
    this.setFilteringContext({
      context: "paintjobs",
      parentalContext: null,
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: addMonthsToDate(toDate, -2),
      showArchivedForFilteringToDate: toDate,
      searchStringForFiltering: "",
      tagsForFiltering: [],
      statusesForFiltering: [],
      contractorsForFiltering: [],
      areasForFiltering: [],
      subAreasForFiltering: []
    });

    this.notifyNewBreadcrumb({
      text: this.$t("job.paint.list.title"),
      to: "/paint",
      resetHistory: true
    });

    this.processing = true;
    try {
      await this.$store.dispatch("LOAD_TAGS");
      await this.loadData();
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  },
  beforeDestroy() {
    if (this.reloadTimer) {
      clearTimeout(this.reloadTimer);
    }
  }
});

