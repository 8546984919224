var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "data-cy": "worktypes" } },
    [
      _vm.inlineMessage.message
        ? _c(
            "v-alert",
            {
              staticClass: "mt-0 mb-3",
              attrs: { type: _vm.inlineMessage.type }
            },
            [_vm._v("\n    " + _vm._s(_vm.inlineMessage.message) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "fd-view no-tag-searching" },
        [
          _c(
            "fd-toolbar",
            {
              attrs: { loading: _vm.processing },
              scopedSlots: _vm._u([
                {
                  key: "extension",
                  fn: function() {
                    return [
                      _c("fd-add-button", {
                        attrs: {
                          disabled: !_vm.currentUserCanConfigureSettings,
                          action: _vm.openNewDialog
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-breadcrumbs", {
                        class: [
                          _vm.processing ? "breadcrumb-processing-opacity" : "",
                          "pl-0"
                        ],
                        attrs: {
                          items: _vm.$store.state.currentBreadcrumbs,
                          large: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "divider",
                            fn: function() {
                              return [
                                _c("v-icon", [_vm._v("fa-chevron-right")])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-subtitle",
            { staticStyle: { "margin-top": "15px" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", sm: "12", md: "12", lg: "12" }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "pa-0 ma-0",
                          attrs: { align: "center", justify: "center" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-5 mr-5 ml-1 ml-sm-0",
                              attrs: {
                                small: "",
                                rounded: "",
                                outlined: "",
                                color: "#7a7a7a"
                              },
                              on: { click: _vm.reloadTableData }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    small: "",
                                    left: _vm.$vuetify.breakpoint.smAndUp
                                  }
                                },
                                [_vm._v("mdi-reload")]
                              ),
                              _vm._v(" "),
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.$t("common.reload")))
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-text-field", {
                            staticClass: "mt-1",
                            attrs: {
                              "append-icon": "search",
                              label: _vm.$t("common.search"),
                              "single-line": "",
                              "hide-details": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.tablesearch,
                              callback: function($$v) {
                                _vm.tablesearch = $$v
                              },
                              expression: "tablesearch"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            directives: [
              { name: "fd-table-sortable", rawName: "v-fd-table-sortable" },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:[iconColumnArgument].no-sort.class_fd-sm-table-icon-cell.hide-when-small",
                value: "",
                expression: "''",
                arg: _vm.iconColumnArgument,
                modifiers: {
                  "no-sort": true,
                  "class_fd-sm-table-icon-cell": true,
                  "hide-when-small": true
                }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:name.no-sort",
                arg: "name",
                modifiers: { "no-sort": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:code.no-sort",
                arg: "code",
                modifiers: { "no-sort": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:isDirect.no-sort",
                value: _vm.$t("common.category"),
                expression: "$t('common.category')",
                arg: "isDirect",
                modifiers: { "no-sort": true }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:archived.no-sort.hide-when-extra-small.hide-when-header-text-empty",
                value: _vm.showArchived ? _vm.$t("common.archived") : "",
                expression:
                  "\n        showArchived ? $t('common.archived') : ''\n      ",
                arg: "archived",
                modifiers: {
                  "no-sort": true,
                  "hide-when-extra-small": true,
                  "hide-when-header-text-empty": true
                }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:actions.no-sort.hide-when-extra-small.class_fd-actions-cell",
                arg: "actions",
                modifiers: {
                  "no-sort": true,
                  "hide-when-extra-small": true,
                  "class_fd-actions-cell": true
                }
              },
              {
                name: "fd-row-navigate",
                rawName: "v-fd-row-navigate.no-sort.show-when-extra-small",
                value: item => _vm.$router.push(`/worktypes/${item.id}`),
                expression:
                  "\n        item => $router.push(`/worktypes/${item.id}`)\n      ",
                modifiers: { "no-sort": true, "show-when-extra-small": true }
              }
            ],
            class: [
              { "fd-archive-bonus-padding-bottom": _vm.showArchived },
              "fd-enabling-table"
            ],
            attrs: {
              "data-cy": "list",
              items: _vm.workTypes,
              search: _vm.tablesearch,
              loading: _vm.processing,
              "loading-text": _vm.$t("common.table-loading-message"),
              "mobile-breakpoint": "0"
            },
            on: { "sort:end": _vm.dragEnded },
            scopedSlots: _vm._u([
              {
                key: "item.hasMisconfiguredSubTypes",
                fn: function({ item }) {
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { right: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function({ on }) {
                                return [
                                  item.hasMisconfiguredSubTypes
                                    ? _c(
                                        "v-icon",
                                        _vm._g(
                                          {
                                            attrs: { small: "", color: "error" }
                                          },
                                          on
                                        ),
                                        [_vm._v("fas fa-exclamation-circle")]
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t(
                                "work-types.list.has-misconfigured-work-sub-type-error-message"
                              )
                            ) +
                            "\n        "
                        )
                      ]
                    )
                  ]
                }
              },
              {
                key: "item.fd-drag",
                fn: function() {
                  return [
                    _c("v-icon", { attrs: { small: "" } }, [
                      _vm._v("far fa-grip-lines")
                    ])
                  ]
                },
                proxy: true
              },
              {
                key: "footer.prepend",
                fn: function() {
                  return [
                    _c("fd-archived-data-loader", {
                      staticClass: "ml-2 mr-3",
                      attrs: {
                        showArchived: _vm.showArchived,
                        showArchivedDateRange: _vm.showArchivedDateRange,
                        showArchivedMinDate: _vm.showArchivedMinDate,
                        showArchivedMaxDate: _vm.showArchivedMaxDate,
                        loading: _vm.archivedLoading,
                        disabled: _vm.processing,
                        "hide-date-range-picker": ""
                      },
                      on: {
                        "input:showArchived": v => (_vm.showArchived = v),
                        "input:showArchivedDateRange": v =>
                          (_vm.showArchivedDateRange = v)
                      }
                    })
                  ]
                },
                proxy: true
              },
              {
                key: "item.fd-nav",
                fn: function() {
                  return [_c("v-icon", [_vm._v("mdi-chevron-right")])]
                },
                proxy: true
              },
              {
                key: "item.isDirect",
                fn: function({ item }) {
                  return [
                    !!item.isDirect
                      ? _c("fd-checkbox", {
                          attrs: {
                            value: true,
                            disabled: _vm.processing,
                            readonly: true,
                            "on-text": _vm.$t("common.direct"),
                            "on-color": "#898F9E",
                            "on-text-color": "white"
                          }
                        })
                      : !!item.isPerDiem
                      ? _c("fd-checkbox", {
                          attrs: {
                            value: item.isPerDiem,
                            disabled: _vm.processing,
                            readonly: true,
                            "on-text": _vm.$t("common.per-diem"),
                            "on-color": "#898F9E",
                            "on-text-color": "white"
                          }
                        })
                      : !!item.isEquipment
                      ? _c("fd-checkbox", {
                          attrs: {
                            value: item.isEquipment,
                            disabled: _vm.processing,
                            readonly: true,
                            "on-text": _vm.$t("common.equipment"),
                            "on-color": "#898F9E",
                            "on-text-color": "white"
                          }
                        })
                      : _c("fd-checkbox", {
                          attrs: {
                            value: true,
                            disabled: _vm.processing,
                            readonly: true,
                            "on-text": _vm.$t("common.indirect"),
                            "on-color": "#898F9E",
                            "on-text-color": "white"
                          }
                        })
                  ]
                }
              },
              {
                key: "item.archived",
                fn: function({ item }) {
                  return [
                    _vm.showArchived
                      ? _c(
                          "div",
                          [
                            _c("v-simple-checkbox", {
                              attrs: {
                                value: item.archived,
                                disabled: _vm.processing
                              },
                              on: {
                                input: function($event) {
                                  return _vm.flipArchived(item)
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              },
              {
                key: "item.actions",
                fn: function({ item }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "table-2-actions-column-min-width" },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: {
                              disabled:
                                !_vm.$vuetify.breakpoint.lgAndUp ||
                                _vm.processing,
                              left: ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              icon: "",
                                              link: "",
                                              disabled: _vm.processing,
                                              to: `/worktypes/${item.id}`
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [
                                              _vm._v(
                                                "\n                  mdi-pencil\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(_vm.$t("common.edit")))])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-tooltip",
                          {
                            attrs: {
                              disabled:
                                !_vm.$vuetify.breakpoint.lgAndUp ||
                                _vm.processing,
                              top: ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              icon: "",
                                              disabled: _vm.processing
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                small: "",
                                                disabled: _vm.processing
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteTableItem(
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  mdi-delete\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("common.remove")))
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }