var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "pa-0",
      staticStyle: { background: "white" },
      attrs: { fluid: "", "data-cy": "scaffoldtypemodifiers" }
    },
    [
      _c(
        "v-card-subtitle",
        { staticStyle: { "margin-top": "15px" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "pt-0 pb-0 order-md-0 order-1",
                  attrs: { cols: "12", sm: "12", md: "7", lg: "7" }
                },
                [
                  _c(
                    "v-row",
                    {
                      attrs: {
                        "no-gutters": "",
                        align: "center",
                        justify: "center"
                      }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-0 mt-md-5 mr-5",
                          attrs: {
                            small: "",
                            rounded: "",
                            outlined: "",
                            color: "#7a7a7a"
                          },
                          on: { click: _vm.reloadData }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                small: "",
                                left: _vm.$vuetify.breakpoint.smAndUp
                              }
                            },
                            [_vm._v("mdi-reload")]
                          ),
                          _vm._v(" "),
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.$t("common.reload")))
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-text-field", {
                        ref: "search",
                        staticClass: "pt-0 mt-0 pt-md-3 mt-md-1",
                        attrs: {
                          "data-cy": "tablesearch",
                          "append-icon": "search",
                          label: _vm.$t("common.search"),
                          "single-line": "",
                          "hide-details": "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.tablesearch,
                          callback: function($$v) {
                            _vm.tablesearch = $$v
                          },
                          expression: "tablesearch"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  staticClass: "pt-0 pb-0 order-md-1 order-0",
                  attrs: { cols: "12", sm: "12", md: "5", lg: "5" }
                },
                [
                  _c("v-select", {
                    staticClass: "pt-md-3 pt-sm-3 pt-3",
                    attrs: {
                      multiple: "",
                      items: _vm.allScaffoldTypes,
                      clearable: "",
                      label: _vm.$t(
                        "estimate-setup.tabs.scaffold-types.filter-by-scaffold-type-label"
                      )
                    },
                    model: {
                      value: _vm.filterByScaffoldTypeIDs,
                      callback: function($$v) {
                        _vm.filterByScaffoldTypeIDs = $$v
                      },
                      expression: "filterByScaffoldTypeIDs"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-data-table", {
        directives: [
          { name: "fd-column", rawName: "v-fd-column:name", arg: "name" },
          {
            name: "fd-column",
            rawName: "v-fd-column:scaffoldTypeID.hide-when-medium",
            value: _vm.$t(
              "scheduler.estimates.components-panel.scaffold-type-label"
            ),
            expression:
              "\n      $t('scheduler.estimates.components-panel.scaffold-type-label')\n    ",
            arg: "scaffoldTypeID",
            modifiers: { "hide-when-medium": true }
          },
          {
            name: "fd-column",
            rawName: "v-fd-column:scaffoldSubTypeID",
            value: _vm.$t(
              "scheduler.estimates.components-panel.scaffold-sub-type-label"
            ),
            expression:
              "\n      $t('scheduler.estimates.components-panel.scaffold-sub-type-label')\n    ",
            arg: "scaffoldSubTypeID"
          },
          {
            name: "fd-column",
            rawName: "v-fd-column:modifier",
            value: _vm.$t("modifiers.modifier-column-label"),
            expression: "$t('modifiers.modifier-column-label')",
            arg: "modifier"
          },
          {
            name: "fd-column",
            rawName: "v-fd-column:isActive",
            value: _vm.$t(
              "estimate-setup.tabs.scaffold-types.is-active-column-label"
            ),
            expression:
              "$t('estimate-setup.tabs.scaffold-types.is-active-column-label')",
            arg: "isActive"
          },
          {
            name: "fd-column",
            rawName:
              "v-fd-column:action.no-sort.hide-when-extra-small.class_fd-actions-cell",
            arg: "action",
            modifiers: {
              "no-sort": true,
              "hide-when-extra-small": true,
              "class_fd-actions-cell": true
            }
          },
          {
            name: "fd-row-navigate",
            rawName: "v-fd-row-navigate.show-when-extra-small",
            value: item =>
              _vm.$router.push(
                `/estimatesetup/scaffoldtypemodifier/${item.id}`
              ),
            expression:
              "\n      item => $router.push(`/estimatesetup/scaffoldtypemodifier/${item.id}`)\n    ",
            modifiers: { "show-when-extra-small": true }
          }
        ],
        class: ["fd-actions-table"],
        attrs: {
          loading: _vm.processing,
          "loading-text": _vm.$t("common.table-loading-message"),
          "mobile-breakpoint": "0",
          items: _vm.scaffoldTypeModifierDetails,
          search: _vm.tablesearch,
          "sort-by": "scaffoldSubTypeID"
        },
        scopedSlots: _vm._u([
          {
            key: "item.isActive",
            fn: function({ item }) {
              return [
                _c("v-simple-checkbox", {
                  attrs: { value: item.isActive, disabled: _vm.processing },
                  on: {
                    input: function($event) {
                      return _vm.flipActive(item)
                    }
                  }
                })
              ]
            }
          },
          {
            key: "item.name",
            fn: function({ item }) {
              return [_c("fd-value-display", { attrs: { value: item.name } })]
            }
          },
          {
            key: "item.scaffoldTypeID",
            fn: function({ item }) {
              return [
                _c("fd-value-display", {
                  attrs: {
                    value:
                      !!item.scaffoldTypeID || item.scaffoldTypeID == 0
                        ? _vm.$t(
                            `work-order-estimates.scaffold-types.${item.scaffoldTypeID}`
                          )
                        : undefined
                  }
                })
              ]
            }
          },
          {
            key: "item.scaffoldSubTypeID",
            fn: function({ item }) {
              return [
                _c("fd-value-display", {
                  attrs: {
                    value:
                      !!item.scaffoldSubTypeID || item.scaffoldSubTypeID == 0
                        ? _vm.$t(
                            `work-order-estimates.scaffold-sub-types.${item.scaffoldSubTypeID}`
                          )
                        : undefined
                  }
                })
              ]
            }
          },
          {
            key: "item.modifier",
            fn: function({ item }) {
              return [
                _c("fd-value-display", {
                  attrs: { value: item.modifier, "no-value-text": "0.00" }
                })
              ]
            }
          },
          {
            key: "item.action",
            fn: function({ item }) {
              return [
                _c(
                  "div",
                  { staticClass: "table-1-action-column-min-width" },
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function({ on }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        attrs: {
                                          icon: "",
                                          link: "",
                                          disabled: _vm.processing,
                                          to: `/estimatesetup/scaffoldtypemodifier/${item.id}`
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v(
                                          "\n                mdi-pencil\n              "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.$t("common.edit")))])
                      ]
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }