var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    { staticClass: "mx-2 pt-3", attrs: { align: "center" } },
    [
      _c(
        "v-col",
        { staticClass: "pa-0", attrs: { cols: "12" } },
        [
          !!_vm.countSheetGroupID
            ? _c(
                "v-row",
                { staticClass: "ml-0 mr-0" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12" } },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "pl-3 pr-0 pt-0 pb-0" },
                        [
                          _c("v-switch", {
                            attrs: {
                              label: _vm.$t(
                                "countsheetgroups.parts.only-included-parts-label"
                              )
                            },
                            model: {
                              value: _vm.showOnlyIncludedParts,
                              callback: function($$v) {
                                _vm.showOnlyIncludedParts = $$v
                              },
                              expression: "showOnlyIncludedParts"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function({ on }) {
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            {
                                              staticClass: "ml-4",
                                              attrs: {
                                                color: "black",
                                                dark: ""
                                              }
                                            },
                                            on
                                          ),
                                          [_vm._v("info")]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                583222350
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "countsheetgroups.parts.parts-selection-sub-menu-information"
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mr-0 ml-0" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      "append-icon": "search",
                      label: "Search",
                      "single-line": "",
                      "hide-details": "",
                      clearable: ""
                    },
                    model: {
                      value: _vm.partsTableSearch,
                      callback: function($$v) {
                        _vm.partsTableSearch = $$v
                      },
                      expression: "partsTableSearch"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-data-table", {
        directives: [
          {
            name: "fd-column",
            rawName: "v-fd-column:publicID",
            value: _vm.$t("common.code"),
            expression: "$t('common.code')",
            arg: "publicID"
          },
          {
            name: "fd-column",
            rawName: "v-fd-column:name",
            value: _vm.$t("common.name"),
            expression: "$t('common.name')",
            arg: "name"
          },
          {
            name: "fd-column",
            rawName: "v-fd-column:description.hide-when-extra-small",
            value: _vm.$t("common.description"),
            expression: "$t('common.description')",
            arg: "description",
            modifiers: { "hide-when-extra-small": true }
          },
          {
            name: "fd-column",
            rawName: "v-fd-column:include.no-sort",
            value: _vm.$t("common.include"),
            expression: "$t('common.include')",
            arg: "include",
            modifiers: { "no-sort": true }
          }
        ],
        ref: "partsDataTable",
        staticClass: "fixed-header v-table__overflow",
        staticStyle: {
          "max-height": "calc(50vh - 100px)",
          "backface-visibility": "hidden"
        },
        attrs: {
          items: _vm.displayedParts,
          search: _vm.partsTableSearch,
          loading: _vm.loading,
          "loading-text": _vm.$t("common.table-loading-message"),
          "sort-by": ["name"],
          "mobile-breakpoint": "0"
        },
        scopedSlots: _vm._u([
          {
            key: "item.include",
            fn: function({ item }) {
              return [
                !_vm.allowTakingOtherGroupParts || _vm.itemCanBeSelected(item)
                  ? _c("fd-checkbox", {
                      attrs: {
                        value: item.selected,
                        disabled: !_vm.itemCanBeSelected(item) || _vm.loading,
                        readonly: !_vm.itemCanBeSelected(item),
                        "off-text": item.countSheetGroupName
                      },
                      on: {
                        input: function($event) {
                          return _vm.flipPartSelected(item)
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.allowTakingOtherGroupParts && !_vm.itemCanBeSelected(item)
                  ? _c(
                      "v-chip",
                      {
                        attrs: {
                          close: !!_vm.countSheetGroupID,
                          "close-icon": "fal fa-check-square"
                        },
                        on: {
                          "click:close": function($event) {
                            return _vm.flipPartSelected(item)
                          }
                        }
                      },
                      [_vm._v(_vm._s(item.countSheetGroupName))]
                    )
                  : _vm._e()
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }