var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "800px", persistent: "" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-card",
        { ref: "content", attrs: { "data-cy": "addtimesheetentriesdialog" } },
        [
          _c(
            "v-card-title",
            {
              class: [
                _vm.processing
                  ? "dialog-new-top-color-processing"
                  : "dialog-new-top-color"
              ]
            },
            [
              _c(
                "span",
                {
                  class: [_vm.processing ? "breadcrumb-processing-opacity" : ""]
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("norms.work-order-entry-dialog.title")) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "font-size": "12px", "line-height": "1.5" } },
                [
                  !!_vm.workOrder.internalNumber
                    ? _c(
                        "v-chip",
                        {
                          staticClass: "ml-2 fd-status-chip",
                          attrs: { small: !_vm.$vuetify.breakpoint.lgAndUp }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "timesheets.entries.dialog-title-wo-number",
                                  [_vm.workOrder.internalNumber]
                                )
                              ) +
                              " -\n          " +
                              _vm._s(
                                _vm.$t(
                                  "timesheets.entries.dialog-title-request-type",
                                  [
                                    _vm.workOrder.requestTypeName,
                                    _vm.workOrder.requestSubTypeName
                                  ]
                                )
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !!_vm.workOrder.scaffoldNumber
                    ? _c(
                        "v-chip",
                        {
                          staticClass: "ml-2 fd-status-chip",
                          attrs: { small: !_vm.$vuetify.breakpoint.lgAndUp }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "timesheets.entries.dialog-title-tag-number",
                                  [_vm.workOrder.scaffoldNumber]
                                )
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.processing
                ? _c("v-progress-circular", {
                    attrs: {
                      indeterminate: true,
                      rotate: 0,
                      size: 32,
                      width: 4,
                      color: "white"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("sp-build-sheet-form", {
            attrs: {
              scaffoldTypeModifiers: _vm.allScaffoldTypes,
              scaffoldElevationModifiers: _vm.allScaffoldElevations,
              totalTimesheetHours: _vm.totalTimesheetHours,
              processing: _vm.processing,
              normsWithResponses: _vm.normsWithResponses,
              length: _vm.workOrder.buildSheetLength,
              width: _vm.workOrder.buildSheetWidth,
              height: _vm.workOrder.buildSheetHeight,
              deckLevels: _vm.workOrder.buildSheetDeckLevels,
              hazardStatusResult: _vm.workOrder.buildSheetHazardLevel,
              confinedSpace: _vm.workOrder.buildSheetConfinedSpace,
              ppeRequired: _vm.workOrder.buildSheetPpeRequired,
              scaffoldTypeModifierID:
                _vm.workOrder.buildSheetScaffoldTypeModifierID,
              scaffoldElevationModifierID:
                _vm.workOrder.buildSheetScaffoldElevationModifierID,
              notes: _vm.workOrder.buildSheetNotes,
              vlfChangeAmount: _vm.workOrder.vlfChangeAmount,
              currentScaffoldVlf: _vm.workOrder.currentScaffoldVlf,
              scaffoldTotalVLF: _vm.workOrder.scaffoldTotalVLF,
              scaffoldRequestType: _vm.workOrder.scaffoldRequestType,
              canEditBuildSheetResponses:
                _vm.workOrder.currentUserPermissions.canEditBuildSheetResponses
            },
            on: {
              "update:normsWithResponses": function($event) {
                _vm.normsWithResponses = $event
              },
              "update:norms-with-responses": function($event) {
                _vm.normsWithResponses = $event
              },
              "update:length": function($event) {
                return _vm.$set(_vm.workOrder, "buildSheetLength", $event)
              },
              "update:width": function($event) {
                return _vm.$set(_vm.workOrder, "buildSheetWidth", $event)
              },
              "update:height": function($event) {
                return _vm.$set(_vm.workOrder, "buildSheetHeight", $event)
              },
              "update:deckLevels": function($event) {
                return _vm.$set(_vm.workOrder, "buildSheetDeckLevels", $event)
              },
              "update:deck-levels": function($event) {
                return _vm.$set(_vm.workOrder, "buildSheetDeckLevels", $event)
              },
              "update:hazardStatusResult": function($event) {
                return _vm.$set(_vm.workOrder, "buildSheetHazardLevel", $event)
              },
              "update:hazard-status-result": function($event) {
                return _vm.$set(_vm.workOrder, "buildSheetHazardLevel", $event)
              },
              "update:confinedSpace": function($event) {
                return _vm.$set(
                  _vm.workOrder,
                  "buildSheetConfinedSpace",
                  $event
                )
              },
              "update:confined-space": function($event) {
                return _vm.$set(
                  _vm.workOrder,
                  "buildSheetConfinedSpace",
                  $event
                )
              },
              "update:ppeRequired": function($event) {
                return _vm.$set(_vm.workOrder, "buildSheetPpeRequired", $event)
              },
              "update:ppe-required": function($event) {
                return _vm.$set(_vm.workOrder, "buildSheetPpeRequired", $event)
              },
              "update:scaffoldTypeModifierID": function($event) {
                return _vm.$set(
                  _vm.workOrder,
                  "buildSheetScaffoldTypeModifierID",
                  $event
                )
              },
              "update:scaffold-type-modifier-i-d": function($event) {
                return _vm.$set(
                  _vm.workOrder,
                  "buildSheetScaffoldTypeModifierID",
                  $event
                )
              },
              "update:scaffoldElevationModifierID": function($event) {
                return _vm.$set(
                  _vm.workOrder,
                  "buildSheetScaffoldElevationModifierID",
                  $event
                )
              },
              "update:scaffold-elevation-modifier-i-d": function($event) {
                return _vm.$set(
                  _vm.workOrder,
                  "buildSheetScaffoldElevationModifierID",
                  $event
                )
              },
              "update:notes": function($event) {
                return _vm.$set(_vm.workOrder, "buildSheetNotes", $event)
              },
              "update:vlfChangeAmount": function($event) {
                return _vm.$set(_vm.workOrder, "vlfChangeAmount", $event)
              },
              "update:vlf-change-amount": function($event) {
                return _vm.$set(_vm.workOrder, "vlfChangeAmount", $event)
              }
            }
          }),
          _vm._v(" "),
          _vm.inlineMessage.message
            ? _c(
                "v-row",
                { staticClass: "mx-0", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _vm.inlineMessage.message
                        ? _c(
                            "v-alert",
                            { attrs: { type: _vm.inlineMessage.type } },
                            [
                              _c(
                                "span",
                                { staticStyle: { "white-space": "pre-line" } },
                                [_vm._v(_vm._s(_vm.inlineMessage.message))]
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { text: "", small: _vm.$vuetify.breakpoint.xsOnly },
                  on: { click: _vm.cancelDialog }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("common.cancel")) + "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    "data-cy": "save-draft",
                    color: "primary",
                    disabled: _vm.processing,
                    small: _vm.$vuetify.breakpoint.xsOnly
                  },
                  on: { click: _vm.saveDialog }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("common.save-and-close")) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }