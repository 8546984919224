import Vue from 'vue'
import VueI18n from 'vue-i18n'

const en = require('vuetify/lib/locale/en').default
const es = require('vuetify/lib/locale/es').default

export enum LANGUAGES {
  ENGLISH,
  SPANISH
}

Vue.use(VueI18n)

// Set the locale for usage in "moment" date formatting library.
// moment currently does NOT come loaded with objects meant for other countries/languages so if
// we wish to use this functionality we will need to populate the library as appropriate. 
// Something we can address later.
//
// moment.locale(process.env.VUE_APP_I18N_LOCALE || 'en');

const messages = {
  en: {
    ...require('./locales/en.json'), 
    $vuetify: en,
  },
  es: {
    ...require('./locales/es.json'),
    $vuetify: es,
  },
}

// Created by Formidable Designs to hold the fully qualified internationalization "locale".
// This is consumed by views to dictate to relevant controls how to modify themselves as appropriate.
export function fdI18nFullyQualifiedLocale() {
  return "es-us";
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages
})
